import React, { useState } from 'react'
import { SearchableTable } from "../../../modules/RecycleBin/RecycleBinImports";
import { JobDataModel } from '../../../Models/Api/Job/JobDataModel';
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import signalRBuilder from '../../../Api/Socket/signalRBuilder';
import signalREventListenerDictionary from '../../../Models/SignalR/signalREventListenerDictionary';
import { SignalRDataModel } from '../../../Models/SignalR/SignalRDataModel';
import SocketEmitWrapper from '../../../services/SocketEmitWrapper/SocketEmitWrapper';
import SignalRHubEnum from '../../../Models/SignalR/SignalRHubEnum';
import HttpRequestWithForbiddenCheck from '../../../services/HttpRequestWithForbiddenCheck/HttpRequestWithForbiddenCheck';
//import { JobDataModelArrZod } from '../../../Models/Zod/Job/JobDataModel';
import Icons from '../../../Imports/MUI/Icons/IconsImports';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../Store/Store';
import { pdmAuthorize, pdmUnauthorize } from '../../../Store/Actions/Actions';
import { MainButton, PermissionsWrapper } from '../../../components/General/GeneralExport';
import { AuthorizationService, JobManagerService, UserService } from '../../../Api/Rest/PDMServer/ApiServices/PDMApiServicesImports';
import PermissionsEnum from "../../../Models/Permissions/PermissionsEnum";
import FiltersSessionStorageService, { SessionStorageFiltersEnum } from '../../../services/SessionStorage/Filters/FiltersSessionStorageService';

function RecycleBin() {
    const [DeletedJobs, setDeletedJobs] = React.useState<JobDataModel[]>([]);
    const [t] = useTranslation();
    const [OpenDeleteAllDialog, setOpenDeleteAllDialog] = React.useState(false);
    const [OpenDeleteJobDialog, setOpenDeleteJobDialog] = React.useState(false);
    const [CurrentDeleteJobNameInDialog, setCurrentDeleteJobNameInDialog] = React.useState('');
    const [CurrentDeleteJobIdInDialog, setCurrentDeleteJobIdInDialog] = React.useState('');
    const dispatch = useDispatch();
    const pdmAuthState = useSelector((state: RootState) => state.PdmAuth);
    const history = useHistory();
    const [enterGroup, setEnterGroup] = React.useState(false);
    const [PermissionsList, setPermissionsList] = useState<string[] | null>(null);
    const userService = UserService.getInstance();
    const [filter, setFilter] = React.useState<string>('');

    React.useEffect(() => {
        asyncInit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        // When leaving the page the return will emit and remove user from group
        return () => {
            if (enterGroup) leaveGroup()
        };
    }, [enterGroup])

    const asyncInit = async () => {
        const pdmAuthorized = await AuthorizationService.getInstance().checkApi();

        if (pdmAuthorized.status === 200) {
            if (!pdmAuthorized.data) {
                if (pdmAuthState !== pdmAuthorized.data) {
                    dispatch(pdmUnauthorize());
                }
                history.push('/AuthorizePDM');
            }
            if (pdmAuthState !== pdmAuthorized.data) {
                dispatch(pdmAuthorize());
            }

            Init();
            signalRInit();
            setEnterGroup(true);

            let sessionStorageFilter =
                FiltersSessionStorageService.GetSessionStorageFilter(SessionStorageFiltersEnum.RECYCLE_BIN_FILTER);

            if (sessionStorageFilter) {
                setFilter(sessionStorageFilter);
            }
        }

        const [permissionsResp, permissionsErr] =
            await HttpRequestWithForbiddenCheck(userService.getUserPermissions(),
                'Get User Permissions', 'Error When Getting User Permissions');

        if (!permissionsErr && permissionsResp !== null) {
            setPermissionsList(permissionsResp);
        }
    };

    const leaveGroup = async () => {
        const b = await signalRBuilder.getInstance(SignalRHubEnum.RECYCLE_BIN_HUB);
        // sign to job manager page group
        await SocketEmitWrapper(b.newConnection.send(signalREventListenerDictionary.LEAVE_RECYCLE_BIN_GROUP));
    };

    const signalRInit = async () => {
        const b = await signalRBuilder.getInstance(SignalRHubEnum.RECYCLE_BIN_HUB);
        // sign to job manager page group
        await SocketEmitWrapper(b.newConnection.send(signalREventListenerDictionary.JOIN_RECYCLE_BIN_GROUP));

        b.addEventListener(
            signalREventListenerDictionary.GET_DELETED_JOBS,
            (signalRModel: SignalRDataModel) => {
                const data = JSON.parse(signalRModel.Data);
                setDeletedJobs(data);
            }
        );
    };

    const Init = async () => {
        const [Jobs, error1] =
            await HttpRequestWithForbiddenCheck(JobManagerService.getInstance().getSoftDeleteJob(),
                'getSoftDeleteJob', 'Error When Getting The Soft Delete Jobs');

        if (!error1 && Jobs !== null) {
            setDeletedJobs(Jobs);
        };
    };

    const restoreJob = async (id: string) => {
        const [, error1] =
            await HttpRequestWithForbiddenCheck(JobManagerService.getInstance().restoreDeletedJob(id),
                'restoreDeletedJob', 'Error When Restoring The Job');

        if (!error1) {
            toast.success("Job was restored successfully", {
                style: { "backgroundColor": "#6f42c1" }
            });

            const [Jobs, error2] =
                await HttpRequestWithForbiddenCheck(JobManagerService.getInstance().getSoftDeleteJob(),
                    'getSoftDeleteJob', 'Error When Getting The Soft Delete Jobs');

            if (!error2 && Jobs !== null) {
                setDeletedJobs(Jobs);
            };
        }
    };

    const removeJob = async (id: string) => {
        const [, error1] =
            await HttpRequestWithForbiddenCheck(JobManagerService.getInstance().deleteJob(id),
                'deleteJob', 'Error When Deleting The Job');

        if (!error1) {
            toast.success("Job was removed successfully", {
                style: { "backgroundColor": "#6f42c1" }
            });

            const [Jobs, error2] =
                await HttpRequestWithForbiddenCheck(JobManagerService.getInstance().getSoftDeleteJob(),
                    'getSoftDeleteJob', 'Error When Getting The Soft Delete Jobs');

            if (!error2 && Jobs !== null) {
                setDeletedJobs(Jobs);
            };
        }
    };

    const removeAll = async () => {
        const [, error1] =
            await HttpRequestWithForbiddenCheck(JobManagerService.getInstance().deleteAll(),
                'deleteAll', 'Error When Deleting All');

        if (!error1) {
            toast.success("Job was removed successfully", {
                style: { "backgroundColor": "#6f42c1" }
            });

            const [Jobs, error2] =
                await HttpRequestWithForbiddenCheck(JobManagerService.getInstance().getSoftDeleteJob(),
                    'getSoftDeleteJob', 'Error When Getting The Soft Delete Jobs');

            if (!error2 && Jobs !== null) {
                setDeletedJobs(Jobs);
            };
        }
        else {
            toast.error("Job could not be removed", {
                style: { "backgroundColor": "#6f42c1" }
            });
        }
    };

    const handleCloseRemoveAllDialog = (answer: boolean) => {
        if (answer) {
            setOpenDeleteAllDialog(false);
            removeAll();
        }
        else {
            setOpenDeleteAllDialog(false);
        }
    };

    const handleCloseRemoveJobDialog = (answer: boolean) => {
        if (answer) {
            setOpenDeleteJobDialog(false);
            removeJob(CurrentDeleteJobIdInDialog);
        }
        else {
            setOpenDeleteJobDialog(false);
        }
    };

    const openDeleteJobDialog = (id: string, name: string) => {
        setCurrentDeleteJobNameInDialog(name);
        setCurrentDeleteJobIdInDialog(id);
        setOpenDeleteJobDialog(true);
    };

    const onSaveFilter = (filter: string) => {
        FiltersSessionStorageService.SetSessionStorageFilter(SessionStorageFiltersEnum.RECYCLE_BIN_FILTER, filter);
        setFilter(filter);
    }

    return (
        <>
            <Dialog
                open={OpenDeleteAllDialog}
                onClose={() => { handleCloseRemoveAllDialog(false); }}
            >
                <DialogTitle>{t("general.dialogs.deleteAllJobs.title")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t("general.dialogs.deleteAllJobs.content")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MainButton onClick={() => { handleCloseRemoveAllDialog(true); }}
                        color='error'
                        lightVariant="text">
                        {t("general.buttons.yes")}
                    </MainButton>
                    <MainButton onClick={() => { handleCloseRemoveAllDialog(false); }}
                        lightVariant="text">
                        {t("general.buttons.no")}
                    </MainButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={OpenDeleteJobDialog}
                onClose={() => { handleCloseRemoveJobDialog(false); }}
            >
                <DialogTitle>{t("general.dialogs.deleteSingleJob.title")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t("general.dialogs.deleteSingleJob.content")} {CurrentDeleteJobNameInDialog}?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MainButton onClick={() => { handleCloseRemoveJobDialog(true); }} color='error' lightVariant="text">
                        {t("general.buttons.yes")}
                    </MainButton>
                    <MainButton onClick={() => { handleCloseRemoveJobDialog(false); }} lightVariant="text">
                        {t("general.buttons.no")}
                    </MainButton>
                </DialogActions>
            </Dialog>

            <ul className="nav float-right">
                <PermissionsWrapper PermissionsList={PermissionsList}
                    Permission={PermissionsEnum.DELETE_JOB_MANAGER_UI}
                    PassedPermissionTemplate={
                        <MainButton style={{ 'marginLeft': '15px' }}
                            color="error" startIcon={<Icons.DeleteSweepIcon />}
                            onClick={() => DeletedJobs?.length > 0 && setOpenDeleteAllDialog(true)}>
                            {t("general.buttons.deleteAll")}
                        </MainButton>
                    }
                />
            </ul>
            <br />
            <br />
            <div>
                <SearchableTable
                    Data={DeletedJobs}
                    restoreJob={restoreJob}
                    removeJob={openDeleteJobDialog}
                    filter={filter}
                    onSaveFilter={onSaveFilter} />
            </div>
        </>
    )
}

export default RecycleBin;