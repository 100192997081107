import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import { MainButton, PermissionsWrapper } from '../../../../components/General/GeneralExport';
import { useTranslation } from "react-i18next";
import Icons from '../../../../Imports/MUI/Icons/IconsImports';
import { Grid } from '@mui/material';
import HttpRequestWithForbiddenCheck from '../../../../services/HttpRequestWithForbiddenCheck/HttpRequestWithForbiddenCheck';
import SearchableTable from '../../../../modules/UsersDashboard/Components/SearchableTable/SearchableTable';
import { UserApiDataModel } from '../../../../Models/Api/User/UserApiDataModel';
import { RootState } from '../../../../Store/Store';
import { useDispatch, useSelector } from 'react-redux';
import { pdmAuthorize, pdmUnauthorize } from '../../../../Store/Actions/Actions';
import AwaitHandler from "../../../../services/AwaitHandling/AwaitHandling";
import { AuthorizationService, UserService, userInfo } from '../../../../Api/Rest/PDMServer/ApiServices/PDMApiServicesImports';
import PermissionsEnum from "../../../../Models/Permissions/PermissionsEnum";
import FiltersSessionStorageService, { SessionStorageFiltersEnum } from "../../../../services/SessionStorage/Filters/FiltersSessionStorageService";

const UsersDashboard = () => {
    const [t] = useTranslation();
    const userService = UserService.getInstance();
    const history = useHistory();
    const [UsersList, setUsersList] = useState<UserApiDataModel[]>([]);
    const pdmAuthState = useSelector((state: RootState) => state.PdmAuth);
    const dispatch = useDispatch();
    const [PermissionsList, setPermissionsList] = useState<string[] | null>(null);
    const [filter, setFilter] = useState<string>('');

    useEffect(() => {
        initData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const moveToAddUserPage = () => {
        history.push("/UsersDashboard/CreateUser");
    };


    const initData = async () => {
        const pdmAuthorized = await AuthorizationService.getInstance().checkApi();

        if (pdmAuthorized.status === 200) {
            if (!pdmAuthorized.data) {
                if (pdmAuthState !== pdmAuthorized.data) {
                    dispatch(pdmUnauthorize());
                }

                history.push('/AuthorizePDM');
            }

            if (pdmAuthState !== pdmAuthorized.data) {
                dispatch(pdmAuthorize());
            }

            const [res, error] = await AwaitHandler(userInfo());

            if (!error && res.data) {
                const [userData, err1] =
                    await HttpRequestWithForbiddenCheck(userService.getUserProfileByEmail(res.data.email),
                        'getUserProfileByEmail', 'Error When Getting The User by Email');

                if (!err1 && userData) {
                    const [activityList, err1] =
                        await HttpRequestWithForbiddenCheck(userService.ListAllUsersUnderRole(userData.RoleNames),
                            'ListAllUsersUnderRole', 'Error When Getting The List Of All Users');

                    if (!err1 && activityList) {
                        setUsersList(activityList);
                    }
                }
            }

            let sessionStorageFilter =
                FiltersSessionStorageService.GetSessionStorageFilter(SessionStorageFiltersEnum.USERS_DASHBOARD_USERS_LIST_FILTER);

            if (sessionStorageFilter) {
                setFilter(sessionStorageFilter);
            }
        }

        const [permissionsResp, permissionsErr] =
            await HttpRequestWithForbiddenCheck(userService.getUserPermissions(),
                'Get User Permissions', 'Error When Getting User Permissions');

        if (!permissionsErr && permissionsResp !== null) {
            setPermissionsList(permissionsResp);
        }
    };

    const onSaveFilter = (filter: string) => {
        FiltersSessionStorageService.SetSessionStorageFilter(SessionStorageFiltersEnum.USERS_DASHBOARD_USERS_LIST_FILTER, filter);
        setFilter(filter);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ul className="nav float-right">
                    <PermissionsWrapper PermissionsList={PermissionsList} Permission={PermissionsEnum.CREATE_USER}
                        PassedPermissionTemplate={
                            <MainButton style={{ 'marginLeft': '15px' }} onClick={moveToAddUserPage} startIcon={<Icons.AddIcon />}>
                                {t("general.buttons.addNewUser")}
                            </MainButton>
                        }
                    />
                </ul>
            </Grid>
            <Grid item xs={12}>
                <SearchableTable
                    UsersList={UsersList}
                    Title={t("general.cards.UsersList")}
                    ShowTitle={true}
                    filter={filter}
                    onSaveFilter={onSaveFilter}
                />
            </Grid>
        </Grid>
    )
}

export default UsersDashboard;
