import axios, { AxiosInstance } from "axios";
import PDMServerLocalStorageService from "../../../services/LocalStorage/PDMServer/PDMServerService";
import settingInterceptors from "./settingInterceptors";

class PDMServerHttpClient {
  static _instance: PDMServerHttpClient;
  instance: AxiosInstance;

  constructor() {
    // just to silence ts errors
    this.instance = axios.create();
  }

  async init() {
    let URL: string;
    const isLocalStorageInit = PDMServerLocalStorageService.checkIfPDMServerLocalStorageIsInit();

    if (isLocalStorageInit) {
      await PDMServerLocalStorageService.initPDMServerLocalStorage();
      URL = await PDMServerLocalStorageService.getPDMServerUrl();
    }
    else {
      URL = await PDMServerLocalStorageService.getPDMServerUrl();
    }

    this.instance = axios.create({
      baseURL: URL,
      timeout: 600000,
      headers: {
        "Content-Type": "application/json",
      },
    });

    settingInterceptors(this.instance);
  }

  static async getInstance() {
    if (PDMServerHttpClient._instance === undefined) {
      PDMServerHttpClient._instance = new PDMServerHttpClient();
      await PDMServerHttpClient._instance.init();
    }
    return PDMServerHttpClient._instance;
  }
}

export default PDMServerHttpClient;
