import { useTranslation } from "react-i18next";
import { ColorsStyle } from '../../ReportCenterStylesImports';
import { PrintersSquareMeterSummeryDataModel } from '../../../../Models/Api/Events/PrintersSquareMeterSummeryDataModel';
import { v4 as uuidv4 } from "uuid";
import roundTo from '../../../../Pipes/RoundTo';
import CustomLi from "../../../../components/General/CustomLi/CustomLi";

type PrintersStatisticsProps = {
    printerStatisticsData: PrintersSquareMeterSummeryDataModel[] | null
};

const PrintersStatistics = ({ printerStatisticsData }: PrintersStatisticsProps) => {
    const [t] = useTranslation();

    return (
        <div>
            <h4 className={ColorsStyle.weight400 + " Orbitron20"} style={{ 'margin': '0', 'marginBottom': '15px' }}>{t("general.cards.printStatistics")}</h4>
            <ul className="list-group list-group-unbordered mb-3" style={{ 'height': '215px', 'overflowY': 'auto' }}>
                {printerStatisticsData &&
                    printerStatisticsData.map(element => {
                        return (
                            <CustomLi key={uuidv4()} className={'bg-grey'} Title={element.Printer + " : "} Data={<div>{element.Summery >= 50 ? roundTo(element.Summery, 0) : roundTo(element.Summery, 3)} m<sup>2</sup></div>} />
                        );
                    })
                }
            </ul>
        </div >
    );
};



export default PrintersStatistics;
