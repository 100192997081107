const Languages = [
    {
        key: "en",
        icon: "flag-icon-us",
        label: "languages.english",
    },
    {
        key: "cn",
        icon: "flag-icon-cn",
        label: "languages.chinese",
    },
    {
        key: "ru",
        icon: "flag-icon-ru",
        label: "languages.russian",
    },
    {
        key: "pt",
        icon: "flag-icon-pt",
        label: "languages.portuguese",
    },
    {
        key: "es",
        icon: "flag-icon-es",
        label: "languages.spanish",
    },    {
        key: "de",
        icon: "flag-icon-de",
        label: "languages.german",
    }
];

export default Languages;
