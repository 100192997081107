import scopeEnum from "../../Models/OpenId/scopeEnum";
import getSecret from "../../services/getSecret";
import PDMServerLocalStorageService from "../../services/LocalStorage/PDMServer/PDMServerService";

let redirectStr = '',
    silentRedirectStr = '',
    postLogoutRedirectStr = '';

if (!window.location.port || window.location.port === '') {
    redirectStr = `${window.location.protocol}//${window.location.hostname}/signin-callback.html`;
    silentRedirectStr = `${window.location.protocol}//${window.location.hostname}/signin-callback.html`;
    postLogoutRedirectStr = `${window.location.protocol}//${window.location.hostname}`;
}
else {
    redirectStr = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/signin-callback.html`;
    silentRedirectStr = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/signin-callback.html`;
    postLogoutRedirectStr = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/`;
}

const OpenIdConfigWithoutAuth = {
    clientId: 'PDM_UI',
    clientSecret: getSecret(),
    responseType: 'code',
    redirectUri: redirectStr,
    scope: `${scopeEnum.OpenId} ${scopeEnum.Profile} ${scopeEnum.Roles} ${scopeEnum.Api} ${scopeEnum.Email} ${scopeEnum.Picture}`,
    silentRedirectUri: silentRedirectStr,
    postLogoutRedirectUri: postLogoutRedirectStr,
    automaticSilentRenew: true
};

const OpenIdConfig = async () => {
    let auth = await PDMServerLocalStorageService.getPDMServerUrl();

    return {
        authority: auth,
        clientId: OpenIdConfigWithoutAuth.clientId,
        clientSecret: OpenIdConfigWithoutAuth.clientSecret,
        responseType: OpenIdConfigWithoutAuth.responseType,
        redirectUri: OpenIdConfigWithoutAuth.redirectUri,
        scope: OpenIdConfigWithoutAuth.scope,
        silentRedirectUri: OpenIdConfigWithoutAuth.silentRedirectUri,
        postLogoutRedirectUri: OpenIdConfigWithoutAuth.postLogoutRedirectUri,
        automaticSilentRenew: OpenIdConfigWithoutAuth.automaticSilentRenew
    };
};

export { OpenIdConfig, OpenIdConfigWithoutAuth }