import PDMServerLocalStorageService from "../../../services/LocalStorage/PDMServer/PDMServerService";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
//import humanizeString from "humanize-string";
import { JobDataModel } from "../../../Models/Api/Job/JobDataModel";
import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Styles from './AllJobsList.module.scss';
import { useEffect, useState } from "react";
//const prettyMetric = require('pretty-metric');

interface AllJobsListProps {
  tableHeight?: string
  Jobs: Array<JobDataModel>
}

const AllJobsList = ({ tableHeight, Jobs }: AllJobsListProps) => {
  const [t] = useTranslation();
  const history = useHistory();
  const [url, setUrl] = useState('');

  useEffect(() => {
    initAsync();
  }, [])

  const initAsync = async () => {
    let tmp = await PDMServerLocalStorageService.getPDMServerUrl();
    setUrl(tmp);
  };

  const onClickRow = (job: JobDataModel) => {
    history.push(`/JobManager/AllJobsList/${job.Id}`);
  };

  return (
    <TableContainer component={Paper} className="noBackgroundImage">
      <Table sx={{ minWidth: { tableHeight } }} aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell className={Styles['min-width-100-px']}>{t("general.tables.jobName")}</TableCell>
            <TableCell className={Styles['min-width-100-px']}>{t("general.tables.projectName")}</TableCell>
            <TableCell className={Styles['min-width-150-px']}>{t("general.tables.tags")}</TableCell>
            <TableCell className={Styles['min-width-150-px']}>{t("general.tables.dateModified")}</TableCell>
            <TableCell className={Styles['min-width-100-px']}>{t("general.tables.size")}</TableCell>
            <TableCell className={Styles['min-width-100-px']}>{t("general.tables.resolution")}</TableCell>
            <TableCell className={Styles['min-width-100-px']}>{t("general.tables.thumb")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Jobs &&
            Jobs.map((job) => {
              return (
                <TableRow
                  key={uuidv4()}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={() => onClickRow(job)}
                  hover
                  style={{ 'cursor': 'pointer' }}
                >

                  <TableCell component="th" scope="row">
                    {job.Name}
                  </TableCell>
                  <TableCell>{job.ProjectName}</TableCell>
                  <TableCell >
                    {job.Tags.map((tag, index) => (
                      <Chip size="small" key={uuidv4()} color='primary' label={tag} style={{ 'marginLeft': '2px', 'marginRight': '2px' }} />
                    ))}
                  </TableCell>
                  <TableCell >
                    {job.ContentInfo.DateModified && moment.utc(job.ContentInfo.DateModified).local().format('DD/MM/YYYY H:mm')}
                  </TableCell>
                  <TableCell>
                    {job.JobSizeInMM && parseFloat(job.JobSizeInMM?.split(' ')[0]).toFixed(2)}
                    {job.JobSizeInMM && " X "}
                    {job.JobSizeInMM && parseFloat(job.JobSizeInMM?.split(' ')[1]).toFixed(2)}
                    {job.JobSizeInMM && " mm"}
                  </TableCell>
                  <TableCell>
                    {Math.round(parseFloat(job.Resolution.split(' ')[0]))}
                    {" "}
                    X
                    {" "}
                    {Math.round(parseFloat(job.Resolution.split(' ')[1]))}
                  </TableCell>
                  <TableCell>
                    <img
                      alt=""
                      className="img-fluid"
                      src={`${url}${job.Thumb}`}
                      style={{ maxHeight: '40px', width: "auto" }}
                    />
                  </TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default AllJobsList;
