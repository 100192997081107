import PDMServerHttpClient from "../../PDMServerHttpClient";
import { InkUsageDataModel } from "../../../../../Models/Api/ReportCenter/InkUsageDataModel";
import { PrintEventStatusDataModel } from "../../../../../Models/Api/Events/PrintEventStatusDataModel";
import { PrintEventDataModel } from "../../../../../Models/Api/Events/PrintEventDataModel";
import { PrintersSquareMeterSummeryDataModel } from "../../../../../Models/Api/Events/PrintersSquareMeterSummeryDataModel";
import { InkUsagePerRangeDataModel } from "../../../../../Models/Api/ReportCenter/InkUsagePerRangeDataModel";

class ByInkUsageService {
  async getInkUsage(dateFrom: string, dateTo: string, limit = 0) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<InkUsageDataModel[]>(`/api/PrintEvents/v1.0/GetInkUsage/InkUsage/${dateFrom}/${dateTo}/${limit}`);
  }

  async getPrintedInkQuantity(dateFrom: string, dateTo: string, limit = 0) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<PrintEventStatusDataModel>(`/api/PrintEvents/v1.0/DateRange/PrintedInkQuantity/InkUsage/${dateFrom}/${dateTo}/${limit}`);
  }

  async getPrintedJobsByRange(colorName: string, dateFrom: string, dateTo: string, limit = 0) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<PrintEventDataModel[]>(`/api/PrintEvents/v1.0/DateRange/ListAll/InkUsage/${colorName}/${dateFrom}/${dateTo}/${limit}`);
  }

  async getPrintedSquareMetersByRange(colorName: string, dateFrom: string, dateTo: string, limit = 0) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<PrintersSquareMeterSummeryDataModel[]>(`/api/PrintEvents/v1.0/DateRange/PrintedSquareMeters/InkUsage/${colorName}/${dateFrom}/${dateTo}/${limit}`);
  }

  async getInkUsagePerRange(dateFrom: string, dateTo: string, limit = 0){
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<InkUsagePerRangeDataModel>(`/api/PrintEvents/v1.0/GetInkUsagePerRange/InkUsage/${dateFrom}/${dateTo}/${limit}`);
  
  }

  static getInstance() {
    return new ByInkUsageService();
  }
}

export default ByInkUsageService;
