import PermissionsEnum from '../../../Models/Permissions/PermissionsEnum';

type PermissionsWrapperProps = {
    PermissionsList: string[] | null,
    Permission: PermissionsEnum | PermissionsEnum[],
    PassedPermissionTemplate: JSX.Element
};

const PermissionsWrapper = ({ PermissionsList, Permission, PassedPermissionTemplate }: PermissionsWrapperProps) => {
    if (!PermissionsList) {
        return null;
    }

    if (Array.isArray(Permission)) {
        if (Permission.every(v => PermissionsList.some(x => x === v))) return PassedPermissionTemplate;
        return null;
    }

    if (PermissionsList.some(x => x === Permission)) {
        return PassedPermissionTemplate;
    }
    
    return null;
};

export default PermissionsWrapper;