const isObj = function (a: any) {
    if ((!!a) && (a.constructor === Object)) {
        return true;
    }
    return false;
};
const _st = function (z: string, g: string) {
    return "" + (g !== "" ? "[" : "") + z + (g !== "" ? "]" : "");
};
const fromObject = function (params: any, skipObjects?: boolean, prefix?: string) {
    if (skipObjects === void 0) {
        skipObjects = false;
    }
    if (prefix === void 0) {
        prefix = "";
    }
    let result = "";
    if (typeof (params) != "object") {
        return prefix + "=" + encodeURIComponent(params) + "&";
    }
    for (let param in params) {
        let c = "" + prefix + _st(param, prefix);
        if (isObj(params[param]) && !skipObjects) {
            result += fromObject(params[param], false, "" + c);
        } else if (Array.isArray(params[param]) && !skipObjects) {
            // eslint-disable-next-line no-loop-func
            params[param].forEach(function (item: any, ind: any) {
                result += fromObject(item, false, c + "[" + ind + "]");
            });
        } else {
            result += c + "=" + encodeURIComponent(params[param]) + "&";
        }
    }
    return result;
};

export default fromObject;