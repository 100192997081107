import React, { useEffect, useState } from 'react'
import { UserApiDataModel } from '../../../Models/Api/User/UserApiDataModel';
import { useTranslation } from "react-i18next";
import { Avatar, Card, CardContent, Chip, List } from '@mui/material';
import { CustomListItem } from '../../../components/ThemeElements/ThemeElementsExport';
import PDMServerLocalStorageService from '../../../services/LocalStorage/PDMServer/PDMServerService';
import { v4 as uuidv4 } from "uuid";

type ProfileDataCardApiProps = {
    User: UserApiDataModel | null
};

const ProfileDataCardApi = ({ User }: ProfileDataCardApiProps) => {
    const [t] = useTranslation();
    const [url, setUrl] = useState('');

    useEffect(() => {
        initAsync();
    }, [])

    const initAsync = async () => {
        let tmp = await PDMServerLocalStorageService.getPDMServerUrl();
        setUrl(tmp);
    };

    return (
        <Card className="noBackgroundImage">
            <CardContent>
                {
                    User !== null && User.AvatarPhotoPath
                        ?
                        <Avatar alt="Avatar" src={`${url}${User.AvatarPhotoPath}`} />
                        :
                        <Avatar>{User && User.Initias}</Avatar>
                }

                <List id="custom-card-list">

                    <CustomListItem Title={t("general.userInfo.fullName") + " :"}>
                        <span>
                            {User ? `${User.FirstName || ''} ${User.LastName || ''}`.trim() : ""}
                        </span>
                    </CustomListItem>

                    <CustomListItem Title={t("general.userInfo.userName") + " :"}>
                        <span>
                            {User !== null && User.UserName}
                        </span>
                    </CustomListItem>

                    <CustomListItem Title={t("general.userInfo.roles") + " :"}>
                        <span>
                            {User !== null && User.RoleNames && User.RoleNames.map((role) => {
                                return <Chip size="small" key={uuidv4()} color='primary' label={role} style={{ 'marginLeft': '2px', 'marginRight': '2px' }} />
                            })}
                        </span>
                    </CustomListItem>

                    <CustomListItem Title={t("general.userInfo.email") + " :"}>
                        <span>
                            {User !== null && User.Email}
                        </span>
                    </CustomListItem>

                    <CustomListItem Title={t("general.userInfo.status") + " :"}>
                        <span>
                            {User !== null && User.IsEnabled ? t("general.userInfo.enabled") : t("general.userInfo.disabled")}
                            {User !== null && User.IsLockedOut && " | " + t("general.userInfo.locked")}
                        </span>
                    </CustomListItem>
                </List>

            </CardContent>
        </Card>
    );
};

export default ProfileDataCardApi;