import React, { useState } from "react";
import { useFormik } from "formik";
import CMiXValidationSchema from "../../../../Validators/Private/CMiX/CMiXValidationSchema";
import { FormError } from "../../../../components/Forms/FormsExport";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { Grid } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { MainButton } from "../../../../components/General/GeneralExport";

interface CMiXColorFormProps {
    submitForm: (item: {
        ColorNumber: any;
        PickedColorPalletsOption: string;
    }) => void
}

const CMiXColorForm = (props: CMiXColorFormProps) => {
    const [t] = useTranslation();
    const colorPallets = ["CMiX", "RAL", "Pantone"];
    const [PickedColorPalletsOption, setPickedColorPaletteOption] = useState<string>("CMiX");

    const formik = useFormik({
        initialValues: {
            colorCode: 0,
        },
        validationSchema: CMiXValidationSchema,
        onSubmit: (values) => {
            submitF(values.colorCode);
        },
    });

    const receiveColorPalletteOption = (e: any) => {
        setPickedColorPaletteOption(e.target.value);
    };

    const submitF = (colorNumber: any) => {
        const formResult = { ColorNumber: colorNumber, PickedColorPalletsOption };
        props.submitForm(formResult);
    };

    return (
        <div>
            <Grid container>
                <Grid item xs={4}>
                    <Box>
                        <FormControl fullWidth>
                            <InputLabel>{t("general.labels.pallet")}</InputLabel>
                            <Select
                                value={PickedColorPalletsOption}
                                label={t("general.labels.pallet")}
                                onChange={receiveColorPalletteOption}
                            >
                                {colorPallets.map((element) => (
                                    <MenuItem key={uuidv4()} value={element}>{element}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>

                <Grid item xs={5} md={6}>
                    <TextField style={{ 'width': '100%' }} autoFocus label={t("general.labels.colorCode")} variant="outlined"
                        {...formik.getFieldProps("colorCode")}
                    />
                </Grid>

                <Grid item xs={1}>
                    <MainButton style={{ 'width': '100%', 'height': '100%' }} onClick={() => submitF(formik.values.colorCode)}>
                        <FontAwesomeIcon icon={faSearch as IconProp} />
                    </MainButton>
                </Grid>
            </Grid>
            <FormError formik={formik} propName={"colorCode"} />
        </div>
    );
};

export default CMiXColorForm;