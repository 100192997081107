import { ColoredLine } from "../../General/GeneralExport";
import { FormikProps } from "formik";

interface FormErrorProps {
    formik: FormikProps<any>,
    propName: string,
    width?: string,
    disableLine?: boolean
}

const FormError = ({ formik, propName, width = '100%', disableLine = false }: FormErrorProps) => {
    return (
        <>
            {
                formik.touched[propName] && formik.errors[propName]
                    ?
                    (
                        <div className='text-center'
                            style={{
                                "color": "red",
                                "paddingBottom": "8px",
                                "marginTop": "4px",
                                "fontSize": "12px",
                                'width': width,
                                'textAlign': 'left' // added property
                            }}>
                            {disableLine === false && <ColoredLine color={"red"} />}
                            {formik.errors[propName]}
                        </div>
                    )
                    :
                    null
            }
        </>
    );
}

export default FormError
