import { Badge, Box, Divider, Drawer, IconButton, LinearProgress, List, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
//import { ListItem, ListItemText, } from '@mui/material';
import React from 'react'
//import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from "uuid";
import ResponsiveWrapper from '../../components/ResponsiveDesign/ResponsiveWrapper';
import Icons from '../../Imports/MUI/Icons/IconsImports';
//import JobUploaderModel from '../../Models/Store/JobUploaderModel';
import { removeJob } from '../../Store/Actions/Actions';
import /*store,*/ { RootState } from '../../Store/Store';

const NotificationsPanel = () => {
  //const [t] = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [MouseOver, setMouseOver] = React.useState(false);
  const [Open, setOpen] = React.useState(false);
  //const state = store.getState();
  const dispatch = useDispatch();
  const jobUploaderState = useSelector((state: RootState) => state.JobUploader);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getIconFromType = (type: "job" | "info" | "error" | "warning") => {
    switch (type) {
      case 'job': return <Icons.PrintIcon fontSize="small" />
      case 'info': return <Icons.InfoIcon fontSize="small" />
      case 'error': return <Icons.ErrorOutlineIcon fontSize="small" />
      case 'warning': return <Icons.WarningAmberIcon fontSize="small" />
    }
  };

  const desktopTemplate = (<>
    <IconButton
      size="large"
      onClick={handleMenu}
      onMouseOver={() => setMouseOver(true)}
      onMouseOut={() => setMouseOver(false)}
      color="inherit"
    >
      <Badge color="error" variant={MouseOver ? 'standard' : "dot"} invisible={jobUploaderState.length === 0} badgeContent={jobUploaderState.length}>
        <Icons.NotificationsIcon />
      </Badge>
    </IconButton>
    <Menu
      disablePortal
      sx={{ mt: '35px' }}
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflowX: 'hidden',
          overflowY: 'auto',
          height: '250px',
          width: '300px',
          maxWidth: '100%',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
    >
      {
        jobUploaderState.map(x => {
          return (
            <div key={uuidv4()}>
              <MenuItem disableRipple disableTouchRipple onClick={undefined}>
                <ListItemIcon>
                  {getIconFromType(x.type)}
                </ListItemIcon>
                <div style={{ display: "block", width: "70%" }}>
                  <Typography variant="body2" color="text.secondary">{x.JobZipFileName.length > 25 ? x.JobZipFileName.slice(0, 25) + '...' : x.JobZipFileName}</Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ width: '100%', mr: 1 }}>
                      <LinearProgress variant="determinate" value={x.progress} />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                      <Typography variant="body2" color="text.secondary">{`${Math.round(x.progress)}%`}</Typography>
                    </Box>
                  </Box>
                </div>

                <IconButton onClick={() => {
                  dispatch(removeJob(x.fileGuid));
                }}>
                  <Icons.CloseIcon />
                </IconButton>
              </MenuItem>
              <Divider sx={{ my: 1 }} />
            </div>
          );
        })
      }
    </Menu>
  </>);

  const tabletOrMobileTemplate = (<>
    <IconButton
      size="large"
      onClick={() => setOpen(!Open)}
      color="inherit"
    >
      <Badge color="error" variant={'standard'} invisible={jobUploaderState.length === 0} badgeContent={jobUploaderState.length}>
        <Icons.NotificationsIcon />
      </Badge>
    </IconButton>

    <Drawer
      anchor={'right'}
      open={Open}
      onClose={() => setOpen(!Open)}
    >
      <Box
        sx={{ width: 250 }}
        role="presentation"
        onClick={() => setOpen(!Open)}
      >
        <List component="nav" style={{ 'marginTop': '50px' }}>

          {
            jobUploaderState.map(x => {
              return (
                <div key={uuidv4()}>
                  <MenuItem disableRipple disableTouchRipple onClick={undefined}>
                    <ListItemIcon>
                      {getIconFromType(x.type)}
                    </ListItemIcon>
                    <div style={{ display: "block", width: "70%" }} id="yy">
                      <Typography sx={{ display: 'flex', alignItems: 'center' }} variant="body2" color="text.secondary">{x.JobZipFileName.length > 25 ? x.JobZipFileName.slice(0, 25) + '...' : x.JobZipFileName}</Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: '100%', mr: 1 }}>
                          <LinearProgress variant="determinate" value={x.progress} />
                        </Box>
                        <Box sx={{ minWidth: 35 }}>
                          <Typography variant="body2" color="text.secondary">{`${Math.round(x.progress)}%`}</Typography>
                        </Box>
                      </Box>
                    </div>

                    <IconButton style={{ "marginTop": "5" }} onClick={() => {
                      dispatch(removeJob(x.fileGuid));
                    }}>
                      <Icons.CloseIcon />
                    </IconButton>
                  </MenuItem>
                  <Divider sx={{ my: 1 }} />
                </div>
              );
            })
          }
        </List>
      </Box>
    </Drawer>
  </>);

  return (
    <ResponsiveWrapper DesktopOrLaptopLayout={desktopTemplate} TabletOrMobileLayout={tabletOrMobileTemplate} />
  );
};

export default NotificationsPanel;
