import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 590,
            md: 750,
            lg: 1200,
            xl: 1536,
        },
    },
    palette: {
        mode: 'light',
        primary: {
            main: "#8a61d5",
            dark: "#8a61d5",
        },
        secondary: {
            main: "#8a61d5",
        },
        background: {
            default: "#e9ecef"
        },
        action: {
            selectedOpacity: 0.8
        },
        success: {
            main: '#239729'
        }
    },
});

export default lightTheme;