import localStorageService from "../localStorageService";
import AwaitHandling from "../../AwaitHandling/AwaitHandling";
import { ConnectionDataService } from "../../../Api/Rest/Volume/ApiServices/VolumeServicesImports";

const initPDMServerLocalStorage = async () => {
  const [res,] = await AwaitHandling(ConnectionDataService.getInstance().ServerConnectionData());
  // const serverData = await getServerDataFromVolume();

  const serverData = res.data;

  if (serverData === null) {
    localStorageService.addToLocalStorage("serverUrl", process.env.REACT_APP_SERVER_URL_ENDPOINT!);
  }
  else {
    localStorageService.addToLocalStorage("serverUrl", serverData.SERVER_URL_ENDPOINT);
  }
};

const checkIfPDMServerLocalStorageIsInit = () => {
  const serverUrl = localStorageService.getFromLocalStorage("serverUrl");
  const isServerUrl = (serverUrl === null || serverUrl === 'undefined');

  return isServerUrl;
};

const getPDMServerUrl = async () => {
  if (checkIfPDMServerLocalStorageIsInit()) {
    await initPDMServerLocalStorage()
  }

  let serverUrl = localStorageService.getFromLocalStorage("serverUrl");

  if (!serverUrl) {
    return '';
  }

  return serverUrl;
};

const PDMServerLocalStorageService = {
  initPDMServerLocalStorage,
  checkIfPDMServerLocalStorageIsInit,
  getPDMServerUrl,
};

export default PDMServerLocalStorageService;
