import { CssBaseline, Box, Container, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router'
import { MainButton } from '../components/General/GeneralExport';
import { NewsParagraph } from "../modules/Welcome/WelcomeImports";
import Styles from './UnauthorizedTemplate.module.scss';

function UnauthorizedTemplate() {
    const [t] = useTranslation();
    const history = useHistory();

    return (
        <div style={{ 'display': 'flex', 'flexDirection': 'column', 'height': '100%', 'justifyContent': 'center' }}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <section className={Styles['full-width']}>
                    <Container>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <div className={Styles['buttons-container']}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <MainButton className={Styles['login-button']} onClick={() => history.go(0)}>
                                                {t("general.buttons.login")}
                                            </MainButton>
                                        </Grid>
                                        {/* <Grid item xs={12}>
                                            <p className={Styles['center-paragraph']}>
                                                {"-- "}{t("general.labels.orSignInWith")}{" --"}
                                            </p>
                                        </Grid> */}
                                        {/* <Grid item xs={12}>
                                            <MainButton className={Styles['login-button']} sx={{ background: 'pallet.text.primary' }}
                                                onClick={() => history.go(0)}>
                                                {t("general.buttons.dipTechLogin")}
                                            </MainButton>
                                        </Grid> */}
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <NewsParagraph />
                            </Grid>
                        </Grid>
                    </Container>
                </section>
            </Box>
        </div>
    )
}

export default UnauthorizedTemplate;