import Barcode from 'react-barcode';
import propTypes from "prop-types";

const BarcodeGenerator = ({ Value, Width = 2.1, Height = 100, DisplayValue = true, FontSize = 20 }) => {
    return <Barcode value={Value} width={Width} height={Height} displayValue={DisplayValue} fontSize={FontSize} />;
};

// barcode params
// {
//     width: 2,
//     height: 100,
//     format: "CODE128",
//     displayValue: true,
//     fontOptions: "",
//     font: "monospace",
//     textAlign: "center",
//     textPosition: "bottom",
//     textMargin: 2,
//     fontSize: 20,
//     background: "#ffffff",
//     lineColor: "#000000",
//     margin: 10,
//     marginTop: undefined,
//     marginBottom: undefined,
//     marginLeft: undefined,
//     marginRight: undefined
// }

// For storybook types
BarcodeGenerator.propTypes = {
    Value: propTypes.string,
    Width: propTypes.number,
    Height: propTypes.number,
    DisplayValue: propTypes.bool,
    FontSize: propTypes.number
};

export default BarcodeGenerator;