import React from 'react'
import { SearchableTable } from '../../../../modules/JobManager/JobManagerImports';
import { JobDataModel } from '../../../../Models/Api/Job/JobDataModel';
import signalRBuilder from '../../../../Api/Socket/signalRBuilder';
import signalREventListenerDictionary from '../../../../Models/SignalR/signalREventListenerDictionary';
import { SignalRDataModel } from '../../../../Models/SignalR/SignalRDataModel';
import SocketEmitWrapper from '../../../../services/SocketEmitWrapper/SocketEmitWrapper';
import SignalRHubEnum from '../../../../Models/SignalR/SignalRHubEnum';
import HttpRequestWithForbiddenCheck from '../../../../services/HttpRequestWithForbiddenCheck/HttpRequestWithForbiddenCheck';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../Store/Store';
import { useHistory } from 'react-router-dom';
import { pdmAuthorize, pdmUnauthorize } from '../../../../Store/Actions/Actions';
import { AuthorizationService, JobManagerService, UserService } from '../../../../Api/Rest/PDMServer/ApiServices/PDMApiServicesImports';
import { Grid } from '@mui/material';
import Icons from '../../../../Imports/MUI/Icons/IconsImports';
import { MainButton, PermissionsWrapper } from '../../../../components/General/GeneralExport';
import { useTranslation } from "react-i18next";
import PermissionsEnum from '../../../../Models/Permissions/PermissionsEnum';
import FiltersSessionStorageService, { SessionStorageFiltersEnum } from '../../../../services/SessionStorage/Filters/FiltersSessionStorageService';

const JobListPage = () => {
  const [Jobs, setJobs] = React.useState<Array<JobDataModel>>([]);

  const dispatch = useDispatch();
  const pdmAuthState = useSelector((state: RootState) => state.PdmAuth);
  const history = useHistory();
  const [enterGroup, setEnterGroup] = React.useState(false);
  const [t] = useTranslation();
  const [PermissionsList, setPermissionsList] = React.useState<string[] | null>(null);
  const userService = UserService.getInstance();
  const [filter, setFilter] = React.useState<string>('');

  React.useEffect(() => {
    asyncInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    // When leaving the page the return will emit and remove user from group
    return () => {
      if (enterGroup) leaveGroup()
    };
  }, [enterGroup])

  const asyncInit = async () => {
    const pdmAuthorized = await AuthorizationService.getInstance().checkApi();

    if (pdmAuthorized.status === 200) {
      if (!pdmAuthorized.data) {
        if (pdmAuthState !== pdmAuthorized.data) {
          dispatch(pdmUnauthorize());
        }
        history.push('/AuthorizePDM');
      }

      if (pdmAuthState !== pdmAuthorized.data) {
        dispatch(pdmAuthorize());
      }

      initSignalR();
      Init();
      setEnterGroup(true);

      let sessionStorageFilter =
        FiltersSessionStorageService.GetSessionStorageFilter(SessionStorageFiltersEnum.JOB_MANAGER_JOBS_LIST_FILTER);

      if (sessionStorageFilter) {
        setFilter(sessionStorageFilter);
      }
    }
  };

  const Init = async () => {
    const [res, err] =
      await HttpRequestWithForbiddenCheck(JobManagerService.getInstance().getJobs(), 'getJobs', 'Error When Getting The Jobs');

    if (!err && res !== null) {
      setJobs(res)
    }

    const [permissionsResp, permissionsErr] = await HttpRequestWithForbiddenCheck(userService.getUserPermissions(), 'Get User Permissions', 'Error When Getting User Permissions');
    if (!permissionsErr && permissionsResp !== null) {
      setPermissionsList(permissionsResp);
    }
  };

  const leaveGroup = async () => {
    const b = await signalRBuilder.getInstance(SignalRHubEnum.JOB_MANAGER_HUB);
    // sign to job manager page group
    await SocketEmitWrapper(b.newConnection.send(signalREventListenerDictionary.LEAVE_JOB_MANAGER_GROUP));
  };

  const initSignalR = async () => {
    const b = await signalRBuilder.getInstance(SignalRHubEnum.JOB_MANAGER_HUB);

    await SocketEmitWrapper(b.newConnection.send(signalREventListenerDictionary.JOIN_JOB_MANAGER_GROUP));

    b.addEventListener(
      signalREventListenerDictionary.GET_LIST_OF_JOBS,
      (signalRModel: SignalRDataModel) => {
        const data = JSON.parse(signalRModel.Data);
        setJobs(data);
      }
    );
  }

  const onSaveFilter = (filter: string) => {
    FiltersSessionStorageService.SetSessionStorageFilter(SessionStorageFiltersEnum.JOB_MANAGER_JOBS_LIST_FILTER, filter);
    setFilter(filter);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ul className="nav nav-pills ml-auto float-right" style={{ 'padding': '0.5rem', 'paddingBottom': '0' }}>
          <PermissionsWrapper PermissionsList={PermissionsList} Permission={PermissionsEnum.CREATE_JOB_MANAGER_UI}
            PassedPermissionTemplate={
              <MainButton startIcon={<Icons.AddIcon />} onClick={() => history.push('/JobManager/AddJob')}>
                {t("general.buttons.addJob")}
              </MainButton>
            }
          />

        </ul>
      </Grid>
      <Grid item xs={12}>
        <SearchableTable
          jobs={Jobs}
          filter={filter}
          onSaveFilter={onSaveFilter} />
      </Grid>
    </Grid>
  )
}

export default JobListPage;
