
type ColoredLineProps = {
  color?: string
  height?: string
  width?: string
  left?: string
  marginBottom?: string
};

const ColoredLine = ({ color = "black", marginBottom = '0', height = '1px', width = "100%", left = "0" }: ColoredLineProps) => (
  <div
    style={{
      color: color,
      backgroundColor: color,
      height: height,
      marginBottom: marginBottom,
      width: width,
      left: left,
      position: 'relative'
    }}
  />
);

export default ColoredLine;
