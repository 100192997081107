import axios from "axios";
import PDMServerLocalStorageService from "../../../../../services/LocalStorage/PDMServer/PDMServerService";
import PDMServerSessionStorageService from "../../../../../services/SessionStorage/PDMServer/PDMServerService";

class CheckFeaturesService {

  async featureEnabled(Feature: string) {
    const CancelToken = axios.CancelToken;
    let source = CancelToken.source();

    const AuthorizationJson = await PDMServerSessionStorageService.generateAuthorizationHeader();
    const url = await PDMServerLocalStorageService.getPDMServerUrl();
    const header = {
      headers: {
        cancelToken: source.token,
        Authorization: AuthorizationJson.Authorization,
      },
    };
    return await axios.get<boolean>(`${url}/api/CheckFeatures/v1.0/Check/${Feature}`, header);
  }

  static getInstance() {
    return new CheckFeaturesService();
  }
}

export default CheckFeaturesService;
