import { ServerConnectionDataModel } from "../../../../../Models/Api/ServerConnectionDataModel";
import VolumeHttpClient from "../../VolumeHttpClient";

class ConnectionDataService {

  async ServerConnectionData() {
    let api = await VolumeHttpClient.getInstance();
    return await api.instance.get<ServerConnectionDataModel>("/settings/network_settings/server_connection_data.json");
  }

  static getInstance() {
    return new ConnectionDataService();
  }
}

export default ConnectionDataService;
