import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../Store/Store';
import { pdmAuthorize, pdmUnauthorize } from "../../../../Store/Actions/Actions";
import { useHistory } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import { MainButton, PermissionsWrapper } from '../../../../components/General/GeneralExport';
import { ActivityCard, ProfileDataCardApi } from '../../../../modules/Profile/ProfileImports';
import { UserApiDataModel } from '../../../../Models/Api/User/UserApiDataModel';
import { useTranslation } from "react-i18next";
import Icons from '../../../../Imports/MUI/Icons/IconsImports';
import { UserEventDataModel } from '../../../../Models/Api/Events/UserEventDataModel';
import HttpRequestWithForbiddenCheck from "../../../../services/HttpRequestWithForbiddenCheck/HttpRequestWithForbiddenCheck";
import { toast } from "react-toastify";
import { AuthorizationService, UserService } from '../../../../Api/Rest/PDMServer/ApiServices/PDMApiServicesImports';
import PermissionsEnum from "../../../../Models/Permissions/PermissionsEnum";
import { useParams } from "react-router-dom"

type SingleUserParamTypes = {
    userId: string
}

const SingleUser = () => {
    const [t] = useTranslation();
    const pdmAuthState = useSelector((state: RootState) => state.PdmAuth);
    const dispatch = useDispatch();
    const history = useHistory();
    const [User, setUser] = useState<UserApiDataModel | null>(null);
    const [UserEvents, setUserEvents] = useState<UserEventDataModel[]>([]);
    const userService = UserService.getInstance();
    const params = useParams<SingleUserParamTypes>();
    const userId = params.userId;
    const [open, setOpen] = useState(false);
    const [PermissionsList, setPermissionsList] = useState<string[] | null>(null);

    useEffect(() => {
        asyncInit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const asyncInit = async () => {
        const pdmAuthorized = await AuthorizationService.getInstance().checkApi();

        if (pdmAuthorized.status === 200) {
            if (!pdmAuthorized.data) {
                if (pdmAuthState !== pdmAuthorized.data) {
                    dispatch(pdmUnauthorize());
                }
                history.push('/AuthorizePDM');
            }

            if (pdmAuthState !== pdmAuthorized.data) {
                dispatch(pdmAuthorize());
            }

            if (userId) {
                const [userData, err1] =
                    await HttpRequestWithForbiddenCheck(userService.getUserProfileById(userId), 'getUserProfileById', 'Error When Getting The User By Id');

                if (!err1 && userData) {
                    setUser(userData);

                    const [activityList, err2] =
                        await HttpRequestWithForbiddenCheck(userService.getUserEvents(userId), 'getUserEvents', 'Error When Getting The User Events');

                    if (!err2 && activityList) {
                        setUserEvents(activityList);
                    }
                }
            }

            const [permissionsResp, permissionsErr] = await HttpRequestWithForbiddenCheck(userService.getUserPermissions(), 'Get User Permissions', 'Error When Getting User Permissions');
            if (!permissionsErr && permissionsResp !== null) {
                setPermissionsList(permissionsResp);
            }
        }
    };

    const handleClose = (answer: boolean) => {
        if (answer) {
            setOpen(false);
            deleteUser();
        }
        else {
            setOpen(false);
        }
    };

    const deleteUser = async () => {
        if (userId !== null) {
            const [, error] =
                await HttpRequestWithForbiddenCheck(userService.deleteUser(userId), 'deleteUser', 'Error When Deleting The User');

            if (!error) {
                toast.success("User Deleted", { style: { "backgroundColor": "#6f42c1" } });
                history.push("/UsersDashboard");
            }
        }
    };

    const dialog = (<Dialog
        open={open}
        onClose={() => { handleClose(false); }}
    >
        <DialogTitle>
            Remove User?
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                Are You sure You Want To Remove User {User?.UserName}?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <MainButton onClick={() => { handleClose(true); }} color='error' lightVariant="text">
                {t("general.buttons.yes")}
            </MainButton>
            <MainButton onClick={() => { handleClose(false); }} lightVariant="text">
                {t("general.buttons.no")}
            </MainButton>
        </DialogActions>
    </Dialog>);

    return (
        <Grid container spacing={2}>
            {dialog}
            <Grid item xs={12}>
                <ul className="nav float-right">
                    <PermissionsWrapper PermissionsList={PermissionsList} Permission={PermissionsEnum.UPDATE_USER}
                        PassedPermissionTemplate={
                            <MainButton style={{ 'marginLeft': '15px' }}
                                onClick={() => history.push(`/UsersDashboard/${userId}/EditUser`)}
                                startIcon={<Icons.EditIcon />}>
                                {t("general.buttons.editUser")}
                            </MainButton>
                        } />
                    <PermissionsWrapper PermissionsList={PermissionsList} Permission={PermissionsEnum.UPDATE_USER}
                        PassedPermissionTemplate={
                            <MainButton style={{ 'marginLeft': '15px' }}
                                onClick={() => history.push(`/UsersDashboard/${userId}/ChangePassword`)}
                                startIcon={<Icons.PasswordIcon />}>
                                {t("general.buttons.changePassword")}
                            </MainButton>
                        } />

                    <PermissionsWrapper PermissionsList={PermissionsList} Permission={PermissionsEnum.DELETE_USER}
                        PassedPermissionTemplate={
                            <MainButton style={{ 'marginLeft': '15px' }} onClick={() => setOpen(true)} startIcon={<Icons.DeleteIcon />} color='error'>
                                {t("general.buttons.deleteUser")}
                            </MainButton>
                        } />
                </ul>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
                <ProfileDataCardApi User={User} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8}>
                <ActivityCard Activity={UserEvents} />
            </Grid>
        </Grid>
    );
};

export default SingleUser;