import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
//import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import { useAuth } from 'oidc-react';
import Icons from '../../Imports/MUI/Icons/IconsImports';
import { MainButton, PermissionsWrapper } from "../../components/General/GeneralExport";
import { /*AuthorizationService,*/ /*JobManagerService,*/ UserService } from '../../Api/Rest/PDMServer/ApiServices/PDMApiServicesImports';
import PermissionsEnum from "../../Models/Permissions/PermissionsEnum";
import HttpRequestWithForbiddenCheck from "../../services/HttpRequestWithForbiddenCheck/HttpRequestWithForbiddenCheck";

const UserDropdown = () => {
  const auth = useAuth();
  const history = useHistory();
  const [t] = useTranslation();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const [PermissionsList, setPermissionsList] = useState<string[] | null>(null);
  const userService = UserService.getInstance();

  React.useEffect(() => {
    asyncInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const asyncInit = async () => {
    const [permissionsResp, permissionsErr] =
      await HttpRequestWithForbiddenCheck(userService.getUserPermissions(),
        'Get User Permissions', 'Error When Getting User Permissions');

    if (!permissionsErr && permissionsResp !== null) {
      setPermissionsList(permissionsResp);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logOut = async () => {
    auth.signOutRedirect();
  };

  return (
    <>
      <IconButton size="large" color="inherit" onClick={handleOpenUserMenu}>
        <Icons.PersonIcon />
      </IconButton>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth>
          <DialogTitle>About The Program</DialogTitle>
          <DialogContent>
            {
              theme.palette.mode === 'dark'
                ?
                <img
                  src="/img/Logos/LogoDipTechWhite.png"
                  alt="Logo"
                  className="brand-image"
                  style={{ opacity: ".8", 'width': '60px' }}
                  onClick={() => history.push('/')}
                />
                :
                <img
                  src="/img/Logos/LogoDipTechBlack.png"
                  alt="Logo"
                  className="brand-image"
                  style={{ opacity: ".8", 'width': '60px' }}
                  onClick={() => history.push('/')}
                />
            }
            <DialogContentText
              sx={{ mt: 1 }}
              id="alert-dialog-description">
              {t("general.labels.version")}: {process.env.REACT_APP_VERSION_INFO}
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
              {t("general.labels.allRightsReserved")}
            </DialogContentText>
            <DialogContentText>
              <span>
                © 2023
                <a href="https://www.diptechmachinery.com" target="_blank" rel="noopener noreferrer">
                  {' '} {t("general.labels.companyName")}
                </a>
              </span>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MainButton onClick={handleClose} lightVariant="text">
              {t("general.buttons.close")}
            </MainButton>
          </DialogActions>
        </Dialog>
      </div>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
      >
        <PermissionsWrapper PermissionsList={PermissionsList} Permission={PermissionsEnum.CREATE_USER}
          PassedPermissionTemplate={
            <MenuItem onClick={() => {
              history.push('/UsersDashboard');
              setAnchorElUser(null);
            }}>
              <ListItemIcon>
                <Icons.GroupIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t("general.buttons.usersDashboard")}</ListItemText>
            </MenuItem>
          }
        />

        <MenuItem onClick={() => {
          history.push('/Profile');
          setAnchorElUser(null);
        }}>
          <ListItemIcon>
            <Icons.PersonIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("general.buttons.profile")}</ListItemText>
        </MenuItem>

        <MenuItem onClick={() => {
          handleClickOpen();
          setAnchorElUser(null);
        }}>
          <ListItemIcon>
            <Icons.InfoIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("general.buttons.about")}</ListItemText>
        </MenuItem>

        <MenuItem onClick={logOut}>
          <ListItemIcon>
            <Icons.LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("general.buttons.logOut")}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserDropdown;
