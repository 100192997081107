import { useAuth } from 'oidc-react';
import { Route } from 'react-router-dom';
import UnauthorizedTemplate from './UnauthorizedTemplate';

type OpenIdRouteProps = {
    children: JSX.Element,
    [rest: string]: any;
};

function OpenIdRoute({ children, rest }: OpenIdRouteProps) {
    const auth = useAuth();

    if (auth && auth.userData) {
        return (
            <Route {...rest} >
                {children}
            </Route>
        );
    };

    return <UnauthorizedTemplate />;
}

export default OpenIdRoute;