import { SessionStorageDataModel } from "../../../Models/SignalR/SessionStorageDataModel";
import PDMServerLocalStorageService from "../../LocalStorage/PDMServer/PDMServerService";
import { OpenIdConfigWithoutAuth } from "../../../Auth/OpenIdConfig/OpenIdConfig";
import SessionStorageService from "../SessionStorageService";

const getTokenLocalStorage = () => {
  const url = PDMServerLocalStorageService.getPDMServerUrl();
  const exp: any = SessionStorageService.getFromSessionStorage(`oidc.user:${url}:${OpenIdConfigWithoutAuth.clientId}`);
  const jsonData = JSON.parse(exp) as SessionStorageDataModel;
  return jsonData.access_token;
};

const getExpireTime = async () => {
  const url = await PDMServerLocalStorageService.getPDMServerUrl();
  const exp = SessionStorageService.getFromSessionStorage(`oidc.user:${url}:${OpenIdConfigWithoutAuth.clientId}`);
  if (exp) {
    const jsonData = JSON.parse(exp) as SessionStorageDataModel;
    return jsonData.expires_at;
  }
  else {
    return null;
  }
};

const generateAuthorizationHeader = async () => {
  const url = await PDMServerLocalStorageService.getPDMServerUrl();
  const exp: any = SessionStorageService.getFromSessionStorage(`oidc.user:${url}:${OpenIdConfigWithoutAuth.clientId}`);
  const jsonData = JSON.parse(exp);
  const token_type = jsonData.token_type;
  const token = jsonData.access_token;
  return { Authorization: `${token_type} ${token}` };
};

const getCurrentUserName = async (): Promise<string | null> => {
  const url = await PDMServerLocalStorageService.getPDMServerUrl();
  const exp: any = SessionStorageService.getFromSessionStorage(`oidc.user:${url}:${OpenIdConfigWithoutAuth.clientId}`);
  const jsonData = JSON.parse(exp);
  const userName = jsonData.profile.name;
  return userName;
};

const PDMServerSessionStorageService = {
  generateAuthorizationHeader,
  getTokenLocalStorage,
  getExpireTime,
  getCurrentUserName
};

export default PDMServerSessionStorageService;
