import { useEffect, useState } from "react";
import { ColoredLine, MainButton } from "../../../components/General/GeneralExport";
import { CMiXColorForm, ColorIDs, CMiXFormulationTable, CMiXQuantityTable } from "../../../modules/CMiX/CMiXImports";
import { CmixDataModel } from "../../../Models/Api/Cmix/CmixDataModel";
import { CmixQuantityDataModel } from "../../../Models/Api/Cmix/CmixQuantityDataModel";
import HttpRequestWithForbiddenCheck from "../../../services/HttpRequestWithForbiddenCheck/HttpRequestWithForbiddenCheck";
import { AxiosError } from "axios";
import { CircularProgress, Grid } from "@mui/material";
import fromObject from '../../../services/NestedModelQueryStringConvertor/NestedModelQueryStringConvertor';
import { useTranslation } from "react-i18next";
import Icons from '../../../Imports/MUI/Icons/IconsImports';
import Slider, { SliderTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';
import Styles from "./Cmix.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Store/Store";
import { useHistory } from "react-router-dom";
import { pdmAuthorize, pdmUnauthorize } from "../../../Store/Actions/Actions";
import { useTheme } from '@mui/material/styles';
import { AuthorizationService, CMiXService } from "../../../Api/Rest/PDMServer/ApiServices/PDMApiServicesImports";

const Cmix = () => {
  const theme = useTheme();
  const [t] = useTranslation();
  const CMiXServiceInstance = CMiXService.getInstance();
  const [quantity, setQuantity] = useState<number>(1);
  const [ColorResult, setColorResult] = useState<CmixDataModel | null>(null);
  const [OpenColorQuantity, setOpenColorQuantity] = useState<boolean>(false);
  const [OpenColorData, setOpenColorData] = useState<boolean>(false);
  const [ColorQuantity, setColorQuantity] = useState<CmixQuantityDataModel | null>(null);
  const [ErrorDiv, setErrorDiv] = useState<boolean>(false);
  const [Amount, setAmount] = useState<number>(1);
  const [ShowSpinner, setShowSpinner] = useState(false);
  const { Handle } = Slider;

  const dispatch = useDispatch();
  const pdmAuthState = useSelector((state: RootState) => state.PdmAuth);
  const history = useHistory();

  useEffect(() => {
    asyncInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const asyncInit = async () => {
    const pdmAuthorized = await AuthorizationService.getInstance().checkApi();

    if (pdmAuthorized.status === 200) {
      if (!pdmAuthorized.data) {
        if (pdmAuthState !== pdmAuthorized.data) {
          dispatch(pdmUnauthorize());
        }
        history.push('/AuthorizePDM');
      }

      if (pdmAuthState !== pdmAuthorized.data) {
        dispatch(pdmAuthorize());
      }
    }
  };


  const searchForTheColor = async (e: any) => {
    let res: CmixDataModel | null;
    let error: any;
    setShowSpinner(true);
    switch (e.PickedColorPalletsOption) {
      case "CMiX":
        [res, error] = await HttpRequestWithForbiddenCheck(CMiXServiceInstance.getCMiXColor(e.ColorNumber), 'get CMiX Color', 'Error When Getting The Cmix Color');
        break;

      case "RAL":
        [res, error] = await HttpRequestWithForbiddenCheck(CMiXServiceInstance.getRALColor(e.ColorNumber), 'getRALColor', 'Error When Getting The Ral Color');
        break;

      case "Pantone":
        [res, error] = await HttpRequestWithForbiddenCheck(CMiXServiceInstance.getPantoneColor(e.ColorNumber), 'getPantoneColor', 'Error When Getting The Pantone Color');
        break;

      default:
        return;
    }
    setShowSpinner(false);
    if (!error && res !== null) {
      setColorResult(res);
      setOpenColorData(true);
      setErrorDiv(false);
      if (OpenColorQuantity) setOpenColorQuantity(false);
      if (quantity !== 1) setQuantity(1);
    }
    else {
      const err = error as AxiosError;
      // check if error is not found cmix but ok request
      if (err.response !== undefined && err.response.status === 404) {
        setErrorDiv(true);
      }
      setOpenColorData(false);
    }
  };

  const calculateQuantity = async () => {
    if (ColorResult !== undefined && ColorResult !== null) {
      const [res, error] = await HttpRequestWithForbiddenCheck(CMiXServiceInstance.getCMiXQuantity(quantity, ColorResult), 'getCMiXQuantity', 'Error When Getting The Cmix Quantity');
      if (!error && res !== null) {
        setAmount(quantity);
        setColorQuantity(res);
        setOpenColorQuantity(true);
        setErrorDiv(false);
      }
      else {
        setOpenColorData(false);
        setErrorDiv(true);
      }
    }
  };

  const openPrintPage = () => {
    let queryLine = 'CmixPrintPage/';
    const a = fromObject(ColorQuantity);
    const b = fromObject(ColorResult);
    const c = fromObject(Amount);
    queryLine += a + '/' + b + '/Amount' + c;
    window.open(queryLine, "_blank");
  };

  const handle = (items: any) => {
    const { value, dragging, index, ...restProps } = items;
    return (
      <SliderTooltip
        prefixCls="rc-slider-tooltip"
        overlay={`${value} KG`}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </SliderTooltip>
    );
  };

  const handleChange = (e: any) => {
    setQuantity(e);
  };

  return (
    <Grid container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      width={{ xs: '100%', lg: '60%' }}
      style={{ height: "100%", 'margin': '0 auto' }}>

      <Grid item xs={12}>
        <CMiXColorForm submitForm={searchForTheColor} />
      </Grid>

      {ShowSpinner && <CircularProgress />}

      <Grid item xs={12}>
        <div style={{ 'display': !ErrorDiv ? "none" : 'block' }}>
          <div className='text-center' style={{ "color": "purple", "paddingBottom": "8px", "fontSize": "22px" }}>
            <ColoredLine color={"purple"} height={"2px"} />
            <i className="fas fa-exclamation-circle"></i>
            {"CMiX Color Could Not Be Found"}
          </div>
        </div>
      </Grid>

      <Grid item xs={12}>
        {ColorResult !== undefined &&
          <>
            <div style={{ 'display': !OpenColorData ? "none" : 'block' }}>
              <ColorIDs ColorResult={ColorResult} />
            </div>
            <br />

            <div style={{ 'display': !OpenColorData ? "none" : 'block' }}>

              <div style={{ 'display': !OpenColorData ? "none" : 'block' }} >
                {ColorResult && <CMiXFormulationTable FormulationList={ColorResult.FormulationList} />}
              </div>

              <br />
              <br />

              <div style={{ 'display': !OpenColorData ? "none" : 'block' }}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6} md={1}>Quantity:</Grid>
                  <Grid item xs={6} sm={6} md={1}><span>{quantity}Kg</span></Grid>
                  <Grid sx={{ marginRight: { md: '10px', sm: 'none' }, textAlign: { md: 'end', sm: 'start' } }} item xs={1} className={Styles["rightText"]}>1Kg</Grid>
                  <Grid item xs={10} sm={10} md={4}>
                    <div>
                      <Slider
                        min={1}
                        max={10}
                        onChange={handleChange}
                        handle={handle}
                        value={quantity}
                        handleStyle={{ border: 'solid 2px' + theme.palette.primary.dark }}
                        trackStyle={{ backgroundColor: theme.palette.primary.main }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={1} className={Styles["leftText"]}>10Kg</Grid>
                  <Grid item xs={6} sm={6} md={2}>
                    <MainButton onClick={calculateQuantity}>
                      {t("general.labels.calculate")}
                    </MainButton>
                  </Grid>
                  <Grid item xs={6} sm={6} md={1}>
                    {ColorQuantity && <MainButton sx={{ float: { xs: 'end' } }} startIcon={<Icons.PrintIcon />} onClick={openPrintPage}>
                      {t("general.buttons.print")}
                    </MainButton>}
                  </Grid>
                </Grid>
              </div>

              <br />
              {ColorQuantity && <CMiXQuantityTable QuantityList={ColorQuantity.CmixColorList} />}
              <br />

              <p>{t("general.cmixDisclaimer")}</p>
            </div>
          </>
        }
      </Grid>
    </Grid>
  );
};

export default Cmix;
