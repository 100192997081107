import { DriveDataModel } from "../../../../../Models/Api/JobManager/DriveDataModel";
import { JobDataModel } from "../../../../../Models/Api/Job/JobDataModel";
import PDMServerHttpClient from "../../PDMServerHttpClient";
import { JobHistoryDataModel } from "../../../../../Models/Api/Job/JobHistoryDataModel";
import { JobDataVersionDataModel } from "../../../../../Models/Api/Job/JobDataVersionDataModel";
import { JobsByMonthDataModel } from "../../../../../Models/Api/JobManager/JobsByMonthDataModel";
import { propertyOf } from "../../../../../Helpers/propertyOf";
import { AddJobFormModel } from "../../../../../Models/Forms/AddJob/AddJobFormModel";

class JobManagerService {
  async getJobs() {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<JobDataModel[]>("/api/JobManager/UI/v1.0/ListAll/0");
  }

  async getJob(jobId: string) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<JobDataModel>(`/api/JobManager/UI/v1.0/GetJob/${jobId}`);
  }

  async getTopJobs(number = 5) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<JobDataModel[]>(`/api/JobManager/UI/v1.0/ListAll/${number}`);
  }

  async deleteJob(jobId: string) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.delete<boolean>(`/api/JobManager/UI/v1.0/DeleteJob/${jobId}`);
  }

  async softDeleteJob(jobId: string) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.delete<boolean>(`/api/JobManager/UI/v1.0/SoftDeleteJob/${jobId}`);
  }

  async getSoftDeleteJob(qty = 0) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<JobDataModel[]>(`/api/JobManager/UI/v1.0/ListAllDeleted/${qty.toString()}`);
  }

  async getJobByMonth() {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<JobsByMonthDataModel>("/api/JobManager/UI/v1.0/getJobsByMonth");
  }

  async getJobQTY() {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<string>("/api/JobManager/UI/v1.0/GetJobQTY");
  }

  async addJob(job: AddJobFormModel) {
    const formData = new FormData();
    formData.append(propertyOf<AddJobFormModel>('JobZipFileName'), job.JobZipFileName);
    formData.append(propertyOf<AddJobFormModel>('Comments'), job.Comments);
    formData.append(propertyOf<AddJobFormModel>('ProjectName'), job.ProjectName);

    for (let index = 0; index < job.Tags.length; index++) {
      formData.append(`${propertyOf<AddJobFormModel>('Tags')}[${index}]`, job.Tags[index]);
    }

    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.post<string>(`/api/JobManager/UI/v1.0/AddJob`, formData, {
      // A progress event for axios, we could show loading status when adding a job
      // onUploadProgress: data => {
      //   //Set the progress value to show the progress bar
      //   progressSetStateInstance(Math.round((100 * data.loaded) / data.total))
      // },
    });
  }

  async editJob(JobId: string, JobName: string, Tags: string[], ProjectName: string, Comments: string) {
    const formData = new FormData();
    formData.append("Id", JobId);
    formData.append("JobName", JobName);

    for (let index = 0; index < Tags.length; index++) {
      formData.append(`Tags[${index}]`, Tags[index]);
    }

    formData.append("Comments", Comments);
    formData.append("ProjectName", ProjectName);

    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.put(`/api/JobManager/UI/v1.0/Edit/${JobId}`, formData);
  }

  async GetSpaceUsing() {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<DriveDataModel>("/api/JobManager/UI/v1.0/SpaceUsing");
  }

  async getJobHistory(jobId: string, limit = 0) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<JobHistoryDataModel[]>(`/api/JobManager/UI/v1.0/getJobHistory/${jobId}/${limit}`);
  }

  async getJobDataVersions(jobId: string) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<JobDataVersionDataModel[]>(`/api/JobManager/UI/v1.0/getJobDataVersions/${jobId}`);
  }

  async restoreJobData(jobId: string, versionId: string) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<boolean>(`/api/JobManager/UI/v1.0/RestoreJobData/${jobId}/${versionId}`);
  }

  async restoreDeletedJob(jobId: string) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get(`/api/JobManager/UI/v1.0/RestoreJob/${jobId}`);
  }

  async deleteAll() {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.delete(`/api/JobManager/UI/v1.0/DeleteManyJobs/-1`);
  }

  async uploadChunks(id: number, fileName: string, jobName: string, chunk: Blob, isFirst: boolean = false) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.post(`/api/JobManager/UI/v1.0/UploadChunks/${id}/${fileName}/${jobName}/${isFirst}`, chunk);
  }

  async uploadComplete(job: AddJobFormModel, fileGuid: string, jobName: string) {
    const formData = new FormData();
    formData.append(propertyOf<AddJobFormModel>('JobZipFileName'), job.JobZipFileName);
    formData.append(propertyOf<AddJobFormModel>('Comments'), job.Comments);
    formData.append(propertyOf<AddJobFormModel>('ProjectName'), job.ProjectName);

    for (let index = 0; index < job.Tags.length; index++) {
      formData.append(`${propertyOf<AddJobFormModel>('Tags')}[${index}]`, job.Tags[index]);
    }

    let api = await PDMServerHttpClient.getInstance();

    return await api.instance.post(`/api/JobManager/UI/v1.0/UploadComplete/${fileGuid}/${jobName}`, formData);
  }

  static getInstance() {
    return new JobManagerService();
  }
}

export default JobManagerService;
