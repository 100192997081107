import { useState, useEffect, useRef } from "react";
import { InkUsage, SearchableTable, PrintStatistics, PrintersStatistics, SearchableTableForPrint } from "../../../../../modules/ReportCenter/ReportCenterImports";
import { useTranslation } from "react-i18next";
import { Grid, Card, CardContent, Divider, Button } from '@mui/material';
import { PrintEventDataModel } from "../../../../../Models/Api/Events/PrintEventDataModel";
import { InkUsageDataModel } from "../../../../../Models/Api/ReportCenter/InkUsageDataModel";
import { PrintEventStatusDataModel } from "../../../../../Models/Api/Events/PrintEventStatusDataModel";
import HttpRequestWithForbiddenCheck from "../../../../../services/HttpRequestWithForbiddenCheck/HttpRequestWithForbiddenCheck";
//import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../Store/Store";
import { pdmAuthorize, pdmUnauthorize } from "../../../../../Store/Actions/Actions";
import { useHistory } from "react-router-dom";
import { PrintersSquareMeterSummeryDataModel } from "../../../../../Models/Api/Events/PrintersSquareMeterSummeryDataModel";
import Icons from "../../../../../Imports/MUI/Icons/IconsImports";
import UseQuery from "../../../../../Hooks/UseQuery";
import ReactToPrint from "react-to-print";
import Styles from './PrintProjects.module.scss';
import { AuthorizationService, /*AllPrintersService,*/ ByProjectService } from "../../../../../Api/Rest/PDMServer/ApiServices/PDMApiServicesImports";
import FiltersSessionStorageService, { SessionStorageFiltersEnum } from "../../../../../services/SessionStorage/Filters/FiltersSessionStorageService";

function PrintProjects() {
  const [t] = useTranslation();
  const layoutDiv = useRef<HTMLDivElement>(null);
  const [printedJobs, setPrintedJobs] = useState<PrintEventDataModel[]>([]);
  const [inkUsageData, setInkUsageData] = useState<InkUsageDataModel[]>([]);
  const [printersSquareMeter, setPrintersSquareMeter] = useState<PrintersSquareMeterSummeryDataModel[] | null>(null)
  const [printStatisticsData, setPrintStatisticsData] = useState<PrintEventStatusDataModel | null>(null);
  const dispatch = useDispatch();
  const pdmAuthState = useSelector((state: RootState) => state.PdmAuth);
  const history = useHistory();
  const [filter, setFilter] = useState<string>('');

  const query = UseQuery();

  useEffect(() => {
    asyncInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const asyncInit = async () => {
    const projectName = query.get("projectName") ?? "";

    const pdmAuthorized = await AuthorizationService.getInstance().checkApi();

    if (pdmAuthorized.status === 200) {
      if (!pdmAuthorized.data) {
        if (pdmAuthState !== pdmAuthorized.data) {
          dispatch(pdmUnauthorize());
        }
        history.push('/AuthorizePDM');
      }

      if (pdmAuthState !== pdmAuthorized.data) {
        dispatch(pdmAuthorize());
      }

      loadPrintStatistic(projectName);
      loadPrinterEventsData(projectName);
      loadInkUsage(projectName);
      loadPrintersSquareMeters(projectName);

      let sessionStorageFilter =
        FiltersSessionStorageService.GetSessionStorageFilter(SessionStorageFiltersEnum.REPORT_CENTER_PROJECTS_PRINTED_JOBS_FILTER);

      if (sessionStorageFilter) {
        setFilter(sessionStorageFilter);
      }
    }
  };

  const loadPrintersSquareMeters = async (projectName: string) => {
    const [data, error] = await HttpRequestWithForbiddenCheck(ByProjectService.getInstance().getPrintedSquareMetersByRange(projectName), 'getInkUsage', 'Error When Getting The Ink Usage');
    if (!error && data !== null) {
      setPrintersSquareMeter(data);
    }
  }

  const loadInkUsage = async (projectName: string) => {
    const [data, error] = await HttpRequestWithForbiddenCheck(ByProjectService.getInstance().getInkUsage(projectName), 'getInkUsage', 'Error When Getting The Ink Usage');
    if (!error && data !== null) {
      setInkUsageData(data);
    }
  };

  const loadPrinterEventsData = async (projectName: string) => {
    const [data, error] = await HttpRequestWithForbiddenCheck(ByProjectService.getInstance().getPrintedJobsByRange(projectName), 'getPrintedJobsByRange', 'Error When Getting The Printed Jobs By Range');
    if (!error && data !== null) {
      setPrintedJobs(data);
    }
  };

  const loadPrintStatistic = async (projectName: string) => {
    const [data, error] = await HttpRequestWithForbiddenCheck(ByProjectService.getInstance().getPrintEventStatus(projectName), 'getPrintEventStatus', 'Error When Getting The Print Event Status');
    if (!error && data !== null) {
      setPrintStatisticsData(data);
    }
  };

  const onSaveFilter = (filter: string) => {
    FiltersSessionStorageService.SetSessionStorageFilter(SessionStorageFiltersEnum.REPORT_CENTER_PROJECTS_PRINTED_JOBS_FILTER, filter);
    setFilter(filter);
  }

  return (
    <div>
      <ReactToPrint pageStyle={""} content={() => layoutDiv.current} trigger={() => <ul className="nav float-right"><Button variant="outlined" startIcon={<Icons.PrintIcon />}>Print</Button></ul>}>
      </ReactToPrint>
      <div ref={layoutDiv}>
        <img
          src='/img/Logos/LogoDipTechBlack.png'
          alt="Logo"
          className={"brand-image " + Styles['width-90-px']}
        />
        <div>
          <div className={Styles['print-name-div']}>
            <b>By Project</b>
          </div>
          <br />

          <Grid container className='show-elements' spacing={2}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <div className="Orbitron20">
                    <h5>{t("general.cards.inkUsage")}</h5>
                  </div>
                  <Divider style={{ 'marginBottom': '15px' }} />
                  <InkUsage inkUsageData={inkUsageData} />
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <PrintersStatistics printerStatisticsData={printersSquareMeter} />
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <PrintStatistics printStatisticsData={printStatisticsData} />
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <SearchableTable
                printedJobs={printedJobs}
                Title={t("general.cards.printedJobs")}
                ShowTitle={true}
                filter={filter}
                onSaveFilter={onSaveFilter}
              />
            </Grid>
          </Grid>

          <Grid container className='print-elements' spacing={2} display="none">
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <div className="Orbitron20">
                    <h5>{t("general.cards.inkUsage")}</h5>
                  </div>
                  <Divider style={{ 'marginBottom': '15px' }} />
                  {/* <InkUsageForPrint inkUsageData={inkUsageData} /> */}
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <PrintersStatistics printerStatisticsData={printersSquareMeter} />
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <PrintStatistics printStatisticsData={printStatisticsData} />
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <br />
              <SearchableTableForPrint printedJobs={printedJobs} Title={t("general.cards.printedJobs")} ShowTitle={true} />
            </Grid>
          </Grid>
        </div>
      </div>
      <style>
        {`@media print {
                    .show-elements{display: none;}
                    .print-elements{display: block;}
                }`}
      </style>
    </div>
  );
};
export default PrintProjects;
