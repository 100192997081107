import { useTheme } from "@mui/material";
import { ColorsStyle } from "../../../modules/ReportCenter/ReportCenterStylesImports";

interface CustomLiProps {
    Title: string
    Data: any
    className: string,
    marginTop?: string
}

const CustomLi = ({ Title, Data, className, marginTop = '5px' }: CustomLiProps) => {
    const theme = useTheme();

    return (
        <li style={{
            'backgroundColor': theme.palette.mode === 'dark' ? '#252929' : 'white',
        }} className="list-group-item mx-5">
            <div className="Orbitron15 text-muted" style={{
                'float': 'left',
                'width': '175px',
                'height': 'auto',
                'marginTop': marginTop
            }}>
                {Title}
            </div>
            <div className={`float-right Orbitron16 ${ColorsStyle[className]}`} style={{ 'width': '225px', 'height': 'auto' }}>
                <span className="d-flex justify-content-center">{Data}</span>
            </div>
        </li>
    );
};

export default CustomLi;