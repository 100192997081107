import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import UseQuery from "../../../../../Hooks/UseQuery";
import humanizeDuration from "humanize-duration";
import humanizeString from "humanize-string";
import { useTranslation } from "react-i18next";
import { CustomListItem } from "../../../../../components/ThemeElements/ThemeElementsExport";
import React from "react";
import { List, Box, Typography, CardContent, Card, Grid, Chip, Divider, Tooltip } from '@mui/material';
import { EventDataModel } from "../../../../../Models/Api/Events/EventDataModel";
import HttpRequestWithForbiddenCheck from "../../../../../services/HttpRequestWithForbiddenCheck/HttpRequestWithForbiddenCheck";
import { pdmAuthorize, pdmUnauthorize } from "../../../../../Store/Actions/Actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../Store/Store";
import moment from "moment";
import roundTo from "../../../../../Pipes/RoundTo";
import { MainButton } from "../../../../../components/General/GeneralExport";
import { AuthorizationService, AllPrintersService } from "../../../../../Api/Rest/PDMServer/ApiServices/PDMApiServicesImports";

const PrintHistory = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const query = UseQuery();
  const allPrintersService = AllPrintersService.getInstance();
  const [state, setState] = React.useState<EventDataModel>({} as EventDataModel);
  const dispatch = useDispatch();
  const pdmAuthState = useSelector((state: RootState) => state.PdmAuth);

  React.useEffect(() => {
    asyncInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const asyncInit = async () => {
    const pdmAuthorized = await AuthorizationService.getInstance().checkApi();

    if (pdmAuthorized.status === 200) {
      if (!pdmAuthorized.data) {
        if (pdmAuthState !== pdmAuthorized.data) {
          dispatch(pdmUnauthorize());
        }
        history.push('/AuthorizePDM');
      }

      if (pdmAuthState !== pdmAuthorized.data) {
        dispatch(pdmAuthorize());
      }

      loadPrintHistory();
    }
  };

  const loadPrintHistory = async () => {
    const Id = query.get("Id");
    let id = '';

    if (Id !== null)
      id = Id;

    const [data, error] =
      await HttpRequestWithForbiddenCheck(allPrintersService.GetEventById(id),
        'PrintHistory', 'Error When Getting The Printed History');

    if (!error && data !== null) {
      setState(data);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={9} xl={8}>
        <Card className="noBackgroundImage">
          <CardContent>
            {state !== undefined &&
              <>

                <Typography gutterBottom variant="h5" component="div">
                  {state.Job && state.Job.JobName && state.Job.JobName}
                </Typography>

                <Box sx={{ width: '100%', backgroundColor: 'background.paper' }}>
                  <nav aria-label="main mailbox folders">
                    <Grid container >
                      <Grid item xs={12} md={6}>
                        <List style={{ 'marginLeft': '20px' }}>
                          <CustomListItem Title={`${t("general.jobInfo.jobName")} :`}>
                            <>
                              {
                                state.Job && state.Job.JobName &&
                                <span className="float-right">
                                  {state.Job.JobName}
                                </span>
                              }
                            </>
                          </CustomListItem>

                          <CustomListItem Title={`${t("general.jobInfo.projectName")} :`}>
                            <>
                              {
                                state.Job && state.Job.ProjectName &&
                                <span className="float-right">
                                  {state.Job.ProjectName}
                                </span>
                              }
                            </>
                          </CustomListItem>

                          <CustomListItem Title={`${t("general.labels.status")} :`}>
                            <>
                              {
                                state.PrintStatus &&
                                <span className="float-right">
                                  {state.PrintStatus}
                                </span>
                              }
                            </>
                          </CustomListItem>

                          <CustomListItem Title={`${t("general.printInfo.eventDate")} :`}>
                            <>
                              {
                                state.CreateEventDateTime && state.CreateEventDateTime &&
                                <span className="float-right">
                                  {moment.utc(state.CreateEventDateTime).local().format('DD/MM/YYYY H:mm')}
                                </span>
                              }
                            </>
                          </CustomListItem>

                          <CustomListItem Title={`${t("general.jobInfo.tags")} :`}>
                            <span className="float-right">
                              {
                                state.Tags &&
                                state.Tags.map((tag) =>
                                  <Chip size="small" key={uuidv4()} color='primary' label={tag} style={{ 'marginLeft': '2px', 'marginRight': '2px' }} />
                                )
                              }
                            </span>
                          </CustomListItem>

                          <CustomListItem Title={`${t("general.printInfo.printedOn")} :`}>
                            <>
                              {
                                state.PrinterId &&
                                <span className="float-right">
                                  {state.PrinterId}
                                </span>
                              }
                            </>
                          </CustomListItem>

                          <CustomListItem Title={`${t("general.printInfo.netoPrintTime")} :`}>
                            <>
                              {
                                state.NetoPrintTime &&
                                <span className="float-right">
                                  {humanizeDuration(state.NetoPrintTime, { round: true, })}
                                </span>
                              }
                            </>
                          </CustomListItem>

                          <CustomListItem Title={`${t("general.printInfo.printMode")} :`}>
                            <>
                              {
                                state.Job && state.Job.PrintInfo &&
                                <span className="float-right">
                                  {state.Job.PrintInfo.PrintMode}
                                </span>
                              }
                            </>
                          </CustomListItem>

                          <CustomListItem Title={`${t("general.printInfo.squareMeter")} :`}>
                            <>
                              {
                                state.Job && state.Job.PrintInfo &&
                                <span className="float-right">
                                  {state.Job.PrintInfo.PrintedSQM >= 50 ? roundTo(state.Job.PrintInfo.PrintedSQM, 0) : roundTo(state.Job.PrintInfo.PrintedSQM, 3)} m<sup>2</sup>
                                </span>
                              }
                            </>
                          </CustomListItem>

                          <CustomListItem Title={`${t("general.printInfo.glassThickness")} :`}>
                            <>
                              {
                                state.Job && state.Job.PrintInfo &&
                                <span className="float-right">
                                  {roundTo(state.Job.PrintInfo.GlassThickness, 2)} {t("general.labels.millimeter")}
                                </span>
                              }
                            </>
                          </CustomListItem>

                          <CustomListItem Title={`${t("general.printInfo.printStrategy")} :`}>
                            <>
                              {
                                state.Job && state.Job.PrintInfo &&
                                <span className="float-right">
                                  {state.Job.PrintInfo.PrintStrategy}
                                </span>
                              }
                            </>
                          </CustomListItem>
                        </List>
                      </Grid>
                      <Grid item xs={1} display={{ xs: 'none', md: 'block' }}>
                        <Divider orientation="vertical" style={{ 'width': '50%', 'marginRight': '65% !important' }} />
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <List>

                          <CustomListItem Title={t("general.printInfo.colorsQuantity") + ' :'} isLast>
                            <>

                            </>
                          </CustomListItem>

                          {
                            state.Job && state.Job.JobColors.map((color, index) => {
                              if (color.InkQuantity) {
                                return (
                                  <CustomListItem
                                    key={uuidv4()}
                                    numberOfIndentation={2}
                                    Title={humanizeString(color.ColorName) + ' :'}
                                    isLast={index < state.Job.JobColors.length}>
                                    <div key={uuidv4()} style={{ 'display': 'flex' }}>
                                      <div style={{ 'marginRight': '15px' }}>{color.InkQuantity.toFixed(3)} cc</div>
                                      <Tooltip placement="right" arrow
                                        title={color.ColorName}>
                                        <div className={`bg-${color.ColorName.toLowerCase()} colorBox`} />
                                      </Tooltip>
                                    </div>
                                  </CustomListItem>
                                )
                              }
                              else return null;
                            }
                            )
                          }

                        </List>
                      </Grid>
                    </Grid>
                  </nav>
                </Box>
              </>
            }
            <MainButton style={{ 'float': 'right', 'marginBottom': '10px' }} onClick={() => history.goBack()}>
              {t("general.buttons.close")}
            </MainButton>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default PrintHistory;
