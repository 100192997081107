import { useState, useEffect, CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Card, CardContent, Typography, CardActions, Chip } from '@mui/material';
import HttpRequestWithForbiddenCheck from "../../../services/HttpRequestWithForbiddenCheck/HttpRequestWithForbiddenCheck";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Store/Store";
import { pdmAuthorize, pdmUnauthorize } from "../../../Store/Actions/Actions";
import { useHistory } from "react-router-dom";
import { MainButton } from "../../../components/General/GeneralExport";
import { PrinterApi } from "../../../Models/Api/General/Printer";
import { AuthorizationService, PrinterTicketService } from "../../../Api/Rest/PDMServer/ApiServices/PDMApiServicesImports";
import Style from './ReportCenter.module.scss';

const ReportCenter = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const pdmAuthState = useSelector((state: RootState) => state.PdmAuth);
  const history = useHistory();
  const printerTicketService = PrinterTicketService.getInstance();
  const [printers, setPrinters] = useState<PrinterApi[]>();

  useEffect(() => {
    asyncInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const asyncInit = async () => {
    const pdmAuthorized = await AuthorizationService.getInstance().checkApi();

    if (pdmAuthorized.status === 200) {
      if (!pdmAuthorized.data) {
        if (pdmAuthState !== pdmAuthorized.data) {
          dispatch(pdmUnauthorize());
        }
        history.push('/AuthorizePDM');
      }
      
      if (pdmAuthState !== pdmAuthorized.data) {
        dispatch(pdmAuthorize());
      }

      const [printersResponse, printersErr] = await HttpRequestWithForbiddenCheck(printerTicketService.getListOfPrinters(), 'getListOfPrinters', 'Error When Getting The list Of Printers');
      if (!printersErr && printersResponse !== null) {
        setPrinters(printersResponse)
      }
    }
  };


  return (
    <Grid container spacing={2}>
      <CustomCard Icon={
        <img
          className={Style['selector']}
          src="/img/Logos/ProjectsIcon.png"
          alt="Logo"
          style={{ opacity: ".8", 'width': '70px' }}
        />
      } header={t('general.cards.projects')} urlPath={'/ReportCenter/Projects'} />

      <CustomCard Icon={
        <img
          className={Style['selector']}
          src="/img/Logos/InkUsageIcon.png"
          alt="Logo"
          style={{ opacity: ".8", 'width': '70px' }}
        />
      } header={t('general.cards.inkUsage')} urlPath={'/ReportCenter/InkUsage'} />

      <CustomCard Icon={
        <img
          className={Style['selector']}
          src="/img/Logos/AllPrintersIcon.png"
          alt="Logo"
          style={{ opacity: ".8", 'width': '70px' }}
        />
      } header={t('general.cards.allPrinters')} urlPath={'/ReportCenter/AllPrinters'} />

      {
        printers && printers.map((printer: PrinterApi) => {
          return (
            <CustomCard body1Style={{
              'padding': '2px',
            }} header={t('general.cards.printer')} body1={printer.PrinterType} body2={printer.PrinterId} urlPath={`/ReportCenter/${printer.PrinterId}`} />
          );
        })
      }

    </Grid>
  );
};
type customCardProps = {
  header: string,
  body1?: string,
  body2?: string,
  urlPath: string,
  useChipInBody1?: boolean
  useChipInBody2?: boolean
  Icon?: JSX.Element,
  body1Style?: CSSProperties | undefined
};

const CustomCard = ({ header, body1, body2, urlPath, Icon, body1Style, useChipInBody1 = false, useChipInBody2 = false }: customCardProps) => {
  const [t] = useTranslation();
  const history = useHistory();

  return (
    <Grid item xs={12} lg={3}>
      <Card style={{ 'height': '180px' }}>
        <CardContent style={{ 'textAlign': 'center', 'height': '67%' }}>
          <Typography variant="h6">
            {header}
          </Typography>
          {Icon}
          {
            useChipInBody1
              ?
              <Chip size="small" color='primary' label={body1} style={{ 'marginBottom': '5px' }} />
              :
              <Typography variant="body1" style={body1Style}>
                {body1}
              </Typography>
          }
          {
            useChipInBody2
              ?
              <Chip size="small" color='primary' label={body2} />
              :
              <Typography variant="body2">
                {body2}
              </Typography>
          }
        </CardContent>
        <CardActions>
          <MainButton fullWidth onClick={() => history.push(urlPath)}>
            {t('general.buttons.openReport')}
          </MainButton>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default ReportCenter;
