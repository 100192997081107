import React from 'react'
import { Grid } from '@mui/material';
import Icons from '../../../Imports/MUI/Icons/IconsImports';

import humanizeString from 'humanize-string';

type ForbiddenLayoutProps = {
  Message: string
};

function ForbiddenLayout({ Message }: ForbiddenLayoutProps) {
  return (
    <Grid container>
      <Grid item xs={2}>
        <Icons.WarningAmberIcon />
      </Grid>
      <Grid item xs={10}>
        {/* For some reason humanizeString slice the cmix word */}
        Seems like you don't have the wanted permission to execute: {Message.toLocaleLowerCase().includes('cmix') ? Message : humanizeString(Message)} operation
      </Grid>
    </Grid>
  )
}

export default ForbiddenLayout;