import React from "react";
import { useDispatch } from "react-redux";
import { tabActive, tabInactive } from "../../../Store/Actions/Actions";

type WindowFocusHandlerProps = {
    Children: JSX.Element
};

const WindowFocusHandler = ({ Children }: WindowFocusHandlerProps) => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        // window.addEventListener("focus", onFocus);
        // window.addEventListener("blur", onBlur);
        document.addEventListener('visibilitychange', onVisibilityChange);
        // Calls onFocus when the window first loads
        // onFocus();
        // Specify how to clean up after this effect:
        return () => {
            // window.removeEventListener("focus", onFocus);
            // window.removeEventListener("blur", onBlur);
            document.removeEventListener('visibilitychange', onVisibilityChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // User has switched back to the tab
    // const onFocus = async () => {
    //     dispatch(tabActive());
    // };

    // User has switched away from the tab (AKA tab is hidden)
    // const onBlur = async () => {
    //     dispatch(tabInactive());
    // };

    const onVisibilityChange = () => {
        if (document.hidden) {
            dispatch(tabInactive());
        } else {
            dispatch(tabActive());
        }
    };

    return Children;
};

export default WindowFocusHandler;