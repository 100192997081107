import /*React,*/ { /*useRef,*/ /*useState,*/ useEffect } from 'react'
import { useFormik } from "formik";
import { Checkbox, FormControl, Grid, /*IconButton,*/ /*InputAdornment,*/ ListItemText, MenuItem, /*OutlinedInput,*/ Select } from '@mui/material';
import { useTranslation } from "react-i18next";
import { MainButton } from '../../../../components/General/GeneralExport';
import { FormError } from '../../../../components/Forms/FormsExport';
import { useTheme } from '@mui/material/styles';
import HttpRequestWithForbiddenCheck from '../../../../services/HttpRequestWithForbiddenCheck/HttpRequestWithForbiddenCheck';
import { toast } from "react-toastify";
//import Visibility from '@mui/icons-material/Visibility';
//import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useHistory } from 'react-router-dom';
//import { v4 as uuidv4 } from "uuid";
import { RootState } from '../../../../Store/Store';
import { useDispatch, useSelector } from 'react-redux';
import { pdmAuthorize, pdmUnauthorize } from '../../../../Store/Actions/Actions';
//import AwaitHandler from "../../../../services/AwaitHandling/AwaitHandling";
import { AuthorizationService /*, UserService*/ /*, userInfo*/ } from '../../../../Api/Rest/PDMServer/ApiServices/PDMApiServicesImports';
import * as Yup from "yup";
import englishAndCharsOnly from '../../../../Validators/Private/englishAndCharsOnly';
import phoneRegExp from '../../../../Validators/Private/phoneNumber';
import { AddTicketEventFormModel } from '../../../../Models/Forms/AddTicketEvent/AddTicketEventFormModel';
import TicketEventService from '../../../../Api/Rest/PDMServer/ApiServices/Events/ticketEventService';
import Styles from './CreateTicketEvent.module.scss';

const CreateTicketEvent = () => {
  const theme = useTheme();
  const [t] = useTranslation();
  const history = useHistory();
  const pdmAuthState = useSelector((state: RootState) => state.PdmAuth);
  const dispatch = useDispatch();
  //const userService = UserService.getInstance();
  const logService = TicketEventService.getInstance();

  const validationSchema = Yup.object({
    TicketType: Yup.string()
      .oneOf(["Error", "Question"])
      .required(t('validation.required')),
    Title: Yup.string()
      .matches(englishAndCharsOnly, t('validation.onlyEnglishLetters'))
      .max(30, t('validation.mustBe30CharsOrLess'))
      .required(t('validation.required')),
    EventDescription: Yup.string()
      .matches(englishAndCharsOnly, t('validation.onlyEnglishLetters'))
      .max(30, t('validation.mustBe30CharsOrLess'))
      .required(t('validation.required')),
    PhoneNumber: Yup.string()
      .matches(phoneRegExp, t('validation.phoneNumber'))
      .required(t('validation.required')),
    Email: Yup.string()
      .email()
      .matches(englishAndCharsOnly, t('validation.onlyEnglishLetters'))
      // .max(30, t('validation.mustBe30CharsOrLess'))
      .required(t('validation.required')),
    AddLogData: Yup.boolean(),
  });

  useEffect(() => {
    Object.keys(formik.values).forEach((fieldName) => {
      formik.setFieldTouched(fieldName);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t])

  useEffect(() => {
    asyncInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const formik = useFormik({
    initialValues: {
      TicketType: '',
      Title: '',
      EventDescription: '',
      PhoneNumber: '',
      Email: '',
      AddLogData: false
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      submitF(values);
    },
  });

  const submitF = async (user: any) => {
    const logModel: AddTicketEventFormModel = {
      TicketType: user.TicketType,
      Title: user.Title,
      EventDescription: user.EventDescription,
      AddLogData: user.AddLogData,
      User: {
        PhoneNumber: user.PhoneNumber,
        Email: user.Email,
      },

      PDMVersion: process.env.REACT_APP_VERSION_INFO ?? 'placeHolderVersion'
    };

    const [, error] = await HttpRequestWithForbiddenCheck(logService.postAddTicketEvent(logModel), 'AddTicketEvent', 'Error When Adding A Ticket Event');
    if (!error) {
      toast.success("Ticket event was added successfully", {
        style: { "backgroundColor": "#6f42c1" }
      });
      history.push('/');
    }
  };

  const asyncInit = async () => {
    const pdmAuthorized = await AuthorizationService.getInstance().checkApi();

    if (pdmAuthorized.status === 200) {
      if (!pdmAuthorized.data) {
        if (pdmAuthState !== pdmAuthorized.data) {
          dispatch(pdmUnauthorize());
        }
        history.push('/AuthorizePDM');
      }

      if (pdmAuthState !== pdmAuthorized.data) {
        dispatch(pdmAuthorize());
      }
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <form onSubmit={formik.handleSubmit}>

          <Grid container spacing={1}>

            <Grid item xs={12}>
              <label className={Styles["add-user-form-label"] + " required"}>{t("general.labels.ticketType")}</label>
              <div style={{ 'width': '100%' }}>
                <FormControl style={{ 'width': '100%' }}>
                  <Select
                    style={{
                      'height': '38px',
                      'backgroundColor': theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white',
                      'color': theme.palette.mode === 'dark' ? 'white' : 'black',
                      'border': theme.palette.mode === 'dark' ? '1px solid grey' : 'none'
                    }}
                    {...formik.getFieldProps("TicketType")}
                  >
                    <MenuItem value={'Error'}>
                      <ListItemText primary={'Error'} />
                    </MenuItem>
                    <MenuItem value={'Question'}>
                      <ListItemText primary={'Question'} />
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>

              <FormError disableLine formik={formik} propName={"TicketType"} />
            </Grid>

            <Grid item xs={12}>
              <label className={Styles["add-user-form-label"] + " required"}>{t("general.labels.phoneNumber")}</label>
              <input className="form-control" type="number" lang="en" {...formik.getFieldProps("PhoneNumber")} style={{
                'backgroundColor': theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white',
                'color': theme.palette.mode === 'dark' ? 'white' : 'black'
              }} />
              <FormError disableLine formik={formik} propName={"PhoneNumber"} />
            </Grid>

            <Grid item xs={12}>
              <label className={Styles["add-user-form-label"] + " required"}>{t("general.labels.email")}</label>
              <input className="form-control" type="text" lang="en" {...formik.getFieldProps("Email")} style={{
                'backgroundColor': theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white',
                'color': theme.palette.mode === 'dark' ? 'white' : 'black'
              }} />
              <FormError disableLine formik={formik} propName={"Email"} />
            </Grid>

            <Grid item xs={12}>
              <label className={Styles["add-user-form-label"] + " required"}>{t("general.labels.title")}</label>
              <input className="form-control" type="text" lang="en" {...formik.getFieldProps("Title")} style={{
                'backgroundColor': theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white',
                'color': theme.palette.mode === 'dark' ? 'white' : 'black'
              }} />
              <FormError disableLine formik={formik} propName={"Title"} />
            </Grid>

            <Grid item xs={12}>
              <Checkbox  {...formik.getFieldProps("AddLogData")} />
              <label className={Styles["add-user-from-label"]}>{t("general.labels.addLogData")}</label>
            </Grid>

            <Grid item xs={12}>
              <label className={Styles["add-user-form-label"] + " required"}>{t("general.labels.message")}</label>
              <textarea className="form-control" lang="en" {...formik.getFieldProps("EventDescription")} style={{
                'backgroundColor': theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white',
                'color': theme.palette.mode === 'dark' ? 'white' : 'black'
              }} />
              <FormError disableLine formik={formik} propName={"EventDescription"} />
            </Grid>

            <Grid item xs={12}>
              <MainButton type='submit'>
                {t("general.labels.submit")}
              </MainButton>
            </Grid>
          </Grid>

        </form>
      </Grid>
    </Grid>
  )
}

export default CreateTicketEvent
