import { Button, Chip, Grid } from '@mui/material';
import React, { useState } from 'react'
import ReactToPrint from 'react-to-print';
import useQuery from '../../../../Hooks/UseQuery';
import { JobDataModel } from '../../../../Models/Api/Job/JobDataModel';
import HttpRequestWithForbiddenCheck from '../../../../services/HttpRequestWithForbiddenCheck/HttpRequestWithForbiddenCheck';
import PDMServerLocalStorageService from '../../../../services/LocalStorage/PDMServer/PDMServerService';
import { CustomListItem } from '../../../../components/ThemeElements/ThemeElementsExport';
import { useTranslation } from "react-i18next";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { BarcodeGenerator } from '../../../../components/General/GeneralExport';
import { v4 as uuidv4 } from "uuid";
import Icons from '../../../../Imports/MUI/Icons/IconsImports';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../../Store/Store';
import { pdmAuthorize, pdmUnauthorize } from '../../../../Store/Actions/Actions';
import Styles from './PrintJob.module.scss';
import { AuthorizationService, JobManagerService } from '../../../../Api/Rest/PDMServer/ApiServices/PDMApiServicesImports';
import Typography from '@mui/material/Typography';
//const prettyMetric = require('pretty-metric');

function PrintJob() {
    const [t] = useTranslation();
    const query = useQuery();
    const [singleJob, setSingleJob] = React.useState<JobDataModel | null>(null);
    const layoutDiv = React.useRef<HTMLDivElement>(null);
    const [url, setUrl] = useState('');

    const dispatch = useDispatch();
    const pdmAuthState = useSelector((state: RootState) => state.PdmAuth);
    const history = useHistory();

    React.useEffect(() => {
        asyncInit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const asyncInit = async () => {
        const pdmAuthorized = await AuthorizationService.getInstance().checkApi();

        if (pdmAuthorized.status === 200) {
            if (!pdmAuthorized.data) {
                if (pdmAuthState !== pdmAuthorized.data) {
                    dispatch(pdmUnauthorize());
                }
                history.push('/AuthorizePDM');
            }

            if (pdmAuthState !== pdmAuthorized.data) {
                dispatch(pdmAuthorize());
            }

            let tmp = await PDMServerLocalStorageService.getPDMServerUrl();
            setUrl(tmp);
            initJob();
        }
    };

    const initJob = async () => {
        const jobId = query.get("Id");
        if (jobId !== null) {
            const [jobData, error] = await HttpRequestWithForbiddenCheck(JobManagerService.getInstance().getJob(jobId), 'getJob', 'Error When Getting The Job');
            if (!error && jobData !== null) {
                setSingleJob(jobData);
            }
        }
    };

    return (
        <div className={Styles['padding-25-px']}>
            <ReactToPrint pageStyle={""} content={() => layoutDiv.current} trigger={() => <ul className="nav float-right"><Button variant="outlined" className={Styles['margin-left-15-px']} startIcon={<Icons.PrintIcon />}>Print</Button></ul>}>
            </ReactToPrint>
            <div ref={layoutDiv}>
                {singleJob && (
                    <>
                        <img
                            src='/img/Logos/LogoDipTechBlack.png'
                            alt="Logo"
                            className={"brand-image " + Styles['width-90-px']}
                        />
                        <div className="nav float-right">
                            <BarcodeGenerator Value={singleJob.Id} Width={1} Height={50} FontSize={16} />
                        </div>

                        <br />
                        <br />
                        <div>
                            <div className={Styles['print-name-div']}>
                                <b>{singleJob.Name}</b>
                            </div>
                            <br />
                            <br />
                            <Grid container>
                                <Grid item xs={6}>
                                    <CustomListItem Title={t("general.jobInfo.jobName") + " :"} isLast />
                                    <span className={"float-right " + Styles['margin-right-15-px']}>
                                        <Typography variant={'body1'} noWrap={false} style={{ 'wordBreak': 'break-all', font: 'inherit' }}>
                                            {singleJob.Name}
                                        </Typography>
                                    </span>
                                    <CustomListItem />

                                    <CustomListItem Title={t("general.jobInfo.projectName") + " :"}>
                                        <span className="float-right">
                                            {singleJob.ProjectName}
                                        </span>
                                    </CustomListItem>

                                    <CustomListItem Title={t("general.jobInfo.jobColors") + " :"}>
                                        <div>
                                            {singleJob.JobColors && singleJob.JobColors.map((color) =>
                                                color.ColorName && <div key={uuidv4()} className={`bg-${color.ColorName.toLowerCase()} colorBox float-right`} />
                                            )}
                                        </div>
                                    </CustomListItem>

                                    <CustomListItem Title={t("general.jobInfo.size") + " :"}>
                                        <span className="float-right">
                                            {singleJob.JobSizeInMM && parseFloat(singleJob.JobSizeInMM?.split(' ')[0]).toFixed(2)}
                                            {singleJob.JobSizeInMM && " X "}
                                            {singleJob.JobSizeInMM && parseFloat(singleJob.JobSizeInMM?.split(' ')[1]).toFixed(2)}
                                            {singleJob.JobSizeInMM && " mm"}
                                        </span>
                                    </CustomListItem>

                                    <CustomListItem Title={t("general.jobInfo.resolution") + " :"}>
                                        <span className="float-right">
                                            {singleJob.Resolution && Math.round(parseFloat(singleJob.Resolution.split(' ')[0]))}
                                            {" "}
                                            X
                                            {" "}
                                            {singleJob.Resolution && Math.round(parseFloat(singleJob.Resolution.split(' ')[1]))}
                                        </span>
                                    </CustomListItem>

                                    <CustomListItem Title={t("general.jobInfo.tags") + " :"} isLast />
                                    <span className={"float-right " + Styles['margin-right-15-px']}>
                                        {singleJob.Tags && singleJob.Tags.map((tag) => (
                                            <Chip size="small" key={uuidv4()} color='primary' label={tag} className={Styles['print-chip']} />
                                        ))}
                                    </span>
                                    <CustomListItem />
                                    <CustomListItem Title={t("general.jobInfo.comments") + " :"} isLast />
                                    <div className={Styles['padding-0']}>
                                        <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                            {singleJob.Comments}
                                        </ReactMarkdown>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <img
                                                alt="Job"
                                                src={`${url}${singleJob.Thumb}`}
                                                className={Styles['job-image-printer']}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </div>
                    </>
                )}
            </div>
            <style>
                {`@media print {
                    body {-webkit-print-color-adjust: exact;}
                }`}
            </style>
        </div >
    );
};

export default PrintJob;