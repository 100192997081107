import React from 'react'
import { ThemeProvider } from '@mui/material/styles';
import lightTheme from "./LightTheme";
import darkTheme from "./DarkTheme";
import Box from '@mui/material/Box';
import localStorageService from "../services/LocalStorage/localStorageService";

const ColorModeContext = React.createContext({ toggleColorMode: () => { } });

type ThemeWrapperProps = {
  children: JSX.Element,
};

function ThemeWrapper({ children }: ThemeWrapperProps) {
  const [mode, setMode] = React.useState<'light' | 'dark'>('light');
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const currentMode = (prevMode === 'light' ? 'dark' : 'light');
          localStorageService.addToLocalStorage('ThemePreference', currentMode);
          return currentMode;
        });
      },
    }),
    [],
  );

  React.useEffect(() => {
    const themeStyle = localStorageService.getFromLocalStorage('ThemePreference');
    if (!themeStyle) {
      localStorageService.addToLocalStorage('ThemePreference', 'light');
      setMode('light');
    }
    else {
      if (themeStyle === 'light')
        setMode('light');
      else
        setMode('dark');
    }
  }, [])


  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={mode === 'light' ? lightTheme : darkTheme}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: 'background',
          }}
        >
          {children}
        </Box>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export { ThemeWrapper, ColorModeContext };