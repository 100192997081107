import { PrinterApi } from "../../../../../Models/Api/General/Printer";
import PDMServerHttpClient from "../../PDMServerHttpClient";

class PrinterTicketService {

  async getListOfPrinters() {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<PrinterApi[]>(`/api/PrinterTicket/UI/v1.0/ListAll`);
  }

  async getPrinterData(printerId: string) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<PrinterApi>(`/api/PrinterTicket/UI/v1.0/GetPrinter/${printerId}`);
  }

  static getInstance() {
    return new PrinterTicketService();
  }
}

export default PrinterTicketService;
