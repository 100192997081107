import { Button, ThemeProvider } from '@mui/material';
import React from 'react'
import ReactToPrint from 'react-to-print';
import { CmixDataModel } from '../../../Models/Api/Cmix/CmixDataModel';
import { CmixQuantityDataModel } from '../../../Models/Api/Cmix/CmixQuantityDataModel';
import { CMiXQuantityTable, ColorIDs } from '../../../modules/CMiX/CMiXImports';
import Icons from '../../../Imports/MUI/Icons/IconsImports';
import { CmixColorListDataModel } from '../../../Models/Api/Cmix/CmixColorListDataModel';
import { FormulationDataModel } from '../../../Models/Api/Cmix/FormulationDataModel';
import { useTranslation } from "react-i18next";
import lightTheme from '../../../Themes/LightTheme';

function CmixPrintPage() {
    const [t] = useTranslation();
    const layoutDiv = React.useRef<HTMLDivElement>(null);
    const [ColorResult, setColorResult] = React.useState<CmixDataModel | null>(null);
    const [ColorQuantity, setColorQuantity] = React.useState<CmixQuantityDataModel | null>(null);
    const [Amount, setAmount] = React.useState(1);

    React.useEffect(() => {
        // CmixQuantity
        const params = window.location.href.split('/');

        let CmixQuantity = params[4];
        const tmp = makeQueryFormat(CmixQuantity);
        makeCmixQuantityData(tmp);

        // Color Result
        let colorResult = params[5];
        const tmp1 = makeQueryFormat(colorResult);
        makeColorResultData(tmp1);

        // Quantity
        let quantity = params[6];
        const tmp2 = makeQueryFormat(quantity);
        const obj = new URLSearchParams(tmp2);
        setAmount(findInStringToFloat(obj, 'Amount'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const makeQueryFormat = (str: string) => {
        let tmp = '?' + str.substring(0, str.length - 1);
        return tmp;
    };

    const makeColorResultData = (queryString: string) => {
        const obj = new URLSearchParams(queryString);
        const formulationList = findFormulationListInString(obj, 'FormulationList');
        const tmp: CmixDataModel = {
            BlueValue: findInStringToFloat(obj, 'BlueValue'),
            CmixId: findInString(obj, 'CmixId'),
            FormulationList: formulationList,
            GreenValue: findInStringToFloat(obj, 'GreenValue'),
            IsEnabled: findInStringToBoolean(obj, 'IsEnabled'),
            Ral: findInString(obj, 'Ral'),
            Pantone: findInString(obj, 'Pantone'),
            RedValue: findInStringToFloat(obj, 'BRedValuelueValue')
        };
        setColorResult(tmp);
    };

    const makeCmixQuantityData = (queryString: string) => {
        const obj = new URLSearchParams(queryString);
        const cmixColorList = findCmixColorArrayArrInString(obj, 'CmixColorList');
        const tmp: CmixQuantityDataModel = {
            CmixColorList: cmixColorList,
            CmixId: findInString(obj, 'CmixId'),
            RGB: findInString(obj, 'RGB'),
            Ral: findInString(obj, 'Ral')
        };
        setColorQuantity(tmp);
    };

    const findFormulationListInString = (obj: URLSearchParams, str: string) => {
        let arr: FormulationDataModel[] = [];
        let counter = 0;
        let continueLoop = true;

        while (continueLoop) {
            arr.push(getEmptyFormulation());
            let val1 = getQuantityValue(obj, str, counter, 'SelectedCassId');
            arr[counter].SelectedCassId = val1;
            let val2 = getQuantityValue(obj, str, counter, 'InkQuantity');
            const n = parseFloat(val2);
            arr[counter].InkQuantity = isNaN(n) ? -1 : n;
            let val3 = getQuantityValue(obj, str, counter, 'CassColor');
            arr[counter].CassColor = val3;
            if (arr[counter].SelectedCassId === '' && arr[counter].CassColor === '' && (arr[counter].InkQuantity !== 0 || arr[counter].InkQuantity === -1)) {
                continueLoop = false;
                arr.pop();
            }
            counter++;
        }

        return arr;
    };

    function findCmixColorArrayArrInString(obj: URLSearchParams, str: string) {
        let arr: CmixColorListDataModel[] = [];
        let counter = 0;
        let continueLoop = true;

        while (continueLoop) {
            arr.push(getEmptyQuantity());
            let val1 = getQuantityValue(obj, str, counter, 'CassColor');
            arr[counter].CassColor = val1;
            let val2 = getQuantityValue(obj, str, counter, 'InkQuantity');
            const n = parseFloat(val2);
            arr[counter].InkQuantity = isNaN(n) ? -1 : n;
            let val3 = getQuantityValue(obj, str, counter, 'CassId');
            arr[counter].CassId = val3;
            if (arr[counter].CassColor === '' && arr[counter].CassId === '' && (arr[counter].InkQuantity !== 0 || arr[counter].InkQuantity === -1)) {
                continueLoop = false;
                arr.pop();
            }
            counter++;
        }

        return arr;
    }

    const findInString = (obj: URLSearchParams, str: string) => {
        let tmp = obj.get(str);
        return tmp !== null ? tmp : '';
    };

    const findInStringToFloat = (obj: URLSearchParams, str: string) => {
        let tmp = obj.get(str);
        if (tmp === null) return -1;
        let res = parseFloat(tmp);
        return isNaN(res) ? -1 : res;
    };

    const findInStringToBoolean = (obj: URLSearchParams, str: string) => {
        let tmp = obj.get(str);
        if (tmp === null) return false;
        let res = !!tmp;
        return res;
    };

    const getQuantityValue = (obj: URLSearchParams, str: string, counter: number, key: string) => {
        let searchKey = `${str}[${counter.toString()}][${key}]`;
        let value = obj.get(searchKey);
        return value !== null ? value : '';
    };

    const getEmptyQuantity = () => {
        let empty: CmixColorListDataModel = {
            CassId: '',
            InkQuantity: 0,
            CassColor: ''
        };
        return empty;
    };

    const getEmptyFormulation = () => {
        let empty: FormulationDataModel = {
            SelectedCassId: '',
            InkQuantity: -1,
            CassColor: ''
        };
        return empty;
    };

    return (
        <ThemeProvider theme={lightTheme}>
            <div style={{ 'padding': '25px' }}>
                <ReactToPrint pageStyle={""} content={() => layoutDiv.current} trigger={() => <ul className="nav float-right"><Button variant="outlined" style={{ 'marginLeft': '15px' }} startIcon={<Icons.PrintIcon />}>Print</Button></ul>}>
                </ReactToPrint>
                <div ref={layoutDiv}>
                    <img
                        src='/img/Logos/LogoDipTechBlack.png'
                        alt="Logo"
                        className="brand-image"
                        style={{ 'width': '90px' }}
                    />
                    <br />
                    <br />
                    <br />
                    <div>
                        <ColorIDs ColorResult={ColorResult} />
                    </div>
                    <br />

                    <div style={{ 'textAlign': 'center' }}>
                        To produce {Amount}KG of CMIX {ColorResult?.CmixId} please use
                    </div>

                    {ColorQuantity && (
                        <div>
                            <CMiXQuantityTable QuantityList={ColorQuantity.CmixColorList} />
                        </div>
                    )}
                    <br />

                    <p>{t("general.cmixDisclaimer")}</p>
                </div>
            </div>
        </ThemeProvider>
    );
};

export default CmixPrintPage;