import axios, { AxiosInstance } from "axios";

class VolumeHttpClient {
  static _instance: VolumeHttpClient;
  instance: AxiosInstance;

  constructor(){
    const URL = window.location.origin;

    this.instance = axios.create({
      baseURL: URL,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static getInstance() {
    if (VolumeHttpClient._instance === undefined) {
      VolumeHttpClient._instance = new VolumeHttpClient();
    }
    return VolumeHttpClient._instance;
  }
}

export default VolumeHttpClient;
