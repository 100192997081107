import PDMServerSessionStorageService from "../../../services/SessionStorage/PDMServer/PDMServerService";
import axios, { AxiosInstance } from "axios";
import TempUserContext from "../../../services/TempUserContext";

// let count = 0;

const settingInterceptors = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    async (config) => {
      const exp = await PDMServerSessionStorageService.getExpireTime();
      if (!exp) {
        throw new axios.Cancel('Operation canceled, could not get the expired at.');
      }

      const parsedTime = parseInt(Date.now().toString().substring(0, 10));
      if (parsedTime >= exp) {
        // token expired
        TempUserContext.userManager.signinRedirect();
        // TempUserContext.userManager.startSilentRenew();
      }

      const AuthorizationHeader = await PDMServerSessionStorageService.generateAuthorizationHeader();
      if (AuthorizationHeader) {
        config.headers["Authorization"] = AuthorizationHeader.Authorization;
      }
      else throw new axios.Cancel('Operation canceled, could not get the token.');
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // axios.interceptors.response.use(function (response) {
  //   // Any status code that lie within the range of 2xx cause this function to trigger
  //   return response;
  // }, function (error: AxiosError) {
  //   // Any status codes that falls outside the range of 2xx cause this function to trigger

  //   // make 3 tries with the global counter, so we won't enter an infinite loop
  //   if (count >= 3 || error.response?.status !== 401) {
  //     return Promise.reject(error);
  //   }
  //   count++;
  //   TempUserContext.userManager.startSilentRenew();

  //   return axios.request(error.config);
  // });
};

export default settingInterceptors;
