import { useTheme } from '@mui/material/styles';
import { ColorsStyle } from "../../JobManagerStylesImports";
import { useTranslation } from "react-i18next";
import React from 'react';

type StorageProps = {
  freeSpace: string
  usedSpace: string
  totalSpace: string
  freeSpaceNumber: string
  freeSpaceSizeString: string
};

const Storage = ({ freeSpace, usedSpace, totalSpace, freeSpaceNumber, freeSpaceSizeString }: StorageProps) => {
  const [t] = useTranslation();
  const [Limit, setLimit] = React.useState<number>(0);

  React.useEffect(() => {
    let tmp = parseFloat(totalSpace);
    // Get 10% of the total space
    let result = (10 / 100) * tmp;
    setLimit(result);
  }, [freeSpace, usedSpace, totalSpace, freeSpaceNumber, freeSpaceSizeString])

  return (
    <div>
      <h4 className={ColorsStyle.weight400 + " Orbitron20"} style={{ 'margin': '0', 'marginBottom': '15px' }}>{t("general.labels.storage")}</h4>
      <ul className="list-group list-group-unbordered mb-3">
        <CustomLi Title={t("general.labels.free") + " : "} Data={freeSpace} TrashHold={Limit} SizeString={freeSpaceSizeString} />
        <CustomLi Title={t("general.labels.used") + " : "} Data={usedSpace} />
        <CustomLi Title={t("general.labels.total") + " : "} Data={totalSpace} />
      </ul>
    </div>
  );
};

interface CustomLiProps {
  Title: string
  SizeString?: string
  Data: any
  TrashHold?: number
}

const CustomLi = ({ Title, Data, SizeString, TrashHold = -1 }: CustomLiProps) => {
  const theme = useTheme();
  const [ColorMode, setColorMode] = React.useState<'bg-Yellow' | 'bg-Red'>('bg-Yellow');

  React.useEffect(() => {
    customDiv();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TrashHold, SizeString])


  const customDiv = () => {
    setColorMode(parseFloat(Data) > TrashHold ? 'bg-Yellow' : 'bg-Red');
  };
  return (
    <li style={{
      'backgroundColor': theme.palette.mode === 'dark' ? '#252929' : 'white',
    }} className="list-group-item mx-5">
      <b className="Orbitron16 text-muted">{Title}</b>{" "}
      {
        TrashHold !== -1
          ?
          // The + operator next to a string will convert it to be a number
          <div className={`float-right px-3 py-2 Orbitron20 width150 ${ColorsStyle[ColorMode]}`}>
            <span className="d-flex justify-content-center">{Data}</span>
          </div>
          :
          <div className="bg-purpledark float-right px-3 py-2 Orbitron20 width150">
            <span className="d-flex justify-content-center">{Data}</span>
          </div>
      }
    </li>
  );
};

export default Storage;
