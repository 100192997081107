import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Pages from "../../Private/privatePagesImports";
import { CustomsBreadCrumbs } from "../../../components/General/GeneralExport";
import { Box, Toolbar, Container, CssBaseline } from '@mui/material';
import { v4 as uuidv4 } from "uuid";
import { Header, MenuSidebar } from "../../../Layout/LayoutImports";
import { GetSideNavOpenState, SetSideNavOpenState } from "../../../services/LocalStorage/SideNavMemory/SideNavMemory";
import { useAuth } from "oidc-react";
import TempUserContext from "../../../services/TempUserContext";
import JobUploader from "../../../services/JobUploader/JobUploader";
import { useLocation } from "react-router-dom";

const Main = () => {
  const auth = useAuth();
  const [open, setOpen] = React.useState(true);
  const jobUploader = JobUploader.getInstance();
  const location = useLocation();

  React.useEffect(() => {
    const state = GetSideNavOpenState();
    setOpen(state);
    TempUserContext.userManager = auth.userManager;

    return () => {
      jobUploader.clearJobDict();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleDrawer = () => {
    const newVal = !open;
    SetSideNavOpenState(newVal);
    setOpen(newVal);
  };

  React.useEffect(() => {
    if (location.pathname !== '/' && jobUploader.prevPath !== location.pathname) {
      jobUploader.prevPath = location.pathname;
      jobUploader.initUpload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return <div className="wrapper" style={{ 'height': '100%' }}>
    <Box sx={{ display: 'flex' }} style={{ 'height': '100%' }}>
      <CssBaseline />
      <Header open={open} toggleDrawer={toggleDrawer} />
      <MenuSidebar open={open} toggleDrawer={toggleDrawer} />
      {/* <ResponsiveWrapper DesktopOrLaptopLayout={<MenuSidebar open={open} toggleDrawer={toggleDrawer} />} TabletOrMobileLayout={<MenuSidebar open={false} toggleDrawer={() => { }} />} /> */}

      <Box
        component="main"
        sx={{
          backgroundColor: (theme: any) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
        style={{ 'height': '100%' }}
      >
        <Toolbar />

        <Container style={{ 'maxWidth': 'none', 'padding': '15px', 'minHeight': '90%', 'position': 'relative' }}>
          <div className="custom-header">
            <CustomsBreadCrumbs />
          </div>
          <Switch>
            <Route exact path="/Welcome" component={Pages.Welcome} />
            <Route exact path="/UsersDashboard" component={Pages.UsersDashboard} />
            <Route exact path="/UsersDashboard/CreateUser" component={Pages.CreateUser} />
            <Route exact path="/UsersDashboard/:userId" component={Pages.SingleUser} />
            <Route exact path="/UsersDashboard/:userId/ChangePassword" component={Pages.ChangePassword} />
            <Route exact path="/UsersDashboard/:userId/EditUser" component={Pages.EditUser} />
            <Route exact path="/Profile" component={Pages.Profile} />
            <Route exact path="/Profile/EditProfile" component={Pages.EditUser} />
            <Route exact path="/Profile/ChangePassword" component={Pages.ChangePassword} />

            <Route exact path="/ReportCenter" component={Pages.ReportCenter} />
            <Route exact path="/ReportCenter/PrintHistory" component={Pages.PrintHistory} />
            <Route exact path="/ReportCenter/AllPrinters" component={Pages.AllPrinters} />
            <Route exact path="/ReportCenter/Projects" component={Pages.Projects} />
            <Route exact path="/ReportCenter/InkUsage" component={Pages.InkUsage} />

            {/* To re render a url with the same path we will need to change the key */}
            <Route path="/ReportCenter/" render={(props) => <Pages.SinglePrinter {...props} key={uuidv4()} />} />

            <Route exact path="/JobManager" component={Pages.JobManager} />
            <Route exact path="/JobManager/AllJobsList" component={Pages.jobListPage} />
            <Route exact path="/JobManager/AllJobsList/:jobId/" component={Pages.JobInfo} />
            <Route exact path="/JobManager/AllJobsList/:jobId/JobDataVersions" component={Pages.JobDataVersions} />
            <Route exact path="/JobManager/AddJob" component={Pages.AddJob} />

            <Route exact path="/TicketEvent/Create" component={Pages.CreateTicketEvent} />


            <Route exact path="/Cmix" component={Pages.Cmix} />

            <Route exact path="/"><Redirect to="/Welcome" /></Route>

            <Route exact path="/RecycleBin" component={Pages.RecycleBin} />

            <Route component={Pages.NotFound} />
          </Switch>
        </Container>
      </Box>
    </Box>
  </div>;
};

export default Main;
