import { useTheme } from '@mui/material';
import React from 'react'
import { CmixDataModel } from '../../../../Models/Api/Cmix/CmixDataModel';
import Styles from '../../Styles/ColorID.module.scss';

type ColorIDsProps = {
    ColorResult: CmixDataModel | null
};

const ColorIDs = ({ ColorResult }: ColorIDsProps) => {
  const theme = useTheme();
  return (
        <>
            {ColorResult && (
                <div>
                        <div
                            className={Styles["color-ball-grid"] + ' noselect'}
                            style={{
                                "border": "1px solid " + theme.palette.text.primary,
                                "backgroundColor": `rgb(${ColorResult.RedValue && ColorResult.RedValue.toString()},${ColorResult.GreenValue && ColorResult.GreenValue.toString()},${ColorResult.BlueValue && ColorResult.BlueValue.toString()})`,
                                "color": `rgb(${ColorResult.RedValue && ColorResult.RedValue.toString()},${ColorResult.GreenValue && ColorResult.GreenValue.toString()},${ColorResult.BlueValue && ColorResult.BlueValue.toString()})`
                            }}
                        >
                        </div>
                        {ColorResult.CmixId !== undefined && (
                            <span>CMix {ColorResult.CmixId}</span>
                        )}
                        {ColorResult.Ral !== undefined && (
                            <span>/ Ral {ColorResult.Ral}</span>
                        )}
                        {ColorResult.Pantone !== undefined && (
                            <span>/ Pantone {ColorResult.Pantone}</span>
                        )}
                </div>
            )}
        </>
    )
}

export default ColorIDs
