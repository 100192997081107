import React, { useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { IconButton } from '@mui/material';
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";
import { TableStyle } from "../../../../components/General/GeneralExport";
import Grid from '@mui/material/Grid';
import { Card, CardContent, Chip, TableSortLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import { availableRowOptions, GetUsersDashboardTableRows, SetUsersDashboardTableRows } from "../../../../services/LocalStorage/SearchableTablesMemory/SearchableTablesMemoryService";
import ResponsiveWrapper from "../../../../components/ResponsiveDesign/ResponsiveWrapper";
import { Order } from "../../../../Models/Table/Order";
import Icons from '../../../../Imports/MUI/Icons/IconsImports';
import TablesSessionStorageService, { SessionStorageTableEnum } from "../../../../services/SessionStorage/Tables/TablesSessionStorageService";
import Styles from './SearchableTable.module.scss';
import { UserApiDataModel } from "../../../../Models/Api/User/UserApiDataModel";
import { UserService } from '../../../../Api/Rest/PDMServer/ApiServices/PDMApiServicesImports';
import PermissionsEnum from "../../../../Models/Permissions/PermissionsEnum";
import HttpRequestWithForbiddenCheck from "../../../../services/HttpRequestWithForbiddenCheck/HttpRequestWithForbiddenCheck";

interface EnhancedTableProps {
    UsersList: UserApiDataModel[] | null,
    Title?: string,
    ShowTitle?: boolean,
    filter: string | null,
    onSaveFilter: (filter: string) => void
}

export default function EnhancedTable({ UsersList, Title, ShowTitle = false, filter, onSaveFilter }: EnhancedTableProps) {
    const [t] = useTranslation();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [searched, setSearched] = React.useState<string>("");
    const [rows, setRows] = React.useState<UserApiDataModel[]>(UsersList ?? []);
    const history = useHistory();

    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof UserApiDataModel>('UserName');
    const [PermissionsList, setPermissionsList] = useState<string[] | null>(null);
    const userService = UserService.getInstance();

    React.useEffect(() => {
        const rowCount = GetUsersDashboardTableRows();
        setRowsPerPage(rowCount);
        const pageCount =
            TablesSessionStorageService.GetTablesPageState(SessionStorageTableEnum.USERS_DASHBOARD, null);
        setPage(pageCount);
        initData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (UsersList) {
            setRows(UsersList);

            if (filter) {
                requestSearch(filter);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [UsersList, filter])

    const initData = async () => {
        const [permissionsResp, permissionsErr] =
            await HttpRequestWithForbiddenCheck(userService.getUserPermissions(), 'Get User Permissions', 'Error When Getting User Permissions');

        if (!permissionsErr && permissionsResp !== null) {
            setPermissionsList(permissionsResp);
        }
    };

    const onClickRow = (userId: string) => {
        const Permission = PermissionsEnum.UPDATE_USER;

        if (!PermissionsList)
            return;

        if (PermissionsList.some(x => x === Permission)) {
            history.push(`/UsersDashboard/${userId}`);
        }

        return;
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        TablesSessionStorageService.SetTableStateState(SessionStorageTableEnum.USERS_DASHBOARD, null, newPage);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        let num = parseInt(event.target.value, 10);
        SetUsersDashboardTableRows(num);
        setRowsPerPage(num);
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const requestSearch = (searchedVal: string) => {
        setSearched(searchedVal);
        onSaveFilter(searchedVal);

        if (searchedVal !== '') {
            const filteredRows = UsersList?.filter((row) => {
                return (row.UserName && row.UserName.toLowerCase().includes(searchedVal.toLowerCase())) ||
                    (row.Email && row.Email.toLowerCase().includes(searchedVal.toLowerCase())) ||
                    (row.FirstName && row.FirstName.toLowerCase().includes(searchedVal.toLowerCase())) ||
                    (row.LastName && row.LastName.toLowerCase().includes(searchedVal.toLowerCase())) ||
                    (row.RoleNames && row.RoleNames.some((tag) => tag.toLowerCase().includes(searchedVal.toLowerCase())));
            });

            setRows(filteredRows ?? []);
            return;
        }

        setRows(UsersList ?? []);
    };

    const handleRequestSort = (property: keyof UserApiDataModel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortFunc = (a: UserApiDataModel, b: UserApiDataModel): 1 | -1 | 0 => {
        if (order === 'asc') {
            return (a[orderBy] > b[orderBy]) ? -1 : ((b[orderBy] > a[orderBy]) ? 1 : 0);
        }
        else {
            return (a[orderBy] > b[orderBy]) ? 1 : ((b[orderBy] > a[orderBy]) ? -1 : 0);
        }
    };

    const table = (<Grid item xs={12}>
        <Card className="noBackgroundImage">
            <CardContent style={{ 'padding': '0px' }}>
                <Box sx={{ width: "100%" }}>
                    <TableContainer >
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size={"small"} >
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        className={Styles['min-width-150-px']}
                                        align={'left'}
                                        padding={'normal'}
                                        sortDirection={orderBy === 'UserName' ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === 'UserName'}
                                            direction={orderBy === 'UserName' ? order : 'asc'}
                                            onClick={() => { handleRequestSort('UserName') }}>
                                            {t("general.userInfo.userName")}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        className={Styles['min-width-150-px']}
                                        align={'left'}
                                        padding={'normal'}
                                        sortDirection={orderBy === 'Email' ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === 'Email'}
                                            direction={orderBy === 'Email' ? order : 'asc'}
                                            onClick={() => { handleRequestSort('Email') }}>
                                            {t("general.userInfo.email")}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        className={Styles['min-width-150-px']}
                                        align={'left'}
                                        padding={'normal'}
                                        sortDirection={orderBy === 'FirstName' ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === 'FirstName'}
                                            direction={orderBy === 'FirstName' ? order : 'asc'}
                                            onClick={() => { handleRequestSort('FirstName') }}>
                                            {t("general.userInfo.firstName")}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        className={Styles['min-width-100-px']}
                                        align={'left'}
                                        padding={'normal'}
                                        sortDirection={orderBy === 'LastName' ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === 'LastName'}
                                            direction={orderBy === 'LastName' ? order : 'asc'}
                                            onClick={() => { handleRequestSort('LastName') }}>
                                            {t("general.userInfo.lastName")}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        className={Styles['min-width-100-px']}
                                        align={'left'}
                                        padding={'normal'}
                                        sortDirection={orderBy === 'RoleNames' ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === 'RoleNames'}
                                            direction={orderBy === 'RoleNames' ? order : 'asc'}
                                            onClick={() => { handleRequestSort('RoleNames') }}>
                                            {t("general.userInfo.roles")}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        className={Styles['min-width-100-px']}
                                        align={'left'}
                                        padding={'normal'}
                                        sortDirection={orderBy === 'IsEnabled' ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === 'IsEnabled'}
                                            direction={orderBy === 'IsEnabled' ? order : 'asc'}
                                            onClick={() => { handleRequestSort('IsEnabled') }}>
                                            {t("general.userInfo.status")}
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {rows
                                    .sort(sortFunc).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                onClick={() => onClickRow(row.UserId)}
                                                tabIndex={-1}
                                                key={row.UserId}
                                                style={{ 'cursor': 'pointer' }}
                                            >
                                                <TableCell>{row.UserName}</TableCell>
                                                <TableCell>{row.Email}</TableCell>
                                                <TableCell>{row.FirstName}</TableCell>
                                                <TableCell>{row.LastName}</TableCell>
                                                <TableCell>
                                                    {row.RoleNames &&
                                                        row.RoleNames.map((tag) => (
                                                            <Chip size="small"
                                                                key={uuidv4()}
                                                                color='primary'
                                                                label={tag}
                                                                style={{ 'marginLeft': '2px', 'marginRight': '2px' }} />
                                                        ))}
                                                </TableCell>
                                                <TableCell>
                                                    {row.IsEnabled ? t("general.userInfo.enabled") : t("general.userInfo.disabled")}
                                                    {row.IsLockedOut && " | " + t("general.userInfo.locked")}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: (53) * emptyRows
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={availableRowOptions}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        className={TableStyle['custom-table-pagination']}
                        labelDisplayedRows={
                            ({ from, to, count }) => {
                                return '' + from + '-' + to + ` ${t("general.tables.to")} ` + count
                            }
                        }
                        labelRowsPerPage={t("general.tables.rowsPerPage")}
                    />
                </Box>
            </CardContent>
        </Card>
    </Grid>);

    const desktopTemplate = (<Grid container>
        {ShowTitle
            ?
            <>
                <Grid item xs={8}>
                    <h4 className="Orbitron20 p-3">{Title}</h4>
                </Grid>

                <Grid item xs={4}>
                    <TextField
                        style={{ 'width': '100%' }}
                        value={searched}
                        label={t("general.labels.filter")} variant="standard"
                        onChange={(searchVal) => requestSearch(searchVal.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        disabled={!searched}
                                        size="small"
                                        onClick={() => {
                                            setSearched('');
                                            requestSearch('');
                                        }}>
                                        <Icons.ClearIcon />
                                    </IconButton>
                                    <Icons.SearchIcon />
                                </InputAdornment>
                            ),
                        }} />
                </Grid>
            </>
            :
            <>
                <Grid item xs={12}>
                    <TextField
                        style={{ 'width': '100%' }}
                        value={searched}
                        label={t("general.labels.filter")}
                        variant="standard" onChange={(searchVal) => requestSearch(searchVal.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        disabled={!searched}
                                        size="small"
                                        onClick={() => {
                                            setSearched('');
                                            requestSearch('');
                                        }}>
                                        <Icons.ClearIcon />
                                    </IconButton>
                                    <Icons.SearchIcon />
                                </InputAdornment>
                            ),
                        }} />
                </Grid>
            </>
        }

        {table}
    </Grid>);

    const tabletOrMobileTemplate = (<Grid container>
        {ShowTitle
            ?
            <>
                <Grid item xs={12}>
                    <h4 className="Orbitron20 p-3">{Title}</h4>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        style={{ 'width': '100%' }}
                        value={searched}
                        label={t("general.labels.filter")}
                        variant="standard" onChange={(searchVal) => requestSearch(searchVal.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        disabled={!searched}
                                        size="small"
                                        onClick={() => {
                                            setSearched('');
                                            requestSearch('');
                                        }}>
                                        <Icons.ClearIcon />
                                    </IconButton>
                                    <Icons.SearchIcon />
                                </InputAdornment>
                            ),
                        }} />
                </Grid>
            </>
            :
            <>
                <Grid item xs={12}>
                    <TextField
                        style={{ 'width': '100%' }}
                        value={searched}
                        label={t("general.labels.filter")}
                        variant="standard"
                        onChange={(searchVal) => requestSearch(searchVal.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        disabled={!searched}
                                        size="small"
                                        onClick={() => {
                                            setSearched('');
                                            requestSearch('');
                                        }}>
                                        <Icons.ClearIcon />
                                    </IconButton>
                                    <Icons.SearchIcon />
                                </InputAdornment>
                            ),
                        }} />
                </Grid>
            </>
        }

        {table}
    </Grid>);

    return (
        <ResponsiveWrapper
            DesktopOrLaptopLayout={desktopTemplate}
            TabletOrMobileLayout={tabletOrMobileTemplate} />
    );
}
