import React, { useState, useEffect } from "react";
import AwaitHandler from "../../../services/AwaitHandling/AwaitHandling";
import { useHistory } from "react-router-dom";
import { Grid } from "@mui/material";
import { MainButton } from "../../../components/General/GeneralExport";
import { useTranslation } from "react-i18next";
import Icons from "../../../Imports/MUI/Icons/IconsImports";
import { ActivityCard, ProfileDataCardApi } from "../../../modules/Profile/ProfileImports";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Store/Store";
import { pdmAuthorize, pdmUnauthorize } from "../../../Store/Actions/Actions";
import HttpRequestWithForbiddenCheck from "../../../services/HttpRequestWithForbiddenCheck/HttpRequestWithForbiddenCheck";
import { UserApiDataModel } from "../../../Models/Api/User/UserApiDataModel";
import { UserEventDataModel } from "../../../Models/Api/Events/UserEventDataModel";
import { AuthorizationService, UserService, userInfo } from "../../../Api/Rest/PDMServer/ApiServices/PDMApiServicesImports";
import { PermissionsWrapper } from '../../../components/General/GeneralExport';
import PermissionsEnum from "../../../Models/Permissions/PermissionsEnum";

const Profile = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const userService = UserService.getInstance();

  // const userEventsService = UserEventsService.getInstance();
  const [Activity, setActivity] = useState<UserEventDataModel[]>([]);
  const [User, setUser] = useState<UserApiDataModel | null>(null);
  const pdmAuthState = useSelector((state: RootState) => state.PdmAuth);
  const dispatch = useDispatch();
  const [PermissionsList, setPermissionsList] = useState<string[] | null>(null);

  useEffect(() => {
    initData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initData = async () => {
    const pdmAuthorized = await AuthorizationService.getInstance().checkApi();

    if (pdmAuthorized.status === 200) {
      if (!pdmAuthorized.data) {
        if (pdmAuthState !== pdmAuthorized.data) {
          dispatch(pdmUnauthorize());
        }
        history.push('/AuthorizePDM');
      }
      if (pdmAuthState !== pdmAuthorized.data) {
        dispatch(pdmAuthorize());
      }

      const [res, error] = await AwaitHandler(userInfo());
      if (!error && res.data) {
        const [userData, err1] = await HttpRequestWithForbiddenCheck(userService.getUserProfileByEmail(res.data.email), 'getUserProfile', 'Error When Getting The User');
        if (!err1 && userData) {
          setUser(userData);

          const [activities, err2] = await HttpRequestWithForbiddenCheck(userService.getUserEvents(userData.UserId), 'getUserEvents', 'Error When Getting The User Events');
          if (!err2 && activities) {
            setActivity(activities);
          }
        }
      }
    }

    const [permissionsResp, permissionsErr] = await HttpRequestWithForbiddenCheck(userService.getUserPermissions(), 'Get User Permissions', 'Error When Getting User Permissions');
    if (!permissionsErr && permissionsResp !== null) {
      setPermissionsList(permissionsResp);
    }

  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ul className="nav float-right">
          <PermissionsWrapper PermissionsList={PermissionsList} Permission={PermissionsEnum.READ_USER}
            PassedPermissionTemplate={
              <MainButton style={{ 'marginLeft': '15px' }} onClick={() => history.push(`/Profile/EditProfile?userId=${User?.UserId}`)} startIcon={<Icons.EditIcon />}>
                {t("general.buttons.editUser")}
              </MainButton>
            } />
          <PermissionsWrapper PermissionsList={PermissionsList} Permission={PermissionsEnum.READ_USER}
            PassedPermissionTemplate={
              <MainButton style={{ 'marginLeft': '15px' }} onClick={() => history.push(`/Profile/ChangePassword?userId=${User?.UserId}`)} startIcon={<Icons.PasswordIcon />}>
                {t("general.buttons.changePassword")}
              </MainButton>
            } />
        </ul>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={4}>
        <ProfileDataCardApi User={User} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={8}>
        <ActivityCard Activity={Activity} />
      </Grid>
    </Grid>
  );
};

export default Profile;
