import { v4 as uuidv4 } from "uuid";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import humanizeString from "humanize-string";
import { JobDataVersionDataModel } from "../../../../Models/Api/Job/JobDataVersionDataModel";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Styles from './JobDataVersionsTable.module.scss';
import { useEffect, useState } from "react";
import { MainButton, PermissionsWrapper } from "../../../../components/General/GeneralExport";
import PermissionsEnum from "../../../../Models/Permissions/PermissionsEnum";
import { UserService } from "../../../../Api/Rest/PDMServer/ApiServices/PDMApiServicesImports";
import Icons from '../../../../Imports/MUI/Icons/IconsImports';
import { InputAdornment, TextField } from "@mui/material";
import { Grid, TableCell } from "@mui/material";

interface JobDataVersionsTableProps {
    dataVersions: JobDataVersionDataModel[] | null
    restoreJobData: (id: string) => void
}

export default function JobDataVersionsTable({ dataVersions, restoreJobData }: JobDataVersionsTableProps) {
    const [rows, setRows] = useState<JobDataVersionDataModel[]>(dataVersions ?? []);
    const [searched, setSearched] = useState<string>("");
    const [PermissionsList, setPermissionsList] = useState<string[] | null>(null);
    const [t] = useTranslation();
    const userService = UserService.getInstance();

    useEffect(() => {
        initAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (dataVersions) {
            setRows(dataVersions);
        }
    }, [dataVersions])

    const initAsync = async () => {
        const tmp = await userService.getUserPermissions();
        if (tmp.status === 200) {
            setPermissionsList(tmp.data);
        }
    };

    const requestSearch = (searchedVal: string) => {
        setSearched(searchedVal);

        if (searchedVal !== '') {
            const filteredRows = dataVersions?.filter((row) => {
                return row.PrinterId.toLowerCase().includes(searchedVal.toLowerCase()) ||
                    moment.utc(row.JobDataHistoryDateTime).local().format('DD/MM/YYYY H:mm').toLowerCase().includes(searchedVal.toLowerCase());
            });
            setRows(filteredRows ?? []);
        }
        else {
            setRows(dataVersions ?? []);
        }
    };

    return (
        <>
            <Grid container spacing={0}>
                <Grid item xs={8} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <TextField style={{ 'width': '100%' }} value={searched} label={t("general.labels.filter")} variant="standard" onChange={(searchVal) => requestSearch(searchVal.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Icons.SearchIcon />
                                </InputAdornment>
                            ),
                        }} />
                </Grid>
                <Grid item xs={12} >
                    <TableContainer component={Paper} className="noBackgroundImage">
                        <Table aria-label="simple table" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={Styles['min-width-100-px']}>{t("general.tables.dateModified")}</TableCell>
                                    <TableCell className={Styles['min-width-100-px']}>{t("general.tables.printerId")}</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows &&
                                    rows.map((ver) => {
                                        return (
                                            <TableRow
                                                key={uuidv4()}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                hover
                                                style={{ 'cursor': 'pointer' }}>
                                                <TableCell >
                                                    {ver.JobDataHistoryDateTime && moment.utc(ver.JobDataHistoryDateTime).local().format('DD/MM/YYYY H:mm')}
                                                </TableCell>

                                                <TableCell >{humanizeString(ver.PrinterId)}</TableCell>

                                                <TableCell align="right">
                                                    <PermissionsWrapper PermissionsList={PermissionsList} Permission={PermissionsEnum.UPDATE_JOB_MANAGER_UI}
                                                        PassedPermissionTemplate={
                                                            <MainButton startIcon={<Icons.RestoreFromTrashIcon />}
                                                                onClick={() => { restoreJobData(ver.Id) }} >
                                                                {t("general.buttons.restore")}
                                                            </MainButton>
                                                        }
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </>
    );
}