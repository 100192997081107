import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import MuiDrawer from '@mui/material/Drawer';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Collapse from '@mui/material/Collapse';
import { Box, Drawer, ListItem } from "@mui/material";
import ResponsiveWrapper from "../../components/ResponsiveDesign/ResponsiveWrapper";
import HttpRequestWithForbiddenCheck from "../../services/HttpRequestWithForbiddenCheck/HttpRequestWithForbiddenCheck";
import Icons from '../../Imports/MUI/Icons/IconsImports';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSwatchbook, faChartBar, faTrash, faServer } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { CheckFeaturesService, UserService } from "../../Api/Rest/PDMServer/ApiServices/PDMApiServicesImports";
import { PermissionsWrapper } from '../../components/General/GeneralExport';
import PermissionsEnum from "../../Models/Permissions/PermissionsEnum";

interface MenuSidebarProps {
  open: boolean,
  toggleDrawer: (user: any) => void
}

const MenuSidebar = (props: MenuSidebarProps) => {
  const history = useHistory()
  const [t] = useTranslation();

  const checkFeaturesService = CheckFeaturesService.getInstance();

  const [JobManagerFeature, setJobManagerFeature] = useState(false);
  const [DashboardFeature, setDashboardFeature] = useState(false);
  const [CmixFeature, setCmixFeature] = useState(false);

  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [JobManagerDrawer, setJobManagerDrawer] = React.useState(false);
  const [PermissionsList, setPermissionsList] = useState<string[] | null>(null);
  const userService = UserService.getInstance();

  useEffect(() => {
    init();

    history.listen((location, action) => {
      forceUpdate();
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = async () => {
    // job manager feature
    const [jobManagerResp, jobManagerErr] =
      await HttpRequestWithForbiddenCheck(checkFeaturesService.featureEnabled("JobManager"),
        'featureEnabled JobManager', 'Error When Getting The Feature Enabled Job Manager');

    if (!jobManagerErr && jobManagerResp !== null) {
      setJobManagerFeature(jobManagerResp);
    }

    // reportCenter feature
    const [dashboardResp, dashboardErr] =
      await HttpRequestWithForbiddenCheck(checkFeaturesService.featureEnabled("Dashboard"),
        'featureEnabled ReportCenter', 'Error When Getting The Feature Enabled ReportCenter');

    if (!dashboardErr && dashboardResp !== null) {
      setDashboardFeature(dashboardResp);
    }

    // cmix feature
    const [cmixResp, cmixErr] =
      await HttpRequestWithForbiddenCheck(checkFeaturesService.featureEnabled("Cmix"),
        'featureEnabled Cmix', 'Error When Getting The Feature Enabled Cmix');

    if (!cmixErr && cmixResp !== null) {
      setCmixFeature(cmixResp);
    }

    const [permissionsResp, permissionsErr] =
      await HttpRequestWithForbiddenCheck(userService.getUserPermissions(),
        'Get User Permissions', 'Error When Getting User Permissions');

    if (!permissionsErr && permissionsResp !== null) {
      setPermissionsList(permissionsResp);
    }
  };

  const checkUrl = (url: string) => {
    return history.location.pathname + history.location.search === url;
  };

  const desktopTemplate = (<CustomDrawer variant="permanent" open={props.open}
    PaperProps={{
      sx: {
        backgroundColor: "background.paper",
      }
    }}>
    <Toolbar
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        px: [1]
      }}
    >
      <div style={{ 'cursor': 'pointer' }} onClick={() => history.push('/')}>
        {t("general.labels.pdmLong")}
      </div>
      <IconButton onClick={props.toggleDrawer}>
        <Icons.ChevronLeftIcon />
      </IconButton>
    </Toolbar>
    <Divider />
    <List component="nav">

      {JobManagerFeature &&
        <PermissionsWrapper PermissionsList={PermissionsList} Permission={PermissionsEnum.READ_JOB_MANAGER_UI}
          PassedPermissionTemplate={
            <>
              <ListItem style={{ 'padding': '0px' }} secondaryAction={
                props.open && <IconButton edge="end" onClick={() => setJobManagerDrawer(!JobManagerDrawer)}>
                  {JobManagerDrawer ? <Icons.ExpandLess /> : <Icons.ExpandMore />}
                </IconButton>
              }>
                <ListItemButton selected={checkUrl("/JobManager")} onClick={() => history.push('/JobManager')}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faServer as IconProp} />
                  </ListItemIcon>
                  <ListItemText primary={t("general.pages.jobManager")} />
                </ListItemButton>
              </ListItem>

              {props.open
                ?
                <Collapse in={JobManagerDrawer} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton selected={checkUrl("/RecycleBin")} onClick={() => history.push('/RecycleBin')} sx={{ pl: 6 }}>
                      <ListItemIcon>
                        <FontAwesomeIcon icon={faTrash as IconProp} />
                      </ListItemIcon>
                      <ListItemText primary={t("general.pages.recycleBin")} />
                    </ListItemButton>
                  </List>
                </Collapse>
                :
                <ListItemButton selected={checkUrl("/RecycleBin")} onClick={() => history.push('/RecycleBin')}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faTrash as IconProp} />
                  </ListItemIcon>
                  <ListItemText primary={t("general.pages.recycleBin")} />
                </ListItemButton>
              }

              <Divider sx={{ my: 1 }} />
            </>
          }
        />
      }

      {DashboardFeature &&
        <PermissionsWrapper PermissionsList={PermissionsList} Permission={PermissionsEnum.READ_DASHBOARD}
          PassedPermissionTemplate={
            <>
              <ListItem style={{ 'padding': '0px' }} >
                <ListItemButton selected={checkUrl("/ReportCenter")} onClick={() => history.push('/ReportCenter')}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faChartBar as IconProp} />
                  </ListItemIcon>
                  <ListItemText primary={t("general.pages.reportCenter")} />
                </ListItemButton>
              </ListItem>
              <Divider sx={{ my: 1 }} />
            </>
          }
        />
      }

      {CmixFeature &&
        <PermissionsWrapper PermissionsList={PermissionsList} Permission={PermissionsEnum.READ_CMIX}
          PassedPermissionTemplate={
            <>
              <ListItemButton selected={checkUrl("/Cmix")} onClick={() => history.push('/Cmix')}>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faSwatchbook as IconProp} />
                </ListItemIcon>
                <ListItemText primary={t("general.pages.CMiX")} />
              </ListItemButton>

              <Divider sx={{ my: 1 }} />
            </>
          }
        />
      }

    </List>
  </CustomDrawer>);

  const tabletOrMobileTemplate = (<Drawer
    anchor={'left'}
    open={props.open}
    onClose={props.toggleDrawer}
  >
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={props.toggleDrawer}
    >
      <List component="nav" style={{ 'marginTop': '50px' }}>

        {JobManagerFeature &&
          <PermissionsWrapper PermissionsList={PermissionsList} Permission={PermissionsEnum.READ_JOB_MANAGER_UI}
            PassedPermissionTemplate={
              <>
                <ListItemButton selected={checkUrl("/JobManager")} onClick={() => history.push('/JobManager')}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faServer as IconProp} />
                  </ListItemIcon>
                  <ListItemText primary={t("general.pages.jobManager")} />
                </ListItemButton>

                <ListItemButton selected={checkUrl("/RecycleBin")} onClick={() => history.push('/RecycleBin')}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faTrash as IconProp} />
                  </ListItemIcon>
                  <ListItemText primary={t("general.pages.recycleBin")} />
                </ListItemButton>

                <Divider sx={{ my: 1 }} />
              </>
            }
          />
        }

        {DashboardFeature &&

          <PermissionsWrapper PermissionsList={PermissionsList} Permission={PermissionsEnum.READ_DASHBOARD}
            PassedPermissionTemplate={
              <>

                <ListItemButton selected={checkUrl("/ReportCenter")} onClick={() => history.push('/ReportCenter')}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faChartBar as IconProp} />
                  </ListItemIcon>
                  <ListItemText primary={t("general.pages.reportCenter")} />
                </ListItemButton>

                < Divider sx={{ my: 1 }} />
              </>
            }
          />
        }

        {CmixFeature &&

          <PermissionsWrapper PermissionsList={PermissionsList} Permission={PermissionsEnum.READ_CMIX}
            PassedPermissionTemplate={
              <>
                <ListItemButton selected={checkUrl("/Cmix")} onClick={() => history.push('/Cmix')}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faSwatchbook as IconProp} />
                  </ListItemIcon>
                  <ListItemText primary={t("general.pages.CMiX")} />
                </ListItemButton>

                <Divider sx={{ my: 1 }} />
              </>
            }
          />
        }

      </List>
    </Box>
  </Drawer>);

  return (
    <ResponsiveWrapper DesktopOrLaptopLayout={desktopTemplate} TabletOrMobileLayout={tabletOrMobileTemplate} />
  );
};

// Custom side drawer
const CustomDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: 240,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(7),
        },
      }),
    },
  }),
);

export default MenuSidebar;
