class DateService {
  static _instance: DateService;

  getStartDate(): string {
    let today = new Date();
    const tmp = this.prevMonth(today);
    let dateNow = tmp.getFullYear() + "-" + (tmp.getMonth() + 1) + "-" + tmp.getDate();
    return dateNow;
  }

  getStartDateAsDate(): Date {
    let today = new Date();
    const tmp = this.prevMonth(today);
    tmp.setMonth(tmp.getMonth());
    return tmp;
  }

  getEndDate(): string {
    let today = new Date();
    const tmp = this.nextMonth(today);
    let dateNow = tmp.getFullYear() + "-" + (tmp.getMonth() + 1) + "-" + tmp.getDate();
    return dateNow;
  }

  getEndDateAsDate(): Date {
    let today = new Date();
    const tmp = this.nextMonth(today);
    return tmp;
  }

  prevMonth(dateObj: Date): Date {
    let tempDateObj = new Date(dateObj);
    // check if january, then go 1 year back
    if (tempDateObj.getMonth) {
      tempDateObj.setDate(1);
      tempDateObj.setMonth(tempDateObj.getMonth() - 1);
    }
    else {
      tempDateObj.setDate(1);
      tempDateObj.setFullYear(tempDateObj.getFullYear() - 1);
      tempDateObj.setMonth(12);
    }
    return tempDateObj
  };

  nextMonth(dateObj: Date): Date {
    let tempDateObj = new Date(dateObj);
    if (tempDateObj.getMonth) {
      tempDateObj.setDate(1);
      tempDateObj.setMonth(tempDateObj.getMonth() + 1);
    }
    else {
      tempDateObj.setDate(1);
      tempDateObj.setFullYear(tempDateObj.getFullYear() + 1);
      tempDateObj.setMonth(1);
    }
    return tempDateObj
  };

  GetCurrentDateInUTC() {
    const date = new Date();
    const now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    return new Date(now_utc);
  }

  static getInstance(): DateService {
    if (DateService._instance === undefined) {
      DateService._instance = new DateService();
    }
    return DateService._instance;
  }
}

export default DateService;
