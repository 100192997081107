import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";
import humanizeDuration from "humanize-duration";
import Grid from '@mui/material/Grid';
import { Card, CardContent, Chip, TableSortLabel } from "@mui/material";
import { IconButton } from '@mui/material';
import humanizeString from 'humanize-string';
import { useTranslation } from "react-i18next";
import { PrintEventDataModel } from "../../../Models/Api/Events/PrintEventDataModel";
import { availableRowOptions, GetSinglePrinterTableRows, SetSinglePrinterTableRows } from "../../../services/LocalStorage/SearchableTablesMemory/SearchableTablesMemoryService";
import { TableStyle } from "../../../components/General/GeneralExport";
import ResponsiveWrapper from "../../../components/ResponsiveDesign/ResponsiveWrapper";
import { Order } from "../../../Models/Table/Order";
import moment from "moment";
import Icons from '../../../Imports/MUI/Icons/IconsImports';
import { MinimumJobDataModel } from "../../../Models/Api/Job/MinimumJobDataModel";
import TablesSessionStorageService, { SessionStorageTableEnum } from "../../../services/SessionStorage/Tables/TablesSessionStorageService";
import Styles from './SearchableTable.module.scss';

interface EnhancedTableProps {
    printedJobs: PrintEventDataModel[],
    Title?: string,
    ShowTitle?: boolean,
    PrinterId: string,
    filter: string | null,
    onSaveFilter: (filter: string) => void
}

export default function EnhancedTable({ printedJobs, Title, PrinterId, ShowTitle = false, filter, onSaveFilter }: EnhancedTableProps) {
    const [t] = useTranslation();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [searched, setSearched] = React.useState<string>("");
    const [rows, setRows] = React.useState<PrintEventDataModel[]>(printedJobs);
    const history = useHistory();

    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof PrintEventDataModel>('Job');
    const [OrderByNested, setOrderByNested] = React.useState<keyof MinimumJobDataModel>('JobId');
    const [ByNested, setByNested] = React.useState(false);

    React.useEffect(() => {
        const rowCount = GetSinglePrinterTableRows(PrinterId);
        setRowsPerPage(rowCount);
        const pageCount = TablesSessionStorageService.GetTablesPageState(SessionStorageTableEnum.SINGLE_PRINTER, PrinterId);
        setPage(pageCount);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        setRows(printedJobs);

        if (filter) {
            requestSearch(filter);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [printedJobs])

    const onClickRow = (id: string) => {
        history.push(`/ReportCenter/PrintHistory?Id=${id}`);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        TablesSessionStorageService.SetTableStateState(SessionStorageTableEnum.SINGLE_PRINTER, PrinterId, newPage);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        let num = parseInt(event.target.value, 10);
        SetSinglePrinterTableRows(PrinterId, num);
        setRowsPerPage(num);
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const requestSearch = (searchedVal: string) => {
        setSearched(searchedVal);
        onSaveFilter(searchedVal);

        if (searchedVal !== '') {
            const filteredRows = printedJobs.filter((row) => {
                return row.Job.JobName.toLowerCase().includes(searchedVal.toLowerCase()) ||
                    row.Job.ProjectName.toLowerCase().includes(searchedVal.toLowerCase()) ||
                    row.PrinterId.toLowerCase().includes(searchedVal.toLowerCase()) ||
                    moment.utc(row.CreateEventDateTime).local().format('DD/MM/YYYY H:mm').toLowerCase().includes(searchedVal.toLowerCase()) ||
                    humanizeDuration(row.NetoPrintTime, { round: true, }).toLowerCase().includes(searchedVal.toLowerCase()) ||
                    row.PrintStatus.toLowerCase().includes(searchedVal.toLowerCase()) ||
                    row.Tags.some((tag) => tag.toLowerCase().includes(searchedVal.toLowerCase()));
            });

            setRows(filteredRows);
            return;
        }

        setRows(printedJobs);
    };

    const handleRequestSort = (property: keyof PrintEventDataModel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        if (ByNested) setByNested(false);
    };

    const handleRequestSortNested = (property: keyof MinimumJobDataModel) => {
        const isAsc = OrderByNested === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderByNested(property);
        if (!ByNested) setByNested(true);
    };

    const sortFunc = (a: PrintEventDataModel, b: PrintEventDataModel): 1 | -1 | 0 => {
        if (ByNested) {
            if (order === 'asc') {
                return (a.Job[OrderByNested] > b.Job[OrderByNested]) ? -1 : ((b.Job[OrderByNested] > a.Job[OrderByNested]) ? 1 : 0);
            }
            else {
                return (a.Job[OrderByNested] > b.Job[OrderByNested]) ? 1 : ((b.Job[OrderByNested] > a.Job[OrderByNested]) ? -1 : 0);
            }
        }
        else {
            if (order === 'asc') {
                return (a[orderBy] > b[orderBy]) ? -1 : ((b[orderBy] > a[orderBy]) ? 1 : 0);
            }
            else {
                return (a[orderBy] > b[orderBy]) ? 1 : ((b[orderBy] > a[orderBy]) ? -1 : 0);
            }
        }
    };

    const table = (<Grid item xs={12}>
        <Card className="noBackgroundImage">
            <CardContent style={{ 'padding': '0px' }}>
                <Box sx={{ width: "100%" }}>
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size={"small"} >
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        className={Styles['min-width-150-px']}
                                        align={'left'}
                                        padding={'normal'}
                                        sortDirection={OrderByNested === 'JobName' ? order : false}>
                                        <TableSortLabel
                                            active={ByNested && OrderByNested === 'JobName'}
                                            direction={OrderByNested === 'JobName' ? order : 'asc'}
                                            onClick={() => { handleRequestSortNested('JobName') }}>
                                            {t("general.tables.jobName")}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        className={Styles['min-width-150-px']}
                                        align={'left'}
                                        padding={'normal'}
                                        sortDirection={OrderByNested === 'ProjectName' ? order : false}>
                                        <TableSortLabel
                                            active={ByNested && OrderByNested === 'ProjectName'}
                                            direction={OrderByNested === 'ProjectName' ? order : 'asc'}
                                            onClick={() => { handleRequestSortNested('ProjectName') }}>
                                            {t("general.tables.projectName")}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        className={Styles['min-width-150-px']}
                                        align={'left'}
                                        padding={'normal'}
                                        sortDirection={orderBy === 'PrinterId' ? order : false}>
                                        <TableSortLabel
                                            active={!ByNested && orderBy === 'PrinterId'}
                                            direction={orderBy === 'PrinterId' ? order : 'asc'}
                                            onClick={() => { handleRequestSort('PrinterId') }}>
                                            {t("general.tables.printerId")}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell className={Styles['min-width-150-px']}>{t("general.tables.color")}</TableCell>
                                    <TableCell
                                        className={Styles['min-width-150-px']}
                                        align={'left'}
                                        padding={'normal'}
                                        sortDirection={orderBy === 'CreateEventDateTime' ? order : false}>
                                        <TableSortLabel
                                            active={!ByNested && orderBy === 'CreateEventDateTime'}
                                            direction={orderBy === 'CreateEventDateTime' ? order : 'asc'}
                                            onClick={() => { handleRequestSort('CreateEventDateTime') }}>
                                            {t("general.tables.createEventDateTime")}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        className={Styles['min-width-150-px']}
                                        align={'left'}
                                        padding={'normal'}
                                        sortDirection={orderBy === 'NetoPrintTime' ? order : false}>
                                        <TableSortLabel
                                            active={!ByNested && orderBy === 'NetoPrintTime'}
                                            direction={orderBy === 'NetoPrintTime' ? order : 'asc'}
                                            onClick={() => { handleRequestSort('NetoPrintTime') }}>
                                            {t("general.tables.printTime")}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        className={Styles['min-width-100-px']}
                                        align={'left'}
                                        padding={'normal'}
                                        sortDirection={orderBy === 'Tags' ? order : false}>
                                        <TableSortLabel
                                            active={!ByNested && orderBy === 'Tags'}
                                            direction={orderBy === 'Tags' ? order : 'asc'}
                                            onClick={() => { handleRequestSort('Tags') }}>
                                            {t("general.tables.tags")}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        className={Styles['min-width-100-px']}
                                        align={'left'}
                                        padding={'normal'}
                                        sortDirection={orderBy === 'PrintStatus' ? order : false}>
                                        <TableSortLabel
                                            active={!ByNested && orderBy === 'PrintStatus'}
                                            direction={orderBy === 'PrintStatus' ? order : 'asc'}
                                            onClick={() => { handleRequestSort('PrintStatus') }}>
                                            {t("general.labels.status")}
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows
                                    .sort(sortFunc).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                onClick={() => onClickRow(row.Id)}
                                                tabIndex={-1}
                                                key={row.Id}
                                                style={{ 'cursor': 'pointer' }}
                                            >
                                                <TableCell>{row.Job.JobName}</TableCell>
                                                <TableCell>{row.Job.ProjectName}</TableCell>
                                                <TableCell>{row.PrinterId}</TableCell>
                                                <TableCell>
                                                    {row.Job.JobColors.map((color) =>
                                                        color.ColorName ? (
                                                            <div
                                                                key={uuidv4()}
                                                                className={`bg-${color.ColorName.toLowerCase()} colorBox my-3`}
                                                            ></div>
                                                        ) : null
                                                    )}
                                                </TableCell>
                                                <TableCell >
                                                    {moment.utc(row.CreateEventDateTime).local().format('DD/MM/YYYY H:mm')}
                                                </TableCell>
                                                <TableCell>{humanizeDuration(row.NetoPrintTime, { round: true, })}</TableCell>
                                                <TableCell>
                                                    {row.Tags &&
                                                        row.Tags.map((tag) => (
                                                            <Chip size="small" key={uuidv4()} color='primary' label={tag} style={{ 'marginLeft': '2px', 'marginRight': '2px' }} />
                                                        ))}
                                                </TableCell>
                                                <TableCell>{humanizeString(row.PrintStatus)}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: (53) * emptyRows
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={availableRowOptions}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        className={TableStyle['custom-table-pagination']}
                        labelDisplayedRows={
                            ({ from, to, count }) => {
                                return '' + from + '-' + to + ` ${t("general.tables.to")} ` + count
                            }
                        }
                        labelRowsPerPage={t("general.tables.rowsPerPage")}
                    />
                </Box>
            </CardContent>
        </Card>
    </Grid>);

    const desktopTemplate = (<Grid container>
        {ShowTitle
            ?
            <>
                <Grid item xs={8}>
                    <h4 className="Orbitron20 p-3">{Title}</h4>
                </Grid>

                <Grid item xs={4}>
                    <TextField style={{ 'width': '100%' }}
                        value={searched}
                        label={t("general.labels.filter")}
                        variant="standard"
                        onChange={(searchVal) => requestSearch(searchVal.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        disabled={!searched}
                                        size="small"
                                        onClick={() => {
                                            setSearched('');
                                            requestSearch('');
                                        }}>
                                        <Icons.ClearIcon />
                                    </IconButton>
                                    <Icons.SearchIcon />
                                </InputAdornment>
                            ),
                        }} />
                </Grid>
            </>
            :
            <>
                <Grid item xs={12}>
                    <TextField style={{ 'width': '100%' }}
                        value={searched}
                        label={t("general.labels.filter")}
                        variant="standard" onChange={(searchVal) => requestSearch(searchVal.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        disabled={!searched}
                                        size="small"
                                        onClick={() => {
                                            setSearched('');
                                            requestSearch('');
                                        }}>
                                        <Icons.ClearIcon />
                                    </IconButton>
                                    <Icons.SearchIcon />
                                </InputAdornment>
                            ),
                        }} />
                </Grid>
            </>
        }

        {table}
    </Grid>);

    const tabletOrMobileTemplate = (<Grid container>
        {ShowTitle
            ?
            <>
                <Grid item xs={12}>
                    <h4 className="Orbitron20 p-3">{Title}</h4>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        style={{ 'width': '100%' }} value={searched}
                        label={t("general.tables.filter")} variant="standard"
                        onChange={(searchVal) => requestSearch(searchVal.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        disabled={!searched}
                                        size="small"
                                        onClick={() => {
                                            setSearched('');
                                            requestSearch('');
                                        }}>
                                        <Icons.ClearIcon />
                                    </IconButton>
                                    <Icons.SearchIcon />
                                </InputAdornment>
                            ),
                        }} />
                </Grid>
            </>
            :
            <>
                <Grid item xs={12}>
                    <TextField
                        style={{ 'width': '100%' }}
                        value={searched}
                        label={t("general.tables.filter")}
                        variant="standard" onChange={(searchVal) => requestSearch(searchVal.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        disabled={!searched}
                                        size="small"
                                        onClick={() => {
                                            setSearched('');
                                            requestSearch('');
                                        }}>
                                        <Icons.ClearIcon />
                                    </IconButton>
                                    <Icons.SearchIcon />
                                </InputAdornment>
                            ),
                        }} />
                </Grid>
            </>
        }

        {table}
    </Grid>);

    return (
        <ResponsiveWrapper DesktopOrLaptopLayout={desktopTemplate} TabletOrMobileLayout={tabletOrMobileTemplate} />
    );
}
