
enum signalREventListenerDictionary {
    // Events
    // Job Manager
    GET_DRIVER_INFO = "GetDriveInfo",
    GET_QTY = "GetQty",
    GET_LAST_FIVE_JOBS = "GetLastFiveJobs",
    GET_LIST_OF_JOBS = "GetListOfJobs",
    GET_JOBS_BY_MONTH = "GetJobsByMonth",

    // ReportCenter

    // Single printer
    GET_INK_USAGE_SINGLE_PRINTER = "GetInkUsageSinglePrinter",
    GET_PRINT_STATISTIC_SINGLE_PRINTER = "GetPrintStatisticSinglePrinter",
    GET_PRINT_EVENTS_LIST_SINGLE_PRINTER = "GetPrintEventsListSinglePrinter",

    // Single Job
    GET_SINGLE_JOB = "GetSingleJob",
    GET_SINGLE_JOB_HISTORY = "GetSingleJobHistory",
    GET_SINGLE_JOB_DATA_HISTORY_LIST = "SingleJobDataHistoryList",

    // Recycle Bin
    GET_DELETED_JOBS = "GetDeletedJobs",

    // Print Event
    GET_INK_USAGE = "GetInkUsage",
    GET_PRINT_STATISTIC = "GetPrintStatistic",
    GET_PRINT_EVENTS_LIST = "GetPrintEventsList",

    // Groups
    JOIN_JOB_MANAGER_GROUP = "JoinJobManagerGroup",
    LEAVE_JOB_MANAGER_GROUP = "LeaveJobManagerGroup",

    JOIN_SINGLE_JOB_GROUP = "JoinSingleJobGroup",
    LEAVE_SINGLE_JOB_GROUP = "LeaveSingleJobGroup",

    JOIN_PRINT_EVENT_GROUP = "JoinPrintEventGroup",
    LEAVE_PRINT_EVENT_GROUP = "LeavePrintEventGroup",

    JOIN_RECYCLE_BIN_GROUP = "JoinRecycleBinGroup",
    LEAVE_RECYCLE_BIN_GROUP = "LeaveRecycleBinGroup",
}

export default signalREventListenerDictionary;
