import PDMServerHttpClient from "../../PDMServerHttpClient";

class CompanyService {
  async getCompanyLogo() {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<string>(`/api/Company/V1.0/GetCompanyLogo`);
  }

  async getWelcomeText() {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<string>(`/api/Company/V1.0/GetWelcomeText`);
  }

  static getInstance() {
    return new CompanyService();
  }
}

export default CompanyService;
