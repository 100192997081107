import { CmixDataModel } from "../../../../../Models/Api/Cmix/CmixDataModel";
import { CmixQuantityDataModel } from "../../../../../Models/Api/Cmix/CmixQuantityDataModel";
import PDMServerHttpClient from "../../PDMServerHttpClient";

class CMiXService {
  async getCMiXColor(number: string) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<CmixDataModel>(`/api/Cmix/V1.0/GetCmix/${number.toString()}`);
  }

  async getRALColor(number: string) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<CmixDataModel>(`/api/Cmix/V1.0/GetRal/${number.toString()}`);
  }

  async getPantoneColor(number: string) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<CmixDataModel>(`/api/Cmix/V1.0/GetPantone/${number.toString()}`);
  }

  async getCMiXQuantity(amount: number, cmixColor: CmixDataModel) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.post<CmixQuantityDataModel>(`/api/Cmix/V1.0/Quantity/${amount}`, cmixColor);
  }

  static getInstance() {
    return new CMiXService();
  }
}

export default CMiXService;
