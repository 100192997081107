import React, { useRef, useState, useEffect } from 'react'
import { useFormik } from "formik";
import { Checkbox, FormControl, Grid, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';
import { useTranslation } from "react-i18next";
import { MainButton } from '../../../../components/General/GeneralExport';
import { FormError } from '../../../../components/Forms/FormsExport';
import { useTheme } from '@mui/material/styles';
import Styles from './EditUser.module.scss';
import HttpRequestWithForbiddenCheck from '../../../../services/HttpRequestWithForbiddenCheck/HttpRequestWithForbiddenCheck';
import { toast } from "react-toastify";
import { useHistory } from 'react-router-dom';
import { pdmAuthorize, pdmUnauthorize } from '../../../../Store/Actions/Actions';
import { RootState } from '../../../../Store/Store';
import { useDispatch, useSelector } from 'react-redux';
import { propertyOf } from '../../../../Helpers/propertyOf';
import { v4 as uuidv4 } from "uuid";
import { EditUserFormModel } from '../../../../Models/Forms/EditUser/EditUserFormModel';
import AwaitHandler from "../../../../services/AwaitHandling/AwaitHandling";
import GetFileFromStorageString from '../../../../Pipes/GetFileFromStorageString';
import { UserApiDataModel } from '../../../../Models/Api/User/UserApiDataModel';
import { AuthorizationService, UserService, userInfo } from '../../../../Api/Rest/PDMServer/ApiServices/PDMApiServicesImports';
import * as Yup from "yup";
import englishAndCharsOnly from '../../../../Validators/Private/englishAndCharsOnly';
import { getFieExtension } from '../../../../Helpers/getFieExtension';
import { useParams, useLocation } from "react-router-dom"
import UseQuery from "../../../../Hooks/UseQuery";

type EditUserParamTypes = {
  userId: string
}

function EditUser() {
  const query = UseQuery();
  const params = useParams<EditUserParamTypes>();
  const location = useLocation();
  const isProfileEdit = location.pathname.toLowerCase().includes('profile');
  const userId = isProfileEdit ? query.get("userId") : params.userId;
  const theme = useTheme();
  const [t] = useTranslation();
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [labelZipJob, setLabelZipJob] = useState<string>(t("general.labels.chooseJob"));
  const [ShowFileError, setShowFileError] = useState(false);
  const [FileError, setFileError] = useState("");
  const userService = UserService.getInstance();
  const history = useHistory();
  const [Roles, setRoles] = useState<string[]>([]);

  const pdmAuthState = useSelector((state: RootState) => state.PdmAuth);
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    RoleNames: Yup.array()
      .min(1, t('validation.pickAtLeastOneItem'))
      .of(Yup.string()),
    FirstName: Yup.string()
      .matches(englishAndCharsOnly, t('validation.onlyEnglishLetters'))
      .max(30, t('validation.mustBe30CharsOrLess'))
      .required(t('validation.required')),
    LastName: Yup.string()
      .matches(englishAndCharsOnly, t('validation.onlyEnglishLetters'))
      .max(30, t('validation.mustBe30CharsOrLess'))
      .required(t('validation.required')),
    UserName: Yup.string()
      .matches(englishAndCharsOnly, t('validation.onlyEnglishLetters'))
      .max(30, t('validation.mustBe30CharsOrLess'))
      .required(t('validation.required')),
    Position: Yup.string()
      .matches(englishAndCharsOnly, t('validation.onlyEnglishLetters'))
      .max(30, t('validation.mustBe30CharsOrLess'))
      .required(t('validation.required')),
    Email: Yup.string()
      .email()
      .matches(englishAndCharsOnly, t('validation.onlyEnglishLetters'))
      .max(30, t('validation.mustBe30CharsOrLess'))
      .required(t('validation.required')),
    AllowImage: Yup.boolean(),
    AvatarPhoto: Yup.mixed().when("AllowImage", (AllowImage: boolean) => {
      if (!AllowImage)
        return Yup.mixed().notRequired();
      else
        return Yup.mixed().test({
          message: t('validation.provideSupportedFileType'),
          test: (file, context) => {
            if (file) {
              const endStr = getFieExtension(file.name).toLowerCase();
              const isValid = ['png', 'jpg'].includes(endStr);
              if (!isValid) {
                context.createError({
                  message: t('validation.provideSupportedFileType'),
                  path: 'AvatarPhoto'
                });
              }
              return isValid;
            }
            return false;
          }
        }).test({
          message: t('validation.fileSizeIsTooBig'),
          test: (file, context) => {
            if (file) {
              // 2MB => 2097152 bytes
              const isValid = file.size <= 2097152;
              if (!isValid) {
                context.createError({
                  message: t('validation.fileSizeIsTooBig'),
                  path: 'AvatarPhoto'
                });
              }
              return isValid;
            }
            return false;
          }
        })
    }),
    IsEnabled: Yup.boolean(),
    IsLockedOut: Yup.boolean(),
  });

  //const [UserData, setUserData] = useState<UserApiDataModel | null>(null);
  const [, setUserData] = useState<UserApiDataModel | null>(null);

  const formik = useFormik({
    initialValues: {
      FirstName: '',
      LastName: '',
      UserName: '',
      Position: '',
      Email: '',
      AvatarPhoto: null,
      RoleNames: [],
      AllowImage: false,
      IsEnabled: true,
      IsLockedOut: true,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      submitF(values);
    },
  });

  useEffect(() => {
    setLabelZipJob(t("general.labels.chooseJob"));

    Object.keys(formik.values).forEach((fieldName) => {
      formik.setFieldTouched(fieldName);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t])

  useEffect(() => {
    asyncInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const asyncInit = async () => {
    const pdmAuthorized = await AuthorizationService.getInstance().checkApi();

    if (pdmAuthorized.status === 200) {
      if (!pdmAuthorized.data) {
        if (pdmAuthState !== pdmAuthorized.data) {
          dispatch(pdmUnauthorize());
        }

        history.push('/AuthorizePDM');
      }

      if (pdmAuthState !== pdmAuthorized.data) {
        dispatch(pdmAuthorize());
      }

      if (userId) {
        const [res, error] = await AwaitHandler(userInfo());

        if (!error && res.data) {
          const [myUser, err2] =
            await HttpRequestWithForbiddenCheck(userService.getUserProfileByEmail(res.data.email), 'getUserProfile', 'Error When Getting The User');

          if (!err2 && myUser) {
            const [roles, err2] =
              await HttpRequestWithForbiddenCheck(userService.postRolesUnderUserRoles(myUser.RoleNames), 'postRolesUnderUserRoles', 'Error When Getting The Roles Under User Roles');

            if (!err2 && roles) {
              setRoles(roles);
            }
          }

          const [userData, err1] =
            await HttpRequestWithForbiddenCheck(userService.getUserProfileById(userId), 'getUserProfileById', 'Error When Getting The User By Id');

          if (!err1 && userData) {
            setUserData(userData);

            formik.setFieldValue(propertyOf<EditUserFormModel>('Email'), userData.Email ?? '');
            formik.setFieldValue(propertyOf<EditUserFormModel>('FirstName'), userData.FirstName ?? '');
            formik.setFieldValue(propertyOf<EditUserFormModel>('LastName'), userData.LastName ?? '');
            formik.setFieldValue(propertyOf<EditUserFormModel>('Position'), userData.Position ?? '');
            formik.setFieldValue(propertyOf<EditUserFormModel>('UserName'), userData.UserName ?? '');

            if (userData.AvatarPhotoPath) {
              // formik.setFieldValue(propertyOf<EditUserFormModel>('AvatarPhoto'), userData.AvatarPhotoPath ?? '');
              setLabelZipJob(GetFileFromStorageString(userData.AvatarPhotoPath));
            }

            formik.setFieldValue(propertyOf<EditUserFormModel>('RoleNames'), userData.RoleNames ?? []);
            formik.setFieldValue(propertyOf<EditUserFormModel>('IsEnabled'), userData.IsEnabled ?? true);
            formik.setFieldValue(propertyOf<EditUserFormModel>('IsLockedOut'), userData.IsLockedOut ?? false);
          }
        }
      }
    }
  };

  const submitF = async (user: any) => {
    if (!userId)
      return;

    const userModel: EditUserFormModel = {
      UserId: userId,
      FirstName: user.FirstName,
      LastName: user.LastName,
      UserName: user.UserName,
      Position: user.Position,
      Email: user.Email,
      AvatarPhoto: user.AvatarPhoto,
      RoleNames: user.RoleNames,
      OverrideAvatarPhoto: user.AllowImage,
      IsEnabled: user.IsEnabled,
      IsLockedOut: user.IsLockedOut,
    };

    const [, error] =
      await HttpRequestWithForbiddenCheck(userService.postEditUser(userModel), 'editUser', 'Error When Editing A User');

    if (!error) {
      toast.success("User was updated successfully", {
        style: { "backgroundColor": "#6f42c1" }
      });

      if (isProfileEdit) {
        history.push('/Profile');
      }
      else {
        history.push(`/UsersDashboard/${userId}`);
      }
    }
  };

  const onBtnClick = () => {
    /*Collecting node-element and performing click*/
    inputFileRef.current?.click();
  };

  const addZipFile = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    if (e.currentTarget.files !== null && e.currentTarget.files[0] !== null) {

      formik.setFieldTouched('AvatarPhoto');
      formik.setFieldValue('AvatarPhoto', e.currentTarget.files[0]);
      const file = e.currentTarget.files[0];
      const fileExtension = file.name.slice((Math.max(0, file.name.lastIndexOf(".")) || Infinity) + 1)
      if (fileExtension.toLowerCase() === 'png' || fileExtension.toLowerCase() === 'jpg') {

        // 4294967296 -> 4GB
        // if (file.size > 4294967296) {
        //     if (!ShowFileError) setShowFileError(true);
        //     if (FileError !== "File Is Too Big") setFileError("File Is Too Big")
        //     setLabelZipJob(t("general.labels.chooseJob"));
        //     return;
        // };

        setLabelZipJob(file.name);
        if (ShowFileError) setShowFileError(false);
      }
      else {
        if (!ShowFileError) setShowFileError(true);
        if (FileError !== "File type isn't supported, only accept .zip files") setFileError("File type isn't supported, only accept .zip files")
        setLabelZipJob(t("general.labels.chooseJob"));
      }
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <form onSubmit={formik.handleSubmit}>

          <Grid container spacing={1}>

            <Grid item xs={12} sm={12} md={6}>
              <label className={Styles["add-user-form-label"] + " required"}>{t("general.labels.firstName")}</label>
              <input className="form-control" type="text" lang="en" {...formik.getFieldProps("FirstName")} style={{
                'backgroundColor': theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white',
                'color': theme.palette.mode === 'dark' ? 'white' : 'black'
              }} />
              <FormError disableLine formik={formik} propName={"FirstName"} />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <label className={Styles["add-user-form-label"] + " required"}>{t("general.labels.lastName")}</label>
              <input className="form-control" type="text" lang="en" {...formik.getFieldProps("LastName")} style={{
                'backgroundColor': theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white',
                'color': theme.palette.mode === 'dark' ? 'white' : 'black'
              }} />
              <FormError disableLine formik={formik} propName={"LastName"} />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <label className={Styles["add-user-form-label"] + " required"}>{t("general.userInfo.email")}</label>
              <input className="form-control" type="text" lang="en" {...formik.getFieldProps("Email")} style={{
                'backgroundColor': theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white',
                'color': theme.palette.mode === 'dark' ? 'white' : 'black'
              }} />
              <FormError disableLine formik={formik} propName={"Email"} />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <label className={Styles["add-user-form-label"] + " required"}>{t("general.userInfo.roles")}</label>
              <div style={{ 'width': '100%' }}>
                <FormControl style={{ 'width': '100%' }}>
                  <Select
                    style={{
                      'height': '38px',
                      'backgroundColor': theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white',
                      'color': theme.palette.mode === 'dark' ? 'white' : 'black',
                      'border': theme.palette.mode === 'dark' ? '1px solid grey' : 'none'
                    }}
                    multiple
                    {...formik.getFieldProps("RoleNames")}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) =>
                      selected.map((obj: any) => obj).join(", ")
                    }
                  >
                    {Roles.map((name) => (
                      <MenuItem key={uuidv4()} value={name}>
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <FormError disableLine formik={formik} propName={"RoleNames"} />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <label className={Styles["add-user-form-label"] + " required"}>{t("general.labels.userName")}</label>
              <input className="form-control" type="text" lang="en" {...formik.getFieldProps("UserName")} style={{
                'backgroundColor': theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white',
                'color': theme.palette.mode === 'dark' ? 'white' : 'black'
              }} />
              <FormError disableLine formik={formik} propName={"UserName"} />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <label className={Styles["add-user-form-label"] + " required"}>{t("general.labels.position")}</label>
              <input className="form-control" type="text" lang="en" {...formik.getFieldProps("Position")} style={{
                'backgroundColor': theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white',
                'color': theme.palette.mode === 'dark' ? 'white' : 'black'
              }} />
              <FormError disableLine formik={formik} propName={"Position"} />
            </Grid>

            <Grid item xs={12}>
              <Checkbox  {...formik.getFieldProps("AllowImage")} />
              <label className={Styles["add-job-from-label"]}>{t("general.labels.replaceImage")}</label>

              <label htmlFor="contained-button-file" style={{ 'display': 'flex' }}>
                <input disabled={!formik.values.AllowImage}
                  style={{ 'display': 'none' }}
                  accept="image/png, image/gif, image/jpeg"
                  id="file"
                  name="file"
                  type="file"
                  lang="en"
                  multiple
                  ref={inputFileRef}
                  onChange={(e: any) => addZipFile(e)} />
                <input className="form-control" lang="en" value={labelZipJob} disabled onClick={onBtnClick} style={{
                  'backgroundColor': theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white',
                  'color': theme.palette.mode === 'dark' ? 'white' : 'black'
                }} />
                <MainButton disabled={!formik.values.AllowImage} onClick={(e: any) => { e.preventDefault(); onBtnClick(); }} type='button'>
                  {t("general.labels.browse")}
                </MainButton>
              </label>
              <FormError formik={formik} propName={"AvatarPhoto"} />
            </Grid>

            <Grid item xs={12} sx={{ mt: -1 }}>
              <Checkbox
                {...formik.getFieldProps("IsEnabled")}
                checked={formik.values.IsEnabled} />
              <label className={Styles["add-job-from-label"]}>
                {formik.values.IsEnabled ? t("general.labels.enabled") : t("general.labels.disabled")}
              </label>
            </Grid>

            {/* <Grid item xs={12} mt={-1} sx={{ mt: -2 }}>
              <Checkbox
                {...formik.getFieldProps("IsLockedOut")}
                checked={formik.values.IsLockedOut} />
              <label className={Styles["add-job-from-label"]}>
                {formik.values.IsLockedOut ? t("general.labels.locked") : t("general.labels.unlocked")}
              </label>
            </Grid> */}

            <Grid item xs={12} mt={-1} sx={{ mt: -2 }}>
              <Checkbox
                {...formik.getFieldProps("IsLockedOut")}
                checked={formik.values.IsLockedOut}
                disabled={!formik.values.IsLockedOut}
              />
              <label className={Styles["add-job-from-label"]}
                style={{
                  opacity: formik.values.IsLockedOut ? 1 : 0.5,
                  pointerEvents: formik.values.IsLockedOut ? 'auto' : 'none',
                  cursor: formik.values.IsLockedOut ? 'default' : 'not-allowed'
                }}>
                {formik.values.IsLockedOut ? t("general.labels.locked") : t("general.labels.unlocked")}
              </label>
            </Grid>

            <Grid item xs={12}>
              <MainButton type='submit'>
                {t("general.labels.submit")}
              </MainButton>
            </Grid>
          </Grid>

        </form>
      </Grid>
    </Grid>
  )
};

export default EditUser;
