import React, { useRef, useEffect } from 'react';
import EasyMDE from "easymde";
// import CommentTemplate from "./EditorTemplate.md";
import "easymde/dist/easymde.min.css";
import "./TextEditor.scss";
import { useTheme } from '@mui/material/styles';
import { useTranslation } from "react-i18next";

type ToolbarButton =
    'bold'
    | 'italic'
    | 'quote'
    | 'unordered-list'
    | 'ordered-list'
    | 'link'
    | 'image'
    | 'strikethrough'
    | 'code'
    | 'table'
    | 'redo'
    | 'heading'
    | 'undo'
    | 'heading-bigger'
    | 'heading-smaller'
    | 'heading-1'
    | 'heading-2'
    | 'heading-3'
    | 'clean-block'
    | 'horizontal-rule'
    | 'preview'
    | 'side-by-side'
    | 'fullscreen'
    | 'guide';

type TextEditorProps = {
    setCommentText: (element: EasyMDE) => void
    CommentText?: string
};

function TextEditor({ setCommentText, CommentText = '' }: TextEditorProps) {
    const [t] = useTranslation();
    const theme = useTheme();
    let editor = useRef<HTMLTextAreaElement>(null);

    const toolbar: boolean | readonly ("|" | ToolbarButton | EasyMDE.ToolbarIcon | EasyMDE.ToolbarDropdownIcon)[] =
        [
            "bold", "italic", "heading", "heading-smaller", "heading-bigger",
            {
                name: "unCheck",
                action: (editor: EasyMDE) => {
                    let cm = editor.codemirror;
                    let selectedText = cm.getSelection();
                    let text = selectedText || 'placeholder';
                    let output = `* [ ] ${text}`;
                    cm.replaceSelection(output);
                },
                className: "fa fa-square",
                title: "Unchecked",
            },
            {
                name: "check",
                action: (editor: EasyMDE) => {
                    let cm = editor.codemirror;
                    let selectedText = cm.getSelection();
                    let text = selectedText || 'placeholder';
                    let output = `* [X] ${text}`;
                    cm.replaceSelection(output);
                },
                className: "fa fa-check-square",
                title: "Checked",
            },
            "|", "quote", "code", "ordered-list", "unordered-list", "|", "preview"
        ];

    useEffect(() => {
        if (CommentText !== undefined && CommentText !== '' && editor.current !== null) {
            let tmp = new EasyMDE({
                element: editor.current,
                autoDownloadFontAwesome: false,
                toolbar: toolbar,
                initialValue: CommentText,
                status: false,
            });
            setCommentText(tmp);
        }
        else {
            // fetch(CommentTemplate)
            //     .then(res => res.text())
            //     .then(post => {
            //         if (editor.current !== null) {
            //             let tmp = new EasyMDE({
            //                 element: editor.current,
            //                 autoDownloadFontAwesome: false,
            //                 toolbar: toolbar,
            //                 initialValue: post,
            //                 status: false,
            //             });
            //             setCommentText(tmp);
            //         }
            //     })
            //     .catch((err) => console.error(err));

            if (editor.current !== null) {
                let tmp = new EasyMDE({
                    element: editor.current,
                    autoDownloadFontAwesome: false,
                    toolbar: toolbar,
                    initialValue: t("general.commentTemplate"),
                    status: false,
                });
                setCommentText(tmp);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editor, CommentText])

    return (
        <div className={theme.palette.mode === 'dark' ? 'darkTextEditor' : 'lightTextEditor'}>
            <textarea className='customTextArea' ref={editor}></textarea>
        </div>
    );
}

export default TextEditor;
