enum ActionTypes {
  TAB_ACTIVE = 'TAB_ACTIVE',
  TAB_INACTIVE = 'TAB_INACTIVE',

  AUTHORIZE_PDM = 'AUTHORIZE_PDM',
  UNAUTHORIZE_PDM = 'UNAUTHORIZE_PDM',

  ADD_JOB = 'ADD_JOB',
  UPDATE_JOB = 'UPDATE_JOB',
  REMOVE_JOB = 'REMOVE_JOB',
  CLEAR_JOBS = 'CLEAR_JOBS',
  ADD_JOB_COUNTER = 'ADD_JOB_COUNTER',
  SET_BEGINING_OF_THE_CHUNK_TO_JOB = 'SET_BEGINING_OF_THE_CHUNK_TO_JOB',
  SET_END_OF_THE_CHUNK_TO_JOB = 'SET_END_OF_THE_CHUNK_TO_JOB',
  SET_PROGRESS_TO_JOB = 'SET_PROGRESS_TO_JOB',
  START_PROCESS = 'START_PROCESS',
  SET_IS_FIRST_TO_JOB = 'SET_IS_FIRST_TO_JOB'
}

export default ActionTypes;
