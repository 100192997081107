import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import firstLetterCapital from "../../../Pipes/firstLetterCapital";
import humanizeString from 'humanize-string';
import { Breadcrumbs, Grid, Link, Typography } from "@mui/material";

const CustomsBreadCrumbs = () => {
  const location = useLocation();
  type pathProps = { path: string, name: string };
  const [pathArray, setPathArray] = useState<Array<pathProps>>();
  const [header, setHeader] = useState("");

  useEffect(() => {
    const tempPathArr = location.pathname.split("/");
    initCrumbs(tempPathArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const initCrumbs = (tempPathArr: Array<string>) => {
    let pathArrayTmp: Array<pathProps> = new Array<pathProps>();
    for (let index = 1; index < tempPathArr.length; index++) {
      let url = "";
      for (let x = 1; x < index + 1; x++) {
        url += "/" + tempPathArr[x];
      }
      pathArrayTmp.push({
        path: url,
        name: "/" + tempPathArr[index],
      });
    }
    setPathArray(pathArrayTmp);
    initHeader(pathArrayTmp);
  };

  const initHeader = (pathArrayTmp: Array<pathProps>) => {
    const arr = humanizeString(pathArrayTmp[pathArrayTmp.length - 1].name.substring(1));
    const headerArr = arr.split(" ");
    let headerTmp = "";

    // check if id
    let regex = /(\d{3}[-]\d{2}[-]\d{3})/g;
    let url = pathArrayTmp[pathArrayTmp.length - 1].name.substring(1);
    const isPrinterId = regex.test(url);

    if (isPrinterId) {
      setHeader(url);
    }
    else {
      for (let index = 0; index < headerArr.length; index++) {
        if (headerArr.length - 1 === index)
          headerTmp += firstLetterCapital(headerArr[index]);
        else
          headerTmp += firstLetterCapital(headerArr[index]) + " ";
      }
      setHeader(headerTmp);
    }
  };

  const customText = (str: string) => {
    let regex = /(\d{3}[-]\d{2}[-]\d{3})/g;
    const isPrinterId = regex.test(str);
    if (isPrinterId) return str;
    else return humanizeString(str);
  };

  return (
    <div>
      <section className="content-header">
        <div className="container-fluid">
          <Grid container>
            <Grid item xs={12} sm={6}>
              <h5 className="Orbitron20">{header}</h5>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Breadcrumbs style={{ 'float': 'right' }}>
                <Link underline="none" sx={{ color: 'primary.dark' }} href="/Welcome">Home</Link>
                {
                  pathArray && pathArray.map((element, index) => {
                    if (element.name !== "/Welcome") {
                      if (index === pathArray.length - 1) {
                        return <Typography key={uuidv4()}>{customText(element.name.substring(1))}</Typography>;
                      }
                      return (
                        element.name !== "/Welcome" && (
                          <Link underline="none" sx={{ color: 'primary.dark' }} key={uuidv4()} href={element.path}>{customText(element.name.substring(1))}</Link>
                        )
                      );
                    }
                    return null;
                  })
                }
              </Breadcrumbs>
            </Grid>
          </Grid>
        </div>
      </section>
    </div>
  );
};

export default CustomsBreadCrumbs;
