import { useState, useEffect } from "react";
import { JobHistoryInfo } from "../../../../modules/JobHistoryInfo/JobHistoryInfoImports";
import PDMServerLocalStorageService from "../../../../services/LocalStorage/PDMServer/PDMServerService";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";

import {
  Grid, Card, CardContent, Typography, Divider, Button, Dialog, DialogActions,
  DialogContent, DialogContentText, DialogTitle, TextField, Box, List, Chip, Tooltip,
  InputAdornment, FormControl, Input, IconButton, ListItemIcon, ListItem, ListItemText, Collapse, useTheme
} from '@mui/material';

import { useTranslation } from "react-i18next";

import Icons from '../../../../Imports/MUI/Icons/IconsImports';

import { v4 as uuidv4 } from "uuid";

import { TextEditor } from "../../../../components/Inputs/InputsExport";
import { CustomListItem } from "../../../../components/ThemeElements/ThemeElementsExport";
import moment from "moment";
import { TagsControl } from "../../../../components/Inputs/InputsExport";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { ColoredLine, MainButton, PermissionsWrapper } from "../../../../components/General/GeneralExport";

import { JobDataModel } from "../../../../Models/Api/Job/JobDataModel";
import signalRBuilder from "../../../../Api/Socket/signalRBuilder";
import signalREventListenerDictionary from "../../../../Models/SignalR/signalREventListenerDictionary";
import { SignalRDataModel } from "../../../../Models/SignalR/SignalRDataModel";
import { JobHistoryDataModel } from "../../../../Models/Api/Job/JobHistoryDataModel";
import HttpRequestWithForbiddenCheck from "../../../../services/HttpRequestWithForbiddenCheck/HttpRequestWithForbiddenCheck";
import SocketEmitWrapper from "../../../../services/SocketEmitWrapper/SocketEmitWrapper";
import SignalRHubEnum from "../../../../Models/SignalR/SignalRHubEnum";

import Styles from './JobInfo.module.scss';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../Store/Store";
import { pdmAuthorize, pdmUnauthorize } from "../../../../Store/Actions/Actions";
import { AuthorizationService, JobManagerService, UserService } from "../../../../Api/Rest/PDMServer/ApiServices/PDMApiServicesImports";
import PermissionsEnum from "../../../../Models/Permissions/PermissionsEnum";
import { BarcodeGenerator } from '../../../../components/General/GeneralExport';
import ResponsiveWrapper from "../../../../components/ResponsiveDesign/ResponsiveWrapper";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
//const prettyMetric = require('pretty-metric');

const JobInfo = () => {
  const { jobId } = useParams<{ jobId: string }>();
  const [t] = useTranslation();
  const history = useHistory();
  const [downloadLink, setDownloadLink] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [jobHistory, setJobHistory] = useState<JobHistoryDataModel[]>([]);
  const [singleJob, setSingleJob] = useState<JobDataModel>({} as JobDataModel);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [barcodeDialogOpen, setBarcodeDialogOpen] = useState(false);
  const theme = useTheme();
  const [url, setUrl] = useState('');

  // Edit states
  const [ShowJobNameEditFormError, setShowJobNameEditFormError] = useState(false);
  const [ShowProjectNameEditFormError, setShowProjectNameEditFormError] = useState(false);
  const [ShowTagsEditFormError, setShowTagsEditFormError] = useState(false);

  const [JobNameEditFormError, setJobNameEditFormError] = useState('');
  const [ProjectNameEditFormError, setProjectNameEditFormError] = useState('');
  const [TagsEditFormError, setTagsEditFormError] = useState('');

  const [JobNameEditForm, setJobNameEditForm] = useState('');
  const [ProjectNameEditForm, setProjectNameEditForm] = useState('');
  const [TagsEditForm, setTagsEditForm] = useState<string[]>([]);
  const [CommentsEditElementForm, setCommentsEditElementForm] = useState<EasyMDE>();
  const [CommentValueEditForm, setCommentValueEditForm] = useState('');

  const [isCopied, setIsCopied] = useState(false);
  const [IdDrawer, setIdDrawer] = useState(false);

  const dispatch = useDispatch();
  const pdmAuthState = useSelector((state: RootState) => state.PdmAuth);
  const [enterGroup, setEnterGroup] = useState(false);

  const [PermissionsList, setPermissionsList] = useState<string[] | null>(null);
  const userService = UserService.getInstance();
  const [truncatedJobName, setTruncatedJobName] = useState(singleJob?.Name ?? "");


  const handleRemoveDialogClose = (answer: boolean) => {
    if (answer) {
      setRemoveDialogOpen(false);
      deleteJob();
    }
    else {
      setRemoveDialogOpen(false);
    }
  };

  const handleBarcodeDialogClose = () => {
    setBarcodeDialogOpen(false);
  };

  useEffect(() => {
    if (pdmAuthState) {
      updateLink();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleJob])

  useEffect(() => {
    asyncInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // When leaving the page the return will emit and remove user from group
    return () => {
      if (enterGroup) leaveGroup()
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterGroup])

  useEffect(() => {
    if (singleJob?.Name?.length > 25) {
      setTruncatedJobName(singleJob.Name.slice(0, 25) + '...');
    } else {
      setTruncatedJobName(singleJob?.Name);
    }
  }, [singleJob.Name]);

  const updateLink = async () => {
    const URL = await PDMServerLocalStorageService.getPDMServerUrl();
    const finalUrl = URL + singleJob.ZipFileUrl;
    setDownloadLink(finalUrl)
  };

  const asyncInit = async () => {
    const pdmAuthorized = await AuthorizationService.getInstance().checkApi();

    if (pdmAuthorized.status === 200) {
      if (!pdmAuthorized.data) {
        if (pdmAuthState !== pdmAuthorized.data) {
          dispatch(pdmUnauthorize());
        }
        history.push('/AuthorizePDM');
      }

      if (pdmAuthState !== pdmAuthorized.data) {
        dispatch(pdmAuthorize());
      }

      let tmp = await PDMServerLocalStorageService.getPDMServerUrl();
      setUrl(tmp);

      initJob();
      initSignalR();
      setEnterGroup(true);
    }

    const tmp = await userService.getUserPermissions();
    if (tmp.status === 200) {
      setPermissionsList(tmp.data);
    }
  };

  const leaveGroup = async () => {
    const b = await signalRBuilder.getInstance(SignalRHubEnum.SINGLE_JOB_HUB);
    // sign to job manager page group

    await SocketEmitWrapper(b.newConnection.invoke(signalREventListenerDictionary.LEAVE_SINGLE_JOB_GROUP, jobId));
  };

  const initSignalR = async () => {
    const b = await signalRBuilder.getInstance(SignalRHubEnum.SINGLE_JOB_HUB);
    // sign to single job page group

    await SocketEmitWrapper(b.newConnection.send(signalREventListenerDictionary.JOIN_SINGLE_JOB_GROUP, jobId));

    b.addEventListener(
      signalREventListenerDictionary.GET_SINGLE_JOB,
      (signalRModel: SignalRDataModel) => {
        const data = JSON.parse(signalRModel.Data);
        if (data.Id === jobId) {
          setSingleJob(data);
        }
      }
    );

    b.addEventListener(
      signalREventListenerDictionary.GET_SINGLE_JOB_HISTORY,
      (signalRModel: SignalRDataModel) => {
        const data = JSON.parse(signalRModel.Data);
        setJobHistory(data);
      }
    );
  }

  const initJob = async () => {
    const [jobData, error] =
      await HttpRequestWithForbiddenCheck(JobManagerService.getInstance().getJob(jobId),
        'getJob', 'Error When Getting The Job');

    if (!error && jobData !== null) {
      setSingleJob(jobData);

      setJobNameEditForm(jobData.Name);
      setProjectNameEditForm(jobData.ProjectName);
      setTagsEditForm(jobData.Tags);
      setCommentValueEditForm(jobData.Comments);

      const [JobHistory, error1] =
        await HttpRequestWithForbiddenCheck(JobManagerService.getInstance().getJobHistory(jobData.Id),
          'getJobHistory', 'Error When Getting The Job History');

      if (!error1 && JobHistory !== null) {
        setJobHistory(JobHistory);
      }
    }
  };

  const deleteJob = async () => {
    const [, error] =
      await HttpRequestWithForbiddenCheck(JobManagerService.getInstance().softDeleteJob(jobId),
        'softDeleteJob', 'Error When Deleting The Job');

    if (!error) {
      toast.success("Job Deleted", { style: { "backgroundColor": "#6f42c1" } });
      history.push("/JobManager/AllJobsList");
    }
  };

  const cancelEditJob = () => {
    // hard remove to the editor
    document.getElementById("custom-card-list")?.lastChild?.removeChild(document.getElementsByClassName("EasyMDEContainer")[0]);
    setEditMode(false);

    // Reset changes
    setJobNameEditForm(singleJob.Name);
    setProjectNameEditForm(singleJob.ProjectName);
    setTagsEditForm(singleJob.Tags);
    setCommentValueEditForm(singleJob.Comments);
  };

  const saveChanges = async () => {
    // validate
    if (!ShowJobNameEditFormError && !ShowProjectNameEditFormError && !ShowTagsEditFormError && ProjectNameEditForm !== "" && JobNameEditForm !== "" && TagsEditForm.length !== 0) {
      // hard remove to the editor
      document.getElementById("custom-card-list")?.lastChild?.removeChild(document.getElementsByClassName("EasyMDEContainer")[0]);

      setEditMode(false);

      let com = CommentsEditElementForm?.value() === undefined ? '' : CommentsEditElementForm?.value();

      const [, error] =
        await HttpRequestWithForbiddenCheck(JobManagerService.getInstance().editJob(singleJob.Id, JobNameEditForm, TagsEditForm, ProjectNameEditForm, com), 'editJob', 'Error When Updating A Job');

      if (!error) {
        toast.success("Job Updated", { style: { "backgroundColor": "#6f42c1" } });
        setSingleJob((prev) => ({ ...prev, Name: JobNameEditForm }));
        setSingleJob((prev) => ({ ...prev, ProjectName: ProjectNameEditForm }));
        setSingleJob((prev) => ({ ...prev, Comments: com }));
        setSingleJob((prev) => ({ ...prev, Tags: TagsEditForm }));
        setCommentValueEditForm(com);
      }
    }

    if (ProjectNameEditForm === "" && JobNameEditForm === "" && TagsEditForm.length === 0) {
      if (!ShowJobNameEditFormError) setShowJobNameEditFormError(true);
      if (JobNameEditFormError !== "Job Name Required") setJobNameEditFormError("Job Name Required");

      if (!ShowProjectNameEditFormError) setShowProjectNameEditFormError(true);
      if (ProjectNameEditFormError !== "Project Name Required") setProjectNameEditFormError("Project Name Required");

      if (!ShowTagsEditFormError) setShowTagsEditFormError(true);
      if (TagsEditFormError !== "Tags Required") setTagsEditFormError("Tags Required");
    }
  };

  const changeJobName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      if (!ShowJobNameEditFormError) setShowJobNameEditFormError(true);
      if (JobNameEditFormError !== "Job Name Missing") setJobNameEditFormError("Job Name Missing");
    }
    else if (e.target.value.length > 25) {
      if (!ShowJobNameEditFormError) setShowJobNameEditFormError(true);
      if (JobNameEditFormError !== "Max length is 25 characters") setJobNameEditFormError("Max length is 25 characters");
    }
    else {
      if (ShowJobNameEditFormError) setShowJobNameEditFormError(false);
    }
    setJobNameEditForm(e.target.value);
  };

  const changeProjectName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      if (!ShowProjectNameEditFormError) setShowProjectNameEditFormError(true);
      if (ProjectNameEditFormError !== "Project Name Missing") setProjectNameEditFormError("Project Name Missing");
    }
    else if (e.target.value.length > 25) {
      if (!ShowJobNameEditFormError) setShowJobNameEditFormError(true);
      if (JobNameEditFormError !== "Max length is 25 characters") setProjectNameEditFormError("Max length is 25 characters");
    }
    else {
      if (ShowProjectNameEditFormError) setShowProjectNameEditFormError(false);
    }
    setProjectNameEditForm(e.target.value);
  };

  const changeTagsList = (newTags: string[]) => {
    if (newTags.length === 0) {
      if (!ShowTagsEditFormError) setShowTagsEditFormError(true);
      if (TagsEditFormError !== "Tags Missing") setTagsEditFormError("Tags Missing");
    }
    else {
      if (ShowTagsEditFormError) setShowTagsEditFormError(false);
    }
    setTagsEditForm(newTags);
  };

  const copyJobIdToClipboard = () => {
    // check if clipboard is supported by the browser
    if ('clipboard' in navigator) {
      try {
        navigator.clipboard.writeText(jobId);
      }
      catch (err) {
        document.execCommand('copy', true, jobId);
      }
    }
    // if not, we will use a local copy in the document
    else {
      document.execCommand('copy', true, jobId);
    }
    setIsCopied(true);

  };

  const openPrintPage = () => {
    window.open(`/JobManager/PrintJob?Id=${jobId}`, "_blank");
  };


  const buttonGroup = (<>
    {editMode
      ?
      <Grid item xs={12} hidden={!editMode}>
        <ul className="nav float-right">
          <MainButton style={{ 'marginLeft': '15px' }} onClick={saveChanges} startIcon={<Icons.CheckCircleIcon />}>
            {t("general.buttons.save")}
          </MainButton>
          <MainButton style={{ 'marginLeft': '15px' }} onClick={cancelEditJob} startIcon={<Icons.CancelIcon />}>
            {t("general.buttons.cancel")}
          </MainButton>
        </ul>
      </Grid>
      :
      <Grid item xs={12} hidden={editMode}>
        <ul className="nav float-right">

          <MainButton style={{ 'marginLeft': '15px' }} onClick={openPrintPage} startIcon={<Icons.PrintIcon />}>
            {t("general.buttons.print")}
          </MainButton>
          <MainButton style={{ 'marginLeft': '15px' }} onClick={() => { setBarcodeDialogOpen(true) }} startIcon={<FontAwesomeIcon icon={faBarcode as IconProp} />}>
            {t("general.buttons.barcode")}
          </MainButton>

          <Button startIcon={<Icons.DownloadIcon />} variant={theme.palette.mode !== 'dark' ? "outlined" : 'contained'} href={downloadLink} download style={{ 'marginLeft': '15px' }}>{t("general.buttons.download")}</Button>

          <PermissionsWrapper PermissionsList={PermissionsList} Permission={PermissionsEnum.DELETE_JOB_MANAGER_UI}
            PassedPermissionTemplate={
              <MainButton style={{ 'marginLeft': '15px' }} onClick={() => setRemoveDialogOpen(true)} startIcon={<Icons.DeleteIcon />} color='error'>
                {t("general.buttons.remove")}
              </MainButton>
            }
          />

          <PermissionsWrapper PermissionsList={PermissionsList} Permission={PermissionsEnum.UPDATE_JOB_MANAGER_UI}
            PassedPermissionTemplate={
              <MainButton style={{ 'marginLeft': '15px' }} onClick={() => setEditMode(true)} startIcon={<Icons.EditIcon />}>
                {t("general.buttons.edit")}
              </MainButton>
            }
          />

          <MainButton style={{ 'marginLeft': '15px' }} onClick={() => history.push('/JobManager/AllJobsList')} startIcon={<Icons.CloseIcon />}>
            {t("general.buttons.close")}
          </MainButton>
        </ul>
      </Grid>
    }
  </>);

  const removeDialog = (<Dialog
    open={removeDialogOpen}
    onClose={() => { handleRemoveDialogClose(false); }}
  >
    <DialogTitle>
      Move Job To recycle Bin?
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        Are You sure You Want To Remove {singleJob.Name} Job?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <MainButton onClick={() => { handleRemoveDialogClose(true); }} color='error' lightVariant="text">
        {t("general.buttons.yes")}
      </MainButton>
      <MainButton onClick={() => { handleRemoveDialogClose(false); }} lightVariant="text">
        {t("general.buttons.no")}
      </MainButton>
    </DialogActions>
  </Dialog>);

  const barcodeDialog = (
    <Dialog
      open={barcodeDialogOpen}
      onClose={handleBarcodeDialogClose}
    >
      <DialogTitle>
        Scan Barcode
      </DialogTitle>
      <DialogContent>
        <BarcodeGenerator Value={singleJob.Id} Width={1} Height={50} FontSize={13} />
      </DialogContent>
      <DialogActions>
        <MainButton onClick={handleBarcodeDialogClose} lightVariant="text">
          {t("general.buttons.close")}
        </MainButton>
      </DialogActions>
    </Dialog>);

  const barcodeDialogForMobileAndTablet = (
    <Dialog
      PaperProps={{
        sx: {
          width: "50%",
          height: 600,
        }
      }}
      open={barcodeDialogOpen}
      onClose={handleBarcodeDialogClose}
    >
      <DialogTitle>
        Scan Barcode
      </DialogTitle>
      <DialogContent>
        <div style={{ "transform": "rotate(90deg)", "background": "transparent" }} >
          <BarcodeGenerator Value={singleJob.Id} Width={1} Height={50} FontSize={13} />
        </div>
      </DialogContent>
      <DialogActions>
        <MainButton onClick={handleBarcodeDialogClose} lightVariant="text">
          {t("general.buttons.close")}
        </MainButton>
      </DialogActions>
    </Dialog>);

  const formTemplate = (<List id="custom-card-list">
    <CustomListItem Title={t("general.jobInfo.jobName")} Required>
      <TextField inputProps={{ maxLength: 25 }} className={Styles["custom-text-field"]} variant="outlined" onChange={changeJobName} value={JobNameEditForm} />
    </CustomListItem>
    {ShowJobNameEditFormError &&
      <div className='text-center' style={{ "color": "red", "paddingBottom": "8px", "fontSize": "18px" }}>
        <ColoredLine color={"red"} />
        {JobNameEditFormError}
      </div>
    }

    <CustomListItem Title={t("general.jobInfo.projectName")} Required>
      <TextField inputProps={{ maxLength: 25 }} className={Styles["custom-text-field"]} variant="outlined" onChange={changeProjectName} value={ProjectNameEditForm} />
    </CustomListItem>
    {ShowProjectNameEditFormError &&
      <div className='text-center' style={{ "color": "red", "paddingBottom": "8px", "fontSize": "18px" }}>
        <ColoredLine color={"red"} />
        {ProjectNameEditFormError}
      </div>
    }

    <CustomListItem Title={t("general.jobInfo.jobColors") + " :"}>
      <div>
        {singleJob.JobColors && singleJob.JobColors.map((color) =>
          color.ColorName && (
            <Tooltip placement="bottom" arrow
              key={uuidv4()}
              title={color.ColorName}>
              <div className={`bg-${color.ColorName.toLowerCase()} colorBox float-right`} />
            </Tooltip>
          )
        )}
      </div>
    </CustomListItem>

    <CustomListItem Title={t("general.jobInfo.size") + " :"}>
      <span className="float-right">
        {/* {singleJob.JobSizeInMM && prettyMetric(Math.round(parseFloat(singleJob.JobSizeInMM?.split(' ')[0]))).input('mm').humanize()} */}
        {/* {singleJob.JobSizeInMM && " x "} */}
        {/* {singleJob.JobSizeInMM && prettyMetric(Math.round(parseFloat(singleJob.JobSizeInMM?.split(' ')[1]))).input('mm').humanize()} */}

        {singleJob.JobSizeInMM && parseFloat(singleJob.JobSizeInMM?.split(' ')[0]).toFixed(2)}
        {singleJob.JobSizeInMM && " X "}
        {singleJob.JobSizeInMM && parseFloat(singleJob.JobSizeInMM?.split(' ')[1]).toFixed(2)}
        {singleJob.JobSizeInMM && " mm"}
      </span>
    </CustomListItem>

    <CustomListItem Title={t("general.jobInfo.resolution") + " :"}>
      <span className="float-right">
        {singleJob.Resolution && Math.round(parseFloat(singleJob.Resolution.split(' ')[0]))}
        {" "}
        X
        {" "}
        {singleJob.Resolution && Math.round(parseFloat(singleJob.Resolution.split(' ')[1]))}
      </span>
    </CustomListItem>

    <CustomListItem Title={t("general.jobInfo.tags")} Required isLast />
    <TagsControl
      tags={TagsEditForm}
      updateTagsList={changeTagsList}
      onTagsLeave={(empty: boolean) => {
        if (empty) {
          if (!ShowTagsEditFormError) setShowTagsEditFormError(true);
          if (TagsEditFormError !== "Press enter to insert the tag") setTagsEditFormError("Press enter to insert the tag");
        }
        else {
          if (ShowTagsEditFormError) setShowTagsEditFormError(false);
        }
      }}
    />
    {ShowTagsEditFormError &&
      <div className='text-center' style={{ "color": "red", "paddingBottom": "8px", "fontSize": "18px" }}>
        <ColoredLine color={"red"} />
        {TagsEditFormError}
      </div>
    }

    <CustomListItem Title={t("general.jobInfo.comments")} Required isLast />

    <TextEditor CommentText={CommentValueEditForm} setCommentText={setCommentsEditElementForm} />
  </List>);

  const viewTemplate = (<List id="custom-card-list">

    <ListItem style={{ 'paddingLeft': '0', 'cursor': 'auto' }} secondaryAction={
      <IconButton edge="end" onClick={() => setIdDrawer(!IdDrawer)}>
        {IdDrawer ? <Icons.ExpandLess /> : <Icons.ExpandMore />}
      </IconButton>
    }>
      <ListItemText>
        <ListItemIcon>
          Id:
        </ListItemIcon>
      </ListItemText>
    </ListItem>

    <Collapse in={IdDrawer} timeout="auto" unmountOnExit>
      <FormControl variant="standard" className={Styles['custom-text-field-like-li']} style={{ 'width': '95%', 'marginTop': '10px' }} >
        <Input
          disabled
          type={'text'}
          value={singleJob.Id}
          endAdornment={
            <InputAdornment position="end" style={{ 'width': '25px' }}>
              <IconButton onClick={copyJobIdToClipboard}>
                <Tooltip
                  open={isCopied}
                  placement="top"
                  title={"Copied to clipboard!"}
                  leaveDelay={1500} onClose={() => { setIsCopied(false); }} >
                  <Icons.ContentCopyIcon />
                </Tooltip>
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </Collapse>

    <Divider />

    <CustomListItem Title={t("general.jobInfo.jobName") + " :"}>
      <span>
        {truncatedJobName}
      </span>
    </CustomListItem>

    <CustomListItem Title={t("general.jobInfo.projectName") + " :"}>
      <span className="float-right">
        {singleJob.ProjectName}
      </span>
    </CustomListItem>

    <CustomListItem Title={t("general.jobInfo.dateCreated") + " :"}>
      <span className="float-right" hidden={editMode}>
        {singleJob?.ContentInfo?.DateCreated && moment.utc(singleJob.ContentInfo.DateCreated).local().format('DD/MM/YYYY H:mm')}
      </span>
    </CustomListItem>

    <CustomListItem Title={t("general.jobInfo.dateModified") + " :"}>
      <span className="float-right" hidden={editMode}>
        {singleJob?.ContentInfo?.DateModified && moment.utc(singleJob.ContentInfo.DateModified).local().format('DD/MM/YYYY H:mm')}
      </span>
    </CustomListItem>

    <CustomListItem Title={t("general.jobInfo.jobColors") + " :"}>
      <div>
        {singleJob.JobColors && singleJob.JobColors.map((color) =>
          color.ColorName && (
            <Tooltip placement="bottom" arrow
              key={uuidv4()}
              title={color.ColorName}>
              <div className={`bg-${color.ColorName.toLowerCase()} colorBox float-right`} />
            </Tooltip>
          )
        )}
      </div>
    </CustomListItem>

    <CustomListItem Title={t("general.jobInfo.size") + " :"}>
      <span className="float-right">
        {singleJob.JobSizeInMM && parseFloat(singleJob.JobSizeInMM?.split(' ')[0]).toFixed(2)}
        {singleJob.JobSizeInMM && " X "}
        {singleJob.JobSizeInMM && parseFloat(singleJob.JobSizeInMM?.split(' ')[1]).toFixed(2)}
        {singleJob.JobSizeInMM && " mm"}

        {/* {singleJob.JobSizeInMM && prettyMetric(Math.round(parseFloat(singleJob.JobSizeInMM?.split(' ')[1]))).input('mm').humanize()} */}
        {/* {singleJob.JobSizeInMM && prettyMetric(Math.round(parseFloat(singleJob.JobSizeInMM?.split(' ')[0]))).input('mm').humanize()} */}
      </span>
    </CustomListItem>

    <CustomListItem Title={t("general.jobInfo.resolution") + " :"}>
      <span className="float-right">
        {singleJob.Resolution && Math.round(parseFloat(singleJob.Resolution.split(' ')[0]))}
        {" "}
        X
        {" "}
        {singleJob.Resolution && Math.round(parseFloat(singleJob.Resolution.split(' ')[1]))}
        {" dpi"}
      </span>
    </CustomListItem>

    <CustomListItem Title={t("general.jobInfo.tags") + " :"} isLast />

    <span className="float-right">
      {singleJob.Tags && singleJob.Tags.map((tag, index) => (
        <Chip size="small" key={uuidv4()} color='primary' label={tag} style={{ 'marginLeft': '2px', 'marginRight': '2px' }} />
      ))}
    </span>


    <CustomListItem Title={t("general.jobInfo.comments") + " :"} isLast />

    <div style={{ "padding": "0" }}>
      <ReactMarkdown remarkPlugins={[remarkGfm]}>
        {singleJob.Comments}
      </ReactMarkdown>
    </div>

    <PermissionsWrapper PermissionsList={PermissionsList} Permission={PermissionsEnum.UPDATE_JOB_MANAGER_UI}
      PassedPermissionTemplate={
        <MainButton className="float-right"
          // disabled={jobHistory.length <= 1}
          style={{ 'marginBottom': '15px' }}
          onClick={() => history.push(`/JobManager/AllJobsList/${singleJob.Id}/JobDataVersions`)}
          startIcon={<Icons.TagIcon />}>
          {t("general.buttons.versions")}
        </MainButton>
      }
    />
  </List>);

  const historyCard = (<>
    {
      jobHistory.length === 0
        ?
        <Card className="noBackgroundImage">
          <CardContent style={{ 'paddingBottom': '16px', 'height': '100%' }}>
            <Typography gutterBottom variant="h5" component="div">{t("general.cards.jobHistory")}</Typography>
            <Divider style={{ 'marginBottom': '15px' }} />
            <JobHistoryInfo jobHistory={jobHistory} />
          </CardContent>
        </Card>
        :
        <Card style={{ 'height': '100%' }} className="noBackgroundImage">
          <CardContent style={{ 'paddingBottom': '16px', 'height': '100%' }}>
            <Typography gutterBottom variant="h5" component="div">{t("general.cards.jobHistory")}</Typography>
            <Divider style={{ 'marginBottom': '15px' }} />
            <JobHistoryInfo jobHistory={jobHistory} />
          </CardContent>
        </Card>
    }
  </>);

  const infoCard = (<Card style={{ 'height': '100%' }} className="noBackgroundImage">
    <CardContent style={{ 'paddingBottom': '16px' }}>
      <Box sx={{ width: '100%', backgroundColor: 'background.paper' }}>
        <div className="position-relative">
          {
            singleJob.IsNew &&
            <div className="ribbon-wrapper">
              <div className="ribbon bg-success">
                {t("general.labels.new")}
              </div>
            </div>
          }
          <img
            alt="Job"
            className="img-fluid"
            src={`${url}${singleJob.Thumb}`}
            style={{ 'maxHeight': '250px', 'maxWidth': '100%', 'margin': 'auto', 'float': 'right' }}
          />
        </div>
        <nav aria-label="main mailbox folders">
          {editMode ? formTemplate : viewTemplate}
        </nav>
      </Box>
    </CardContent>
  </Card>);

  // The correct way to use responsive design, still need to use responsive wrapper to different models that depends on the size
  return (
    <Grid container spacing={2}>
      {removeDialog}
      <ResponsiveWrapper DesktopOrLaptopLayout={barcodeDialog} TabletOrMobileLayout={barcodeDialogForMobileAndTablet} />
      {buttonGroup}

      <Grid item xs={12} sm={12} md={5} lg={4} xl={3}>
        {infoCard}
      </Grid>
      <Grid item xs={12} sm={12} md={7} lg={8} xl={9}>
        {historyCard}
      </Grid>
    </Grid >
  );
};

export default JobInfo;
