import PDMServerHttpClient from "../../PDMServerHttpClient";
import { EventDataModel } from "../../../../../Models/Api/Events/EventDataModel";
import { InkUsageDataModel } from "../../../../../Models/Api/ReportCenter/InkUsageDataModel";
import { PrintEventStatusDataModel } from "../../../../../Models/Api/Events/PrintEventStatusDataModel";
import { PrintEventDataModel } from "../../../../../Models/Api/Events/PrintEventDataModel";
import { PrintersSquareMeterSummeryDataModel } from "../../../../../Models/Api/Events/PrintersSquareMeterSummeryDataModel";

class AllPrintersService {
  async getInkUsage(dateFrom: string, dateTo: string, limit = 0) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<InkUsageDataModel[]>(`/api/PrintEvents/v1.0/GetInkUsage/${dateFrom}/${dateTo}/${limit}`);
  }

  async getPrintEventStatus(dateFrom: string, dateTo: string, limit = 0) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<PrintEventStatusDataModel>(`/api/PrintEvents/v1.0/DateRange/PrintStatus/${dateFrom}/${dateTo}/${limit}`);
  }

  async getPrintedJobsByRange(dateFrom: string, dateTo: string, limit = 0) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<PrintEventDataModel[]>(`/api/PrintEvents/v1.0/DateRange/ListAll/${dateFrom}/${dateTo}/${limit}`);
  }

  async getPrintedSquareMetersByRange(dateFrom: string, dateTo: string, limit = 0) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<PrintersSquareMeterSummeryDataModel[]>(`/api/PrintEvents/v1.0/DateRange/PrintedSquareMeters/${dateFrom}/${dateTo}/${limit}`);
  }

  async GetEventById(id: string) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<EventDataModel>(`/api/PrintEvents/v1.0/GetById/${id}`);
  }

  static getInstance() {
    return new AllPrintersService();
  }
}

export default AllPrintersService;
