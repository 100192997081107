import React from 'react'
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

interface IconButtonWithTooltipProps {
    Title: string
    Icon: any
    onClick: any
    color? : "inherit" | "primary" | "default" | "secondary" | "error" | "info" | "success" | "warning" | undefined
}

function IconButtonWithTooltip({ Icon, Title, onClick, color = 'primary' }: IconButtonWithTooltipProps) {
    if(color === 'primary'){
        return (
            <Tooltip title={Title}>
                <IconButton style={{ 'marginLeft': '15px', 'border': '1px solid' }} color="primary" onClick={onClick} sx={{
                    borderColor: 'primary.main'
                }}>
                    {Icon}
                </IconButton>
            </Tooltip>
        ) 
    }
    let c = 'primary.' + color;
    return (
        <Tooltip title={Title}>
            <IconButton style={{ 'marginLeft': '15px', 'border': '1px solid' }} color={color} onClick={onClick} sx={{
                borderColor: {c}
            }}>
                {Icon}
            </IconButton>
        </Tooltip>
    )
}

export default IconButtonWithTooltip;