type ConvertBytesResponse = {
  ByteSize: string,
  Size: string
};

function convertBytes(bytes: number) {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

  if (bytes === 0) return "n/a";

  const op1 = (Math.log(bytes) / Math.log(1024));

  const i = Math.floor(op1);
  if (i === 0) {
    const res: ConvertBytesResponse = {
      ByteSize: bytes.toString(),
      Size: sizes[i]
    }

    return res;
  }
  const res: ConvertBytesResponse = {
    ByteSize: (bytes / Math.pow(1024, i)).toFixed(1),
    Size: sizes[i]
  }
  return res;
};

export default convertBytes;
