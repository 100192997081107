

class SessionStorageService {
  static addToSessionStorage(key: string, value: string) { sessionStorage.setItem(key, value); }
  static getFromSessionStorage(key: string) { return sessionStorage.getItem(key); }
  static removeFromSessionStorage(key: string) { sessionStorage.removeItem(key); }
  static clearSessionStorage() { sessionStorage.clear(); }
}

export default SessionStorageService;
