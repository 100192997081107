import { Button, SxProps, Theme, useTheme } from '@mui/material';
import React from 'react'

type MainButtonProps = {
    style?: React.CSSProperties | undefined,
    onClick?: React.EventHandler<React.MouseEvent<HTMLButtonElement>>,
    children: JSX.Element | string,
    fullWidth?: boolean | undefined,
    startIcon?: React.ReactNode,
    color?: "inherit" | "error" | "primary" | "secondary" | "info" | "success" | "warning" | undefined,
    className?: string | undefined,
    sx?: SxProps<Theme> | undefined,
    darkVariant?: "text" | "outlined" | "contained" | undefined,
    lightVariant?: "text" | "outlined" | "contained" | undefined,
    type?: "button" | "submit" | "reset" | undefined
    disabled?: boolean | undefined
};

function MainButton({
    onClick,
    disabled,
    type,
    style,
    children,
    fullWidth,
    startIcon,
    color,
    className,
    sx,
    darkVariant,
    lightVariant }: MainButtonProps) {
    const theme = useTheme();

    return <Button
        disabled={disabled}
        type={type}
        sx={sx}
        className={className}
        color={color}
        startIcon={startIcon}
        fullWidth={fullWidth}
        style={style}
        variant={theme.palette.mode !== 'dark' ? (lightVariant ?? "outlined") : (darkVariant ?? "contained")}
        onClick={onClick}>{children}</Button>;
};

export default MainButton;
