import React from "react";
import { useHistory } from "react-router-dom";

import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { LanguagesDropdown, NotificationsPanel, UserDropdown } from "../LayoutImports";
import ResponsiveWrapper from "../../components/ResponsiveDesign/ResponsiveWrapper";
import { ColorModeContext } from "../../Themes/ThemeWrapper";
import Icons from '../../Imports/MUI/Icons/IconsImports';
import { pdmAuthorize, pdmUnauthorize } from "../../Store/Actions/Actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Store/Store";
import HttpRequestWithForbiddenCheck from "../../services/HttpRequestWithForbiddenCheck/HttpRequestWithForbiddenCheck";
import { AuthorizationService, CompanyService } from "../../Api/Rest/PDMServer/ApiServices/PDMApiServicesImports";
import PDMServerLocalStorageService from "../../services/LocalStorage/PDMServer/PDMServerService";

interface HeaderProps {
  open: boolean,
  toggleDrawer: (user: any) => void
}

const Header = (props: HeaderProps) => {
  const history = useHistory()
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  const dispatch = useDispatch();
  const pdmAuthState = useSelector((state: RootState) => state.PdmAuth);
  const [companyLogo, setCompanyLogo] = React.useState('');
  const [url, setUrl] = React.useState('');

  React.useEffect(() => {
    asyncInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const asyncInit = async () => {
    const pdmAuthorized = await AuthorizationService.getInstance().checkApi();

    if (pdmAuthorized.status === 200) {
      if (!pdmAuthorized.data) {
        if (pdmAuthState !== pdmAuthorized.data) {
          dispatch(pdmUnauthorize());
        }
        history.push('/AuthorizePDM');
      }

      if (pdmAuthState !== pdmAuthorized.data) {
        dispatch(pdmAuthorize());
      }

      initUrl();
      initLogo();
    }
  };

  const initUrl = async () => {
    let tmp = await PDMServerLocalStorageService.getPDMServerUrl();
    setUrl(tmp);
  };

  const initLogo = async () => {
    const [Jobs, error1] = await HttpRequestWithForbiddenCheck(CompanyService.getInstance().getCompanyLogo(), 'getCompanyLogo', 'Error When Getting The Company Logo');

    if (!error1 && Jobs !== null) {
      setCompanyLogo(Jobs);
    };
  };

  const showCompanyLogo = () => {
    if (companyLogo === '' && theme.palette.mode === 'dark') {
      return (
        <img
          src="/img/Logos/LogoDipTechWhite.png"
          alt="Logo"
          className="brand-image"
          style={{ opacity: ".8", 'width': '40px', 'cursor': 'pointer' }}
          onClick={() => history.push('/')}
        />
      );
    }

    if (companyLogo === '' && theme.palette.mode === 'light') {
      return (
        <img
          src="/img/Logos/LogoDipTechBlack.png"
          alt="Logo"
          className="brand-image"
          style={{ opacity: ".8", 'width': '40px', 'cursor': 'pointer' }}
          onClick={() => history.push('/')}
        />
      );
    }

    return (
      <img
        src={`${url}${companyLogo}`}
        alt="Logo"
        className="brand-image"
        style={{ opacity: ".8", 'width': '40px', 'cursor': 'pointer' }}
        onClick={() => history.push('/')}
      />
    );
  }

  const desktopTemplate = (<AppBar position="absolute" open={props.open} sx={{ backgroundColor: 'secondary.main' }}>
    <Toolbar
      sx={{
        pr: '24px', // keep right padding when drawer closed
      }}
    >
      <IconButton
        edge="start"
        color="inherit"
        aria-label="open drawer"
        onClick={props.toggleDrawer}
        sx={{
          marginRight: '36px',
          ...(props.open && { display: 'none' }),
        }}
      >
        <Icons.MenuIcon />
      </IconButton>

      <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        sx={{ flexGrow: 1 }}
      >
        {showCompanyLogo()}
      </Typography>

      <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
        {theme.palette.mode === 'dark' ? <Icons.LightModeIcon /> : <Icons.DarkModeIcon />}
      </IconButton>

      <Box sx={{ flexGrow: 0 }}>
        <LanguagesDropdown />
      </Box>

      <Box sx={{ flexGrow: 0 }}>
        <NotificationsPanel />
      </Box>

      <Box sx={{ flexGrow: 0 }}>
        <UserDropdown />
      </Box>

    </Toolbar>
  </AppBar>);

  const tabletOrMobileTemplate = (<AppBar position="absolute" open={false} sx={{ backgroundColor: 'secondary.main' }}>
    <Toolbar
      sx={{
        pr: '24px', // keep right padding when drawer closed
      }}
    >
      <IconButton
        edge="start"
        color="inherit"
        aria-label="open drawer"
        onClick={props.toggleDrawer}
      >
        <Icons.MenuIcon />
      </IconButton>

      <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        sx={{ flexGrow: 1 }}
      >
        {showCompanyLogo()}
      </Typography>

      <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
        {theme.palette.mode === 'dark' ? <Icons.LightModeIcon /> : <Icons.DarkModeIcon />}
      </IconButton>

      <Box sx={{ flexGrow: 0 }}>
        <LanguagesDropdown />
      </Box>

      <Box sx={{ flexGrow: 0 }}>
        <NotificationsPanel />
      </Box>

      <Box sx={{ flexGrow: 0 }}>
        <UserDropdown />
      </Box>

    </Toolbar>
  </AppBar>);

  return (
    <ResponsiveWrapper DesktopOrLaptopLayout={desktopTemplate} TabletOrMobileLayout={tabletOrMobileTemplate} />
  );
};
const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default Header;
