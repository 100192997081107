import * as Yup from "yup";

const validation = Yup.object({
  companyId: Yup.string()
    .max(10, "Must be 30 characters or less")
    .min(5, 'Company Id should be of minimum 5 characters length')
    .required("Required"),
  authorizationId: Yup.string()
    .max(30, "Must be 30 characters or less")
    .min(5, 'Authorization Id should be of minimum 5 characters length')
    .required("Required")
});

export default validation;