const printedJobsChart = {
    labels: [],
    datasets: [
        {
            fill: true,
            borderColor: "gray",
            borderWidth: 1,
            borderDash: [],
            borderDashOffset: 0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: []
        }
    ],
    options: {
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                position: 'nearest',
                xAlign: 'center',
                yAlign: 'bottom',
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += (' ' + context.parsed.y + " cc");
                        }
                        return label;
                    }
                }
            }
        },
        responsive: true,
        maintainAspectRatio: false
    }
};

export default printedJobsChart;
