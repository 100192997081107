import localStorageService from "../localStorageService";

const availableLng = ['en', 'ru', 'pt', 'cn', 'es'];

function SetLocalizationState(lng: string) {
    localStorageService.addToLocalStorage('localization_language', lng);
};

function GetLocalizationState() {
    return LocalStorageCheck('localization_language', 'en', SetLocalizationState);
};

function LocalStorageCheck(key: string, defaultVal: string, setFunction: (val: string) => void) {
    const errFunc = () => {
        setFunction(defaultVal);
        return defaultVal;
    };

    const res = localStorageService.getFromLocalStorage(key);
    if (res) {
        try {
            const isOk = availableLng.includes(res);
            if (!isOk) return errFunc();
            return res;
        }
        catch (err) {
            return errFunc();
        }
    }
    else {
        return errFunc();
    }
}

export { SetLocalizationState, GetLocalizationState };