import { createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    palette: {
        mode: 'dark',
        primary: {
            main: "#5528a8",
            dark: "#7536e9"
        },
        secondary: {
            main: "#26104e",
        },
        background: {
            default: "#181A1B",
            paper: "#252929",
        },
        action: {
            selectedOpacity: 0.4
        }
    },
});

export default darkTheme;