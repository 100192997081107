import React from 'react'
import { v4 as uuidv4 } from "uuid";
import { JobHistoryDataModel } from '../../../Models/Api/Job/JobHistoryDataModel';
import Pagination from '@mui/material/Pagination';
import { Grid } from '@mui/material';

import  JobHistoryElement from './JobHistoryElement';


type JobHistoryInfoProps = {
    jobHistory: JobHistoryDataModel[]
};

function JobHistoryInfo({ jobHistory }: JobHistoryInfoProps) {
    const AmountOfElementsInPage = 4;
    const [page, setPage] = React.useState(1);
    const historyWrapper = React.useRef<HTMLDivElement>(null);

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    return (
        <>
            {jobHistory && jobHistory.length !== 0 &&
                <>
                    <div key={uuidv4()} className="timeline timeline-inverse mt-2 ml-2" ref={historyWrapper} style={{ 'margin': '0', 'position': 'relative', 'height': '93%' }}>
                        {
                            jobHistory
                                .slice((page - 1) * AmountOfElementsInPage, (page - 1) * AmountOfElementsInPage + AmountOfElementsInPage)
                                .map((jobHistoryItem) => {
                                    return (
                                        <JobHistoryElement jobHistory={jobHistoryItem} key={uuidv4()} />
                                        );
                                })
                        }
                        <div style={{ 'position': 'absolute', 'bottom': '0', 'width': '100%', 'margin': '0' }}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <i className="fas fa-clock bg-gray" style={{
                                        'borderRadius': '50%', 'fontSize': '16px',
                                        'height': '30px',
                                        'left': '18px',
                                        'lineHeight': '30px',
                                        'position': 'absolute',
                                        'textAlign': 'center',
                                        'top': '0',
                                        'width': '30px'
                                    }}></i>
                                </Grid>
                                <Grid item xs={6}>
                                    <ul className="nav float-right">
                                        <Pagination count={Math.round(jobHistory.length / AmountOfElementsInPage)} color="primary" page={page} onChange={handleChange} />
                                    </ul>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default JobHistoryInfo;
