import React, { useState, useEffect } from "react";
import { InkUsage, PrintStatistics, InkUsageByDate } from "../../../../../modules/ReportCenter/ReportCenterImports";
import { MuiCalender } from "../../../../../components/Inputs/InputsExport";
import DatesService from "../../../../../Helpers/DateService";
import { useTranslation } from "react-i18next";
import { Grid, Card, CardContent, Divider } from '@mui/material';
import { InkUsageDataModel } from "../../../../../Models/Api/ReportCenter/InkUsageDataModel";
import { PrintEventStatusDataModel } from "../../../../../Models/Api/Events/PrintEventStatusDataModel";
import signalRBuilder from "../../../../../Api/Socket/signalRBuilder";
import SocketEmitWrapper from "../../../../../services/SocketEmitWrapper/SocketEmitWrapper";
import SignalRHubEnum from "../../../../../Models/SignalR/SignalRHubEnum";
import signalREventListenerDictionary from "../../../../../Models/SignalR/signalREventListenerDictionary";
//import { SignalRDataModel } from "../../../../../Models/SignalR/SignalRDataModel";
import HttpRequestWithForbiddenCheck from "../../../../../services/HttpRequestWithForbiddenCheck/HttpRequestWithForbiddenCheck";
//import { PrintEventStatusDataModelZod } from "../../../../../Models/Zod/Events/PrintEventStatusDataModelZod";
//import { InkUsageDataModelArrZod } from "../../../../../Models/Zod/ReportCenter/InkUsageDataModelZod";
import { DateRange } from "materialui-daterange-picker";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../Store/Store";
import { pdmAuthorize, pdmUnauthorize } from "../../../../../Store/Actions/Actions";
import { useHistory } from "react-router-dom";
import Icons from "../../../../../Imports/MUI/Icons/IconsImports";
import { MainButton } from "../../../../../components/General/GeneralExport";
import { AuthorizationService, ByInkUsageService } from "../../../../../Api/Rest/PDMServer/ApiServices/PDMApiServicesImports";
import { InkUsagePerRangeDataModel } from "../../../../../Models/Api/ReportCenter/InkUsagePerRangeDataModel";

const ByInkUsage = () => {
  const [t] = useTranslation();
  const [inkUsageData, setInkUsageData] = useState<InkUsageDataModel[]>([]);
  const [printStatisticsData, setPrintStatisticsData] = useState<PrintEventStatusDataModel | null>(null);
  const dispatch = useDispatch();
  const pdmAuthState = useSelector((state: RootState) => state.PdmAuth);
  const history = useHistory();
  const [enterGroup, setEnterGroup] = useState(false);

  const [ShowResults, setShowResults] = useState<boolean>(false);
  const [EmptyData, setEmptyData] = useState<boolean>(false);
  const [GraphWithGroupedDates, setGraphWithGroupedDates] = useState<InkUsagePerRangeDataModel | null>(null);

  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: DatesService.getInstance().getStartDateAsDate(),
    endDate: DatesService.getInstance().getEndDateAsDate()
  });

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  useEffect(() => {
    asyncInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // When leaving the page the return will emit and remove user from group
    return () => {
      if (enterGroup) leaveGroup()
    };
  }, [enterGroup])

  const asyncInit = async () => {
    const pdmAuthorized = await AuthorizationService.getInstance().checkApi();

    if (pdmAuthorized.status === 200) {
      if (!pdmAuthorized.data) {
        if (pdmAuthState !== pdmAuthorized.data) {
          dispatch(pdmUnauthorize());
        }
        history.push('/AuthorizePDM');
      }

      if (pdmAuthState !== pdmAuthorized.data) {
        dispatch(pdmAuthorize());
      }

      signalRInit();
      setEnterGroup(true);
    }
  };

  const leaveGroup = async () => {
    const b = await signalRBuilder.getInstance(SignalRHubEnum.PRINT_EVENT_HUB);
    await SocketEmitWrapper(b.newConnection.send(signalREventListenerDictionary.LEAVE_PRINT_EVENT_GROUP));
  };

  const signalRInit = async () => {
    const b = await signalRBuilder.getInstance(SignalRHubEnum.PRINT_EVENT_HUB);

    await SocketEmitWrapper(b.newConnection.send(signalREventListenerDictionary.JOIN_PRINT_EVENT_GROUP));

    b.addEventListener(
      signalREventListenerDictionary.GET_INK_USAGE,
      loadInkUsage
    );

    b.addEventListener(
      signalREventListenerDictionary.GET_PRINT_STATISTIC,
      loadPrintStatistic
    );
  };

  const loadData = async () => {

    const start = moment(dateRange.startDate).format('YYYY-MM-DD');
    const end = moment(dateRange.endDate).format('YYYY-MM-DD');

    const [InkUsagePerRangeData, InkUsagePerRangeError] =
      await HttpRequestWithForbiddenCheck(ByInkUsageService.getInstance().getInkUsagePerRange(start, end), 'getInkUsagePerRange', 'Error When Getting The Ink Usage Per Range');

    if (!InkUsagePerRangeError && InkUsagePerRangeData !== null) {
      setGraphWithGroupedDates(InkUsagePerRangeData);
    }

    loadPrintStatistic();
    loadInkUsage();
  };


  const loadInkUsage = async () => {
    const start = moment(dateRange.startDate).format('YYYY-MM-DD');
    const end = moment(dateRange.endDate).format('YYYY-MM-DD');

    let allDataReceived = true;

    const [InkUsageData, InkUsageError] =
      await HttpRequestWithForbiddenCheck(ByInkUsageService.getInstance().getInkUsage(start, end), 'getInkUsage', 'Error When Getting The Ink Usage');

    if (!InkUsageError && InkUsageData !== null) {
      if (InkUsageData.length === 0) {
        allDataReceived = false;
      }
      setInkUsageData(InkUsageData);
    }

    if (!allDataReceived) {
      setShowResults(false);
      setEmptyData(true);
    }
    else {
      setEmptyData(false);
      setShowResults(true);
    }
  };

  const loadPrintStatistic = async () => {
    const start = moment(dateRange.startDate).format('YYYY-MM-DD');
    const end = moment(dateRange.endDate).format('YYYY-MM-DD');
    const [printEventStatuesData, printEventStatuesError] =
      await HttpRequestWithForbiddenCheck(ByInkUsageService.getInstance().getPrintedInkQuantity(start, end), 'getPrintEventStatus', 'Error When Getting The Print Event Status');

    if (!printEventStatuesError && printEventStatuesData !== null) {
      setPrintStatisticsData(printEventStatuesData);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div>
          <Grid container>
            <Grid item xs={11}>
              <MuiCalender dateRange={dateRange} UpdateRange={(range) => setDateRange(range)} />
            </Grid>

            <Grid item xs={1}>
              {/* disabled={!ShowResults && !EmptyData} */}
              <MainButton startIcon={<Icons.PrintIcon />}
                style={{ 'marginLeft': '15px' }}
                disabled={true}
                onClick={() => history.push(`/ReportCenter/Projects/Print?StartDate=${dateRange.startDate}&EndDate=${dateRange.endDate}`)}>
                {t("general.buttons.print")}
              </MainButton>
            </Grid>
          </Grid>
        </div>
      </Grid>

      {EmptyData && (
        <>
          <Grid item xs={12}>
            <Card style={{ 'height': '100%' }} className="noBackgroundImage">
              <CardContent>
                <div className="Orbitron20">
                  <h5>{t("general.cards.noDataFound")}</h5>
                </div>
                <Divider style={{ 'marginBottom': '15px' }} />
                <div>
                  No data for the required dates
                </div>
              </CardContent>
            </Card>
          </Grid>
        </>
      )}

      {ShowResults && (
        <>
          <Grid item xs={12} lg={8}>
            <Card style={{ 'height': '100%' }} className="noBackgroundImage">
              <CardContent>
                <div className="Orbitron20">
                  <h5>{t("general.cards.inkUsageByColor")}</h5>
                </div>
                <Divider style={{ 'marginBottom': '15px' }} />
                <InkUsage inkUsageData={inkUsageData} />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} lg={4}>
            <Card style={{ 'height': '100%' }} className="noBackgroundImage">
              <CardContent>
                <PrintStatistics printStatisticsData={printStatisticsData} ShowInkQuantity />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} style={{ "paddingTop": "10px" }}>
            <Card style={{ 'height': '100%' }} className="noBackgroundImage">
              <CardContent>
                <div className="Orbitron20">
                  <h5>{t("general.cards.inkUsageByDate")}</h5>
                </div>
                <Divider style={{ 'marginBottom': '15px' }} />
                <InkUsageByDate inkUsageData={GraphWithGroupedDates} />
              </CardContent>
            </Card>
          </Grid>
        </>
      )}

    </Grid>
  );
};

export default ByInkUsage;
