import React, { useState } from 'react'
import { useFormik } from "formik";
import { FormControl, Grid, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import { useTranslation } from "react-i18next";
import { MainButton } from '../../../../components/General/GeneralExport';
import { FormError } from '../../../../components/Forms/FormsExport';
import { useTheme } from '@mui/material/styles';
import Styles from './ChangePassword.module.scss';
import HttpRequestWithForbiddenCheck from '../../../../services/HttpRequestWithForbiddenCheck/HttpRequestWithForbiddenCheck';
import { toast } from "react-toastify";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useHistory } from 'react-router-dom';
import { ChangePasswordFormModel } from '../../../../Models/Forms/ChangePassword/ChangePasswordFormModel';
import { UserService } from '../../../../Api/Rest/PDMServer/ApiServices/PDMApiServicesImports';
import * as Yup from "yup";
import { useParams, useLocation } from "react-router-dom"
import UseQuery from "../../../../Hooks/UseQuery";

type ChangePasswordParamTypes = {
    userId: string
}

const ChangePassword = () => {
    const theme = useTheme();
    const [t] = useTranslation();
    const [ShowPassword, setShowPassword] = useState(false);
    const [ShowConfirmPassword, setShowConfirmPassword] = useState(false);
    const history = useHistory();
    const userService = UserService.getInstance();
    const query = UseQuery();
    const params = useParams<ChangePasswordParamTypes>();
    const location = useLocation();
    const isProfileEdit = location.pathname.toLowerCase().includes('profile');
    const userId = isProfileEdit ? query.get("userId") : params.userId;

    // t('validation.newPasswordMustBeDifferent')

    const validationSchema = Yup.object({
        OldPassword: Yup.string().required(t('validation.fieldRequired')),
        NewPassword: Yup.string()
            .required(t('validation.fieldRequired'))
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{6,}$/,
                t('validation.mustContainAtLeast6charsOneUpperAndOneNumber')
            )
            .test('not-same-as-old', t('validation.oldAndNewPasswordCannotBeTheSame'), function (value) {
                return value !== this.parent.OldPassword;
            }),
        PasswordRetype: Yup.string()
            .oneOf([Yup.ref('NewPassword'), null], t('validation.passwordMustMatch'))
            .required(t('validation.fieldRequired')),
    });


    const formik = useFormik({
        initialValues: {
            OldPassword: '',
            NewPassword: '',
            PasswordRetype: ''
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            submitF(values);
        },
    });

    const submitF = async (user: any) => {
        if (!userId)
            return;

        const model: ChangePasswordFormModel = {
            UserId: userId,
            CurrentPassword: user.OldPassword,
            NewPassword: user.NewPassword
        };

        const [, error] =
            await HttpRequestWithForbiddenCheck(userService.postChangePassword(model),
                'postChangePassword', 'Error When Changing A Password');

        if (!error) {
            toast.success("Password was updated successfully", {
                style: { "backgroundColor": "#6f42c1" }
            });

            if (isProfileEdit) {
                history.push('/Profile');
            }
            else {
                history.push(`/UsersDashboard/${userId}`);
            }
        }
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
                <form onSubmit={formik.handleSubmit}>

                    <Grid container spacing={1}>

                        <Grid item xs={12} sm={12} md={6}>
                            <label className={Styles["add-user-form-label"] + " required"}>{t("general.labels.oldPassword")}</label>
                            <input className="form-control" type="password" lang="en" {...formik.getFieldProps("OldPassword")} style={{
                                'backgroundColor': theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white',
                                'color': theme.palette.mode === 'dark' ? 'white' : 'black'
                            }} />
                            <FormError disableLine formik={formik} propName={"OldPassword"} />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <label className={Styles["add-user-form-label"] + " required"}>{t("general.labels.newPassword")}</label>
                            <FormControl
                                style={{
                                    'width': '100%',
                                }}
                                variant="outlined">
                                <OutlinedInput
                                    style={{
                                        'height': '38px',
                                        'backgroundColor': theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white',
                                        'color': theme.palette.mode === 'dark' ? 'white' : 'black',
                                        'border': theme.palette.mode === 'dark' ? '1px solid grey' : 'none'
                                    }}
                                    type={ShowPassword ? 'text' : 'password'}
                                    {...formik.getFieldProps("NewPassword")}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowPassword(!ShowPassword)}
                                                onMouseDown={(event) => event.preventDefault()}
                                                edge="end"
                                            >
                                                {ShowPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                            </FormControl>
                            <FormError disableLine formik={formik} propName={"NewPassword"} />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <label className={Styles["add-user-form-label"] + " required"}>{t("general.labels.repeatPassword")}</label>
                            <FormControl
                                style={{
                                    'width': '100%',
                                }}
                                variant="outlined">
                                <OutlinedInput
                                    style={{
                                        'height': '38px',
                                        'backgroundColor': theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white',
                                        'color': theme.palette.mode === 'dark' ? 'white' : 'black',
                                        'border': theme.palette.mode === 'dark' ? '1px solid grey' : 'none'
                                    }}
                                    type={ShowConfirmPassword ? 'text' : 'password'}
                                    {...formik.getFieldProps("PasswordRetype")}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowConfirmPassword(!ShowConfirmPassword)}
                                                onMouseDown={(event) => event.preventDefault()}
                                                edge="end"
                                            >
                                                {ShowConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                            </FormControl>
                            <FormError disableLine formik={formik} propName={"PasswordRetype"} />
                        </Grid>

                        <Grid item xs={12}>
                            <MainButton type='submit'>
                                {t("general.labels.submit")}
                            </MainButton>
                        </Grid>
                    </Grid>

                </form>
            </Grid>
        </Grid>
    )
};

export default ChangePassword;