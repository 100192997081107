import /*React,*/ { useRef, useState, useEffect } from "react";
import { InkUsage, SearchableTable, PrintStatistics, PrintersStatistics } from "../../../../../modules/ReportCenter/ReportCenterImports";
import { useTranslation } from "react-i18next";
import { Grid, Card, CardContent, Divider, TextField, Autocomplete } from '@mui/material';
import { PrintEventDataModel } from "../../../../../Models/Api/Events/PrintEventDataModel";
import { InkUsageDataModel } from "../../../../../Models/Api/ReportCenter/InkUsageDataModel";
import { PrintEventStatusDataModel } from "../../../../../Models/Api/Events/PrintEventStatusDataModel";
import signalRBuilder from "../../../../../Api/Socket/signalRBuilder";
import SocketEmitWrapper from "../../../../../services/SocketEmitWrapper/SocketEmitWrapper";
import SignalRHubEnum from "../../../../../Models/SignalR/SignalRHubEnum";
import signalREventListenerDictionary from "../../../../../Models/SignalR/signalREventListenerDictionary";
//import { SignalRDataModel } from "../../../../../Models/SignalR/SignalRDataModel";
import HttpRequestWithForbiddenCheck from "../../../../../services/HttpRequestWithForbiddenCheck/HttpRequestWithForbiddenCheck";
//import { PrintEventStatusDataModelZod } from "../../../../../Models/Zod/Events/PrintEventStatusDataModelZod";
//import { InkUsageDataModelArrZod } from "../../../../../Models/Zod/ReportCenter/InkUsageDataModelZod";
//import { PrintEventDataModelArrZod } from "../../../../../Models/Zod/Events/PrintEventDataModel";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../Store/Store";
import { pdmAuthorize, pdmUnauthorize } from "../../../../../Store/Actions/Actions";
import { useHistory } from "react-router-dom";
import { PrintersSquareMeterSummeryDataModel } from "../../../../../Models/Api/Events/PrintersSquareMeterSummeryDataModel";
import Icons from "../../../../../Imports/MUI/Icons/IconsImports";
import { MainButton } from "../../../../../components/General/GeneralExport";
import { AuthorizationService, ByProjectService } from "../../../../../Api/Rest/PDMServer/ApiServices/PDMApiServicesImports";
import Styles from './Projects.module.scss';
import FiltersSessionStorageService, { SessionStorageFiltersEnum } from "../../../../../services/SessionStorage/Filters/FiltersSessionStorageService";

const Projects = () => {
  const [t] = useTranslation();
  const [projectName, _setProjectName] = useState<string>("");
  const projectNameRef = useRef(projectName);
  const [printedJobs, setPrintedJobs] = useState<PrintEventDataModel[]>([]);
  const [inkUsageData, setInkUsageData] = useState<InkUsageDataModel[]>([]);
  const [printersSquareMeter, setPrintersSquareMeter] = useState<PrintersSquareMeterSummeryDataModel[] | null>(null)
  const [printStatisticsData, setPrintStatisticsData] = useState<PrintEventStatusDataModel | null>(null);
  const dispatch = useDispatch();
  const pdmAuthState = useSelector((state: RootState) => state.PdmAuth);
  const history = useHistory();
  const [enterGroup, setEnterGroup] = useState(false);

  const [ShowResults, setShowResults] = useState<boolean>(false);
  const [EmptyData, setEmptyData] = useState<boolean>(false);
  const [ProjectNamesArray, setProjectNamesArray] = useState<string[]>([]);
  const [filter, setFilter] = useState<string>('');

  const setProjectName = (data: string) => {
    projectNameRef.current = data;
    _setProjectName(data);
  };

  useEffect(() => {
    asyncInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // When leaving the page the return will emit and remove user from group
    return () => {
      if (enterGroup) leaveGroup()
    };
  }, [enterGroup])

  useEffect(() => {
    if (projectName !== "")
      loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectName])


  const asyncInit = async () => {
    const pdmAuthorized = await AuthorizationService.getInstance().checkApi();

    if (pdmAuthorized.status === 200) {
      if (!pdmAuthorized.data) {
        if (pdmAuthState !== pdmAuthorized.data) {
          dispatch(pdmUnauthorize());
        }
        history.push('/AuthorizePDM');
      }

      if (pdmAuthState !== pdmAuthorized.data) {
        dispatch(pdmAuthorize());
      }

      const [data, error] =
        await HttpRequestWithForbiddenCheck(ByProjectService.getInstance().getProjectNamesList(),
          'getProjectNamesList', 'Error When Getting The Project Names List');

      if (!error && data !== null) {
        setProjectNamesArray(data);
      }
      signalRInit();
      setEnterGroup(true);

      let sessionStorageFilter =
        FiltersSessionStorageService.GetSessionStorageFilter(SessionStorageFiltersEnum.REPORT_CENTER_PROJECTS_PRINTED_JOBS_FILTER);

      if (sessionStorageFilter) {
        setFilter(sessionStorageFilter);
      }
    }
  };

  const leaveGroup = async () => {
    const b = await signalRBuilder.getInstance(SignalRHubEnum.PRINT_EVENT_HUB);
    await SocketEmitWrapper(b.newConnection.send(signalREventListenerDictionary.LEAVE_PRINT_EVENT_GROUP));
  };

  const signalRInit = async () => {
    const b = await signalRBuilder.getInstance(SignalRHubEnum.PRINT_EVENT_HUB);

    await SocketEmitWrapper(b.newConnection.send(signalREventListenerDictionary.JOIN_PRINT_EVENT_GROUP));

    b.addEventListener(
      signalREventListenerDictionary.GET_INK_USAGE,
      loadInkUsage
    );

    b.addEventListener(
      signalREventListenerDictionary.GET_PRINT_STATISTIC,
      loadPrintStatistic
    );

    b.addEventListener(
      signalREventListenerDictionary.GET_PRINT_EVENTS_LIST,
      loadPrinterEventsData
    );
  };

  const loadData = async () => {
    loadInkUsage();
    loadPrintersSquareMeters();
    loadPrintStatistic();
    loadPrinterEventsData();
  };

  const loadPrintersSquareMeters = async () => {
    const [PrintedSquareMetersByRangeData, PrintedSquareMetersByRangeError] =
      await HttpRequestWithForbiddenCheck(ByProjectService.getInstance().getPrintedSquareMetersByRange(projectNameRef.current),
        'getInkUsage', 'Error When Getting The Printed Square Meters');

    if (!PrintedSquareMetersByRangeError && PrintedSquareMetersByRangeData !== null) {
      setPrintersSquareMeter(PrintedSquareMetersByRangeData);
    }
  }

  const loadInkUsage = async () => {
    const [InkUsageData, InkUsageError] =
      await HttpRequestWithForbiddenCheck(ByProjectService.getInstance().getInkUsage(projectNameRef.current),
        'getInkUsage', 'Error When Getting The Ink Usage');

    if (!InkUsageError && InkUsageData !== null) {
      setInkUsageData(InkUsageData);
    }
  };

  const loadPrinterEventsData = async () => {
    let dataReceived = true;
    const [PrintedJobsByRangeData, PrintedJobsByRangeError] =
      await HttpRequestWithForbiddenCheck(ByProjectService.getInstance().getPrintedJobsByRange(projectNameRef.current),
        'getPrintedJobsByRange', 'Error When Getting The Printed Jobs By Range');

    if (!PrintedJobsByRangeError && PrintedJobsByRangeData !== null) {
      if (PrintedJobsByRangeData.length === 0) dataReceived = false;
      setPrintedJobs(PrintedJobsByRangeData);
    }

    setShowResults(dataReceived);
    setEmptyData(!dataReceived);
  };

  const loadPrintStatistic = async () => {
    const [printEventStatuesData, printEventStatuesError] =
      await HttpRequestWithForbiddenCheck(ByProjectService.getInstance().getPrintEventStatus(projectNameRef.current),
        'getPrintEventStatus', 'Error When Getting The Print Event Status');

    if (!printEventStatuesError && printEventStatuesData !== null) {
      setPrintStatisticsData(printEventStatuesData);
    }
  };

  const onSaveFilter = (filter: string) => {
    FiltersSessionStorageService.SetSessionStorageFilter(SessionStorageFiltersEnum.REPORT_CENTER_PROJECTS_PRINTED_JOBS_FILTER, filter);
    setFilter(filter);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>

        <div>
          <Grid container>
            <Grid item xs={11}>
              <Autocomplete
                disablePortal
                options={ProjectNamesArray}
                onChange={(e, newVal) => {
                  if (newVal) {
                    setProjectName(newVal);
                  }
                }}
                disableClearable
                className={Styles['Autocomplete-custom-styles']}
                renderInput={(params) => <TextField {...params} label="Project Name" />}
              />
            </Grid>

            <Grid item xs={1}>
              <MainButton style={{ 'marginLeft': '15px', 'height': '100%' }}
                disabled={!ShowResults && !EmptyData} startIcon={<Icons.PrintIcon />}
                onClick={() => history.push(`/ReportCenter/Projects/Print?projectName=${projectName}`)}>
                {t("general.buttons.print")}
              </MainButton>
            </Grid>
          </Grid>
        </div>

      </Grid>
      {EmptyData && (
        <>
          <Grid item xs={12}>
            <Card style={{ 'height': '100%' }} className="noBackgroundImage">
              <CardContent>
                <div className="Orbitron20">
                  <h5>{t("general.cards.noDataFound")}</h5>
                </div>
                <Divider style={{ 'marginBottom': '15px' }} />
                <div>
                  No data for the required dates and project name
                </div>
              </CardContent>
            </Card>
          </Grid>
        </>
      )}
      {ShowResults && (
        <>
          <Grid item xs={12} lg={4}>
            <Card style={{ 'height': '100%' }} className="noBackgroundImage">
              <CardContent>
                <div className="Orbitron20">
                  <h5>{t("general.cards.inkUsage")}</h5>
                </div>
                <Divider style={{ 'marginBottom': '15px' }} />
                <InkUsage inkUsageData={inkUsageData} />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} lg={4}>
            <Card style={{ 'height': '100%' }} className="noBackgroundImage">
              <CardContent>
                <PrintersStatistics printerStatisticsData={printersSquareMeter} />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} lg={4}>
            <Card style={{ 'height': '100%' }} className="noBackgroundImage">
              <CardContent>
                <PrintStatistics printStatisticsData={printStatisticsData} />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} style={{ "paddingTop": "10px" }}>
            <SearchableTable
              printedJobs={printedJobs}
              Title={t("general.cards.printedJobs")}
              ShowTitle={true}
              filter={filter}
              onSaveFilter={onSaveFilter}
            />
          </Grid>
        </>
      )}

    </Grid>
  );
};

export default Projects;
