import React, { useState, useEffect } from "react";
import { Table, TableBody, TableContainer, TableHead, TableRow, TablePagination } from "@mui/material";
import { Paper, InputAdornment, TextField, Box } from "@mui/material";
import { Chip, Grid, TableCell, TableSortLabel } from "@mui/material";
import { IconButton } from '@mui/material';
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";
import { JobDataModel } from "../../../../Models/Api/Job/JobDataModel";
import PDMServerLocalStorageService from "../../../../services/LocalStorage/PDMServer/PDMServerService";
import { TableStyle } from "../../../../components/General/GeneralExport";
import { useTranslation } from "react-i18next";
import { availableRowOptions, GetJobManagerTableRows, SetJobManagerTableRows } from "../../../../services/LocalStorage/SearchableTablesMemory/SearchableTablesMemoryService";
import { Order } from "../../../../Models/Table/Order";
import moment from "moment";
import Icons from '../../../../Imports/MUI/Icons/IconsImports';
import Styles from './SearchableTable.module.scss';
//const prettyMetric = require('pretty-metric');

interface EnhancedTableProps {
    jobs: JobDataModel[] | null,
    filter: string | null,
    onSaveFilter: (filter: string) => void
}

export default function EnhancedTable({ jobs, filter, onSaveFilter }: EnhancedTableProps) {
    const [t] = useTranslation();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [searched, setSearched] = React.useState<string>("");
    const [rows, setRows] = React.useState<JobDataModel[]>(jobs ?? []);
    const history = useHistory();
    const [url, setUrl] = useState('');

    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof JobDataModel>('ZipFileUrl');

    useEffect(() => {
        const rowCount = GetJobManagerTableRows();
        setRowsPerPage(rowCount);
        asyncInit();
    }, [])

    useEffect(() => {
        if (jobs) {
            setRows(jobs);

            if (filter) {
                requestSearch(filter);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobs])

    const asyncInit = async () => {
        let serverUrl = await PDMServerLocalStorageService.getPDMServerUrl();
        setUrl(serverUrl);
    };

    const onClickRow = (jobId: string) => {
        history.push(`/JobManager/AllJobsList/${jobId}`);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        let num = parseInt(event.target.value, 10);
        SetJobManagerTableRows(num);
        setRowsPerPage(num);
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const requestSearch = (searchedVal: string) => {
        setSearched(searchedVal);
        onSaveFilter(searchedVal);

        if (searchedVal !== '') {
            const filteredRows = jobs?.filter((row) => {
                const resolution = Math.round(parseFloat(row.Resolution.split(' ')[0])) + ' X ' + Math.round(parseFloat(row.Resolution.split(' ')[1]));
                const size = parseFloat(row.JobSizeInMM?.split(' ')[0]).toFixed(2) + ' X ' + parseFloat(row.JobSizeInMM?.split(' ')[1]).toFixed(2) + ' mm';

                return row.Name.toLowerCase().includes(searchedVal.toLowerCase()) ||
                    row.Id.toLowerCase().includes(searchedVal.toLowerCase()) ||
                    row.ProjectName.toLowerCase().includes(searchedVal.toLowerCase()) ||
                    size.toLowerCase().includes(searchedVal.toLowerCase()) ||
                    resolution.toLowerCase().includes(searchedVal.toLowerCase()) ||
                    moment.utc(row.ContentInfo.DateModified).local().format('DD/MM/YYYY H:mm').toLowerCase().includes(searchedVal.toLowerCase()) ||
                    row.Tags.some((tag) => tag.toLowerCase().includes(searchedVal.toLowerCase()));
            });

            setRows(filteredRows ?? []);
            return;
        }

        setRows(jobs ?? []);
    };

    const handleRequestSort = (property: keyof JobDataModel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortFunc = (a: JobDataModel, b: JobDataModel): 1 | -1 | 0 => {
        if (order === 'asc') {
            return (a[orderBy] > b[orderBy]) ? -1 : ((b[orderBy] > a[orderBy]) ? 1 : 0);
        }
        else {
            return (a[orderBy] > b[orderBy]) ? 1 : ((b[orderBy] > a[orderBy]) ? -1 : 0);
        }
    };

    return (
        <Grid container spacing={0}>

            <Grid item xs={8} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
            </Grid>
            
            <Grid item xs={12} sm={12} md={4}>
                <TextField style={{ 'width': '100%', marginBottom: 5 }}
                    value={searched}
                    label={t("general.labels.filter")}
                    variant="standard"
                    onChange={(searchVal) => requestSearch(searchVal.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    disabled={!searched}
                                    size="small"
                                    onClick={() => {
                                        setSearched('');
                                        requestSearch('');
                                    }}>
                                    <Icons.ClearIcon />
                                </IconButton>
                                <Icons.SearchIcon />
                            </InputAdornment>
                        ),
                    }} />
            </Grid>

            <Grid item xs={12}>
                <Box sx={{ width: "100%" }}>
                    <Paper sx={{ width: "100%", mb: 2 }} className="noBackgroundImage">
                        <TableContainer>
                            <Table
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size={"small"} >
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            className={Styles['min-width-100-px']}
                                            align={'left'}
                                            padding={'normal'}
                                            sortDirection={orderBy === 'Name' ? order : false}>
                                            <TableSortLabel
                                                active={orderBy === 'Name'}
                                                direction={orderBy === 'Name' ? order : 'asc'}
                                                onClick={() => { handleRequestSort('Name') }}>
                                                {t("general.tables.jobName")}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell
                                            className={Styles['min-width-150-px']}
                                            align={'left'}
                                            padding={'normal'}
                                            sortDirection={orderBy === 'ProjectName' ? order : false}>
                                            <TableSortLabel
                                                active={orderBy === 'ProjectName'}
                                                direction={orderBy === 'ProjectName' ? order : 'asc'}
                                                onClick={() => { handleRequestSort('ProjectName') }}>
                                                {t("general.tables.projectName")}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell
                                            className={Styles['min-width-150-px']}
                                            align={'left'}
                                            padding={'normal'}
                                            sortDirection={orderBy === 'Tags' ? order : false}>
                                            <TableSortLabel
                                                active={orderBy === 'Tags'}
                                                direction={orderBy === 'Tags' ? order : 'asc'}
                                                onClick={() => { handleRequestSort('Tags') }}>
                                                {t("general.tables.tags")}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell className={Styles['min-width-150-px']}>{t("general.tables.dateModified")}</TableCell>

                                        <TableCell className={Styles['min-width-150-px']} align={'left'} padding={'normal'}>
                                            {t("general.tables.size")}
                                        </TableCell>

                                        <TableCell
                                            className={Styles['min-width-100-px']}
                                            align={'left'}
                                            padding={'normal'}
                                            sortDirection={orderBy === 'Resolution' ? order : false}>
                                            <TableSortLabel
                                                active={orderBy === 'Resolution'}
                                                direction={orderBy === 'Resolution' ? order : 'asc'}
                                                onClick={() => { handleRequestSort('Resolution') }}>
                                                {t("general.tables.resolution")}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell
                                            className={Styles['min-width-100-px']}
                                            align={'left'}
                                            padding={'normal'}>
                                            {t("general.tables.thumb")}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows
                                        .sort(sortFunc).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={() => onClickRow(row.Id)}
                                                    tabIndex={-1}
                                                    style={{ 'cursor': 'pointer' }}
                                                    key={uuidv4()}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {row.Name}
                                                    </TableCell>
                                                    <TableCell align="left">{row.ProjectName}</TableCell>
                                                    <TableCell align="left">
                                                        {row.Tags.map((tag, index) => (
                                                            <Chip size="small" key={uuidv4()} color='primary' label={tag} style={{ 'marginLeft': '2px', 'marginRight': '2px' }} />
                                                        ))}
                                                    </TableCell>
                                                    <TableCell >
                                                        {row.ContentInfo.DateModified && moment.utc(row.ContentInfo.DateModified).local().format('DD/MM/YYYY H:mm')}
                                                    </TableCell>
                                                    <TableCell>
                                                        {/* {row.JobSizeInMM && prettyMetric(Math.round(parseFloat(row.JobSizeInMM?.split(' ')[0]))).input('mm').humanize()} */}
                                                        {/* {row.JobSizeInMM && prettyMetric(Math.round(parseFloat(row.JobSizeInMM?.split(' ')[1]))).input('mm').humanize()} */}
                                                        {row.JobSizeInMM && parseFloat(row.JobSizeInMM?.split(' ')[0]).toFixed(2)}
                                                        {row.JobSizeInMM && " X "}
                                                        {row.JobSizeInMM && parseFloat(row.JobSizeInMM?.split(' ')[1]).toFixed(2)}
                                                        {row.JobSizeInMM && " mm"}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {Math.round(parseFloat(row.Resolution.split(' ')[0]))}
                                                        {" "}
                                                        X
                                                        {" "}
                                                        {Math.round(parseFloat(row.Resolution.split(' ')[1]))}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <img
                                                            alt=""
                                                            className="img-fluid"
                                                            src={`${url}${row.Thumb}`}
                                                            style={{ width: "auto", maxHeight: '40px' }}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: (53) * emptyRows
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={availableRowOptions}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            className={TableStyle['custom-table-pagination']}
                            labelDisplayedRows={
                                ({ from, to, count }) => {
                                    return '' + from + '-' + to + ` ${t("general.tables.to")} ` + count
                                }
                            }
                            labelRowsPerPage={t("general.tables.rowsPerPage")}
                        />
                    </Paper>
                </Box>
            </Grid>
        </Grid>
    );
}
