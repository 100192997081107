import React from "react";
import { Line } from "react-chartjs-2";
import { InkUsagePerRangeDataModel } from "../../../../Models/Api/ReportCenter/InkUsagePerRangeDataModel";
import roundTo from "../../../../Pipes/RoundTo";

type InkUsageByDateProps = {
    inkUsageData: InkUsagePerRangeDataModel | null
};

const InkUsageByDate = ({ inkUsageData }: InkUsageByDateProps) => {

    type simpleGraph = {
        labels: string[],
        datasets: any[],
    };

    const [inkUsageChart, setInkUsageChart] = React.useState<simpleGraph>({
        labels: ['0', '1', '2', '3'],
        datasets: [
            {
                fill: false,
                label: 'My First Dataset',
                data: [65, 59, 80, 81, 56, 55, 40],
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1,
                borderWidth: 1
            }
        ],
    });

    React.useEffect(() => {
        loadInkUsage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inkUsageData])


    const loadInkUsage = () => {
        if (!inkUsageData) {
            return;
        }

        let inkChart: simpleGraph = {
            labels: [],
            datasets: [],
        };

        inkChart.labels = inkUsageData.RangeArr;

        inkUsageData.ColorsArray.forEach(function (color) {

            inkChart.datasets.push(
                {
                    label: color.ColorName,
                    data: color.RangeData,
                    fill: false,
                    borderColor: color.ColorName,
                    tension: 0.1,
                    borderWidth: 1
                }
            );
        });

        setInkUsageChart(inkChart);
    };

    return (
        <div style={{ 'height': '220px', 'width': '100%' }}>
            <Line
                type={'Line'}
                data={inkUsageChart}
                options={
                    {
                        plugins: {
                            tooltip: {
                                position: 'nearest',
                                xAlign: 'center',
                                yAlign: 'bottom',
                                callbacks: {
                                    label: function (context: any) {
                                        let label = ' ' + context.dataset.label || '';

                                        if (label) {
                                            label += ': ';
                                        }

                                        if (context.parsed.y !== null) {
                                            label += roundTo(context.parsed.y, 2).toString() + " cc";
                                        }

                                        return label;
                                    }
                                }
                            }
                        },
                        responsive: true,
                        maintainAspectRatio: false
                    }
                }
            />
        </div>
    );
};

//  InkUsageByDate.propTypes = {
//    inkUsageData: propTypes.instanceOf(InkUsagePerRangeDataModel),
//  };

export default InkUsageByDate;
