import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import MenuIcon from '@mui/icons-material/Menu';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import LanguageIcon from '@mui/icons-material/Language';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import InfoIcon from '@mui/icons-material/Info';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import ListIcon from '@mui/icons-material/List';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import PasswordIcon from '@mui/icons-material/Password';
import OpacityIcon from '@mui/icons-material/Opacity';
import BookIcon from '@mui/icons-material/Book';
import NotificationsIcon from '@mui/icons-material/Notifications';
import TagIcon from '@mui/icons-material/Tag';
import ClearIcon from '@mui/icons-material/Clear';

const Icons = {
    ErrorOutlineIcon,
    WarningAmberIcon,
    MenuIcon,
    DarkModeIcon,
    LightModeIcon,
    LanguageIcon,
    ChevronLeftIcon,
    ExpandLess,
    ExpandMore,
    GroupIcon,
    PersonIcon,
    LogoutIcon,
    InfoIcon,
    SearchIcon,
    AddIcon,
    ListIcon,
    RestoreFromTrashIcon,
    DeleteForeverIcon,
    PrintIcon,
    DeleteIcon,
    EditIcon,
    CancelIcon,
    CheckCircleIcon,
    DownloadIcon,
    CloseIcon,
    ContentCopyIcon,
    DeleteSweepIcon,
    PasswordIcon,
    OpacityIcon,
    BookIcon,
    NotificationsIcon,
    TagIcon,
    ClearIcon,
};

export default Icons;