
enum scopeEnum {
    OpenId = "openid",
    Profile = "profile",
    Roles = "roles",
    Api = "api",
    OfflineAccess = "offline_access",
    Email = "email",
    Picture = "picture",
}

export default scopeEnum;
