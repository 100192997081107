import React from "react";
import { Container } from "reactstrap";
// eslint-disable-next-line no-unused-vars
import { CMixGraphStyle } from "../../CMiXImports";
import { v4 as uuidv4 } from "uuid";
import { CheckIfColorDarkOrLightHexadecimal } from '../../../../services/CheckIfColorDarkOrLight/CheckIfColorDarkOrLight';
import propTypes from "prop-types";

const CMixGraph = ({ formula, symbol = "%", amount = 1 }) => {
  return (
    <Container className="graph-wrapper" style={{ "overflowX": "auto", "overflowY": "hidden" }}>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/charts.css/dist/charts.min.css" />
      <table id="column-example-6" className="show-labels charts-css column multiple stacked">
        <caption> Column Example #6 </caption>
        <tbody>

          {
            symbol === "%"
              ?
              formula !== undefined &&
              formula.map((element) => {
                const color = `#${element.CassColor}`;
                const percentage = `${element.InkQuantity / 100}`;

                return (
                  <tr className="graph-row" key={uuidv4()}>
                    <th scope="row"> {element.SelectedCassId} </th>
                    <td className="chart-length" style={{ "--size": percentage, "--color": color }} />
                    <td className="chart-head" style={{ "--size": "calc(5 / 14)", "--color": color }}>
                      <div className={CheckIfColorDarkOrLightHexadecimal(color, 'circleWhite', 'circle')}>
                        <div className={CheckIfColorDarkOrLightHexadecimal(color, 'textWhite', 'text')}>{element.InkQuantity}{symbol}</div>
                      </div>
                    </td>
                  </tr>
                );
              })
              :
              formula !== undefined &&
              formula.map((element) => {
                const color = `#${element.CassColor}`;
                const percentage = `${element.InkQuantity / amount}`;

                return (
                  <tr className="graph-row" key={uuidv4()}>
                    <th scope="row"> {element.CassId} </th>
                    <td className="chart-length" style={{ "--size": percentage, "--color": color }} />
                    <td className="chart-head" style={{ "--size": "calc(5 / 14)", "--color": color }}>
                      <div className={CheckIfColorDarkOrLightHexadecimal(color, 'circleWhite', 'circle')}>
                        <div className={CheckIfColorDarkOrLightHexadecimal(color, 'textWhite', 'text')}>{element.InkQuantity}{symbol}</div>
                      </div>
                    </td>
                  </tr>
                );
              })
          }


        </tbody>

      </table>
    </Container>
  );
};

CMixGraph.propTypes = {
  formula: propTypes.any,
  symbol : propTypes.string,
  amount : propTypes.number
};

export default CMixGraph;
