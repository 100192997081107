import { HubConnectionBuilder, LogLevel, HttpTransportType, HubConnection, } from "@microsoft/signalr";
import EventEmitter from "events";
import signalREventListenerDictionary from "../../Models/SignalR/signalREventListenerDictionary";
import PDMServerLocalStorageService from "../../services/LocalStorage/PDMServer/PDMServerService";
import PDMServerSessionStorageService from "../../services/SessionStorage/PDMServer/PDMServerService";
import Store from "../../Store/Store";
import watch from 'redux-watch';

class signalRBuilder {
  static myInstance: signalRBuilder;
  event: any;
  newConnection: HubConnection;
  connected: boolean = false;

  constructor() {
    // just to silence ts error
    this.newConnection = {} as HubConnection;
  }

  CreateAccessToken() {
    let tmp = PDMServerSessionStorageService.getTokenLocalStorage();
    return tmp;
  }

  async init(HubUrl: string) {
    this.event = new EventEmitter.EventEmitter();

    const URL = await PDMServerLocalStorageService.getPDMServerUrl();

    let tmp = new HubConnectionBuilder();

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      // dev code
      tmp.configureLogging(LogLevel.Debug);
    } else {
      // production code
      tmp.configureLogging(LogLevel.None);
    }

    tmp.withUrl(`${URL}${HubUrl}`, {
      // a token factory for the server, will refresh the token after a refresh token will happened
      // accessTokenFactory: () => this.CreateAccessToken(),
      skipNegotiation: true,
      transport: HttpTransportType.WebSockets,
    })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: retryContext => {
          const state = Store.getState();
          const tabIsOpen: boolean = state.IsTabOpen;
          if (!tabIsOpen) {
            return null;
          }

          if (retryContext.elapsedMilliseconds < 60000) {
            // If we've been reconnecting for less than 60 seconds so far,
            // wait between 0 and 10 seconds before the next reconnect attempt.
            return Math.random() * 10000;
          }
          else {
            // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
            return null;
          }
        }
      });

    this.newConnection = tmp.build();

    this.newConnection.onreconnecting(err => {
      console.log('Connection lost due to error' + err);
    });

    this.newConnection.onclose(async () => {
      this.connected = false;
    });

    // Bind the tab change state to the builder, when the tab is opened and the signalR already disconnected, try to reconnect
    let w = watch(Store.getState, 'IsTabOpen')
    Store.subscribe(w((newVal, oldVal, objectPath) => {
      if (newVal && !this.connected) {
        this.newConnection.start();
      }
    }))

    try {
      this.InitSocketEvent(signalREventListenerDictionary.GET_DRIVER_INFO);

      this.InitSocketEvent(signalREventListenerDictionary.GET_QTY);

      this.InitSocketEvent(signalREventListenerDictionary.GET_LAST_FIVE_JOBS);

      this.InitSocketEvent(signalREventListenerDictionary.GET_JOBS_BY_MONTH);

      this.InitSocketEvent(signalREventListenerDictionary.GET_SINGLE_JOB);

      this.InitSocketEvent(signalREventListenerDictionary.GET_SINGLE_JOB_HISTORY);

      this.InitSocketEvent(signalREventListenerDictionary.GET_SINGLE_JOB_DATA_HISTORY_LIST);

      this.InitSocketEvent(signalREventListenerDictionary.GET_DELETED_JOBS);

      this.InitSocketEvent(signalREventListenerDictionary.GET_INK_USAGE);

      this.InitSocketEvent(signalREventListenerDictionary.GET_PRINT_STATISTIC);

      this.InitSocketEvent(signalREventListenerDictionary.GET_PRINT_EVENTS_LIST);

      this.InitSocketEvent(signalREventListenerDictionary.GET_LIST_OF_JOBS);

      this.InitSocketEvent(signalREventListenerDictionary.GET_INK_USAGE_SINGLE_PRINTER);

      this.InitSocketEvent(signalREventListenerDictionary.GET_PRINT_STATISTIC_SINGLE_PRINTER);

      this.InitSocketEvent(signalREventListenerDictionary.GET_PRINT_EVENTS_LIST_SINGLE_PRINTER);

      await this.newConnection.start();
      this.connected = true;
    }
    catch (e) {
      console.log("Connection failed: ", e);
    }
  }

  private InitSocketEvent(key: signalREventListenerDictionary) {
    this.newConnection.on(key, (data: any) => {
      this.event.emit(key, {
        connection: this.newConnection,
        Data: data,
      });
    });
  }

  addEventListener = (eventName: string, func: any) =>
    this.event.addListener(eventName, func);

  static async getInstance(HubUrl: string) {
    if (signalRBuilder.myInstance === undefined) {
      signalRBuilder.myInstance = new signalRBuilder();
      await signalRBuilder.myInstance.init(HubUrl);
    }
    else {
      // if (signalRBuilder.myInstance.newConnection.connectionStarted === undefined) {
      signalRBuilder.myInstance = new signalRBuilder();
      await signalRBuilder.myInstance.init(HubUrl);
      // }
    }
    return signalRBuilder.myInstance;
  }
}

export default signalRBuilder;
