import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Main, CmixPrintPage } from "./Pages/Public/publicPagesImports";
import { AuthProvider } from 'oidc-react';
import OpenIdRoute from "./Routes/OpenIdRoute";
import { OpenIdConfig, OpenIdConfigWithoutAuth } from "./Auth/OpenIdConfig/OpenIdConfig";
import Pages, { PrintJob } from "./Pages/Private/privatePagesImports";
import "./App.scss";
import { useEffect, useState } from "react";

// eslint-disable-next-line
const App = () => {
  const [first, setfirst] = useState<any>()
  const [show, setshow] = useState(false);

  useEffect(() => {
    initAsync();
  }, [])

  const initAsync = async () => {
    let res = await OpenIdConfig();
    setfirst(res.authority);
    setshow(true);
  };

  return (
    <Router>
      {show && (
        <AuthProvider authority={first} {...OpenIdConfigWithoutAuth}>
          <Switch>
            {/* This route is a public route to build the cmix print page */}
            <Route path="/CmixPrintPage" component={CmixPrintPage} />

            {/* This route is private so we will need open id but won't receive the styling and layout of the site */}
            <OpenIdRoute path="/JobManager/PrintJob">
              <PrintJob />
            </OpenIdRoute>

            <OpenIdRoute path="/ReportCenter/Projects/Print">
              <Pages.PrintProjects />
            </OpenIdRoute>

            <OpenIdRoute path="/AuthorizePDM">
              <Pages.AuthorizePDM />
            </OpenIdRoute>

            <OpenIdRoute path="/">
              <Main />
            </OpenIdRoute>
          </Switch>
        </AuthProvider>
      )}
    </Router>
  );
};

export default App;
