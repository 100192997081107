import { InkUsageDataModel } from "../../../../../Models/Api/ReportCenter/InkUsageDataModel";
import { PrintEventDataModel } from "../../../../../Models/Api/Events/PrintEventDataModel";
import { PrintEventStatisticsDataModel } from "../../../../../Models/Api/Events/PrintEventStatisticsDataModel";
import { PrintEventStatusDataModel } from "../../../../../Models/Api/Events/PrintEventStatusDataModel";
import PDMServerHttpClient from "../../PDMServerHttpClient";

class SinglePrinterService {
  async getPrintedJobsByRange(PrinterId: string, dateFrom: string, dateTo: string, limit = 0) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<PrintEventDataModel[]>(`/api/PrintEvents/v1.0/DateRange/ListAll/SinglePrinter/${PrinterId}/${dateFrom}/${dateTo}/${limit}`);
  }

  async getPrintEventStatus(PrinterId: string, dateFrom: string, dateTo: string, limit = 0) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<PrintEventStatusDataModel>(`/api/PrintEvents/v1.0/DateRange/PrintStatus/SinglePrinter/${PrinterId}/${dateFrom}/${dateTo}/${limit}`);
  }

  async getInkUsage(PrinterId: string, dateFrom: string, dateTo: string, limit = 0) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<InkUsageDataModel[]>(`/api/PrintEvents/v1.0/GetInKUsage/SinglePrinter/${PrinterId}/${dateFrom}/${dateTo}/${limit}`);
  }

  async getPrintEventsStatistics(PrinterId: string, dateFrom: string, dateTo: string, limit = 0) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<PrintEventStatisticsDataModel>(`/api/PrintEvents/v1.0/DateRange/PrintStatistics/SinglePrinter/${PrinterId}/${dateFrom}/${dateTo}/${limit}`);
  }

  static getInstance() {
    return new SinglePrinterService();
  }
}

export default SinglePrinterService;
