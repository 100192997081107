import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { toast } from "react-toastify";
import Store from "./Store/Store";
import { ThemeWrapper } from "./Themes/ThemeWrapper";
import "./index.scss";
import "./i18n";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import rootElement from "./Models/rootElement";
import WindowFocusHandler from "./components/General/WindowFocusHandler/WindowFocusHandler";

// init root element
const r = document.getElementById("root");

if (r !== null) {
  rootElement.initRoot(r);
}

toast.configure({
  autoClose: 4000,
  draggable: false,
  position: "top-right",
  hideProgressBar: false,
  newestOnTop: true,
  closeOnClick: true,
  rtl: false,
  pauseOnHover: true
});

ReactDOM.render(
  <ThemeWrapper>
    <Provider store={Store}>
      <WindowFocusHandler Children={<App />} />
    </Provider>
  </ThemeWrapper>
  , document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
