import PDMServerHttpClient from "../../PDMServerHttpClient";

class AuthorizationService {

  async checkApi() {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<boolean>(`/api/Authorization/V1.0/Check`);
  }

  async AuthorizeLogin(companyId: string, authorizationId: string) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<boolean>(`/api/Authorization/v1.0/Authorize/${companyId}/${authorizationId}`);
  }

  static getInstance() {
    return new AuthorizationService();
  }
}

export default AuthorizationService;
