import { useState, useRef, useEffect } from "react";
import { useTheme } from '@mui/material/styles';
import Styles from './AddJob.module.scss';
import { useTranslation } from "react-i18next";
import { AddJobFormModel } from "../../../../Models/Forms/AddJob/AddJobFormModel";
import { ColoredLine, MainButton } from "../../../../components/General/GeneralExport";
import { TagsControl, TextEditor } from "../../../../components/Inputs/InputsExport";
import { Box, Card, CardContent, Grid, LinearProgress, Typography } from "@mui/material";
import HttpRequestWithForbiddenCheck from "../../../../services/HttpRequestWithForbiddenCheck/HttpRequestWithForbiddenCheck";
import { toast } from "react-toastify";
import { JobManagerService } from "../../../../Api/Rest/PDMServer/ApiServices/PDMApiServicesImports";
import Icons from "../../../../Imports/MUI/Icons/IconsImports";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from "react-redux";
import { addJob /*, removeJob*/ } from "../../../../Store/Actions/Actions";
import JobUploaderModel from "../../../../Models/Store/JobUploaderModel";
import JobUploader from "../../../../services/JobUploader/JobUploader";

const AddJob = () => {
  const [t] = useTranslation();
  const theme = useTheme();
  const inputFileRef = useRef<HTMLInputElement>(null);
  const history = useHistory();

  const [Tags, setTags] = useState<string[]>([]);
  const [zipJob, setZipJob] = useState<File | null>(null);
  const [CommentsElement, setCommentsElement] = useState<EasyMDE>();

  const [ProjectName, setProjectName] = useState("");

  const [ShowFileError, setShowFileError] = useState(false);
  const [ShowTagsError, setShowTagsError] = useState(false);
  const [ShowProjectNameError, setShowProjectNameError] = useState(false);

  const [labelZipJob, setLabelZipJob] = useState<string>(t("general.labels.chooseJob"));

  const [TagsError, setTagsError] = useState("");
  const [FileError, setFileError] = useState("");
  const [ProjectNameError, setProjectNameError] = useState("");

  // File
  const FIFTEEN_GIGABYTES = 16_106_127_360;
  const chunkSize = 1048576 * 3;//its 3MB, increase the number measure in mb
  const [showProgress/*, setShowProgress*/] = useState(false)
  const [counter, setCounter] = useState(1)
  const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0)
  const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize)
  const [progress, setProgress] = useState(0)
  const [fileGuid, setFileGuid] = useState("")
  const [fileSize, setFileSize] = useState(0)
  const [chunkCount, setChunkCount] = useState(0)

  // redux
  const dispatch = useDispatch();
  const jobUploader = JobUploader.getInstance();

  useEffect(() => {
    setLabelZipJob(t("general.labels.chooseJob"));
  }, [t])

  useEffect(() => {
    if (fileSize > 0) {
      fileUpload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress])

  const fileUpload = () => {
    setCounter(counter + 1);
    let isFirst = true;

    if (counter <= chunkCount && zipJob) {
      var chunk = zipJob.slice(beginingOfTheChunk, endOfTheChunk);
      uploadChunk(chunk, isFirst);
    }
  }

  const uploadChunk = async (chunk: Blob, isFirst: boolean = false) => {
    try {
      const [jobResp, jobErr] =
        await HttpRequestWithForbiddenCheck(JobManagerService.getInstance().uploadChunks(counter, fileGuid, labelZipJob, chunk, isFirst), 'uploadChunks', 'Error When Uploading Chunks');

      if (!jobErr && jobResp !== null) {
        setBeginingOfTheChunk(endOfTheChunk);
        setEndOfTheChunk(endOfTheChunk + chunkSize);

        if (counter === chunkCount) {
          console.log('Process is complete, counter', counter)

          await uploadCompleted();
        } else {
          var percentage = (counter / chunkCount) * 95;
          setProgress(percentage);
        }
      }

    } catch (error) {
      console.log('error', error)
    }
  }

  const uploadCompleted = async () => {
    const com = CommentsElement?.value() === undefined ? '' : CommentsElement?.value();

    const job: AddJobFormModel = {
      Tags: Tags,
      JobZipFileName: labelZipJob,
      Comments: com,
      ProjectName: ProjectName
    };

    const [jobResp, jobErr] =
      await HttpRequestWithForbiddenCheck(JobManagerService.getInstance().uploadComplete(job, fileGuid, labelZipJob),
        'uploadComplete', 'Error When Uploading Complete');

    if (!jobErr && jobResp !== null && zipJob) {
      const [, error] =
        await HttpRequestWithForbiddenCheck(JobManagerService.getInstance().addJob(job),
          'addJob', 'Error When Adding A Job');

      if (!error) {
        setProgress(100);
        toast.success("Job was added successfully", {
          style: { "backgroundColor": "#6f42c1" }
        });
      }

      history.push('/JobManager')
    }
  }

  //const fileExtension = file.name.slice((Math.max(0, file.name.lastIndexOf(".")) || Infinity) + 1)

  const addZipFile = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    if (e.currentTarget.files !== null && e.currentTarget.files[0] !== null) {
      const file = e.currentTarget.files[0];

      const match = file.name.match(/\.(.+)$/); // Regular expression to get the file extension
      const fileExtension = (match ? match[1] : '').toLowerCase();

      if (fileExtension === "zip") {

        //16106127360 -> 15GB
        if (file.size > FIFTEEN_GIGABYTES) {
          if (!ShowFileError) {
            setShowFileError(true);
          }

          if (FileError !== "File Is Too Big") {
            setFileError("File Is Too Big")
          }

          setLabelZipJob(t("general.labels.chooseJob"));
          return;
        };

        setZipJob(file);
        const fileName = file.name.replace(/\.[^/.]+$/, "")
        setLabelZipJob(fileName);
        setFileSize(file.size)
        const _totalCount = file.size % chunkSize === 0 ? file.size / chunkSize : Math.floor(file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
        setChunkCount(_totalCount)
        const _fileID = uuidv4() + "." + file.name.split('.').pop();
        setFileGuid(_fileID)

        if (ShowFileError) {
          setShowFileError(false);
        }
      }
      else {
        if (!ShowFileError) {
          setShowFileError(true);
        }

        if (FileError !== "File type isn't supported, only accept .zip files") {
          setFileError("File type isn't supported, only accept .zip files")
        }

        setLabelZipJob(t("general.labels.chooseJob"));
      }
    }
  };

  const addNewJob = async (e: any) => {
    e.preventDefault();

    if (!ShowFileError && !ShowTagsError && !ShowProjectNameError &&
      zipJob && zipJob.name !== undefined && Tags.length !== 0 && ProjectName !== "") {

      // for using in page upload

      // for making the upload only in this page, uncomment the 2 lines under and comment the other lines in this if statement
      // setShowProgress(true);
      // setProgress(1);

      // for using redux upload
      const com = CommentsElement?.value() === undefined ? '' : CommentsElement?.value();
      const model: JobUploaderModel = {
        type: 'job',
        counter: 0,
        beginingOfTheChunk: 0,
        endOfTheChunk: chunkSize,
        progress: 0,
        fileSize: zipJob.size,
        chunkCount: chunkCount,
        fileGuid: fileGuid,
        JobZipFileName: labelZipJob,
        Tags: Tags,
        Comments: com,
        ProjectName: ProjectName,
        isWorking: false,
        isFirst: true
      };

      dispatch(addJob(model));
      jobUploader.addJob(model.fileGuid, zipJob);
      history.push('/JobManager');
    }

    if (!zipJob || zipJob.name === "init") {
      if (!ShowFileError) {
        setShowFileError(true);
      }

      if (FileError !== "File Required") {
        setFileError("File Required")
      }
    }

    if (Tags.length === 0) {
      if (!ShowTagsError) {
        setShowTagsError(true);
      }

      if (TagsError !== "Tags Required") {
        setTagsError("Tags Required");
      }
    }

    if (ProjectName === "") {
      if (!ShowProjectNameError) {
        setShowProjectNameError(true);
      }

      if (ProjectNameError !== "Project Name Required") {
        setProjectNameError("Project Name Required");
      }
    }
  };

  const updateTagsList = (newTags: string[]) => {
    if (newTags.length === 0) {
      if (!ShowTagsError) {
        setShowTagsError(true);
      }

      if (TagsError !== "Job Name Required") {
        setTagsError("Job Name Required");
      }
    }
    else {
      if (TagsError !== "Press enter to insert the tag") {
        setShowTagsError(false);
      }
    }

    setTags(newTags);
  };

  const updateProjectName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      if (!ShowProjectNameError) {
        setShowProjectNameError(true);
      }

      if (ProjectNameError !== "Project Name Required") {
        setProjectNameError("Project Name Required");
      }
    }
    else {
      if (ShowProjectNameError) {
        setShowProjectNameError(false);
      }

      setProjectName(e.target.value);
    }
  };

  const onBtnClick = () => {
    /*Collecting node-element and performing click*/
    inputFileRef.current?.click();
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <ul className="nav float-right">
          <MainButton
            startIcon={<Icons.AddIcon />}
            style={{ 'marginLeft': '15px' }}
            onClick={addNewJob}>
            {t("general.buttons.add")}
          </MainButton>

          <MainButton style={{ 'marginLeft': '15px' }} onClick={() => history.push('/JobManager')} startIcon={<Icons.CloseIcon />}>
            {t("general.buttons.close")}
          </MainButton>
        </ul>

        <div style={{ display: showProgress ? "block" : "none" }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress variant="determinate" value={progress} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="text.secondary">{`${Math.round(progress)}%`}</Typography>
            </Box>
          </Box>
        </div>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={6}>

        <Card style={{ 'height': '100%' }} className="noBackgroundImage">
          <CardContent style={{ 'paddingBottom': '16px' }}>
            <form onSubmit={addNewJob}>
              <label className={Styles["add-job-from-label"] + " required"}>{t("general.labels.projectName")}</label>

              <input maxLength={25} className="form-control" type="text" lang="en" onChange={updateProjectName} style={{
                'backgroundColor': theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white',
                'color': theme.palette.mode === 'dark' ? 'white' : 'black'
              }} />

              {ShowProjectNameError &&
                <div className='text-center' style={{ "color": "red", "paddingBottom": "8px", "fontSize": "18px" }}>
                  <ColoredLine color={"red"} />
                  {ProjectNameError}
                </div>
              }
              <label className={Styles["add-job-from-label"] + " required"}>{t("general.labels.tags")}</label>
              <TagsControl
                tags={Tags}
                updateTagsList={updateTagsList}
                onTagsLeave={(empty: boolean) => {
                  if (empty) {
                    setShowTagsError(true);
                    if (TagsError !== "Press enter to insert the tag") setTagsError("Press enter to insert the tag");
                  }
                  else {
                    if (TagsError !== "Tags Required") setShowTagsError(false);
                  }
                }}
              />
              {ShowTagsError &&
                <div className='text-center' style={{ "color": "red", "paddingBottom": "8px", "fontSize": "18px" }}>
                  <ColoredLine color={"red"} />
                  {TagsError}
                </div>
              }

              <label className={Styles["add-job-from-label"] + " required"}>{t("general.labels.jobZip")}</label>
              <div>
                <label htmlFor="contained-button-file" style={{ 'display': 'flex' }}>
                  <input style={{ 'display': 'none' }}
                    accept="application/zip, application/x-zip-compressed, multipart/x-zip"
                    lang="en"
                    type="file"
                    ref={inputFileRef}
                    onChange={(e: any) => addZipFile(e)}
                  />
                  <input className="form-control" type="text" lang="en" value={labelZipJob} disabled onClick={onBtnClick} style={{
                    'backgroundColor': theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white',
                    'color': theme.palette.mode === 'dark' ? 'white' : 'black'
                  }} />
                  <MainButton onClick={onBtnClick}>
                    {t("general.labels.browse")}
                  </MainButton>
                </label>

                {ShowFileError &&
                  <div
                    className='text-center'
                    style={{ "color": "red", "paddingBottom": "8px", "fontSize": "18px" }}>
                    <ColoredLine color={"red"} />
                    {FileError}
                  </div>}
              </div>

              <label className={Styles["add-job-from-label"]}>{t("general.labels.comment")}</label>
              <div>
                <TextEditor setCommentText={setCommentsElement} />
              </div>

            </form>
          </CardContent>
        </Card>


      </Grid>
    </Grid>
  );
};

export default AddJob;
