import React from 'react'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import Styles from '../../Styles/ColorID.module.scss';
import { CmixColorListDataModel } from '../../../../Models/Api/Cmix/CmixColorListDataModel';

type CMiXQuantityTableProps = {
    QuantityList: CmixColorListDataModel[]
};

function CMiXQuantityTable({ QuantityList }: CMiXQuantityTableProps) {
    const [t] = useTranslation();

    return (
        <div className="content" style={{'display': 'flex','justifyContent': 'center'}}>
            <TableContainer component={Paper} sx={{width: {xs: '100%', md: '80%'}}} className="noBackgroundImage">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("general.tables.color")}</TableCell>
                            <TableCell>{t("general.tables.cassNumber")}</TableCell>
                            <TableCell>{t("general.tables.weight")}(KG)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {QuantityList &&
                            QuantityList.map((formula) => {
                                return (
                                    <TableRow
                                        key={uuidv4()}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >

                                        <TableCell component="th" scope="row">
                                            <div
                                                className={Styles["color-ball-grid"] + ' noselect'}
                                                style={{
                                                    "backgroundColor": `#${formula.CassColor}`,
                                                    "color": `#${formula.CassColor}`,
                                                }}
                                            >
                                            </div>
                                            #{formula.CassColor}
                                        </TableCell>
                                        <TableCell >{formula.CassId}</TableCell>
                                        <TableCell >{formula.InkQuantity}</TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
};

export default CMiXQuantityTable;