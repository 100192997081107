import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';
import { ColorsStyle } from '../../ReportCenterStylesImports';
import { PrintEventStatusDataModel } from '../../../../Models/Api/Events/PrintEventStatusDataModel';
import CustomLi from '../../../../components/General/CustomLi/CustomLi';
import roundTo from "../../../../Pipes/RoundTo";

type PrintStatisticsProps = {
    printStatisticsData: PrintEventStatusDataModel | null,
    ShowInkQuantity?: boolean
};

const PrintStatistics = ({ printStatisticsData, ShowInkQuantity = false }: PrintStatisticsProps) => {
    const [t] = useTranslation();
    const [total, setTotal] = useState('0');
    const [completed, setCompleted] = useState('0');
    const [canceled, setCanceled] = useState('0');
    const [crushed, setCrushed] = useState('0');

    useEffect(() => {
        // const sum = tmp.reduce((partialSum, a) => partialSum + a, 0);
        if (printStatisticsData !== null && (printStatisticsData.Canceled || printStatisticsData.Completed || printStatisticsData.Crushed)) {
            const sum = printStatisticsData.Completed + printStatisticsData.Canceled + printStatisticsData.Crushed;
            if (ShowInkQuantity) {
                setTotal(roundTo(sum, 2).toString() + ' CC');
                setCompleted(roundTo(printStatisticsData.Completed, 2).toString() + ' CC');
                setCanceled(roundTo(printStatisticsData.Canceled, 2).toString() + ' CC');
                setCrushed(roundTo(printStatisticsData.Crushed, 2).toString() + ' CC');
            }
            else {
                setTotal(sum.toString());
                setCompleted(printStatisticsData.Completed.toString());
                setCanceled(printStatisticsData.Canceled.toString());
                setCrushed(printStatisticsData.Crushed.toString());
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [printStatisticsData])


    return (
        <div>
            <h4 className={ColorsStyle.weight400 + " Orbitron20"} style={{ 'margin': '0', 'marginBottom': '15px' }}>{t("general.cards.printStatus")}</h4>
            <ul className="list-group list-group-unbordered mb-3">
                <CustomLi marginTop='4px' className={'bg-green'} Title={t("general.labels.completed") + " : "} Data={completed} />
                <CustomLi marginTop='4px' className={'bg-orange'} Title={t("general.labels.canceled") + " : "} Data={canceled} />
                <CustomLi marginTop='4px' className={'bg-purple'} Title={t("general.labels.crushed") + " : "} Data={crushed} />
                <CustomLi marginTop='4px' className={'bg-grey'} Title={t("general.labels.total") + " : "} Data={total} />
            </ul>
        </div>
    );
};

export default PrintStatistics;
