import { Line } from "react-chartjs-2";
import { jobManagerLineChart } from "../../JobManagerImports";
import { ColorsStyle } from "../../JobManagerStylesImports";
import { chartDataModel } from '../../../../Models/Ui/JobManager/chartDataModel';
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

interface JobManagerSummeryProps {
    printedJobs: chartDataModel | undefined
    countNumberJobs: string
}

function JobManagerSummery({ printedJobs, countNumberJobs }: JobManagerSummeryProps) {
    const [t] = useTranslation();

    return (
        <Grid container>
            <Grid item xs={10}>
                <div style={{ 'height': '270px', 'width': '100%' }}>
                    <h4 className={ColorsStyle.weight400 + " Orbitron20"} style={{ 'margin': '0', 'marginBottom': '15px' }}>{t("general.cards.summary")}</h4>
                    <div style={{ 'height': '240px', 'width': '100%' }}>
                        <Line data={printedJobs} options={jobManagerLineChart.options} type={undefined} />
                    </div>
                </div>
            </Grid>
            <Grid item xs={2}>
                <div>
                    <br />
                    <br />
                    <h2 className="d-flex justify-content-center text-orange Orbitron20" style={{ 'fontSize': '30px' }}>
                        {countNumberJobs !== undefined ? countNumberJobs : -1}
                    </h2>
                    <p className="d-flex justify-content-center text-orange Orbitron14">
                        {t("general.labels.jobsInStorage")}
                    </p>
                </div>
            </Grid>
        </Grid>
    )
};

export default JobManagerSummery;
