import { AddTicketEventFormModel } from "../../../../../Models/Forms/AddTicketEvent/AddTicketEventFormModel";
import PDMServerHttpClient from "../../PDMServerHttpClient";

class TicketEventService {

  async postAddTicketEvent(ticketEvent: AddTicketEventFormModel) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.post<boolean>(`/api/Ticket/UI/V1.0/create`, ticketEvent);
  }

  static getInstance() {
    return new TicketEventService();
  }
}

export default TicketEventService;
