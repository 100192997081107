import { propertyOf } from "../../../../../Helpers/propertyOf";
import { UserEventDataModel } from "../../../../../Models/Api/Events/UserEventDataModel";
import { UserApiDataModel } from "../../../../../Models/Api/User/UserApiDataModel";
import { addUserFormModel } from "../../../../../Models/Forms/AddUser/AddUserFormModel";
import { ChangePasswordFormModel } from "../../../../../Models/Forms/ChangePassword/ChangePasswordFormModel";
import { EditUserFormModel } from "../../../../../Models/Forms/EditUser/EditUserFormModel";
import PDMServerHttpClient from "../../PDMServerHttpClient";

class UserService {
  async ListAllUsersUnderRole(RoleNames: string[]) {
    let api = await PDMServerHttpClient.getInstance();
    const formData = new FormData();
    for (let index = 0; index < RoleNames.length; index++) {
      formData.append(`${propertyOf<addUserFormModel>('RoleNames')}[${index}]`, RoleNames[index]);
    }
    return await api.instance.post<UserApiDataModel[]>(`/api/User/V1.0/ListAllUsersUnderRole`, formData);
  }

  async getUserPermissions() {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<string[]>(`/api/User/V1.0/GetPermissions`);
  }

  async getUserProfileById(userId: string) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<UserApiDataModel>(`/api/User/V1.0/UserProfileById/${userId}`);
  }

  async getUserProfileByEmail(email: string) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<UserApiDataModel>(`/api/User/V1.0/UserProfileByEmail/${email}`);
  }

  async getUserEvents(userId: string, limit: number = 0) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<UserEventDataModel[]>(`/api/User/V1.0/UserEvents/getUserEvents/${userId}/${limit}`);
  }

  async postAddUser(user: addUserFormModel) {
    let api = await PDMServerHttpClient.getInstance();
    const formData = new FormData();
    formData.append(propertyOf<addUserFormModel>('Email'), user.Email);
    formData.append(propertyOf<addUserFormModel>('FirstName'), user.FirstName);
    formData.append(propertyOf<addUserFormModel>('LastName'), user.LastName);
    formData.append(propertyOf<addUserFormModel>('Position'), user.Position);
    formData.append(propertyOf<addUserFormModel>('UserName'), user.UserName);
    formData.append(propertyOf<addUserFormModel>('Password'), user.Password);
    formData.append(propertyOf<addUserFormModel>('AvatarPhoto'), user.AvatarPhoto);
    formData.append(propertyOf<addUserFormModel>('AllowImage'), user.AllowImage.toString());

    for (let index = 0; index < user.RoleNames.length; index++) {
      formData.append(`${propertyOf<addUserFormModel>('RoleNames')}[${index}]`, user.RoleNames[index]);
    }

    return await api.instance.post<boolean>(`/api/User/V1.0/AddUser`, formData);
  }

  async postEditUser(user: EditUserFormModel) {
    let api = await PDMServerHttpClient.getInstance();

    const formData = new FormData();
    formData.append(propertyOf<EditUserFormModel>('UserId'), user.UserId);
    formData.append(propertyOf<EditUserFormModel>('FirstName'), user.FirstName);
    formData.append(propertyOf<EditUserFormModel>('LastName'), user.LastName);
    formData.append(propertyOf<EditUserFormModel>('UserName'), user.UserName);
    formData.append(propertyOf<EditUserFormModel>('Position'), user.Position);
    formData.append(propertyOf<EditUserFormModel>('Email'), user.Email);
    formData.append(propertyOf<EditUserFormModel>('AvatarPhoto'), user.AvatarPhoto);
    formData.append(propertyOf<EditUserFormModel>('OverrideAvatarPhoto'), user.OverrideAvatarPhoto.toString());

    for (let index = 0; index < user.RoleNames.length; index++) {
      formData.append(`${propertyOf<EditUserFormModel>('RoleNames')}[${index}]`, user.RoleNames[index]);
    }

    formData.append(propertyOf<EditUserFormModel>('IsEnabled'), user.IsEnabled.toString());
    formData.append(propertyOf<EditUserFormModel>('IsLockedOut'), user.IsLockedOut.toString());

    return await api.instance.post<boolean>(`/api/User/V1.0/EditUser`, formData);
  }

  async deleteUser(userId: string) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.delete<boolean>(`/api/User/V1.0/DeleteUser/${userId}`);
  }

  async postRolesUnderUserRoles(RoleNames: string[]) {
    let api = await PDMServerHttpClient.getInstance();
    const formData = new FormData();
    for (let index = 0; index < RoleNames.length; index++) {
      formData.append(`${propertyOf<addUserFormModel>('RoleNames')}[${index}]`, RoleNames[index]);
    }
    return await api.instance.post<string[]>(`/api/User/V1.0/RolesUnderUserRole`, formData);
  }

  async postChangePassword(User: ChangePasswordFormModel) {
    let api = await PDMServerHttpClient.getInstance();
    const formData = new FormData();
    formData.append(propertyOf<ChangePasswordFormModel>('UserId'), User.UserId);
    formData.append(propertyOf<ChangePasswordFormModel>('CurrentPassword'), User.CurrentPassword);
    formData.append(propertyOf<ChangePasswordFormModel>('NewPassword'), User.NewPassword);
    
    return await api.instance.post<string[]>(`/api/User/V1.0/ChangePassword`, formData);
  }

  async getUserNameValidation(userName: string) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<boolean>(`/api/User/Validation/V1.0/UserName/${userName}`);
  }

  async getEmailValidation(Email: string) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<boolean>(`/api/User/Validation/V1.0/Email/${Email}`);
  }

  static getInstance() {
    return new UserService();
  }
}

export default UserService;
