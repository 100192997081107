import { createStore, combineReducers } from "redux";
import { TabReducer, PDMAuthReducer, JobUploaderReducer } from "./Reducers/Reducer";
import { composeWithDevTools } from 'redux-devtools-extension';

const rootReducer = combineReducers({
  IsTabOpen: TabReducer,
  PdmAuth: PDMAuthReducer,
  JobUploader: JobUploaderReducer
});


let store = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
  ?
  createStore(rootReducer, composeWithDevTools())
  :
  createStore(rootReducer);


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;
