import Divider from '@mui/material/Divider';
import { useTranslation } from "react-i18next";

type NewsParagraphProps = {
    CustomText?: string
};

function NewsParagraph({ CustomText = '' }: NewsParagraphProps) {
    const [t] = useTranslation();

    return (
        <>
            <h1 className="display-2 h1-size-changer" style={{ "textAlign": "initial" }}>{t("general.labels.introHeader")}</h1>
            <Divider light style={{ 'marginBottom': '15px', 'padding': '15px' }} />
            {CustomText !== ''
                ?
                <h5>{CustomText}</h5>
                :
                <h5>{t("general.labels.introParagraph")}</h5>
            }
        </>
    )
}

export default NewsParagraph
