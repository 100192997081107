import { Grid } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AuthorizationService, CompanyService } from "../../../Api/Rest/PDMServer/ApiServices/PDMApiServicesImports";
import { NewsParagraph } from "../../../modules/Welcome/WelcomeImports";
import HttpRequestWithForbiddenCheck from "../../../services/HttpRequestWithForbiddenCheck/HttpRequestWithForbiddenCheck";
import { pdmAuthorize, pdmUnauthorize } from "../../../Store/Actions/Actions";
import { RootState } from "../../../Store/Store";

const Welcome = () => {
  const dispatch = useDispatch();
  const pdmAuthState = useSelector((state: RootState) => state.PdmAuth);
  const history = useHistory();
  const [welcomeText, setWelcomeText] = useState('');

  React.useEffect(() => {
    asyncInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const asyncInit = async () => {
    const pdmAuthorized = await AuthorizationService.getInstance().checkApi();

    if (pdmAuthorized.status === 200) {
      if (!pdmAuthorized.data) {
        if (pdmAuthState !== pdmAuthorized.data) {
          dispatch(pdmUnauthorize());
        }
        history.push('/AuthorizePDM');
      }

      if (pdmAuthState !== pdmAuthorized.data) {
        dispatch(pdmAuthorize());
      }

      initWelcomeText();
    }
  };

  const initWelcomeText = async () => {
    const [Jobs, error1] = await HttpRequestWithForbiddenCheck(CompanyService.getInstance().getWelcomeText(), 'getWelcomeText', 'Error When Getting The Welcome Text');
    if (!error1 && Jobs !== null) {
      setWelcomeText(Jobs);
    };
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} display={{ xs: 'none', lg: 'block' }}>
        {/* <NewsCarousel /> */}
      </Grid>

      <Grid item xs={12} lg={5}>
        <NewsParagraph CustomText={welcomeText} />
      </Grid>
    </Grid >
  );
};

export default Welcome;
