import React from 'react'
import Styles from './MuiCalender.module.scss';
import moment from "moment";
import { useTheme } from '@mui/material';
import { DateRangePicker, DateRange } from "materialui-daterange-picker";

type MuiCalenderProps = {
    dateRange: DateRange
    UpdateRange: (range: DateRange) => void,
    LockToggle?: boolean,
    Open?: boolean
};

function MuiCalender({ dateRange, UpdateRange, LockToggle = false, Open = false }: MuiCalenderProps) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    React.useEffect(() => {
        if (LockToggle) {
            setOpen(Open);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggle = () => setOpen(!open);

    return (
        <>
            <input style={{ 'cursor': 'pointer' }} type="text" className={"form-control float-right " + (theme.palette.mode !== 'dark' ? Styles["custom-calender-input-light"] : Styles["custom-calender-input-dark"])} readOnly value={`${moment(dateRange.startDate).format('DD/MM/YYYY')} - ${moment(dateRange.endDate).format('DD/MM/YYYY')}`} onClick={() => setOpen(true)} disabled={LockToggle} />
            <div className={Styles['custom-calender-mui'] + " " + (theme.palette.mode !== 'dark' ? Styles["custom-calender-mui-light"] : Styles["custom-calender-mui-dark"])}>
                <DateRangePicker
                    initialDateRange={dateRange}
                    open={open}
                    toggle={!LockToggle ? toggle : () => { }}
                    onChange={UpdateRange}
                />
            </div>
        </>
    )
}

export default MuiCalender;