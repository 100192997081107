import SessionStorageService from "../SessionStorageService";

export enum SessionStorageFiltersEnum {
    JOB_MANAGER_JOBS_LIST_FILTER = 'JobManagerJobsListFilter',
    RECYCLE_BIN_FILTER = 'RecycleBinFilter',
    REPORT_CENTER_ALL_PRINTERS_PRINTED_JOBS = 'ReportCenterAllPrintersFilter',
    REPORT_CENTER_PROJECTS_PRINTED_JOBS_FILTER = 'ReportCenterProjectsPrintersFilter',
    SINGLE_PRINTER_PRINTED_JOBS_FILTER = 'SinglePrinterPrintedJobsFilter',
    USERS_DASHBOARD_USERS_LIST_FILTER = 'UsersDashboardUsersListFilter',
}

function SetSessionStorageFilter(sessionStorageFiltersEnum: SessionStorageFiltersEnum, filter: string) {
    SessionStorageService.addToSessionStorage(sessionStorageFiltersEnum, filter);
};

function GetSessionStorageFilter(sessionStorageFiltersEnum: SessionStorageFiltersEnum) {
    return SessionStorageService.getFromSessionStorage(sessionStorageFiltersEnum);
};

const FiltersSessionStorageService = {
    SetSessionStorageFilter,
    GetSessionStorageFilter
};

export default FiltersSessionStorageService;


