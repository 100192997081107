import PDMServerHttpClient from "../../PDMServerHttpClient";
import { InkUsageDataModel } from "../../../../../Models/Api/ReportCenter/InkUsageDataModel";
import { PrintEventStatusDataModel } from "../../../../../Models/Api/Events/PrintEventStatusDataModel";
import { PrintEventDataModel } from "../../../../../Models/Api/Events/PrintEventDataModel";
import { PrintersSquareMeterSummeryDataModel } from "../../../../../Models/Api/Events/PrintersSquareMeterSummeryDataModel";

class ByProjectService {
  async getInkUsage(projName: string, limit = 0) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<InkUsageDataModel[]>(`/api/PrintEvents/v1.0/GetInkUsage/Project/${projName}/${limit}`);
  }

  async getPrintEventStatus(projName: string, limit = 0) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<PrintEventStatusDataModel>(`/api/PrintEvents/v1.0/DateRange/PrintStatus/Project/${projName}/${limit}`);
  }

  async getPrintedJobsByRange(projName: string, limit = 0) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<PrintEventDataModel[]>(`/api/PrintEvents/v1.0/DateRange/ListAll/Project/${projName}/${limit}`);
  }

  async getPrintedSquareMetersByRange(projName: string, limit = 0) {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<PrintersSquareMeterSummeryDataModel[]>(`/api/PrintEvents/v1.0/DateRange/PrintedSquareMeters/Project/${projName}/${limit}`);
  }

  async getProjectNamesList() {
    let api = await PDMServerHttpClient.getInstance();
    return await api.instance.get<string[]>(`/api/PrintEvents/v1.0/GetProjectNamesList/Project`);
  }

  static getInstance() {
    return new ByProjectService();
  }
}

export default ByProjectService;
