import { useMediaQuery } from 'react-responsive';

type ResponsiveWrapperProps = {
    DesktopOrLaptopLayout: JSX.Element
    TabletOrMobileLayout: JSX.Element
    BigScreenLayout?: JSX.Element
    RetinaLayout?: JSX.Element
};

function ResponsiveWrapper({ DesktopOrLaptopLayout, TabletOrMobileLayout, BigScreenLayout = undefined, RetinaLayout = undefined }: ResponsiveWrapperProps) {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1225px)' });
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
    // const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });

    return (
        <>
            {isDesktopOrLaptop &&
                <>
                    {isBigScreen
                        ?
                        BigScreenLayout !== undefined ? BigScreenLayout : DesktopOrLaptopLayout
                        :
                        DesktopOrLaptopLayout
                    }
                </>
            }

            {isTabletOrMobile && TabletOrMobileLayout}

            {/* <p>Your are in {isPortrait ? 'portrait' : 'landscape'} orientation</p> */}

            {/* {isRetina && <p>You are retina</p>} */}
        </>
    )
}

export default ResponsiveWrapper;