import Cmix from './Cmix/Cmix';
import CreateUser from './Profile/CreateUser/CreateUser';
import ReportCenter from './ReportCenter/ReportCenter';
import JobManager from './JobManager/JobManager';
import jobListPage from './JobManager/JobListPage/JobListPage';
import AddJob from './JobManager/AddJob/AddJob';

import PrintHistory from './ReportCenter/Modules/PrintHistory/PrintHistory';
import JobInfo from './JobManager/JobInfo/JobInfo';
import Profile from './Profile/Profile';
import SinglePrinter from './ReportCenter/Modules/SinglePrinter/SinglePrinter';
import UsersDashboard from './Profile/UsersDashboard/UsersDashboard';
import Welcome from './Welcome/Welcome';
import NotFound from './NotFound/NotFound';
import RecycleBin from './RecycleBin/RecycleBin';
import PrintJob from './JobManager/PrintJob/PrintJob';
import AuthorizePDM from './AuthorizePDM/AuthorizePDM';
import EditUser from './Profile/EditUser/EditUser';
import AllPrinters from './ReportCenter/Modules/AllPrinters/AllPrinters';
import Projects from './ReportCenter/Modules/Projects/Projects';
import SingleUser from './Profile/SingleUser/SingleUser';
import ChangePassword from './Profile/ChangePassword/ChangePassword';
import PrintProjects from './ReportCenter/Modules/Projects/PrintProjects';
import InkUsage from './ReportCenter/Modules/InkUsage/InkUsage';
import JobDataVersions from './JobManager/JobInfo/JobDataVersions';

import CreateTicketEvent from './TicketEvent/CreateTicketEvent/CreateTicketEvent';

export { default as Cmix } from './Cmix/Cmix';
export { default as CreateUser } from './Profile/CreateUser/CreateUser';
export { default as ReportCenter } from './ReportCenter/ReportCenter';
export { default as JobManager } from './JobManager/JobManager';
export { default as jobListPage } from './JobManager/JobListPage/JobListPage';
export { default as PrintHistory } from './ReportCenter/Modules/PrintHistory/PrintHistory';
export { default as JobInfo } from './JobManager/JobInfo/JobInfo';
export { default as AddJob } from './JobManager/AddJob/AddJob';
export { default as Profile } from './Profile/Profile';
export { default as SinglePrinter } from './ReportCenter/Modules/SinglePrinter/SinglePrinter';
export { default as UsersDashboard } from './Profile/UsersDashboard/UsersDashboard';
export { default as Welcome } from './Welcome/Welcome';
export { default as NotFound } from './NotFound/NotFound';
export { default as RecycleBin } from './RecycleBin/RecycleBin';
export { default as PrintJob } from './JobManager/PrintJob/PrintJob';
export { default as AuthorizePDM } from './AuthorizePDM/AuthorizePDM';
export { default as EditUser } from './Profile/EditUser/EditUser';
export { default as AllPrinters } from './ReportCenter/Modules/AllPrinters/AllPrinters';
export { default as Projects } from './ReportCenter/Modules/Projects/Projects';
export { default as SingleUser } from './Profile/SingleUser/SingleUser';
export { default as ChangePassword } from './Profile/ChangePassword/ChangePassword';
export { default as PrintProjects } from './ReportCenter/Modules/Projects/PrintProjects';
export { default as InkUsage } from './ReportCenter/Modules/InkUsage/InkUsage';
export { default as CreateTicketEvent } from './TicketEvent/CreateTicketEvent/CreateTicketEvent';
export { default as JobDataVersions } from './JobManager/JobInfo/JobDataVersions';


const Pages = {
    Cmix,
    CreateUser,
    ReportCenter,
    JobManager,
    jobListPage,
    PrintHistory,
    JobInfo,
    Profile,
    SinglePrinter,
    UsersDashboard,
    Welcome,
    NotFound,
    RecycleBin,
    PrintJob,
    AuthorizePDM,
    EditUser,
    AllPrinters,
    Projects,
    SingleUser,
    ChangePassword,
    PrintProjects,
    InkUsage,
    AddJob,
    CreateTicketEvent,
    JobDataVersions
};

export default Pages;

// There are 2 options for importing the file:

// 1.
// import Pages from './';
// <Pages.Cmix/>

// 2.
// import {Cmix} from './';
// <Cmix/>
