import localStorageService from "../localStorageService";

enum LocalStorageTableEnum {
    RECYCLE_BIN = 'Table_RecycleBin',
    JOB_MANAGER = 'Table_JobManager',
    REPORT_CENTER = 'Table_ReportCenter',
    SINGLE_PRINTER = 'Table_SinglePrinter',
    USERS_DASHBOARD = 'Table_UsersDashboard',
}

const availableRowOptions = [10, 15, 25, 50, 100];

function SetRecycleBinTableRows(amount: number) {
    localStorageService.addToLocalStorage(LocalStorageTableEnum.RECYCLE_BIN, amount.toString());
};

function GetRecycleBinTableRows() {
    return LocalStorageCheck(LocalStorageTableEnum.RECYCLE_BIN, 25, SetRecycleBinTableRows);
};

function SetJobManagerTableRows(amount: number) {
    localStorageService.addToLocalStorage(LocalStorageTableEnum.JOB_MANAGER, amount.toString());
};

function GetJobManagerTableRows() {
    return LocalStorageCheck(LocalStorageTableEnum.JOB_MANAGER, 25, SetJobManagerTableRows);
};

function SetDashboardTableRows(amount: number) {
    localStorageService.addToLocalStorage(LocalStorageTableEnum.REPORT_CENTER, amount.toString());
};

function GetDashboardTableRows() {
    return LocalStorageCheck(LocalStorageTableEnum.REPORT_CENTER, 15, SetDashboardTableRows);
};

function GetUsersDashboardTableRows() {
    return LocalStorageCheck(LocalStorageTableEnum.USERS_DASHBOARD, 15, SetDashboardTableRows);
};

function SetUsersDashboardTableRows(amount: number) {
    localStorageService.addToLocalStorage(LocalStorageTableEnum.USERS_DASHBOARD, amount.toString());
};

function SetSinglePrinterTableRows(printerId: string, amount: number) {
    const key = LocalStorageTableEnum.SINGLE_PRINTER + '_' + printerId;
    localStorageService.addToLocalStorage(key, amount.toString());
};

function GetSinglePrinterTableRows(printerId: string) {
    const errFunc = () => {
        const defaultVal = 15;
        SetSinglePrinterTableRows(printerId, defaultVal);
        return defaultVal;
    };

    const key = LocalStorageTableEnum.SINGLE_PRINTER + '_' + printerId;

    const res = localStorageService.getFromLocalStorage(key);
    if (res) {
        try {
            const num = parseInt(res, 10);
            const inArr = checkIfInTheAvailableRow(num);
            if (!num || !inArr) return errFunc();
            return num;
        }
        catch (err) {
            return errFunc();
        }
    }
    else {
        return errFunc();
    }
};

function LocalStorageCheck(key: string, defaultVal: number, setFunction: (val: number) => void) {
    const errFunc = () => {
        setFunction(defaultVal);
        return defaultVal;
    };

    const res = localStorageService.getFromLocalStorage(key);
    if (res) {
        try {
            const num = parseInt(res, 10);
            const inArr = checkIfInTheAvailableRow(num);
            if (!num || !inArr) return errFunc();
            return num;
        }
        catch (err) {
            return errFunc();
        }
    }
    else {
        return errFunc();
    }
}

function checkIfInTheAvailableRow(num: number) {
    return availableRowOptions.includes(num);
}

export {
    SetUsersDashboardTableRows,
    GetUsersDashboardTableRows,
    SetRecycleBinTableRows,
    GetRecycleBinTableRows,
    SetJobManagerTableRows,
    GetJobManagerTableRows,
    SetDashboardTableRows,
    GetDashboardTableRows,
    SetSinglePrinterTableRows,
    GetSinglePrinterTableRows,
    availableRowOptions
};