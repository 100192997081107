import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import ForbiddenLayout from "../../components/Toast/ForbiddenLayout/ForbiddenLayout";
import AwaitHandling from "../AwaitHandling/AwaitHandling";

async function HttpRequestWithForbiddenCheck<T>(request: Promise<AxiosResponse<T | null>>, forbiddenMessage: string, errorMessage: string, OnForbidden?: () => void): Promise<[T | null, any]> {
    const [res, error] = await AwaitHandling(request);
    if (!error) {
        if (res.data !== null) {
            return [res.data, null];
        }
        toast.success(errorMessage, { style: { "backgroundColor": "#6f42c1" } });
        return [null, null];
    }
    else {
        const err = error as AxiosError;
        if (err.response !== undefined && err.response.status === 403) {
            toast.warning(<ForbiddenLayout Message={forbiddenMessage} />);
        }
        else if (err.response !== undefined && err.response.status === 401) {
            if(OnForbidden !== undefined) OnForbidden();
        }
        else {
            toast.success(errorMessage, { style: { "backgroundColor": "#6f42c1" } });
        }
        return [null, err]
    }
}

export default HttpRequestWithForbiddenCheck;