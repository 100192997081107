import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { PrintEventDataModel } from "../../../../Models/Api/Events/PrintEventDataModel";
import { v4 as uuidv4 } from "uuid";
import humanizeDuration from "humanize-duration";
import Grid from '@mui/material/Grid';
import { Card, CardContent, Chip } from "@mui/material";
import humanizeString from 'humanize-string';
import { useTranslation } from "react-i18next";
import ResponsiveWrapper from "../../../../components/ResponsiveDesign/ResponsiveWrapper";
import moment from "moment";

interface EnhancedTableProps {
    printedJobs: PrintEventDataModel[] | null
    Title?: string
    ShowTitle?: boolean
}

export default function EnhancedTable({ printedJobs, Title, ShowTitle = false }: EnhancedTableProps) {
    const [t] = useTranslation();

    const table = (<Grid item xs={12}>
        <Card>
            <CardContent style={{ 'padding': '0px' }}>
                <Box >
                    <TableContainer >
                        <Table
                            aria-labelledby="tableTitle"
                            size={"small"} >
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        {t("general.tables.jobName")}
                                    </TableCell>
                                    <TableCell>
                                        {t("general.tables.projectName")}
                                    </TableCell>
                                    <TableCell>
                                        {t("general.tables.printerId")}
                                    </TableCell>
                                    <TableCell >{t("general.tables.color")}</TableCell>
                                    <TableCell>
                                        {t("general.tables.createEventDateTime")}
                                    </TableCell>
                                    <TableCell>
                                        {t("general.tables.printTime")}
                                    </TableCell>
                                    <TableCell>
                                        {t("general.tables.tags")}
                                    </TableCell>
                                    <TableCell>
                                        {t("general.labels.status")}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {printedJobs && printedJobs.map((row, index) => {
                                    return (
                                        <TableRow key={uuidv4()} >
                                            <TableCell>{row.Job.JobName}</TableCell>
                                            <TableCell>{row.Job.ProjectName}</TableCell>
                                            <TableCell>{row.PrinterId}</TableCell>
                                            <TableCell>
                                                {row.Job.JobColors.map((color) =>
                                                    color.ColorName ? (
                                                        <div
                                                            key={uuidv4()}
                                                            className={`bg-${color.ColorName.toLowerCase()} colorBox my-3`}
                                                        ></div>
                                                    ) : null
                                                )}
                                            </TableCell>
                                            <TableCell >
                                                {moment.utc(row.CreateEventDateTime).local().format('DD/MM/YYYY H:mm')}
                                            </TableCell>
                                            <TableCell>{humanizeDuration(row.NetoPrintTime, { round: true, })}</TableCell>
                                            <TableCell>
                                                {row.Tags &&
                                                    row.Tags.map((tag) => (
                                                        <Chip size="small" key={uuidv4()} color='primary' label={tag} style={{ 'marginLeft': '2px', 'marginRight': '2px' }} />
                                                    ))}
                                            </TableCell>
                                            <TableCell>{humanizeString(row.PrintStatus)}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </CardContent>
        </Card>
    </Grid>);

    const desktopTemplate = (<Grid container>
        {ShowTitle
            ?
            <>
                <Grid item xs={8}>
                    <h4 className="Orbitron20 p-3">{Title}</h4>
                </Grid>

                <Grid item xs={4}>

                </Grid>
            </>
            :
            <>
                <Grid item xs={12}>

                </Grid>
            </>
        }

        {table}
    </Grid>);

    const tabletOrMobileTemplate = (<Grid container>
        {ShowTitle
            ?
            <>
                <Grid item xs={12}>
                    <h4 className="Orbitron20 p-3">{Title}</h4>
                </Grid>

                <Grid item xs={12}>

                </Grid>
            </>
            :
            <>
                <Grid item xs={12}>

                </Grid>
            </>
        }

        {table}
    </Grid>);

    return (
        <ResponsiveWrapper DesktopOrLaptopLayout={desktopTemplate} TabletOrMobileLayout={tabletOrMobileTemplate} />
    );
}
