import React from "react";
import { Bar } from "react-chartjs-2";
import { printedJobsChart } from "../../ReportCenterImports";
import { useTranslation } from "react-i18next";
import roundTo from "../../../../Pipes/RoundTo";

const InkUsage = ({ inkUsageData }) => {
  const [t] = useTranslation();

  const [inkUsageChart, setInkUsageChart] = React.useState({
    labels: [],
    datasets: printedJobsChart.datasets,
  });

  const fillChardColor = (inkChart, color, amount = 0) => {
    inkChart.labels.push(color);
    inkChart.data.push(Number.parseFloat(amount).toFixed(3));
    inkChart.backgroundColor.push(color);
  };

  React.useEffect(() => {
    loadInkUsage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inkUsageData, t])


  const loadInkUsage = () => {
    if (!inkUsageData) return;

    let inkChart = {
      labels: [],
      data: [],
      backgroundColor: [],
    };

    // fill with empty line if array is empty
    if (inkUsageData.length === 0) {

      fillChardColor(inkChart, t("general.colors.blue"));
      fillChardColor(inkChart, t("general.colors.green"));
      fillChardColor(inkChart, t("general.colors.white"));
      fillChardColor(inkChart, t("general.colors.orange"));
      fillChardColor(inkChart, t("general.colors.red"));
      fillChardColor(inkChart, t("general.colors.black"));
    }
    else {
      inkUsageData.forEach(function (color) {
        let label = color["ColorName"].toLowerCase();
        // let label = t(`general.colors.${color["ColorName"].toLowerCase()}`);
        inkChart.labels.push(label);
        inkChart.data.push(roundTo(color["InkQuantity"], 2));
        inkChart.backgroundColor.push(color["ColorName"]);
      });
    }

    let inkuChart = { ...inkUsageChart };
    inkuChart.labels = inkChart.labels;
    inkuChart.datasets[0].data = inkChart.data; // do with foreach - tom
    inkuChart.datasets[0].backgroundColor = inkChart.backgroundColor;

    setInkUsageChart(inkuChart);
  };

  return (
    <div style={{ 'height': '220px', 'width': '100%' }}>
      <Bar
        data={inkUsageChart}
        options={printedJobsChart.options}
        plugins={printedJobsChart.options.plugins}
      />
    </div>
  );
};

// InkUsage.propTypes = {
//   inkUsageData: propTypes.arrayOf(propTypes.instanceOf(InkUsageDataModel)),
// };

export default InkUsage;
