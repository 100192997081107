import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import { ListItemText } from '@mui/material';

interface CustomListItemProps {
    isLast?: boolean
    Title?: string,
    children?: JSX.Element
    numberOfIndentation?: number
    Required?: boolean
}

const CustomListItem = ({ children, Title = '', isLast = false, numberOfIndentation = 0, Required = false }: CustomListItemProps) => {

    if (Title === '') {
        return (
            <>
                <ListItem sx={{ pl: numberOfIndentation }}>
                    {children}
                </ListItem>

                {!isLast && <Divider />}
            </>
        );
    }

    return (
        <>
            <ListItem sx={{ pl: numberOfIndentation }}
                secondaryAction={
                    <>
                        {children}
                    </>
                }
                style={{ 'cursor': 'auto' }}
            >
                <ListItemText>
                    <ListItemIcon className={Required === true ? 'required': ''}>
                        {Title}
                    </ListItemIcon>
                </ListItemText>
            </ListItem>

            {!isLast && <Divider />}
        </>
    );
};

export default CustomListItem;