import { useTranslation } from "react-i18next";
import { ColorsStyle } from '../../ReportCenterStylesImports';
import { PrintEventStatisticsDataModel } from '../../../../Models/Api/Events/PrintEventStatisticsDataModel';
import roundTo from '../../../../Pipes/RoundTo';
import CustomLi from '../../../../components/General/CustomLi/CustomLi';

type PrintInfoStatisticsProps = {
    PrintInfoStatisticsData: PrintEventStatisticsDataModel | null
};

const PrintInfoStatistics = ({ PrintInfoStatisticsData }: PrintInfoStatisticsProps) => {
    const [t] = useTranslation();

    return (
        <div>
            <h4 className={ColorsStyle.weight400 + " Orbitron20"} style={{ 'margin': '0', 'marginBottom': '15px' }}>{t("general.cards.printStatistics")}</h4>
            <ul className="list-group list-group-unbordered mb-3">
                <CustomLi className={'bg-grey'} Title={t("general.printInfo.squareMeter") + " : "} Data={<div>{PrintInfoStatisticsData?.PrintedSQM ?? 0 >= 50 ? roundTo(PrintInfoStatisticsData?.PrintedSQM ?? 0, 0) : roundTo(PrintInfoStatisticsData?.PrintedSQM ?? 0, 3)} m<sup>2</sup></div>} />
            </ul>
        </div>
    );
};

export default PrintInfoStatistics;
