import React from "react";
import { v4 as uuidv4 } from "uuid";
import { useTheme } from '@mui/material/styles';
// eslint-disable-next-line no-unused-vars
import styles from "./Tags.module.css";
import { useTranslation } from "react-i18next";

type TagsControlProps = {
  tags: string[]
  updateTagsList: (newArr: string[]) => void
  onTagsLeave: (isEmpty: boolean) => void,
};

const TagsControl = ({ tags, updateTagsList, onTagsLeave }: TagsControlProps) => {
  const [t] = useTranslation();
  const theme = useTheme();
  const [InputValue, setInputValue] = React.useState('');

  const removeTag = (i: number) => {
    const newTags = [...tags];
    newTags.splice(i, 1);
    tags = newTags;
    updateTagsList(newTags);
  };

  const inputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const val = event.currentTarget.value;
    if (event.key === "Enter" && val) {
      event.preventDefault();
      if (
        tags.find((tag) => tag.toLowerCase() === val.toLowerCase())
      ) {
        return;
      }
      const newTags = [...tags, val];
      tags = newTags;
      updateTagsList(newTags);
      setInputValue('');
    } else if (event.key === "Backspace" && !val) {
      removeTag(tags.length - 1);
    }
  };

  return (
    <div className={styles['input-tag']} style={{
      'backgroundColor': theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white',
      'color': theme.palette.mode === 'dark' ? 'white' : 'black'
    }}>
      <ul className={styles["input-tag__tags"]}>
        {tags &&
          tags.map((tag, i) => (
            <li key={uuidv4()} style={{
              'backgroundColor': theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[200],
              'color': theme.palette.mode === 'dark' ? 'white' : 'black',
              'borderColor': theme.palette.primary.main
            }}>
              {tag}
              <button
                type="button"
                style={{
                  'backgroundColor': theme.palette.primary.main,
                }}
                onClick={() => {
                  removeTag(i);
                }}
              >
                +
              </button>
            </li>
          ))}
        <li className={styles["input-tag__tags__input"]}>
          <input
            id={styles["tag-input-field"]}
            type="text"
            placeholder={t("general.jobInfo.tags")}
            onKeyDown={inputKeyDown}
            onChange={(e) => setInputValue(e.target.value)}
            value={InputValue}
            onBlur={(e) => {
              if (onTagsLeave !== undefined) {
                onTagsLeave(InputValue !== '');
              }
            }}
            style={{
              'backgroundColor': theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white',
              'color': theme.palette.mode === 'dark' ? 'white' : 'black'
            }}
          />
        </li>
      </ul>
    </div>
  );
}

export default TagsControl;
