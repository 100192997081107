import JobUploaderModel from "../../Models/Store/JobUploaderModel";
import ActionTypes from "../Actions/ActionTypes";
// import update from "react-addons-update";

const TabReducer = (state = true, action: any) => {
  switch (action.type) {
    case ActionTypes.TAB_ACTIVE:
      return true;

    case ActionTypes.TAB_INACTIVE:
      return false;

    default:
      return state;
  }
};

const PDMAuthReducer = (state = false, action: any) => {
  switch (action.type) {
    case ActionTypes.AUTHORIZE_PDM:
      return true;

    case ActionTypes.UNAUTHORIZE_PDM:
      return false;

    default:
      return state;
  }
};

const JobUploaderReducer = (state: JobUploaderModel[] = [], action: any) => {
  switch (action.type) {
    case ActionTypes.ADD_JOB:
      state.push(action.payload);
      return state;

    case ActionTypes.UPDATE_JOB:
      const indx1 = state.findIndex(x => x.fileGuid === action.payload.fileGuid);
      if (indx1 !== -1) state[indx1] = action.payload;
      return [...state];

    case ActionTypes.REMOVE_JOB:
      const indx2 = state.findIndex(x => x.fileGuid === action.payload);
      if (indx2 !== -1) state.splice(indx2, 1);
      return [...state];

    case ActionTypes.ADD_JOB_COUNTER:
      const indx3 = state.findIndex(x => x.fileGuid === action.payload);
      if (indx3 !== -1) state[indx3].counter++;
      return [...state];

    case ActionTypes.SET_BEGINING_OF_THE_CHUNK_TO_JOB:
      const indx4 = state.findIndex(x => x.fileGuid === action.payload.fileGuid);
      if (indx4 !== -1) state[indx4].beginingOfTheChunk = action.payload.newBeginingOfTheChunk;
      return [...state];

    case ActionTypes.SET_END_OF_THE_CHUNK_TO_JOB:
      const indx5 = state.findIndex(x => x.fileGuid === action.payload.fileGuid);
      if (indx5 !== -1) state[indx5].endOfTheChunk = action.payload.newEndOfTheChunk;
      return [...state];

    case ActionTypes.SET_PROGRESS_TO_JOB:
      const indx6 = state.findIndex(x => x.fileGuid === action.payload.fileGuid);
      if (indx6 !== -1) state[indx6].progress = action.payload.progress;
      return [...state];

    case ActionTypes.START_PROCESS:
      const indx7 = state.findIndex(x => x.fileGuid === action.payload);
      if (indx7 !== -1) state[indx7].isWorking = true;
      return [...state];

    case ActionTypes.SET_IS_FIRST_TO_JOB:
      const indx8 = state.findIndex(x => x.fileGuid === action.payload);
      if (indx8 !== -1) state[indx8].isFirst = false;
      return [...state];

    case ActionTypes.CLEAR_JOBS:
      return [];

    default:
      return state;
  }
};

export { TabReducer, PDMAuthReducer, JobUploaderReducer };
