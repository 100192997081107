import * as Yup from "yup";

const validation = Yup.object({
  colorCode: Yup.number()
    .min(100, "CMiX number must be 3 digits")
    .max(999, "CMiX number must be 3 digits")
    .integer("Must be full numbers")
    .typeError('Input is not a number')
    .required("Required")
});

export default validation;