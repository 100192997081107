import React, { useState, useEffect } from "react";
import { JobManagerSummery, Storage, jobManagerLineChart } from "../../../modules/JobManager/JobManagerImports";
import { AllJobsList } from "../../../components/ThemeElements/ThemeElementsExport";
import signalRBuilder from "../../../Api/Socket/signalRBuilder";
import signalREventListenerDictionary from "../../../Models/SignalR/signalREventListenerDictionary";
import { SignalRDataModel } from "../../../Models/SignalR/SignalRDataModel";
import { chartDataModel } from "../../../Models/Ui/JobManager/chartDataModel";
import { Grid, Card, CardContent } from '@mui/material';
import { useTranslation } from "react-i18next";
import SocketEmitWrapper from "../../../services/SocketEmitWrapper/SocketEmitWrapper";
import SignalRHubEnum from "../../../Models/SignalR/SignalRHubEnum";
import HttpRequestWithForbiddenCheck from "../../../services/HttpRequestWithForbiddenCheck/HttpRequestWithForbiddenCheck";
import generateMonthArray from "../../../Helpers/generateMonthArray";
import convertBytes from "../../../Helpers/convertBytes";
import { JobDataModel } from "../../../Models/Api/Job/JobDataModel";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../../Store/Store";
import { pdmAuthorize, pdmUnauthorize } from "../../../Store/Actions/Actions";
import { AuthorizationService, JobManagerService, UserService } from "../../../Api/Rest/PDMServer/ApiServices/PDMApiServicesImports";
import Icons from '../../../Imports/MUI/Icons/IconsImports';
import { MainButton, PermissionsWrapper } from '../../../components/General/GeneralExport';
import PermissionsEnum from "../../../Models/Permissions/PermissionsEnum";

const JobManager = () => {
  const [t] = useTranslation();

  const [printedJobs, setPrintedJobs] = useState<chartDataModel>();
  const [countNumberJobs, setCountNumberJobs] = useState<string>('');

  const [freeSpace, setFreeSpace] = useState<string>("");
  const [usedSpace, setUsedSpace] = useState<string>("");
  const [totalSpace, setTotalSpace] = useState<string>("");
  const [freeSpaceNumber, setFreeSpaceNumber] = useState<string>("");
  const [freeSpaceSizeString, setFreeSpaceSizeString] = useState<string>("");

  const [Jobs, setJobs] = React.useState<Array<JobDataModel>>([]);

  const dispatch = useDispatch();
  const pdmAuthState = useSelector((state: RootState) => state.PdmAuth);
  const history = useHistory();
  const [enterGroup, setEnterGroup] = useState(false);

  const [PermissionsList, setPermissionsList] = useState<string[] | null>(null);
  const userService = UserService.getInstance();

  useEffect(() => {
    if (pdmAuthState) {
      let labels = [
        t("general.months.jan"),
        t("general.months.feb"),
        t("general.months.mar"),
        t("general.months.apr"),
        t("general.months.may"),
        t("general.months.jun"),
        t("general.months.jul"),
        t("general.months.aug"),
        t("general.months.sep"),
        t("general.months.oct"),
        t("general.months.nov"),
        t("general.months.dec"),
      ];

      setPrintedJobs((prev: any) => ({ ...prev, labels: labels }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t])

  useEffect(() => {
    asyncInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    // When leaving the page the return will emit and remove user from group
    return () => {
      if (enterGroup) leaveGroup()
    };
  }, [enterGroup])

  const asyncInit = async () => {
    const pdmAuthorized = await AuthorizationService.getInstance().checkApi();

    if (pdmAuthorized.status === 200) {
      if (!pdmAuthorized.data) {
        if (pdmAuthState !== pdmAuthorized.data) {
          dispatch(pdmUnauthorize());
        }
        history.push('/AuthorizePDM');
      }
      if (pdmAuthState !== pdmAuthorized.data) {
        dispatch(pdmAuthorize());
      }
      initTopJobs();
      initSpaceUsing();
      initChart();
      initQTY();
      signalRInit();
      let labels = [
        t("general.months.jan"),
        t("general.months.feb"),
        t("general.months.mar"),
        t("general.months.apr"),
        t("general.months.may"),
        t("general.months.jun"),
        t("general.months.jul"),
        t("general.months.aug"),
        t("general.months.sep"),
        t("general.months.oct"),
        t("general.months.nov"),
        t("general.months.dec"),
      ];
      let tmp: chartDataModel = jobManagerLineChart;
      tmp.labels = labels;
      setPrintedJobs(tmp);
      setEnterGroup(true);
    }

    const [permissionsResp, permissionsErr] = await HttpRequestWithForbiddenCheck(userService.getUserPermissions(), 'Get User Permissions', 'Error When Getting User Permissions');
    if (!permissionsErr && permissionsResp !== null) {
      setPermissionsList(permissionsResp);
    }
  };

  const initTopJobs = async () => {
    const [jobResp, jobErr] = await HttpRequestWithForbiddenCheck(JobManagerService.getInstance().getTopJobs(), 'getTopJobs', 'Error When Getting The List Of Top Jobs');
    if (!jobErr && jobResp !== null) {
      setJobs(jobResp);
    }
  };

  const initQTY = async () => {
    const [JobHistory, error1] = await HttpRequestWithForbiddenCheck(JobManagerService.getInstance().getJobQTY(), 'getJobQTY', 'Error When Getting The Jobs Quantity');
    if (!error1 && JobHistory !== null) {
      setCountNumberJobs(JobHistory);
    }
  };

  const initChart = async () => {
    const [JobHistory, error1] = await HttpRequestWithForbiddenCheck(JobManagerService.getInstance().getJobByMonth(), 'getJobByMonth', 'Error When Getting The Job By Month');
    if (!error1 && JobHistory !== null) {
      let arr = generateMonthArray(JobHistory);
      onJobsPerMonth(arr);
    }
  };

  const initSpaceUsing = async () => {
    const [data, error] = await HttpRequestWithForbiddenCheck(JobManagerService.getInstance().GetSpaceUsing(), 'GetSpaceUsing', 'Error When Getting The Space Using');
    if (!error && data !== null) {
      const free = convertBytes(data.AvailableFreeSpace);
      if (free !== "n/a") {
        setFreeSpace(free.ByteSize + " " + free.Size);
      }
      const used = convertBytes(data.UsedSpace);
      if (used !== "n/a") {
        setUsedSpace(used.ByteSize + " " + used.Size);
      }
      const total = convertBytes(data.TotalSize);
      if (total !== "n/a") {
        setTotalSpace(total.ByteSize + " " + total.Size);
      }
    }
  };

  const leaveGroup = async () => {
    const b = await signalRBuilder.getInstance(SignalRHubEnum.JOB_MANAGER_HUB);
    // sign to job manager page group
    await SocketEmitWrapper(b.newConnection.send(signalREventListenerDictionary.LEAVE_JOB_MANAGER_GROUP));
  };

  const signalRInit = async () => {
    const b = await signalRBuilder.getInstance(SignalRHubEnum.JOB_MANAGER_HUB);
    // sign to job manager page group
    await SocketEmitWrapper(b.newConnection.send(signalREventListenerDictionary.JOIN_JOB_MANAGER_GROUP));

    b.addEventListener(
      signalREventListenerDictionary.GET_QTY,
      (signalRModel: SignalRDataModel) => {
        let myObject = signalRModel.Data;
        setCountNumberJobs(String(myObject));
      }
    );

    b.addEventListener(
      signalREventListenerDictionary.GET_JOBS_BY_MONTH,
      (signalRModel: SignalRDataModel) => {
        let myObject = signalRModel.Data;
        let arr = generateMonthArray(myObject);
        onJobsPerMonth(arr);
      }
    );

    b.addEventListener(
      signalREventListenerDictionary.GET_DRIVER_INFO,
      (signalRModel: SignalRDataModel) => {
        let myObject = signalRModel.Data;
        const free = convertBytes(myObject.availableFreeSpace)
        const used = convertBytes(myObject.usedSpace)
        const total = convertBytes(myObject.totalSize)
        if (free !== "n/a") {
          setFreeSpaceNumber(free.ByteSize);
          setFreeSpaceSizeString(free.Size);
          setFreeSpace(free.ByteSize + " " + free.Size);
        }
        if (used !== "n/a") {
          setUsedSpace(used.ByteSize + " " + used.Size);
        }
        if (total !== "n/a") {
          setTotalSpace(total.ByteSize + " " + total.Size);
        }
      }
    );

    b.addEventListener(
      signalREventListenerDictionary.GET_LAST_FIVE_JOBS,
      (signalRModel: SignalRDataModel) => {
        const data = JSON.parse(signalRModel.Data);
        setJobs(data)
      }
    );
  };

  const onJobsPerMonth = (jobsData: any) => {
    setPrintedJobs((prev: any) => ({
      ...prev, datasets:
        [
          {
            data: jobsData,
            fill: true,
            borderColor: "#742774",
            tension: 0.1
          }
        ]
    }));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={8}>
        <Card className="noBackgroundImage">
          <CardContent>
            <JobManagerSummery printedJobs={printedJobs} countNumberJobs={countNumberJobs} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={6} lg={4}>
        <Card style={{ 'height': '100%' }} className="noBackgroundImage">
          <CardContent style={{ 'height': '100%' }}>
            <Storage freeSpace={freeSpace} usedSpace={usedSpace} totalSpace={totalSpace} freeSpaceNumber={freeSpaceNumber} freeSpaceSizeString={freeSpaceSizeString} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={5} md={6}>
        <h4 className="Orbitron20 p-3">{t("general.cards.lastFiveJobs")}</h4>
      </Grid>

      <Grid item xs={7} md={6}>
        <ul className="nav nav-pills ml-auto p-2 float-right">
          <MainButton startIcon={<Icons.ListIcon />} onClick={() => history.push('/JobManager/AllJobsList')}>
            {t("general.buttons.allJobs")}
          </MainButton>

          <PermissionsWrapper PermissionsList={PermissionsList} Permission={PermissionsEnum.CREATE_JOB_MANAGER_UI}
            PassedPermissionTemplate={
              <MainButton style={{ 'marginLeft': '15px' }} startIcon={<Icons.AddIcon />} onClick={() => history.push('/JobManager/AddJob')}>
                {t("general.buttons.addJob")}
              </MainButton>
            }
          />

        </ul>
      </Grid>

      <Grid item xs={12} style={{ 'paddingTop': '0' }}>
        <AllJobsList tableHeight={"350px"} Jobs={Jobs} />
      </Grid>
    </Grid>
  );
};

export default JobManager;
