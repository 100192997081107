import JobUploaderModel from "../../Models/Store/JobUploaderModel";
import ActionTypes from "./ActionTypes";

export const tabActive = () => {
  return { type: ActionTypes.TAB_ACTIVE };
};

export const tabInactive = () => {
  return { type: ActionTypes.TAB_INACTIVE };
};

export const pdmAuthorize = () => {
  return { type: ActionTypes.AUTHORIZE_PDM };
};

export const pdmUnauthorize = () => {
  return { type: ActionTypes.UNAUTHORIZE_PDM };
};

export const addJob = (model: JobUploaderModel) => {
  return { type: ActionTypes.ADD_JOB, payload: model };
};

export const updateJob = (model: JobUploaderModel) => {
  return { type: ActionTypes.UPDATE_JOB, payload: model };
};

export const removeJob = (fileGuid: string) => {
  return { type: ActionTypes.REMOVE_JOB, payload: fileGuid };
};

export const clearJobs = () => {
  return { type: ActionTypes.CLEAR_JOBS };
};
export const addToJobCounter = (fileGuid: string) => {
  return { type: ActionTypes.ADD_JOB_COUNTER, payload: fileGuid };
};

export const setBeginingOfTheChunkToJob = (fileGuid: string, newBeginingOfTheChunk: number) => {
  return { type: ActionTypes.SET_BEGINING_OF_THE_CHUNK_TO_JOB, payload: { fileGuid, newBeginingOfTheChunk } };
};

export const setEndOfTheChunkToJob = (fileGuid: string, newEndOfTheChunk: number) => {
  return { type: ActionTypes.SET_END_OF_THE_CHUNK_TO_JOB, payload: { fileGuid, newEndOfTheChunk } };
};

export const setProgressToJob = (fileGuid: string, progress: number) => {
  return { type: ActionTypes.SET_PROGRESS_TO_JOB, payload: { fileGuid, progress } };
};

export const startProcess = (fileGuid: string) => {
  return { type: ActionTypes.START_PROCESS, payload: fileGuid };
};


export const setIsFirstToJob = (fileGuid: string) => {
  return { type: ActionTypes.SET_IS_FIRST_TO_JOB, payload: fileGuid };
};

// export const sampleActionWithPayload = (value: string) => {
//     return {type: ActionTypes.SAMPLE_ACTION, payload: value};
// };
