class rootElement {
  private static root: HTMLElement;

  static initRoot(element: HTMLElement): void{
    rootElement.root = element;
  }

  static clearClasses(): void{
    rootElement.root.className = "";
  }

  static setClasses(classes: string): void{
    rootElement.root.className = classes;
  }
}

export default rootElement;
