import { AddJobFormModel } from "../../Models/Forms/AddJob/AddJobFormModel";
//import { v4 as uuidv4 } from 'uuid';
import HttpRequestWithForbiddenCheck from "../HttpRequestWithForbiddenCheck/HttpRequestWithForbiddenCheck";
import { JobManagerService } from "../../Api/Rest/PDMServer/ApiServices/PDMApiServicesImports";
import store from "../../Store/Store";
import JobUploaderModel from "../../Models/Store/JobUploaderModel";
import { addToJobCounter, startProcess, removeJob, setBeginingOfTheChunkToJob, setEndOfTheChunkToJob, setProgressToJob, setIsFirstToJob } from "../../Store/Actions/Actions";

// supposed to be a react redux background task, when detecting there's a job in the redux, start uploading it,
// possible to async the uploads in the future, and continue from same chunk when moving pages -Dor
class JobUploader {
  static _instance: JobUploader;
  readonly chunkSize = 1048576 * 3;//its 3MB, increase the number measure in mb
  static initSubscribe = false;
  jobBufferDict: { [id: string]: File; } = {};
  jobsRedux: JobUploaderModel[] = [];
  prevPath: string = '';

  init() {
    if (this !== undefined) {
      this.jobBufferDict = {};
      this.jobsRedux = store.getState().JobUploader;

      if (!JobUploader.initSubscribe) {
        store.subscribe(this.init);
        JobUploader.initSubscribe = true;
      }
    }
  }

  async BackgroundUpload(jobGuid: string) {
    let elem = this.jobsRedux.find(x => x.fileGuid === jobGuid);

    if (!elem) {
      return;
    }

    let {
      counter,
      beginingOfTheChunk,
      endOfTheChunk,
      progress,
      Tags,
      JobZipFileName,
      Comments,
      ProjectName,
      fileGuid,
      isFirst
    } = elem;

    const job: AddJobFormModel = {
      Tags: Tags,
      JobZipFileName: JobZipFileName,
      Comments: Comments,
      ProjectName: ProjectName
    };

    const zipJob = this.jobBufferDict[jobGuid];
    store.dispatch(startProcess(jobGuid));
    const _totalCount = zipJob.size % this.chunkSize === 0 ? zipJob.size / this.chunkSize : Math.floor(zipJob.size / this.chunkSize) + 1; // Total count of chunks will have been upload to finish the file
    const fileName = zipJob.name.replace(/\.[^/.]+$/, "");
    let first = isFirst;

    while (zipJob.size > 0) {
      counter++;
      store.dispatch(addToJobCounter(jobGuid));

      if (counter <= _totalCount && zipJob) {
        var chunk = zipJob.slice(beginingOfTheChunk, endOfTheChunk);

        try {
          const [jobResp, jobErr] =
            await HttpRequestWithForbiddenCheck(JobManagerService.getInstance().uploadChunks(counter, fileGuid, fileName, chunk, first), 'uploadChunks', 'Error When Uploading Chunks');

          if (!jobErr && jobResp !== null) {
            if (first) {
              store.dispatch(setIsFirstToJob(jobGuid));
              first = false;
            }

            beginingOfTheChunk = endOfTheChunk;
            store.dispatch(setBeginingOfTheChunkToJob(jobGuid, beginingOfTheChunk));
            endOfTheChunk = endOfTheChunk + this.chunkSize;
            store.dispatch(setEndOfTheChunkToJob(jobGuid, beginingOfTheChunk));

            if (counter === _totalCount) {
              const [jobResp, jobErr] =
                await HttpRequestWithForbiddenCheck(JobManagerService.getInstance().uploadComplete(job, fileGuid, fileName), 'uploadComplete', 'Error When Adding job');

              if (!jobErr && jobResp !== null && zipJob) {
                progress = 100;
                store.dispatch(removeJob(jobGuid));
                delete this.jobBufferDict[jobGuid];
                break;
              }
              else {
                store.dispatch(removeJob(jobGuid));
                delete this.jobBufferDict[jobGuid];
                break;
              }
            } else {
              var percentage = (counter / _totalCount) * 95;
              progress = percentage;
              store.dispatch(setProgressToJob(jobGuid, progress));
            }
          }
        } catch (error) {
          console.log('error', error)
        }
      }
    }
  }

  initUpload() {
    this.jobsRedux = store.getState().JobUploader;
    console.log(this.jobsRedux);

    this.jobsRedux.forEach(element => {
      if (!element.isWorking) {
        this.BackgroundUpload(element.fileGuid);
      }
    });
  }

  addJob(jobGuid: string, file: File) {
    if (this.jobBufferDict !== undefined) {
      this.jobBufferDict[jobGuid] = file;
    }
  }

  clearJobDict() {
    this.jobBufferDict = {};
    // store.dispatch(clearJobs());
  }

  static getInstance() {
    if (JobUploader._instance === undefined) {
      JobUploader._instance = new JobUploader();
      JobUploader._instance.init();
    }
    return JobUploader._instance;
  }
}

export default JobUploader;
