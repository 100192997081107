//import { useCallback } from 'react';
import { JobDataVersionDataModel } from "../../../../Models/Api/Job/JobDataVersionDataModel";
import { useEffect, useState } from "react";
import { JobManagerService, AuthorizationService } from "../../../../Api/Rest/PDMServer/ApiServices/PDMApiServicesImports";
import { useParams } from "react-router-dom"
import HttpRequestWithForbiddenCheck from "../../../../services/HttpRequestWithForbiddenCheck/HttpRequestWithForbiddenCheck";
import { JobDataVersionsTable } from '../../../../modules/JobManager/JobManagerImports';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../Store/Store";
import { pdmAuthorize, pdmUnauthorize } from '../../../../Store/Actions/Actions';
import { useHistory } from 'react-router-dom';
import { MainButton } from "../../../../components/General/GeneralExport";
import Icons from '../../../../Imports/MUI/Icons/IconsImports';
import { useTranslation } from "react-i18next";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import signalRBuilder from "../../../../Api/Socket/signalRBuilder";
import SocketEmitWrapper from "../../../../services/SocketEmitWrapper/SocketEmitWrapper";
import signalREventListenerDictionary from "../../../../Models/SignalR/signalREventListenerDictionary";
import { SignalRDataModel } from "../../../../Models/SignalR/SignalRDataModel";
import SignalRHubEnum from "../../../../Models/SignalR/SignalRHubEnum";

type JobDataVersionsParamTypes = {
  jobId: string
}

function JobDataVersions() {
  const pdmAuthState = useSelector((state: RootState) => state.PdmAuth);
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<JobDataVersionsParamTypes>();
  const jobId = params.jobId;
  const [JobDataVersions, setJobDataVersions] = useState<Array<JobDataVersionDataModel>>([]);
  const [t] = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);
  const [JobDataVersionId, setJobDataVersionId] = useState('');

  // const initSignalR = useCallback(async () => {
  //   const b = await signalRBuilder.getInstance(SignalRHubEnum.SINGLE_JOB_HUB);
  //   // sign to single job page group

  //   await SocketEmitWrapper(b.newConnection.send(signalREventListenerDictionary.JOIN_SINGLE_JOB_GROUP, jobId));

  //   b.addEventListener(
  //     signalREventListenerDictionary.GET_SINGLE_JOB_DATA_HISTORY_LIST,
  //     (signalRModel: SignalRDataModel) => {
  //       const data = JSON.parse(signalRModel.Data);
  //       setJobDataVersions(data);
  //     }
  //   );
  // }, [jobId]);

  // const initVersions = useCallback(async () => {
  //   const [verResp, verErr] =
  //     await HttpRequestWithForbiddenCheck(JobManagerService.getInstance().getJobDataVersions(jobId),
  //       'getJobDataVersions', 'Error When Getting Job Dta Versions');

  //   if (!verErr && verResp !== null) {
  //     setJobDataVersions(verResp);
  //   }
  // }, [jobId]);

  // const initAsync = useCallback(async () => {
  //   const pdmAuthorized = await AuthorizationService.getInstance().checkApi();

  //   if (pdmAuthorized.status === 200) {
  //     if (!pdmAuthorized.data) {
  //       if (pdmAuthState !== pdmAuthorized.data) {
  //         dispatch(pdmUnauthorize());
  //       }
  //       history.push('/AuthorizePDM');
  //     }
  //     if (pdmAuthState !== pdmAuthorized.data) {
  //       dispatch(pdmAuthorize());
  //     }
  //   }

  //   initSignalR();
  //   initVersions();
  // }, [pdmAuthState, dispatch, history, initSignalR, initVersions]); // Add dependencies here

  // useEffect(() => {
  //   initAsync();
  // }, [initAsync]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(() => {
  //   const initSignalR = async () => {
  //     const b = await signalRBuilder.getInstance(SignalRHubEnum.SINGLE_JOB_HUB);
  //     // sign to single job page group

  //     await SocketEmitWrapper(b.newConnection.send(signalREventListenerDictionary.JOIN_SINGLE_JOB_GROUP, jobId));

  //     b.addEventListener(
  //       signalREventListenerDictionary.GET_SINGLE_JOB_DATA_HISTORY_LIST,
  //       (signalRModel: SignalRDataModel) => {
  //         const data = JSON.parse(signalRModel.Data);
  //         setJobDataVersions(data);
  //       }
  //     );
  //   }

  //   const initVersions = async () => {
  //     const [verResp, verErr] =
  //       await HttpRequestWithForbiddenCheck(JobManagerService.getInstance().getJobDataVersions(jobId),
  //         'getJobDataVersions', 'Error When Getting Job Dta Versions');

  //     if (!verErr && verResp !== null) {
  //       setJobDataVersions(verResp);
  //     }
  //   };

  //   const initAsync = async () => {
  //     const pdmAuthorized = await AuthorizationService.getInstance().checkApi();

  //     if (pdmAuthorized.status === 200) {
  //       if (!pdmAuthorized.data) {
  //         if (pdmAuthState !== pdmAuthorized.data) {
  //           dispatch(pdmUnauthorize());
  //         }
  //         history.push('/AuthorizePDM');
  //       }

  //       if (pdmAuthState !== pdmAuthorized.data) {
  //         dispatch(pdmAuthorize());
  //       }
  //     }

  //     initSignalR();
  //     initVersions();
  //   };

  //   initAsync();
  // }, [dispatch, history, pdmAuthState, jobId]);

  // const initSignalR = useCallback(async () => {
  //   const b = await signalRBuilder.getInstance(SignalRHubEnum.SINGLE_JOB_HUB);
  //   // sign to single job page group

  //   await SocketEmitWrapper(b.newConnection.send(signalREventListenerDictionary.JOIN_SINGLE_JOB_GROUP, jobId));

  //   b.addEventListener(
  //     signalREventListenerDictionary.GET_SINGLE_JOB_DATA_HISTORY_LIST,
  //     (signalRModel: SignalRDataModel) => {
  //       const data = JSON.parse(signalRModel.Data);
  //       setJobDataVersions(data);
  //     }
  //   );
  // }, [jobId]);

  const initSignalR = async () => {
    const b = await signalRBuilder.getInstance(SignalRHubEnum.SINGLE_JOB_HUB);
    // sign to single job page group

    await SocketEmitWrapper(b.newConnection.send(signalREventListenerDictionary.JOIN_SINGLE_JOB_GROUP, jobId));

    b.addEventListener(
      signalREventListenerDictionary.GET_SINGLE_JOB_DATA_HISTORY_LIST,
      (signalRModel: SignalRDataModel) => {
        const data = JSON.parse(signalRModel.Data);
        setJobDataVersions(data);
      }
    );
  };

  const initVersions = async () => {
    const [verResp, verErr] =
      await HttpRequestWithForbiddenCheck(JobManagerService.getInstance().getJobDataVersions(jobId),
        'getJobDataVersions', 'Error When Getting Job Dta Versions');

    if (!verErr && verResp !== null) {
      setJobDataVersions(verResp);
    }
  };

  const initAsync = async () => {
    const pdmAuthorized = await AuthorizationService.getInstance().checkApi();

    if (pdmAuthorized.status === 200) {
      if (!pdmAuthorized.data) {
        if (pdmAuthState !== pdmAuthorized.data) {
          dispatch(pdmUnauthorize());
        }
        history.push('/AuthorizePDM');
      }
      if (pdmAuthState !== pdmAuthorized.data) {
        dispatch(pdmAuthorize());
      }
    }

    initSignalR();
    initVersions();
  };

  useEffect(() => {
    initAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDialogCancel = () => {
    setOpenDialog(false);
  };

  const handleDialogConfirm = async () => {
    setOpenDialog(false);

    await restoreJobData(JobDataVersionId)
  };

  const onRestoreJobData = async (versionId: string) => {
    setJobDataVersionId(versionId);
    setOpenDialog(true);
  };

  const restoreJobData = async (versionId: string) => {
    const [res, error1] =
      await HttpRequestWithForbiddenCheck(JobManagerService.getInstance().restoreJobData(jobId, versionId),
        'restoreJobData', 'Error When Restoring Job Data');

    if (!error1 && res === true) {
      toast.success("Job Data was restored successfully", {
        style: { "backgroundColor": "#6f42c1" }
      });

      return;
    }

    toast.success("Failed to restore job data", {
      style: { "backgroundColor": "#6f42c1" }
    });
  };

  return (
    <>
      <ul className="nav float-right">
        <MainButton style={{ 'marginLeft': '15px' }}
          onClick={() => history.push(`/JobManager/AllJobsList/${jobId}`)} startIcon={<Icons.CloseIcon />}>
          {t("general.buttons.close")}
        </MainButton>
      </ul>
      <br />
      <br />
      <>
        <JobDataVersionsTable dataVersions={JobDataVersions}
          restoreJobData={onRestoreJobData} />
      </>

      {openDialog &&
        <Dialog open={openDialog} onClose={handleDialogCancel}
          fullWidth>
          <DialogTitle>Confirm Restore History</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to restore history?
              {/* {t("general.labels.allRightsReserved")} */}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MainButton onClick={handleDialogConfirm} lightVariant="text">
              Confirm
            </MainButton>
            <MainButton onClick={handleDialogCancel} lightVariant="text">
              Cancel
            </MainButton>
          </DialogActions>
        </Dialog>
      }
    </>
  );
}

export default JobDataVersions;