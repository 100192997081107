import axios from "axios";
import { UserDataModel } from "../../../../../Models/Api/UserDataModel";
import PDMServerLocalStorageService from "../../../../../services/LocalStorage/PDMServer/PDMServerService";
import PDMServerSessionStorageService from "../../../../../services/SessionStorage/PDMServer/PDMServerService";

const userInfo = async () => {
  const URL = await PDMServerLocalStorageService.getPDMServerUrl();
  const AuthorizationHeader = await PDMServerSessionStorageService.generateAuthorizationHeader();

  return await axios.post<UserDataModel>(
    `${URL}/connect/userinfo`,
    {},
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: AuthorizationHeader.Authorization,
      },
    }
  );
};

export default userInfo;
