import React from 'react'
import { Grid, useTheme } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { UserEventDataModel } from '../../../Models/Api/Events/UserEventDataModel';

type HistoryElementUserEventProps = {
    userEvent: UserEventDataModel
};

const HistoryElementUserEvent = ({ userEvent }: HistoryElementUserEventProps) => {
    const theme = useTheme();
    return (
        <div key={uuidv4()}>
            <div className="time-label" style={{ 'marginBottom': '10px' }}>
                <span className="history-date bg-primary font-weight-light">
                    {userEvent.CreateEventDateTime && moment.utc(userEvent.CreateEventDateTime).local().format('DD/MM/YYYY')}
                </span>
            </div>
            <div>
                <i className="fas fa-sign-in-alt history-icon" style={{ 'color': 'black' }}></i>
                <div className="timeline-item mr-1 history-card" style={{
                    'backgroundColor': theme.palette.mode === 'dark' ? '#121212' : 'white',
                    'color': theme.palette.mode === 'dark' ? 'white' : 'black'
                }}>
                    <Grid container className='history-header'>
                        <Grid item xs={6}>
                            <h3 className="timeline-header history-title">
                                {userEvent?.Title}
                            </h3>
                        </Grid>
                        <Grid item xs={6}>
                            <span className="time history-time">
                                <i className="far fa-clock"></i>{" "}
                                {userEvent.CreateEventDateTime && moment.utc(userEvent.CreateEventDateTime).local().format('H:mm')}
                            </span>
                        </Grid>
                    </Grid>
                    <div className="timeline-body history-body">
                        <div>
                            {userEvent.EventDescription}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HistoryElementUserEvent;