enum PermissionsEnum {
    MANAGE_SETTINGS = "ManageSettings",
    PUBLISH_CONTENT = "PublishContent",
    EDIT_CONTENT = "EditContent",
    DELETE_CONTENT = "DeleteContent",
    PREVIEW_CONTENT = "PreviewContent",
    CLONE_CONTENT = "CloneContent",
    ACCESS_CONTENT_API = "AccessContentApi",
    LIST_CONTENT = "ListContent",
    VIEW_CONTENT_TYPES = "ViewContentTypes",
    EDIT_CONTENT_TYPES = "EditContentTypes",
    MANAGE_MEDIA_FOLDER = "ManageMediaFolder",
    MANAGE_MEDIA_PROFILES = "ManageMediaProfiles",
    VIEW_MEDIA_OPTIONS = "ViewMediaOptions",
    MANAGE_MONGO_DB_CREDENTIALS = "ManageMongoDBCredentials",
    IMPORT_PRINTER_DB = "ImportPrinterDB",
    READ_CMIX = "Read_Cmix",
    READ_DASHBOARD = "Read_Dashboard",
    CREATE_DASHBOARD = "Create_Dashboard",
    CREATE_JOB_MANAGER_UI = "Create_JobManager_UI",
    READ_JOB_MANAGER_UI = "Read_JobManager_UI",
    UPDATE_JOB_MANAGER_UI = "Update_JobManager_UI",
    DELETE_JOB_MANAGER_UI = "Delete_JobManager_UI",
    ACCESS_ADMIN_PANEL = "AccessAdminPanel",
    MANAGE_ADMIN_SETTINGS = "ManageAdminSettings",
    MANAGE_ADMIN_MENU = "ManageAdminMenu",
    MANAGE_BACKGROUND_TASKS = "ManageBackgroundTasks",
    MANAGE_USERS = "ManageUsers",
    IMPORT = "Import",
    EXPORT = "Export",
    MANAGE_REMOTE_INSTANCES = "ManageRemoteInstances",
    MANAGE_REMOTE_CLIENTS = "ManageRemoteClients",
    EXPORT_REMOTE_INSTANCES = "ExportRemoteInstances",
    MANAGE_APPLICATIONS = "ManageApplications",
    MANAGE_SCOPES = "ManageScopes",
    MANAGE_CLIENT_SETTINGS = "ManageClientSettings",
    MANAGE_SERVER_SETTINGS = "ManageServerSettings",
    MANAGE_VALIDATION_SETTINGS = "ManageValidationSettings",
    MANAGE_QUERIES = "ManageQueries",
    MANAGE_ROLES = "ManageRoles",
    SITE_OWNER = "SiteOwner",
    APPLY_THEME = "ApplyTheme",
    PDM_SETTINGS = "PDMSettings",
    CHECK_FEATURES_API_ACCESS = "CheckFeaturesApiAccess",
    AUTHORIZE_PDM = "AuthorizePdm",
    CREATE_PRINTER_TICKET = "Create_PrinterTicket",
    READ_PRINTER_TICKET = "Read_PrinterTicket",
    UPDATE_PRINTER_TICKET = "Update_PrinterTicket",
    DELETE_PRINTER_TICKET = "Delete_PrinterTicket",
    CREATE_USER = "Create_User",
    READ_USER = "Read_User",
    UPDATE_USER = "Update_User",
    DELETE_USER = "Delete_User",
    READ_PERMISSIONS = "Read_Permissions"
};

export default PermissionsEnum;