import { Button, Card, CardContent, TextField, Typography, Alert } from '@mui/material';
import React, { useState } from 'react'
import { useFormik } from "formik";
import AuthorizePDMValidationSchema from '../../../Validators/Private/AuthorizePDM/AuthorizePDMValidationSchema';
import { useTranslation } from "react-i18next";
import AwaitHandling from '../../../services/AwaitHandling/AwaitHandling';
//import { useHistory } from 'react-router-dom';
import './AuthorizePDM.css';
import { AuthorizationService } from '../../../Api/Rest/PDMServer/ApiServices/PDMApiServicesImports';
import { useAuth } from 'oidc-react';

function AuthorizePDM() {
  const auth = useAuth();
  const [t] = useTranslation();
  //const history = useHistory();
  const [alert, setAlert] = useState(false);

  React.useEffect(() => {
    document.body.classList.add('body-auth');
    document.body.style.backgroundImage = "url('/img/Patterns/Pattern-01.png')";
    return () => {
      document.body.classList.remove('body-auth');
    };
  }, [])

  const formik = useFormik({
    initialValues: {
      companyId: '',
      authorizationId: '',
    },
    validationSchema: AuthorizePDMValidationSchema,
    onSubmit: (values) => {
      setAlert(false);
      submitF(values.companyId, values.authorizationId);
    },
  });

  const submitF = async (companyId: string, authorizationId: string) => {
    const [, err] =
      await AwaitHandling(AuthorizationService.getInstance().AuthorizeLogin(companyId, authorizationId));

    if (!err) {
      try {
        await auth.signOutRedirect();
        // auth.userManager.signinSilent()
        //TempUserContext.userManager.startSilentRenew();
      }
      catch (error) {
        console.error('Failed to signOutRedirect', error);
      }

      //history.push('/');
    }
    else {
      setAlert(true);
      console.error(err);
    }
  };

  return (
    <div className="center">
      <Card className="noBackgroundImage" style={{
        'position': 'absolute',
        'top': '50%',
        'left': '50%',
        'transform': 'translate(-50%, -50%)',
        'width': '400px'
      }}>
        <CardContent>
          <div className="custom-text">
            {t('general.labels.pdmShort')}
            <span className="custom-text text-stroke">
              {' '}{t('general.labels.authorize')}
            </span>
          </div>
          <Typography className='text-center' variant="body2">
            {t('general.labels.authorizePDMBody')}
          </Typography>
          <br />

          {alert ?
            <Alert sx={{ mb: 2 }} severity="error">Company Id or Authorization Id is incorrect.</Alert> : <></>}

          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              name="CompanyId"
              label={t('general.labels.companyId')}
              value={formik.values.companyId}
              onChange={formik.handleChange('companyId')}
              onKeyDown={() => setAlert(false)}
              error={formik.touched.companyId && Boolean(formik.errors.companyId)}
              helperText={formik.touched.companyId && formik.errors.companyId}
            />
            <br />
            <br />
            <TextField
              fullWidth
              name="AuthorizationId"
              label={t('general.labels.authorizationId')}
              value={formik.values.authorizationId}
              onChange={formik.handleChange('authorizationId')}
              onKeyDown={() => setAlert(false)}
              error={formik.touched.authorizationId && Boolean(formik.errors.authorizationId)}
              helperText={formik.touched.authorizationId && formik.errors.authorizationId}
            />

            <br />
            <br />

            <Button style={{
              'background': 'linear-gradient(330deg, rgba(110,107,156,1) 0%, rgba(138,97,213,1) 50%, rgb(119 90 171) 100%)'
            }} variant="contained" fullWidth type="submit">
              {t('general.labels.submit')}
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default AuthorizePDM;