import localStorageService from "../localStorageService";

function SetSideNavOpenState(amount: boolean) {
    localStorageService.addToLocalStorage('navBar_open', amount.toString());
};

function GetSideNavOpenState() {
    return LocalStorageCheck('navBar_open', true, SetSideNavOpenState);
};

function LocalStorageCheck(key: string, defaultVal: boolean, setFunction: (val: boolean) => void) {
    const errFunc = () => {
        setFunction(defaultVal);
        return defaultVal;
    };

    const res = localStorageService.getFromLocalStorage(key);
    if (res) {
        try {
            const isBool = (res === "true" || res === "false");
            if (!isBool) return errFunc();
            const val = (res === "true");
            return val;
        }
        catch (err) {
            return errFunc();
        }
    }
    else {
        return errFunc();
    }
}

export { SetSideNavOpenState, GetSideNavOpenState };