
import { Card, CardContent, Divider, Grid, Pagination, Typography } from "@mui/material";
import { UserEventDataModel } from "../../../Models/Api/Events/UserEventDataModel";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { HistoryElementUserEvent } from "../../../components/General/GeneralExport";

type ActivityCardProps = {
  Activity: UserEventDataModel[]
};

function ActivityCard({ Activity }: ActivityCardProps) {
  const [t] = useTranslation();
  const AmountOfElementsInPage = 4;
  const [page, setPage] = useState(1);
  const historyWrapper = useRef<HTMLDivElement>(null);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <Card className="noBackgroundImage">
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">{t("general.cards.activities")}</Typography>
        <Divider style={{ 'marginBottom': '15px' }} />

        {Activity && Activity.length !== 0 &&
          <>
            <div key={uuidv4()} className="timeline timeline-inverse mt-2 ml-2" ref={historyWrapper} style={{ 'margin': '0', 'position': 'relative', 'height': '93%' }}>
              {
                Activity
                  .slice((page - 1) * AmountOfElementsInPage, (page - 1) * AmountOfElementsInPage + AmountOfElementsInPage)
                  .map((userHistory) => {
                    return (
                      <HistoryElementUserEvent userEvent={userHistory} key={uuidv4()} />
                    );
                  })
              }
              <div style={{ 'position': 'absolute', 'bottom': '0', 'width': '100%', 'margin': '0' }}>
                <Grid container>
                  <Grid item xs={6}>
                    <i className="fas fa-clock bg-gray" style={{
                      'borderRadius': '50%', 'fontSize': '16px',
                      'height': '30px',
                      'left': '18px',
                      'lineHeight': '30px',
                      'position': 'absolute',
                      'textAlign': 'center',
                      'top': '0',
                      'width': '30px'
                    }}></i>
                  </Grid>
                  <Grid item xs={6}>
                    <ul className="nav float-right">
                      <Pagination count={Math.round(Activity.length / AmountOfElementsInPage)} color="primary" page={page} onChange={handleChange} />
                    </ul>
                  </Grid>
                </Grid>
              </div>
            </div>
          </>
        }
      </CardContent>
    </Card>
  )
}

export default ActivityCard;
