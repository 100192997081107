export default async function SocketEmitWrapper(request: Promise<any>,counter = 0): Promise<any>{
    try{
        return await request;
    }
    catch (err){
        if(counter === 3) return null;
        counter++;
        await Delay(1000);
        // try again
        return SocketEmitWrapper(request, counter);
    }
}

function Delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
}