import SessionStorageService from "../SessionStorageService";

export enum SessionStorageTableEnum {
    RECYCLE_BIN = 'Table_RecycleBin',
    JOB_MANAGER = 'Table_JobManager',
    REPORT_CENTER = 'Table_ReportCenter_Page',
    SINGLE_PRINTER = 'Table_SinglePrinter',
    USERS_DASHBOARD = 'Table_UsersDashboard',
}

function SetTableStateState(pageType: SessionStorageTableEnum, printerId: string | null = null, page: number) {
    if (SessionStorageTableEnum.SINGLE_PRINTER === pageType && printerId !== null) {
        SessionStorageService.addToSessionStorage(`${printerId}_${pageType}`, page.toString());
        return;
    }
    SessionStorageService.addToSessionStorage(pageType, page.toString());
};

function GetTablesPageState(pageType: SessionStorageTableEnum, printerId: string | null = null) {
    if (SessionStorageTableEnum.SINGLE_PRINTER === pageType && printerId !== null) {
        return SessionStorageCheck(pageType, `${printerId}_${pageType}`, 0, SetTableStateState, printerId);
    }
    return SessionStorageCheck(pageType, pageType, 0, SetTableStateState, null);
};

function SessionStorageCheck(pageType: SessionStorageTableEnum, key: string, defaultVal: number, setFunction: (pageType: SessionStorageTableEnum, printerId: string | null, page: number) => void, printerId: string | null = null) {
    const errFunc = () => {
        setFunction(pageType, printerId, defaultVal);
        return defaultVal;
    };

    const res = SessionStorageService.getFromSessionStorage(key);
    if (res) {
        try {
            const isOk = Number(res);
            if (Number.isNaN(isOk)) return errFunc();
            return isOk;
        }
        catch (err) {
            return errFunc();
        }
    }
    else {
        return errFunc();
    }
}

const TablesSessionStorageService = {
    SetTableStateState,
    GetTablesPageState
};

export default TablesSessionStorageService;
