import { useState, useEffect } from "react";
import { InkUsage, PrintInfoStatistics, PrintStatistics } from "../../../../../modules/ReportCenter/ReportCenterImports";
import { SearchableTable } from "../../../../../modules/SinglePrinter/SinglePrinterImports";
import { MuiCalender } from "../../../../../components/Inputs/InputsExport";
import DatesService from "../../../../../Helpers/DateService";
import dateFormat from "dateformat";
import { useTranslation } from "react-i18next";
import { Grid, Card, CardContent, Divider, Breadcrumbs, Typography } from '@mui/material';
import HttpRequestWithForbiddenCheck from "../../../../../services/HttpRequestWithForbiddenCheck/HttpRequestWithForbiddenCheck";
import { PrinterApi } from "../../../../../Models/Api/General/Printer";
import { PrintEventDataModel } from "../../../../../Models/Api/Events/PrintEventDataModel";
import { PrintEventStatusDataModel } from "../../../../../Models/Api/Events/PrintEventStatusDataModel";
import { InkUsageDataModel } from "../../../../../Models/Api/ReportCenter/InkUsageDataModel";
import { DateRange } from "materialui-daterange-picker";
import moment from "moment";
import signalRBuilder from "../../../../../Api/Socket/signalRBuilder";
import SocketEmitWrapper from "../../../../../services/SocketEmitWrapper/SocketEmitWrapper";
import signalREventListenerDictionary from "../../../../../Models/SignalR/signalREventListenerDictionary";
import SignalRHubEnum from "../../../../../Models/SignalR/SignalRHubEnum";
import { SignalRDataModel } from "../../../../../Models/SignalR/SignalRDataModel";
//import { InkUsageDataModelArrZod } from "../../../../../Models/Zod/ReportCenter/InkUsageDataModelZod";
//import { PrintEventStatusDataModelZod } from "../../../../../Models/Zod/Events/PrintEventStatusDataModelZod";
//import { PrintEventDataModelArrZod } from "../../../../../Models/Zod/Events/PrintEventDataModel";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../../../../Store/Store";
import { pdmAuthorize, pdmUnauthorize } from "../../../../../Store/Actions/Actions";
import { AuthorizationService, PrinterTicketService, SinglePrinterService } from "../../../../../Api/Rest/PDMServer/ApiServices/PDMApiServicesImports";
import FiltersSessionStorageService, { SessionStorageFiltersEnum } from "../../../../../services/SessionStorage/Filters/FiltersSessionStorageService";

const SinglePrinter = () => {
  const [t] = useTranslation();
  const [printedJobs, setPrintedJobs] = useState<PrintEventDataModel[]>([]);
  const [inkUsageData, setInkUsageData] = useState<InkUsageDataModel[]>([]);
  const [printStatisticsData, setPrintStatisticsData] = useState<any>(null);
  const [printStatusData, setPrintStatusData] = useState<PrintEventStatusDataModel | null>(null);
  const [printerTicket, setPrinterTicket] = useState<PrinterApi>({} as PrinterApi);
  const Id = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
  const dispatch = useDispatch();
  const pdmAuthState = useSelector((state: RootState) => state.PdmAuth);
  const history = useHistory();
  const [enterGroup, setEnterGroup] = useState(false);
  const [filter, setFilter] = useState<string>('');

  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: DatesService.getInstance().getStartDateAsDate(),
    endDate: DatesService.getInstance().getEndDateAsDate()
  });

  useEffect(() => {
    if (pdmAuthState) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  useEffect(() => {
    asyncInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // When leaving the page the return will emit and remove user from group
    return () => {
      if (enterGroup) leaveGroup()
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterGroup])


  const leaveGroup = async () => {
    const b = await signalRBuilder.getInstance(SignalRHubEnum.PRINT_EVENT_HUB);

    await SocketEmitWrapper(b.newConnection.send(signalREventListenerDictionary.LEAVE_PRINT_EVENT_GROUP));
  };

  const signalRInit = async () => {
    const b = await signalRBuilder.getInstance(SignalRHubEnum.PRINT_EVENT_HUB);

    await SocketEmitWrapper(b.newConnection.send(signalREventListenerDictionary.JOIN_PRINT_EVENT_GROUP));

    b.addEventListener(
      signalREventListenerDictionary.GET_INK_USAGE,
      (signalRModel: SignalRDataModel) => {
        const updatedPrinterId = signalRModel.Data;
        if (updatedPrinterId === Id) {
          loadInkUsage();
        }
      }
    );

    b.addEventListener(
      signalREventListenerDictionary.GET_PRINT_STATISTIC,
      (signalRModel: SignalRDataModel) => {
        const updatedPrinterId = signalRModel.Data;
        if (updatedPrinterId === Id) {
          loadPrintStatistic();
        }
      }
    );

    b.addEventListener(
      signalREventListenerDictionary.GET_PRINT_EVENTS_LIST,
      (signalRModel: SignalRDataModel) => {
        const updatedPrinterId = signalRModel.Data;
        if (updatedPrinterId === Id) {
          loadPrinterEventsData();
        }
      }
    );
  };

  const asyncInit = async () => {
    const pdmAuthorized = await AuthorizationService.getInstance().checkApi();

    if (pdmAuthorized.status === 200) {
      if (!pdmAuthorized.data) {
        if (pdmAuthState !== pdmAuthorized.data) {
          dispatch(pdmUnauthorize());
        }
        history.push('/AuthorizePDM');
      }

      if (pdmAuthState !== pdmAuthorized.data) {
        dispatch(pdmAuthorize());
      }

      loadData();
      signalRInit();
      setEnterGroup(true);

      let sessionStorageFilter =
        FiltersSessionStorageService.GetSessionStorageFilter(SessionStorageFiltersEnum.SINGLE_PRINTER_PRINTED_JOBS_FILTER);

      if (sessionStorageFilter) {
        setFilter(sessionStorageFilter);
      }
    }
  };

  const loadData = () => {
    loadPrinterTicket();
    loadPrintStatistic();
    loadPrinterEventsData();
    loadInkUsage();
    loadPrintEventsStatistics();
  };

  const loadPrinterTicket = async () => {
    const [data, error] = await HttpRequestWithForbiddenCheck(PrinterTicketService.getInstance().getPrinterData(Id), 'getPrinterData', 'Error When Getting The Printer Data');
    if (!error && data !== null) {
      setPrinterTicket(data);
    }
  };

  const loadPrintEventsStatistics = async () => {
    const start = moment(dateRange.startDate).format('YYYY-MM-DD');
    const end = moment(dateRange.endDate).format('YYYY-MM-DD');
    const [data, error] = await HttpRequestWithForbiddenCheck(SinglePrinterService.getInstance().getPrintEventsStatistics(Id, start, end), 'getPrintEventsStatistics', 'Error When Getting The Print Event Statistics');
    if (!error && data !== null) {
      setPrintStatisticsData(data);
    }
  };

  const loadInkUsage = async () => {
    const start = moment(dateRange.startDate).format('YYYY-MM-DD');
    const end = moment(dateRange.endDate).format('YYYY-MM-DD');
    const [data, error] = await HttpRequestWithForbiddenCheck(SinglePrinterService.getInstance().getInkUsage(Id, start, end), 'getInkUsage', 'Error When Getting The Ink Usage');
    if (!error && data !== null) {
      setInkUsageData(data);
    }
  };

  const loadPrinterEventsData = async () => {
    const start = moment(dateRange.startDate).format('YYYY-MM-DD');
    const end = moment(dateRange.endDate).format('YYYY-MM-DD');
    const [data, error] = await HttpRequestWithForbiddenCheck(SinglePrinterService.getInstance().getPrintedJobsByRange(Id, start, end), 'getPrintedJobsByRange', 'Error When Getting The Printed Jobs By Range');
    if (!error && data !== null) {
      setPrintedJobs(data);
    }
  };

  const loadPrintStatistic = async () => {
    const start = moment(dateRange.startDate).format('YYYY-MM-DD');
    const end = moment(dateRange.endDate).format('YYYY-MM-DD');
    const [data, error] = await HttpRequestWithForbiddenCheck(SinglePrinterService.getInstance().getPrintEventStatus(Id, start, end), 'getPrintEventStatus', 'Error When Getting The Printed Print Event Status');
    if (!error && data !== null) {
      setPrintStatusData(data);
    }
  };

  const onSaveFilter = (filter: string) => {
    FiltersSessionStorageService.SetSessionStorageFilter(SessionStorageFiltersEnum.SINGLE_PRINTER_PRINTED_JOBS_FILTER, filter);
    setFilter(filter);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MuiCalender dateRange={dateRange} UpdateRange={(range) => setDateRange(range)} />
      </Grid>

      <Grid item xs={12}>
        <ol className="printer-header float-sm-right">
          <Breadcrumbs className="float-sm-right">
            <Typography>{t("general.labels.printerType")} : {printerTicket.PrinterType && printerTicket.PrinterType}</Typography>
            <Typography>{t("general.tables.printerId")} : {printerTicket.PrinterId && printerTicket.PrinterId}</Typography>
            <Typography>{t("general.labels.licenseDate")} : {printerTicket.LicenseValidDate && dateFormat(printerTicket.LicenseValidDate, "mmmm dS, yyyy")}</Typography>
            <Typography>{t("general.labels.status")} : {printerTicket.IsActivated && printerTicket.IsActivated ? "Active" : "Inactive"}</Typography>
          </Breadcrumbs>
        </ol>
      </Grid>

      <Grid item xs={12} lg={4}>
        <Card style={{ 'height': '100%' }} className="noBackgroundImage">
          <CardContent style={{ 'height': '100%' }}>
            <div className="Orbitron20">
              <h5>{t("general.cards.inkUsage")}</h5>
            </div>
            <Divider style={{ 'marginBottom': '15px' }} />
            <div className="chart-area">
              <InkUsage inkUsageData={inkUsageData} />
            </div>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} lg={4}>
        <Card style={{ 'height': '100%' }} className="noBackgroundImage">
          <CardContent style={{ 'height': '100%' }}>
            <PrintInfoStatistics PrintInfoStatisticsData={printStatisticsData} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} lg={4}>
        <Card style={{ 'height': '100%' }} className="noBackgroundImage">
          <CardContent>
            <PrintStatistics printStatisticsData={printStatusData} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <SearchableTable
          PrinterId={Id}
          printedJobs={printedJobs}
          Title={t("general.cards.printedJobs")}
          ShowTitle={true}
          filter={filter}
          onSaveFilter={onSaveFilter}
        />
      </Grid>
    </Grid>
  );
};

export default SinglePrinter;
