import React, { useState, useEffect } from "react";
import { InkUsage, SearchableTable, PrintStatistics, PrintersStatistics } from "../../../../../modules/ReportCenter/ReportCenterImports";
import { MuiCalender } from "../../../../../components/Inputs/InputsExport";
import DatesService from "../../../../../Helpers/DateService";
import { useTranslation } from "react-i18next";
import { Grid, Card, CardContent, Divider } from '@mui/material';
import { PrintEventDataModel } from "../../../../../Models/Api/Events/PrintEventDataModel";
import { InkUsageDataModel } from "../../../../../Models/Api/ReportCenter/InkUsageDataModel";
import { PrintEventStatusDataModel } from "../../../../../Models/Api/Events/PrintEventStatusDataModel";
import signalRBuilder from "../../../../../Api/Socket/signalRBuilder";
import SocketEmitWrapper from "../../../../../services/SocketEmitWrapper/SocketEmitWrapper";
import SignalRHubEnum from "../../../../../Models/SignalR/SignalRHubEnum";
import signalREventListenerDictionary from "../../../../../Models/SignalR/signalREventListenerDictionary";
//import { SignalRDataModel } from "../../../../../Models/SignalR/SignalRDataModel";
import HttpRequestWithForbiddenCheck from "../../../../../services/HttpRequestWithForbiddenCheck/HttpRequestWithForbiddenCheck";
//import { PrintEventStatusDataModelZod } from "../../../../../Models/Zod/Events/PrintEventStatusDataModelZod";
//import { InkUsageDataModelArrZod } from "../../../../../Models/Zod/ReportCenter/InkUsageDataModelZod";
//import { PrintEventDataModelArrZod } from "../../../../../Models/Zod/Events/PrintEventDataModel";
import { DateRange } from "materialui-daterange-picker";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../Store/Store";
import { pdmAuthorize, pdmUnauthorize } from "../../../../../Store/Actions/Actions";
import { useHistory } from "react-router-dom";
import { PrintersSquareMeterSummeryDataModel } from "../../../../../Models/Api/Events/PrintersSquareMeterSummeryDataModel";
import { AuthorizationService, AllPrintersService } from "../../../../../Api/Rest/PDMServer/ApiServices/PDMApiServicesImports";
import FiltersSessionStorageService, { SessionStorageFiltersEnum } from "../../../../../services/SessionStorage/Filters/FiltersSessionStorageService";

const AllPrinters = () => {
  const [t] = useTranslation();
  const [printedJobs, setPrintedJobs] = useState<PrintEventDataModel[]>([]);
  const [inkUsageData, setInkUsageData] = useState<InkUsageDataModel[]>([]);
  const [printersSquareMeter, setPrintersSquareMeter] = useState<PrintersSquareMeterSummeryDataModel[] | null>(null)
  const [printStatisticsData, setPrintStatisticsData] = useState<PrintEventStatusDataModel | null>(null);
  const dispatch = useDispatch();
  const pdmAuthState = useSelector((state: RootState) => state.PdmAuth);
  const history = useHistory();
  const [enterGroup, setEnterGroup] = useState(false);
  const [filter, setFilter] = useState<string>('');

  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: DatesService.getInstance().getStartDateAsDate(),
    endDate: DatesService.getInstance().getEndDateAsDate()
  });

  useEffect(() => {
    if (pdmAuthState) {
      loadData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  useEffect(() => {
    asyncInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // When leaving the page the return will emit and remove user from group
    return () => {
      if (enterGroup) leaveGroup()
    };
  }, [enterGroup])

  const asyncInit = async () => {
    const pdmAuthorized = await AuthorizationService.getInstance().checkApi();

    if (pdmAuthorized.status === 200) {
      if (!pdmAuthorized.data) {
        if (pdmAuthState !== pdmAuthorized.data) {
          dispatch(pdmUnauthorize());
        }
        history.push('/AuthorizePDM');
      }

      if (pdmAuthState !== pdmAuthorized.data) {
        dispatch(pdmAuthorize());
      }

      loadData();
      signalRInit();
      setEnterGroup(true);

      let sessionStorageFilter =
        FiltersSessionStorageService.GetSessionStorageFilter(SessionStorageFiltersEnum.REPORT_CENTER_ALL_PRINTERS_PRINTED_JOBS);

      if (sessionStorageFilter) {
        setFilter(sessionStorageFilter);
      }
    }
  };

  const leaveGroup = async () => {
    const b = await signalRBuilder.getInstance(SignalRHubEnum.PRINT_EVENT_HUB);

    await SocketEmitWrapper(b.newConnection.send(signalREventListenerDictionary.LEAVE_PRINT_EVENT_GROUP));
  };

  const signalRInit = async () => {
    const b = await signalRBuilder.getInstance(SignalRHubEnum.PRINT_EVENT_HUB);

    await SocketEmitWrapper(b.newConnection.send(signalREventListenerDictionary.JOIN_PRINT_EVENT_GROUP));

    b.addEventListener(
      signalREventListenerDictionary.GET_INK_USAGE,
      loadInkUsage
    );

    b.addEventListener(
      signalREventListenerDictionary.GET_PRINT_STATISTIC,
      loadPrintStatistic
    );

    b.addEventListener(
      signalREventListenerDictionary.GET_PRINT_EVENTS_LIST,
      loadPrinterEventsData
    );
  };

  const loadData = () => {
    loadPrintStatistic();
    loadPrinterEventsData();
    loadInkUsage();
    loadPrintersSquareMeters();
  };

  const loadPrintersSquareMeters = async () => {
    const start = moment(dateRange.startDate).format('YYYY-MM-DD');
    const end = moment(dateRange.endDate).format('YYYY-MM-DD');
    const [data, error] = await HttpRequestWithForbiddenCheck(AllPrintersService.getInstance().getPrintedSquareMetersByRange(start, end), 'getInkUsage', 'Error When Getting The Ink Usage');
    if (!error && data !== null) {
      setPrintersSquareMeter(data);
    }
  }

  const loadInkUsage = async () => {
    const start = moment(dateRange.startDate).format('YYYY-MM-DD');
    const end = moment(dateRange.endDate).format('YYYY-MM-DD');
    const [data, error] = await HttpRequestWithForbiddenCheck(AllPrintersService.getInstance().getInkUsage(start, end), 'getInkUsage', 'Error When Getting The Ink Usage');
    if (!error && data !== null) {
      setInkUsageData(data);
    }
  };

  const loadPrinterEventsData = async () => {
    const start = moment(dateRange.startDate).format('YYYY-MM-DD');
    const end = moment(dateRange.endDate).format('YYYY-MM-DD');
    const [data, error] = await HttpRequestWithForbiddenCheck(AllPrintersService.getInstance().getPrintedJobsByRange(start, end), 'getPrintedJobsByRange', 'Error When Getting The Printed Jobs By Range');
    if (!error && data !== null) {
      setPrintedJobs(data);
    }
  };

  const loadPrintStatistic = async () => {
    const start = moment(dateRange.startDate).format('YYYY-MM-DD');
    const end = moment(dateRange.endDate).format('YYYY-MM-DD');
    const [data, error] = await HttpRequestWithForbiddenCheck(AllPrintersService.getInstance().getPrintEventStatus(start, end), 'getPrintEventStatus', 'Error When Getting The Print Event Status');
    if (!error && data !== null) {
      setPrintStatisticsData(data);
    }
  };

  const onSaveFilter = (filter: string) => {
    FiltersSessionStorageService.SetSessionStorageFilter(SessionStorageFiltersEnum.REPORT_CENTER_ALL_PRINTERS_PRINTED_JOBS, filter);
    setFilter(filter);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MuiCalender dateRange={dateRange} UpdateRange={(range) => setDateRange(range)} />
      </Grid>
      <Grid item xs={12} lg={4}>
        <Card style={{ 'height': '100%' }} className="noBackgroundImage">
          <CardContent>
            <div className="Orbitron20">
              <h5>{t("general.cards.inkUsage")}</h5>
            </div>
            <Divider style={{ 'marginBottom': '15px' }} />
            <InkUsage inkUsageData={inkUsageData} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} lg={4}>
        <Card style={{ 'height': '100%' }} className="noBackgroundImage">
          <CardContent>
            <PrintersStatistics printerStatisticsData={printersSquareMeter} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} lg={4}>
        <Card style={{ 'height': '100%' }} className="noBackgroundImage">
          <CardContent>
            <PrintStatistics printStatisticsData={printStatisticsData} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} style={{ "paddingTop": "10px" }}>
        <SearchableTable
          printedJobs={printedJobs}
          Title={t("general.cards.printedJobs")}
          ShowTitle={true}
          filter={filter}
          onSaveFilter={onSaveFilter}
        />
      </Grid>

    </Grid>
  );
};

export default AllPrinters;
