import React from 'react'
import SvgIcon from "./SvgIcon";
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { MainButton } from '../../../components/General/GeneralExport';

function NotFound() {
    const [t] = useTranslation();
    const history = useHistory();

    return (
        <div className='not-found-wrapper'>
            <div className="wrapper not-found-child">
                <SvgIcon />
                <h1 className='text-center'>{t("general.labels.pageCouldNotBeFound")}</h1>
                <MainButton style={{ "left": "42%", "position": "relative" }} onClick={() => history.push("/")}>
                    {t("general.buttons.backToHome")}
                </MainButton>
            </div>
        </div>
    )
}

export default NotFound;
