import { AxiosRequestConfig, AxiosResponse } from "axios";

async function AwaitHandling<T>(request: Promise<AxiosResponse<T | null>>) {
  let res: [AxiosResponse<T | null>, any];
  try {
    const data = await request;
    res = [data, null];
    return res;
  } catch (err) {
    console.error(err);
    let e: AxiosRequestConfig = {

    };
    let empty: AxiosResponse<T | null> = {
      data: null,
      status: 0,
      statusText: '',
      headers: {},
      config: e,
    };
    res = [empty, err];
    return res;
  }
}

export default AwaitHandling;
