import { Grid, useTheme } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import roundTo from '../../../Pipes/RoundTo';
import { JobHistoryDataModel } from "../../../Models/Api/Job/JobHistoryDataModel";
import { useTranslation } from "react-i18next";
const humanizeDuration = require("humanize-duration");

type JobHistoryElementProps = {
  jobHistory: JobHistoryDataModel
};

const JobHistoryElement = ({ jobHistory }: JobHistoryElementProps) => {
  const [t] = useTranslation();
  const theme = useTheme();

  const GetPastOperation = (operation: string) => {
    let operationText = "";
    switch (operation.toLowerCase()) {
      case "create":
        operationText = t("general.operations.created")
        break;
      case "update":
        operationText = t("general.operations.updated")
        break;
      case "delete":
        operationText = t("general.operations.deleted")
        break;
      case "softdelete":
        operationText = t("general.operations.softDeleted")
        break;
      case "restore":
        operationText = t("general.operations.restored")
        break;
      default:
        operationText = "";
    }
    return operationText.toLowerCase();
  }

  if (jobHistory.EventType === 'Printing')
  {
    return (
      <div key={uuidv4()}>
        <div className="time-label" style={{ 'marginBottom': '10px' }}>
          <span className="history-date bg-primary font-weight-light">
            {jobHistory.CreateEventDateTime && moment.utc(jobHistory.CreateEventDateTime).local().format('DD/MM/YYYY')}
          </span>
        </div>
        <div>
          <i className="fas fa-sign-in-alt history-icon" style={{ 'color': 'black' }}></i>
          <div className="timeline-item mr-1 history-card" style={{
            'backgroundColor': theme.palette.mode === 'dark' ? '#121212' : 'white',
            'color': theme.palette.mode === 'dark' ? 'white' : 'black'
          }}>
            <Grid container className='history-header'>
              <Grid item xs={6}>
                <h3 className="timeline-header history-title">
                  Printing Job
                </h3>
              </Grid>
              <Grid item xs={6}>
                <span className="time history-time">
                  <i className="far fa-clock"></i>{" "}
                  {jobHistory.CreateEventDateTime && moment.utc(jobHistory.CreateEventDateTime).local().format('H:mm')}
                </span>
              </Grid>
            </Grid>
            <div className="timeline-body history-body">
              <p>
                {jobHistory.PrintStatus ? jobHistory.PrintStatus : 'Printed'} after {jobHistory.NetoPrintTime && humanizeDuration(jobHistory.NetoPrintTime, { round: true, })} on printer {jobHistory.PrinterId && jobHistory.PrinterId}.
              </p>
              {
                jobHistory.Job && jobHistory.Job.PrintInfo &&
                <div>
                  Job was printed with {jobHistory.Job.PrintInfo.PrintMode && jobHistory.Job.PrintInfo.PrintMode} print mode on a {jobHistory.Job.PrintInfo.GlassThickness && jobHistory.Job.PrintInfo.GlassThickness} mm glass over {jobHistory.Job.PrintInfo.PrintedSQM && (jobHistory.Job.PrintInfo.PrintedSQM >= 0.001 ? roundTo(jobHistory.Job.PrintInfo.PrintedSQM, 0) : roundTo(jobHistory.Job.PrintInfo.PrintedSQM, 3))} m<sup>2</sup>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
  else if (jobHistory.EventType === 'User')
  {

    return (
      <div key={uuidv4()}>
        <div className="time-label" style={{ 'marginBottom': '10px' }}>
          <span className="history-date bg-primary font-weight-light">
            {jobHistory.CreateEventDateTime && moment.utc(jobHistory.CreateEventDateTime).local().format('DD/MM/YYYY')}
          </span>
        </div>
        <div>
          <i className="fas fa-sign-in-alt history-icon" style={{ 'color': 'black' }}></i>
          <div className="timeline-item mr-1 history-card" style={{
            'backgroundColor': theme.palette.mode === 'dark' ? '#121212' : 'white',
            'color': theme.palette.mode === 'dark' ? 'white' : 'black'
          }}>
            <Grid container className='history-header'>
              <Grid item xs={6}>
                <h3 className="timeline-header history-title">
                  Job was {GetPastOperation(jobHistory.Operation)}
                </h3>
              </Grid>
              <Grid item xs={6}>
                <span className="time history-time">
                  <i className="far fa-clock"></i>{" "}
                  {jobHistory.CreateEventDateTime && moment.utc(jobHistory.CreateEventDateTime).local().format('H:mm')}
                </span>
              </Grid>
            </Grid>
            <div className="timeline-body history-body">
              <div>
                Job was {GetPastOperation(jobHistory.Operation)} by {jobHistory.User.UserName}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  else//Any Other EventType is not supported
  {
    return (
      <div key={uuidv4()}>
        <div className="time-label" style={{ 'marginBottom': '10px' }}>
          <span className="history-date bg-primary font-weight-light">
            {jobHistory.CreateEventDateTime && moment.utc(jobHistory.CreateEventDateTime).local().format('DD/MM/YYYY')}
          </span>
        </div>
        <div>
          <i className="fas fa-sign-in-alt history-icon" style={{ 'color': 'black' }}></i>
          <div className="timeline-item mr-1 history-card" style={{
            'backgroundColor': theme.palette.mode === 'dark' ? '#121212' : 'white',
            'color': theme.palette.mode === 'dark' ? 'white' : 'black'
          }}>
            <Grid container className='history-header'>
              <Grid item xs={6}>
                <h3 className="timeline-header history-title">
                  {/* empty */}
                </h3>
              </Grid>
              <Grid item xs={6}>
                <span className="time history-time">
                  <i className="far fa-clock"></i>{" "}
                  {jobHistory.CreateEventDateTime && moment.utc(jobHistory.CreateEventDateTime).local().format('H:mm')}
                </span>
              </Grid>
            </Grid>
            <div className="timeline-body history-body">
              <div>
                {/* empty */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default JobHistoryElement;
